
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal, Button, ModalHeader, ModalBody, Label, 
  Input
} from "reactstrap";
import Select from "react-select";
import { 
  AvForm, 
  AvField, 
  AvCheckboxGroup, 
  AvCheckbox 
} from "availity-reactstrap-validation";
import Loader from "components/Common/Loader";
import AvFieldSelect from "components/Common/AvFieldSelect";
import validatePositiveInputs from "helpers/validatePositiveInputs";

import { fetchServerStart, updateAccountType } from "store/actions";

const PLATFORMS = ["MT5", "MT4"];
const TYPES = ["LIVE", "DEMO", "IB", "MAM", "INVESTOR"];
const LEVERAGE = [
  1, 25, 50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 2000,
];
const CURRENCIES = ["USD", "EUR", "GBP", "EGP"];

function EditAccountGroupType({ isOpen, onClose, selectedAccountType, t }) {
  // Changed props names
  const dispatch = useDispatch();
  const { updating, error } = useSelector(
    (state) => state.tradingAccountReducer
  );

  // State management
  const [leverageOptions, setLeverageOptions] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [type, setType] = useState("");
  const [platform, setPlatform] = useState("");
  const [servers, setServers] = useState([]);
  const [filteredServers, setFilteredServers] = useState([]);
  const [currentSelectedServer, setCurrentSelectedServer] = useState(null);
  const [serverError, setServerError] = useState(false);


  const [isDefault, setIsDefault] = useState(false);
  console.log(selectedAccountType);

  const allServersFromStore = useSelector(
    (state) => state?.MTServer?.servers?.docs
  );

  const handleCheckboxChange = (event) => {
    setIsDefault(event.target.checked); // Convert the value to a boolean
  };

  // Reset states when modal opens
  useEffect(() => {
    if (isOpen && selectedAccountType) {
      setType(selectedAccountType.type || "");
      setPlatform(selectedAccountType.platform || "");
      setCurrentSelectedServer(selectedAccountType.serverId || null);
      setCurrencies(
        selectedAccountType.currencies?.map((currency) => currency.currency)
      );
      setLeverageOptions(selectedAccountType.leverages || []);
      setIsDefault(selectedAccountType?.isDefault);
    }
  }, [isOpen, selectedAccountType]);

  // Initial server fetch
  useEffect(() => {
    if (isOpen) {
      dispatch(fetchServerStart());
    }
  }, [dispatch, isOpen]);

  // Update servers when store changes
  useEffect(() => {
    if (allServersFromStore?.length > 0) {
      setServers(allServersFromStore);
    }
  }, [allServersFromStore]);

  // Filter servers based on type and platform
  useEffect(() => {
    if (type && platform && servers.length > 0) {
      const newFilteredServers = servers
        .filter(
          (item) =>
            item?.platform === platform &&
            item?.type === type &&
            item?._id &&
            item?.name
        )
        .map((item) => ({
          value: item._id,
          label: item.name,
        }));

      setFilteredServers(newFilteredServers);

      // Reset selected server if it's no longer in filtered options
      if (
        !newFilteredServers.some(
          (server) => server.value === currentSelectedServer
        )
      ) {
        setCurrentSelectedServer(null);
        setServerError(true);
      }
    } else {
      setFilteredServers([]);
    }
  }, [type, platform, servers, currentSelectedServer]);

  // Handle successful update
  useEffect(() => {
    if (!updating && !error && isOpen) {
      onClose();
    }
  }, [updating]);

  const handleSubmit = (e, v) => {
  

    const formData = {
      ...v,
      isDefault: isDefault,
      serverId: currentSelectedServer
    };

    dispatch(updateAccountType(selectedAccountType._id, formData));
  };

  const isMamPam = ["MAM", "INVESTOR"].includes(type);

  return (
    <Modal isOpen={isOpen} toggle={onClose} centered={true}>
      <ModalHeader toggle={onClose}>Edit Account Type</ModalHeader>
      <ModalBody>
        <AvForm className="px-4" onValidSubmit={handleSubmit}>
          <AvField
            name="title"
            label="Title"
            placeholder="Enter Title"
            type="text"
            value={selectedAccountType?.title}
            validate={{
              required: {
                value: true,
                errorMessage: "Title is required" 
              },
              pattern: {
                value: "^[A-Za-z0-9 ]+$",
                errorMessage:
                  "Title must contain only letters, numbers and spaces",
              },
            }}
          />
          <div className="mb-3">
            <Label>{t("Server")}</Label>
            <Select
              isClearable={true}
              options={filteredServers}
              value={filteredServers.find(
                (server) => server.value === currentSelectedServer
              )}
              onChange={(option) => {
                setCurrentSelectedServer(option ? option.value : null);
                setServerError(!option);
              }}
              placeholder={t("Choose a Server")}
              isDisabled={!type || !platform}
            />
            {serverError && (
              <div className="text-danger mt-2">
                {t("Server selection is required")}
              </div>
            )}
          </div>

          <AvFieldSelect
            name="leverages"
            label="Leverage Options"
            placeholder="Select Leverage Options"
            ismulti={true}
            value={leverageOptions}
            options={LEVERAGE.map((l) => ({
              value: l,
              label: l 
            }))}
            onChange={(values) => setLeverageOptions(values)}
            validate={{
              required: {
                value: true,
                errorMessage: "Leverage options are required",
              },
            }}
          />

          <div className="mt-3">
            <label>
              <Input
                className="me-2"
                type="checkbox"
                name="isDefault"
                value={isDefault}
                onChange={() => setIsDefault(!isDefault)}
                checked={isDefault}
              />
              {"Use Default Auto"}
            </label>
          </div>

          <div className="text-center mt-4">
            {updating ? (
              <Loader />
            ) : (
              <Button
                type="submit"
                color="primary"
                disabled={updating}
              >
                Save Changes
              </Button>
            )}
          </div>
        </AvForm>
      </ModalBody>
    </Modal>
  );
}

export default EditAccountGroupType;