import React, { useState, useEffect } from "react";
import {
  useDispatch, useSelector
} from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Row,
  Col,
  UncontrolledAlert,
  Card,
  CardBody,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  NavLink,
} from "reactstrap";
import {
  checkingTransferIbStart,
  fetchClientsStart,
  transferIbStart
} from "store/client/actions";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import { fetchAgreement, fetchAgreements } from "store/actions";
import AgreementDetails from "pages/ClientDetail/Partnership/AgreementDetails";
import SearchableClientIbSelect from "components/Common/SearchableClientIbSelect";
import CustomSelect from "components/Common/CustomSelect";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import classNames from "classnames";
import MT5 from "./MT5";
import MT4 from "./MT4";
import SearchableAgentDropdown from "components/Common/SearchableAgentDropdown";
import SearchableClientIbSelectTransfer from "components/Common/SearchableClientIbSelectTransfer";


function TransferToIB({ clientId, isLead, t }) {
  const {
    clientDetails,
  } = useSelector(state => state.clientReducer);
  const tiers = [
    {
      label: "MIB",
      value: "0"
    },
    {
      label: "SUIB1",
      value: 1
    },
    {
      label: "SUIB2",
      value: 2
    },
    {
      label: "SUIB3",
      value: 3
    },
    {
      label: "SUIB4",
      value: 4
    },
    {
      label: "SUIB5",
      value: 5
    },
    {
      label: "SUIB6",
      value: 6
    },
    {
      label: "SUIB7",
      value: 7
    },
    {
      label: "SUIB8",
      value: 8
    },
    {
      label: "SUIB9",
      value: 9
    },
    {
      label: "SUIB10",
      value: 10
    }
  ];
  const agentTypes = [
    {
      label: clientDetails.parentId?.level !== 0 
        ? `${clientDetails?.parentId?.firstName} ${clientDetails?.parentId?.lastName}` 
        : "Master",
      value: clientDetails?.parentId?._id || ""
    }
  ];
  const dispatch = useDispatch();
  const { transfering, checkTransferingPossibleStatus, checkTransfering } = useSelector((state) => state.clientReducer);
  // const { agreements } = useSelector((state) => state.ibAgreements);
  const { accountTypes } = useSelector((state) => state.tradingAccountReducer);

  const [selectedClientId, setSelectedClientId] = useState("");
  const [selectedTier, setSelectedTier] = useState("0");
  const [agentType, setAgentType] = useState("");

  const [selectedAgreement, setSelectedAgreement] = useState("");
  const [linkClientModal, setLinkClientModal] = useState(false);
  const { products, agreements, agreement, deleting, clearingCounter } = useSelector((state) => state.ibAgreements);
  const [platforms, setPlatForms] = useState([]);
  const [aggrementValues, setAggrementValues] = useState([]);
  const [productsValues, setProductsValues] = useState({});
  const [clientAggrement, setClientAggrement] = useState({});
  const [clientAggrementValues, setClientAggrementValues] = useState([]);
  const [clientProductsValues, setClientProductsValues] = useState({});
  function handleChangeProductValue(value, product) {
    let modifiedPorducts = {
      ...clientProductsValues
    };
    modifiedPorducts[product] = value;
    setClientProductsValues(modifiedPorducts);
  }
  useEffect(() => {
    if (agreement) {
      const platformsSet = new Set();
      const values = [];
      const products = {};
      agreement?.members?.forEach((member) => {
        member?.values?.forEach((value) => {
          Object.entries(value?.products)?.forEach((product) => {
            products[`${product?.[0]}`] = [
              ...(products[`${product?.[0]}`] || []),
              product?.[1]?.rebate,
            ];
          });
          values?.push(value);
          platformsSet.add(value?.accountTypeId?.platform?.toLowerCase());
        });
      });
      setAggrementValues([...values]);
      setPlatForms([...platformsSet]);
      setProductsValues({ ...products });
    }
  }, [agreement]);

  useEffect(() => {
    if (clientAggrement) {
      const platformsSet = new Set();
      const values = [];
      const products = {};
      // clientAggrement?.members?.forEach((member) => {
      clientAggrement?.members?.[clientAggrement?.members?.length - 1]?.values?.forEach((value) => {
        Object.entries(value?.products)?.forEach((product) => {
          products[`${product?.[0]}`] = product?.[1]?.rebate;
        });
        values?.push(value);
        platformsSet.add(value?.accountTypeId?.platform?.toLowerCase());
      });
      // });
      setClientAggrementValues([...values]);
      // setPlatForms([...platformsSet]);
      setClientProductsValues({ ...products });
    }
  }, [agreement]);

  useEffect(() => {
    if (clientAggrementValues && selectedTier !== "0") {
      const values = [...aggrementValues];
      clientAggrementValues?.forEach((value) => {
        values?.push(value);
      });
      setAggrementValues([...values]);
    }
  }, [clientAggrementValues]);

  useEffect(() => {
    linkClientModal && dispatch(fetchClientsStart({ limit: 100 }));
  }, [linkClientModal]);

  useEffect(() => {
    if (clientDetails && agreements) {
      setClientAggrement(agreements?.find(agg=> agg?._id === clientDetails?.fx?.agrementId ));
    }
  }, [agreements, clientDetails]);


  useEffect(() => {
    console.log("selectedClientId", selectedClientId);
    if (selectedTier && selectedClientId?._id) {
      checkPossibleTransferFun();
    }
  }, [selectedTier, selectedClientId]);

  useEffect(() => {
    if (selectedTier) {
      setSelectedClientId("");
    }
  }, [selectedTier]);

  useEffect(() => {
    setSelectedAgreement("");
    if (selectedClientId?._id){
      dispatch(fetchAgreement({ agrementId: selectedClientId?.fx?.agrementId }));
    }
  }, [selectedClientId?._id]);

  useEffect(() => {
    if (Number(selectedTier) === 0){
      dispatch(fetchAgreement({ agrementId: clientDetails?.fx?.agrementId }));
    }
  }, [selectedTier]);

  useEffect(() => {
    dispatch(fetchAgreements({ customerId: clientDetails?._id }));
  }, []);

  const toggleModal = () => {
    setLinkClientModal(!linkClientModal);
  };

  useEffect(() => {
    if (transfering && linkClientModal) {
      setLinkClientModal(false);
    }
  }, [transfering]);

  const toggle = () =>{
    setSelectedAgreement("");
    setSelectedTier("");
    setSelectedClientId("");
    toggleModal();
  };

  function checkPossibleTransferFun() {
    let obj = {
      newParentId: selectedClientId?._id || selectedClientId,
      clientId,
      newLevel: selectedTier,
    };
    dispatch(checkingTransferIbStart(obj));
  }

  const { profileMetaInfo = {} } = useSelector((state) => state.Profile);
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const tab = query.get("tab");
  const tabToggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      query.set("tab", tab);
    }
  };
  const loadTabs = ({
    transactions: { depositDetails: {  forex } = {} } = {},
  }) => [
    {
      tabId: "mt5",
      navLinkName: "MT5",
      component: <MT5 selectedPromoLink={clientDetails.promoLink} />,
    },
    {
      tabId: "mt4",
      navLinkName: "MT4",
      component: <MT4 selectedPromoLink={clientDetails.promoLink} />,
    },
  ];

  const tabs = loadTabs(profileMetaInfo).filter((tab) => !tab.hidden);
  const [activeTab, setActiveTab] = useState("1");
  useEffect(() => {
    if (tabs.length > 0) {
      setActiveTab(tabs[0].tabId);
    }
    if (tab) {
      setActiveTab(tab);
    }
  }, [profileMetaInfo]);

  const handleChangeAmount = (maxAmount, product) => (value, ctx, input, cb) => {
    // Remove any pre-existing min/max validations dynamically
    delete input.validations?.min;
    delete input.validations?.max;
    
    const valueP = clientProductsValues?.[product];
    const enteredAmount = parseFloat(valueP);
    // const maxAllowed = clientProductsValues?.[product?.[0]] || Infinity;
    const maxAllowed = maxAmount || Infinity; // Use a context value or a fallback
    const minAllowed = ctx?.minAmount || 0;       // Use a context value or a fallback
    // Validation logic
    if (!valueP || isNaN(enteredAmount)) {
      // Check if the value is empty or not a valid number
      cb("Amount is required and must be a number");
    } else if (enteredAmount < minAllowed) {
      // Ensure the value is not below the minimum allowed
      cb(`Amount must be at least ${minAllowed}`);
    } else if (enteredAmount > maxAllowed) {
      // Ensure the value is not above the maximum allowed
      cb(`Amount must be less than or equal to ${maxAllowed}`);
    } else {
      // Validation passed
      cb(true);
    }
  };
  

  return (
    <React.Fragment >
      <button
        type="button"
        disabled={isLead}
        className="btn btn-primary waves-effect waves-light w-100 me-1"
        onClick={toggle}
      >
        {t("Transfer To Another IB")}
      </button>
      <Modal isOpen={linkClientModal} toggle={toggle} centered={true} size="lg">
        <ModalHeader toggle={toggle} tag="h4">
          {t("Transfer To Another IB")}
        </ModalHeader>
        <ModalBody >
          <AvForm
            className='p-4'
            onValidSubmit={(e, v) => {
              if (checkTransfering) {
                let obj = {
                  newParentId: selectedClientId?._id || selectedClientId,
                  clientId,
                  newLevel: selectedTier,
                  clientProductsValues: JSON.stringify(clientProductsValues),
                  // agrementId: selectedAgreement?._id
                };
                dispatch(transferIbStart(obj));
              }
            }}
          >

            <Row className="mb-3">
              <Row>
                <Col className="mb-2" md="6">
                  <Label>{t("Agent Type")}</Label>
                  <div>
                    <CustomSelect
                      isRequired
                      placeholder={t("Select Agent Type")}
                      onChange={(e) => {
                        setAgentType(e?.value);
                      }}
                      isSearchable={false}
                      options={agentTypes?.map((agr) => (
                        {
                          label: agr.label,
                          value: agr.value
                        }
                      ))}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                </Col>
              </Row>
              <Row>

                <Col className="mb-2" md="6">
                  <Label>{t("Tier")}</Label>
                  <div>
                    <CustomSelect
                      isRequired
                      placeholder={t("Select Tier")}
                      onChange={(e) => {
                        setSelectedTier(e?.value);
                      }}
                      isSearchable={true}
                      options={tiers?.map((agr) => (
                        {
                          label: agr.label,
                          value: agr.value
                        }
                      ))}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                </Col>
                {
                  selectedTier != 0 ? 
                    <Col md="6">
                      <SearchableClientIbSelectTransfer
                        name="selectedClientId"
                        label="IBs"
                        level={selectedTier}
                        type="IB"
                        isRequired
                        value={selectedClientId}
                        disallowed={clientId}
                        onChange={(e) => setSelectedClientId(e?.value)}
                      />
                    </Col>
                    :
                    <Col>
                      <SearchableAgentDropdown
                        isRequired={true}
                        // clientData={clientDetails || null}
                        title="Agent"
                        value={selectedClientId}
                        onChange={(e) => setSelectedClientId(e?.value)}
                      />
                    </Col>
                }
              </Row>
              <Row>
                <Col className="col-12">
                  <Card>
                    <CardBody>
                      <Nav tabs>
                        {platforms?.map((tabItem) => (
                          <NavItem key={tabItem}>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classNames({
                                active: activeTab === tabItem,
                              })}
                              onClick={() => {
                                tabToggle(tabItem);
                              }}
                            >
                              {tabItem}
                            </NavLink>
                          </NavItem>
                        ))}
                      </Nav>
                      <TabContent activeTab={activeTab} className="p-3 text-muted">
                        {tabs.map((tabItem) => (
                          <>
                            <TabPane tabId={tabItem.tabId}>
                              <Row>
                                <Col sm="12">
                                  <div className="row my-3">
                                    {aggrementValues?.map((value) => (
                                      Object?.entries(value?.products)?.map((product) => (
                                        <>
                                          <div className="col-md-6 mt-3" key={product._id}>
                                            <AvField
                                              name={`amountVal${value?.group}:${product?.[0]}`}
                                              label={`${value?.group}:${product?.[0]}`}
                                              placeholder={t("Enter value")}
                                              type="number"
                                              defaultValue={Number(clientProductsValues?.[product?.[0]] || 0)}
                                              value={Number(clientProductsValues?.[product?.[0]] || 0)}
                                              errorMessage={t("Enter valid value")}
                                              onChange={(e) => handleChangeProductValue(e.target.value, product?.[0])}
                                              // validate={{ required: { value: true } }}
                                              validate={{
                                                // required: { value: true, },
                                                custom: handleChangeAmount(productsValues?.[`${product?.[0]}`]?.[productsValues?.[`${product?.[0]}`]?.length - 1], product?.[0]),
                                                // minAmount: 0,
                                                // maxAmount: (clientProductsValues?.[`${product?.[0]}`] || 0),
                                              }}
                                            />
                                            <p className="text-success"> 
                                              {productsValues?.[`${product?.[0]}`]?.map((rebate, index) => index !== productsValues?.[`${product?.[0]}`]?.length - 1 ? `${rebate} |` : "")}
                                              {Number(productsValues?.[`${product?.[0]}`]?.at(-1) || 0) - Number(clientProductsValues?.[`${product?.[0]}`] || 0) } |
                                              {(clientProductsValues?.[`${product?.[0]}`] || 0) }
                                            </p>
                                          </div>
                                        </>
                                      ))
                                    ))}
                                  </div>
                                </Col>
                              </Row>
                            </TabPane>
                          </>
                        ))}
                      </TabContent>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              
              {/* {console.log("🚀 ~ TransferToIB ~ selectedClientId:", selectedClientId)}
              {console.log("🚀 ~ TransferToIB ~ agreements:", agreements)}
              {
                selectedClientId && agreements && agreements.length > 0 && <Col className="mt-2" md="12">
                  <Label>{t("Agreements")}</Label>
                  <div>
                    <CustomSelect
                      isRequired
                      placeholder={t("Select agreement")}
                      onChange={(e) => {
                        setSelectedAgreement(e.value);
                      }}
                      isSearchable={true}
                      options={agreements?.map((agr) => (
                        {
                          label: agr.title,
                          value: agr
                        }
                      ))}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                </Col>
              }
              {selectedAgreement && <Col className="mt-3" md="12">
                <AgreementDetails agreement={selectedAgreement} accountTypes={accountTypes} />
              </Col>} */}
            </Row>
            <div className='text-center pt-3 p-2'>
              <Button type="submit" disabled={!checkTransferingPossibleStatus} color="primary" className=""
              >
                {t("Transfer")}
              </Button>
            </div>
          </AvForm>
          {(!checkTransferingPossibleStatus && checkTransfering) && <UncontrolledAlert color="danger">
            <i className="mdi mdi-block-helper me-2"></i>
            It is not possible to transfer to this ib
          </UncontrolledAlert>}
        </ModalBody>
      </Modal>
    </React.Fragment >
  );
}

export default withTranslation()(TransferToIB);