import {
  FETCH_SYMBOLS_REQUESTED,
  FETCH_SYMBOLS_SUCCESS,
  FETCH_SYMBOLS_FAIL,

  SYNC_SYMBOL_REQUESTED,
  SYNC_SYMBOL_SUCCESS,
  SYNC_SYMBOL_FAIL,

  ADD_INTERNAL_TRANSFER_REQUESTED,
  ADD_INTERNAL_TRANSFER_SUCCESS,
  ADD_INTERNAL_TRANSFER_FAIL,
  ADD_INTERNAL_TRANSFER_CLEAR,
  ADD_INTERNAL_TRANSFER_ERROR_CLEAR,
  APPROVE_INTERNAL_TRANSFER,
  REJECT_INTERNAL_TRANSFER,
  CHANGE_SYMBOL_START,
  CHANGE_SYMBOL_END
} from "./actionTypes";


// fetch internal transfers
export const fetchSymbols = (params = {}) => {
  return {
    type: FETCH_SYMBOLS_REQUESTED,
    payload: params
  };
};
export const fetchSymbolsSuccess = (data) => {
  return {
    type: FETCH_SYMBOLS_SUCCESS,
    payload: data
  };
};
export const fetchSymbolsFail = (error) => {
  return {
    type: FETCH_SYMBOLS_FAIL,
    payload: { error }
  };
};

export const changeSymbolStart = (params = {}) => {
  return {
    type: CHANGE_SYMBOL_START,
    payload: params
  };
};

export const changeSymbolEnd = (data) => {
  return {
    type: CHANGE_SYMBOL_END,
    payload: data
  };
};
// add internal transfer
export const syncSymbolRequest = (params = {}) => {
  return {
    type: SYNC_SYMBOL_REQUESTED,
    payload: params
  };
};
export const syncSymbolRequestsuccess = (data) => {
  return {
    type: SYNC_SYMBOL_SUCCESS,
    payload: data
  };
};
export const syncSymbolRequestFail = (error) => {
  return {
    type: SYNC_SYMBOL_FAIL,
    payload: { error }
  };
};

// add internal transfer
export const addInternalTransfer = (params = {}) => {
  return {
    type: ADD_INTERNAL_TRANSFER_REQUESTED,
    payload: params
  };
};
export const addInternalTransferFail = (error) => {
  return {
    type: ADD_INTERNAL_TRANSFER_FAIL,
    payload: { error }
  };
};
export const addInternalTransferClear = (data) => {
  return {
    type: ADD_INTERNAL_TRANSFER_CLEAR,
    payload: data
  };
};
export const addInternalTransferErrorClear = () => {
  return {
    type: ADD_INTERNAL_TRANSFER_ERROR_CLEAR
  };
};

export const approveInternalTransfer = (payload) =>{
  return {
    type: APPROVE_INTERNAL_TRANSFER,
    payload: payload
  };
};

export const rejectInternalTransfer = (payload) =>{
  return {
    type: REJECT_INTERNAL_TRANSFER,
    payload: payload
  };
};