import * as axiosHelper from "./api_helper";
import qs from "qs";
const FundingEndpoints = {
  fundingDepartmentInitial: "funding-initial-step",
  riskDepartmentReview: "risk-review-step",
  fundingDepartmentDeduction: "funding-deduction-step",
  withdrawalRemittance: "withdrawal-remittance-step",
};
// fetch forex withdrawals
export const getForexWithdrawals = async ({ payload }) => {
  const result = await axiosHelper.get(`/fxtransactions/withdrawals?${qs.stringify(payload)}`);
  if (!result.status){
    throw new Error(result.message);
  }

  return result;
};

// download transaction pdf
export const createTransactionPdf = async (id) => {
  const result = await axiosHelper.get(`/fxtransactions/download-details/${id}`);
  if (!result.status){
    throw new Error(result.message);
  }

  return result;
};

// add forex withdrawal
export const postForexWithdrawal = async ({ payload }) => {
  const result = await axiosHelper.post("/fxtransactions/withdrawals", payload);
  if (!result.status){
    throw new Error(result.message);
  }

  return result;
};

export const approveForexWithdrawal = async (payload) => {
  const result = await axiosHelper.patch(`/fxtransactions/withdraw/${payload.id}/approve-${FundingEndpoints?.[payload?.fundingStep]}`, { ...payload });
  if (!result.status){
    throw new Error(result.message);
  }

  return result;
};

export const rejectForexWithdrawal = async (payload)=>{
  const result = await axiosHelper.patch(`/fxtransactions/withdraw/${payload.id}/reject-${FundingEndpoints?.[payload?.fundingStep]}`, { ...payload });
  if (!result.status){
    throw new Error(result.message);
  }

  return result;
};

export const onHoldForexWithdrawal = async (payload)=>{
  const result = await axiosHelper.patch(`/fxtransactions/withdraw/${payload.id}/on-hold-${FundingEndpoints?.[payload?.fundingStep]}`, { ...payload });
  if (!result.status){
    throw new Error(result.message);
  }

  return result;
};

export const returnForexWithdrawal = async (payload)=>{
  const result = await axiosHelper.patch(`/fxtransactions/withdraw/${payload.id}/return-withdrawal-remittance-step`, { ...payload });
  if (!result.status){
    throw new Error(result.message);
  }

  return result;
};

export const calculateWithdrawalFreeMargin = async (payload)=>{
  const result = await axiosHelper.post("/fxtransactions/calculate-free-margin", { ...payload });
  if (!result.status){
    throw new Error(result.message);
  }

  return result;
};