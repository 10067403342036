import { DELETE_TODO_END, DELETE_TODO_START } from "store/todos/actionTypes";
import { APPROVE_FOREX_DEPOSIT_SUCCESS } from "../deposits/actionTypes";
import {
  FETCH_REBATE_RULES_REQUESTED,
  FETCH_REBATE_RULES_SUCCESS,
  FETCH_REBATE_RULES_FAIL,

  
  ADD_REBATE_RULE_REQUESTED,
  ADD_REBATE_RULE_SUCCESS,
  ADD_REBATE_RULE_FAIL,
  ADD_REBATE_RULE_CLEAR,
  ADD_REBATE_RULE_ERROR_CLEAR,
  APPROVE_INTERNAL_TRANSFER,
  APPROVE_INTERNAL_TRANSFER_SUCCESS,
  REJECT_INTERNAL_TRANSFER,
  REJECT_INTERNAL_TRANSFER_SUCCESS,
  DELETE_REBATE_RULE_START,
  DELETE_REBATE_RULE_END,
  EDIT_REBATE_RULES_START,
  EDIT_REBATE_RULES_DONE,
  EDIT_REBATE_RULES_CLEAR,
  CHANGE_REBATE_RULES_START,
  CHANGE_REBATE_RULES_END
} from "./actionTypes";

const initalState = {
  rebateRules: [],
  fetchRebateRulesLoading: false,
  addRebateRulesLoading: false,
  editLoading: false,
  editSuccess: false,
  disableAddButton: false,
  showAddSuccessMessage: false,
  showEditSuccessMessage: false,
  error: "",
  modalClear: false,
  rebateRulesResponseMessage: "",
  updating: false,
  updatingClearCounter: 0,
  deletingClearCounter: 0,
  editClearingCounter: 0,
  addRebateRulesClearingCounter: 0
};

const RebateRulesReducer = (state = initalState, action) => {
  switch (action.type){
    // fetch internal transfers
    case FETCH_REBATE_RULES_REQUESTED:
      state = {
        ...state,
        fetchRebateRulesLoading: true
      };
      break;
    case FETCH_REBATE_RULES_SUCCESS:
      state = {
        ...state,
        rebateRules: [...action.payload.result.docs],
        rebateRulesTotalDocs: action.payload.result.totalDocs,
        hasNextPage: action.payload.result.hasNextPage,
        hasPrevPage: action.payload.result.hasPrevPage,
        limit: action.payload.result.limit,
        nextPage: action.payload.result.nextPage,
        page: action.payload.result.page,
        pagingCounter: action.payload.result.pagingCounter,
        prevPage: action.payload.result.prevPage,
        totalPages: action.payload.result.totalPages,
        fetchRebateRulesLoading: false,  
        totalDocs: action.payload.result.totalDocs
      };
      break;
    case FETCH_REBATE_RULES_FAIL:
      state = {
        ...state,
        fetchRebateRulesLoading: false,
        rebateRulesError: action.payload
      };
      break;

    case CHANGE_REBATE_RULES_START:
      return {
        ...state,
        updating: true,
        editSuccess: false,
      };
    case CHANGE_REBATE_RULES_END:
      return {
        ...state,
        editSuccess: true,
        updating: false,
        updatingClearCounter: state.updatingClearCounter + 1,
      };
      // Sync Symbols
      // case SYNC_SYMBOL_REQUESTED:
      //   state = {
      //     ...state,
      //     addRebateRulesLoading: true,
      //     disableAddButton: true,
      //   };
      //   break;
      // case SYNC_SYMBOL_SUCCESS:
      //   state = {
      //     ...state,
      //     newSymbol: action.payload.result,
      //     showAddSuccessMessage: true,
      //     addSuccess: true,
      //     addRebateRulesFail: false,
      //     disableAddButton: false,
      //   };
      //   break;
      // case SYNC_SYMBOL_FAIL:
      //   state = {
      //     ...state,
      //     addSuccess: false,
      //     disableAddButton: false,
      //     addRebateRulesFail: true,
      //     addRebateRulesLoading: false,
      //     addRebateRulesFailDetails: action.payload.error
      //   };
      //   break;

    // add REBATE_RULE
    case ADD_REBATE_RULE_REQUESTED:
      state = {
        ...state,
        addRebateRulesLoading: true,
        disableAddButton: true,
      };
      break;
    case ADD_REBATE_RULE_SUCCESS:
      state = {
        ...state,
        newRebateRules: action.payload.result,
        disableAddButton: false,
        addSuccess: true,
        addRebateRulesFail: false
      };
      break;
    case ADD_REBATE_RULE_FAIL:
      state = {
        ...state,
        addSuccess: false,
        disableAddButton: false,
        addRebateRulesFail: true,
        addRebateRulesLoading: false,
        addRebateRulesFailDetails: action.payload.error
      };
      break;
    case ADD_REBATE_RULE_CLEAR:
      state = {
        ...state,
        addRebateRulesLoading: false,
        addRebateRulesClearingCounter: state.addRebateRulesClearingCounter + 1,
        addRebateRulesFail: false,
        addSuccess: false,
        disableAddButton: false,
        modalClear: true
      };
      break;
    case ADD_REBATE_RULE_ERROR_CLEAR:
      state = {
        ...state,
        addRebateRulesLoading: false,
        addRebateRulesFail: false,
        disableAddButton: false,
        addRebateRulesFailDetails: null
      };
      break;

    case DELETE_REBATE_RULE_START:
      return {
        ...state,
        deleting: true,
      };
    case DELETE_REBATE_RULE_END:
      return {
        ...state,
        deleting: false,
        deletingClearCounter: state.deletingClearCounter + 1,
      };
      
    case EDIT_REBATE_RULES_START:
      state = {
        ...state,
        editSuccess: false,
        editLoading: true,
        disableAddButton: true,
        showEditSuccessMessage: false,
      };
      break;
    case EDIT_REBATE_RULES_DONE:
      // eslint-disable-next-line no-case-declarations
      const { id, ...payload } = action.payload;
      state = {
        ...state,
        // docs: state.docs.map(obj => {
        //   if (obj._id === id) {
        //     return {
        //       ...obj,
        //       title: payload.result.title,
        //       permissions: payload.result.permissions,
        //     };
        //   } else {
        //     return obj;
        //   }
        // }),
        disableAddButton: false,
        showEditSuccessMessage: true,
        editSuccess: true,
        editLoading: false,
        editResult: action.payload.result,
        editError: action.payload.error,
      };
      break;
    case EDIT_REBATE_RULES_CLEAR:
      state = {
        ...state,
        disableAddButton: false,
        showEditSuccessMessage: false,
        editSuccess: false,
        editLoading: false,
        editResult: null,
        editError: null,
        editClearingCounter: state.editClearingCounter + 1
      };
      break;
    case APPROVE_INTERNAL_TRANSFER: 
      state = {
        ...state,
      };
      break;
    case APPROVE_INTERNAL_TRANSFER_SUCCESS: 
      state = {
        ...state,
        internalTransfers: state.internalTransfers.map(d => {
          if (d._id === action.payload){
            d.status = "APPROVED";
          }
          return d;
        }),
      };
      break;
    case REJECT_INTERNAL_TRANSFER: 
      state = {
        ...state,
      };
      break;
    case REJECT_INTERNAL_TRANSFER_SUCCESS: 
      state = {
        ...state,
        internalTransfers: state.internalTransfers.map(d => {
          if (d._id === action.payload){
            d.status = "REJECTED";
          }
          return d;
        }),
      };
      break;
    default:
      state = { ...state };
  }
  return state;
};

export default RebateRulesReducer;