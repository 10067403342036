import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Label,
  Spinner,
  Button,
  
} from "reactstrap";
import { editClientDetails } from "store/client/actions";
import AvFieldSelect from "components/Common/AvFieldSelect";
import { AvForm } from "availity-reactstrap-validation";
const TYPES = ["VU", "MU"];
function SwapAccess(props){
  const [type, setType] = useState();
  const [open, setOpen] = useState(false);
  const [addModal, setAddUserModal] = useState(false);
  const { editSuccess, updating } = useSelector(state=>state.clientReducer);
  const clientDetails = props.clientDetails;
  const clientId = props.clientId;
  const dispatch = useDispatch();
  const toggleModal = ()=>{
    setOpen(!open);
  };

  const toggleAddModal = () => {
    setAddUserModal(!addModal);
  };

  useEffect(() => {
    if (addModal) {
      setAddUserModal(false);
    }
  }, [editSuccess]);

  useEffect(()=>{
    if (open && editSuccess)
      setTimeout(() => {
        toggleModal();
      }, 800);
  }, [editSuccess]);

  return (
    <React.Fragment>
      <button 
        type="button" 
        className="btn btn-primary waves-effect waves-light w-100 me-1"
        onClick={toggleModal}
      >
        Swap Access
      </button>
      <Modal isOpen={open} toggle={toggleModal} centered={true}>
        <ModalHeader toggle={toggleModal}>
             Swap Access
        </ModalHeader>
        <ModalBody>
          <div className="d-flex gap-3 d-flex flex-column align-items-center">
            <h3>{clientDetails?.swapAccess ? "Client has swap access" : "Client does not have swap access"}</h3>
            {updating ? <Spinner className="ms-2"></Spinner> : <Input type="checkbox" id={props.clientDetails?._id} switch="none" checked={clientDetails?.swapAccess  ? true : false} onChange={()=>dispatch(editClientDetails({
              values:{ 
                swapAccess:clientDetails?.swapAccess ? false : true 
              },
              id:clientId,
            }))} />}
            <Label className="me-1" htmlFor={props.clientDetails?._id} data-on-label="" data-off-label=""></Label>
          </div>
        </ModalBody>
      </Modal>

      
    </React.Fragment>
    
   
  );

}

export default SwapAccess;