/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import { 
  useDispatch,
  connect,
  useSelector 
} from "react-redux";
// eslint-disable-next-line object-curly-newline
import { Row, Col, Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import SearchBar from "components/Common/SearchBar";
import CustomPagination from "components/Common/CustomPagination";
// eslint-disable-next-line object-curly-newline
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import CustomDropdown from "components/Common/CustomDropDown";
import TableLoader from "components/Common/TableLoader";
import Notification from "components/Common/Notification";
import logo from "assets/images/logo-sm.svg";
import { withTranslation } from "react-i18next";
import { checkAllBoxes } from "common/utils/checkAllBoxes";
import { Link, useLocation } from "react-router-dom";
import { captilazeFirstLetter } from "common/utils/manipulateString";
import {
  approveInternalTransfer,
  fetchInternalTransfers,
  rejectInternalTransfer,
} from "store/forexTransactions/internalTransfers/actions";
import Badge from "components/Common/Badge";
import Filter from "./Filter";
import formatDate from "helpers/formatDate";
import FeatherIcon from "feather-icons-react";
import SymbolsAdd from "./SymbolsAdd";
import DeleteModal from "components/Common/DeleteModal";
import SymbolsEdit from "./SymbolsEdit";
import SwitchStatus from "../symbols/switchStatus";
import RebateRulesModal from "./RebateBenchMarkModal";
import useModal from "hooks/useModal";
import DeleteStatus from "../symbolsGroups/deleteStatus";
import { fetchPromoStart, showSuccessNotification } from "store/actions";
import { cpUrl } from "content";

function MT4Groups(props) {
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedSymbol, setSelectedSymbol] = useState();
  const [editModal, setEditModal] = useState(false);
  const [switchStatus, setSwitchStatus] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [viewRules, toggleViewRules] = useModal();


  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState("");
  const [showNotication, setShowNotifaction] = useState(false);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [selectedFilter, setSelectedFilter] = useState("ALL");
  const deleteSymbol = () => {
    console.log("deleted");

  };
  const { userData } = useSelector((state) => state.Profile);
  const [isLoading, setIsLoading] = useState(
    useSelector((state) => state?.Promo?.loading) || false
  );
  const promoLinks = useSelector((state) => state?.Promo?.promos?.docs);
  const promolinksPagination = useSelector((state) => state?.Promo?.promos);

  const loadPromotionLinks = async (page, limit) => {
    await dispatch(
      fetchPromoStart({
        page,
        limit,
        applicableToAm: true,
        status: "Enabled"
      })
    );
  };

  useEffect(() => {
    loadPromotionLinks(1, 10);
  }, [isLoading]);

  const columns = [
    // {
    //   dataField: "checkbox",
    //   text: (
    //     <input
    //       type="checkbox"
    //       id="check-all-deposits"
    //       onChange={() =>
    //         checkAllBoxes("check-all-deposits", ".deposit-checkbox")
    //       }
    //     />
    //   ),
    // },
    {
      dataField: "name",
      text: props.t("Name"),
      formatter: (val) => val?.title
    },
    {
      dataField: "accountType",
      text: props.t("Account Type"),
      formatter: (val) => val?.accountType?.title
    },
    {
      dataField: "promotionLink",
      text: props.t("Promotion Link"),
      formatter: (val) => `${cpUrl}/register/client/live?salesRef=${userData.userId}&promotionLink=${val?._id}`
    },
    {
      dataField: "",
      isDummyField: true,
      editable: false,
      text: "Operation",
      formatter: (val) => (
        <div className="text-center">
          <Link className={"text-success"} to="#">
            <i
              className="mdi mdi-clipboard font-size-22"
              id="edittooltip"
              onClick={() => {
                navigator.clipboard.writeText(`${cpUrl}/register/client/live?salesRef=${userData.userId}&promotionLink=${val._id}`);
                dispatch(showSuccessNotification("Link copied to clipboard"));
              }}
            ></i>
          </Link>
          <Link className={"text-success"} to="#">
            <i
              className="mdi mdi-qrcode font-size-22"
              id="edittooltip"
              onClick={() => {
                /////
              }}
            ></i>
          </Link>
        </div>
      ),
    },
  ];


  const handleSearchInput = (e) => {
    setSearchInput(e.target.value);
  };
  const initFilteredValues = {
    customerId: "",
    filterDate: {
      fromDate: "",
      toDate: "",
    },
    status: query.get("status") || "",
    currency: "",
    amount: "",
    fee: "",
    tradingAccountFrom: "",
    tradingAccountTo: "",
    agent: "",
  };
  const [filteredValues, setFilteredValues] = useState(initFilteredValues);
  const filterChangeHandler = (filteredValuesData) => {
    setFilteredValues(filteredValuesData);
  };

  const closeNotifaction = () => {
    setShowNotifaction(false);
  };


  return (
    <React.Fragment>
      <Notification
        onClose={closeNotifaction}
        body={props.t("The internal transfer has been updated successfully")}
        show={showNotication}
        header={props.t("Internal Transfer Update")}
        logo={logo}
      />
      <Row>
        <Col className="col-12">
          <Card>
            <CardHeader className="d-flex flex-column gap-3 ">
              <div className="d-flex justify-content-between align-items-center">
                {/* <CardTitle className="color-primary">
                  {props.t(
                    `MT4 Groups(${props.internalTransfersTotalDocs})`
                  )}
                  <FeatherIcon
                    icon="refresh-cw"
                    className="icon-lg ms-2"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      loadInternalTransfers(1, sizePerPage);
                    }}
                  />
                </CardTitle> */}
              </div>
              <div className="d-flex flex-row align-items-center justify-content-between gap-2">
                <div className="d-flex flex-row align-items-center">
                  <SearchBar
                    handleSearchInput={handleSearchInput}
                    placeholder={props.t("Enter Name")}
                  />
                  {/* <Filter
                    filterChangeHandler={filterChangeHandler}
                    filteredValues={filteredValues}
                  /> */}
                </div>
              </div>
            </CardHeader>
            <CardBody>
              <div className="table-rep-plugin">
                <div
                  className="table-responsive mb-0"
                  data-pattern="priority-columns"
                >
                  <Table id="tech-companies-1" className="table  table-hover ">
                    <Thead className="text-center table-light">
                      <Tr>
                        {columns.map((column, index) => (
                          <Th data-priority={index} key={index}>
                            <span className="color-primary">{column.text}</span>
                          </Th>
                        ))}
                      </Tr>
                    </Thead>

                    {promoLinks?.length === 0 ? (
                      <Tbody>
                        {isLoading && <TableLoader colSpan={4} />}
                        {!isLoading && (
                          <>
                            <Tr>
                              <Td
                                colSpan={"100%"}
                                className="fw-bolder text-center"
                                st
                              >
                                <h3 className="fw-bolder text-center">
                                  No records
                                </h3>
                              </Td>
                            </Tr>
                          </>
                        )}
                      </Tbody>
                    ) : (
                      <Tbody
                        style={{
                          fontSize: "12px",
                          textAlign: "center",
                          textTransform: "capitalize",
                        }}
                      >
                        {isLoading && <TableLoader colSpan={4} />}
                        {!isLoading &&
                          promoLinks
                            ?.filter((row) => row.applicableToAm)
                            .map((row, rowIndex) => (
                              <Tr key={rowIndex}>
                                {columns?.map((column, index) => (
                                  <Td
                                    key={`${rowIndex}-${index}`}
                                    className="pt-4"
                                  >
                                    {column.formatter
                                      ? column.formatter(row, rowIndex)
                                      : row[column.dataField]}
                                  </Td>
                                ))}
                              </Tr>
                            ))}
                      </Tbody>
                    )}
                  </Table>
                  <CustomPagination
                    {...promolinksPagination}
                    setSizePerPage={setSizePerPage}
                    sizePerPage={sizePerPage}
                    onChange={loadPromotionLinks}
                    docs={promoLinks}
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {editModal && (
        <SymbolsEdit show={editModal} setEditModal={setEditModal} />
      )}
      {switchStatus && (
        <SwitchStatus
          show={switchStatus}
          setSwitchStatus={setSwitchStatus}
          text="rebate rules"
        />
      )}
      {deleteStatus && (
        <DeleteStatus
          show={deleteStatus}
          setSwitchStatus={setDeleteStatus}
          text="rebate rules"
        />
      )}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  fetchInternalTransfersLoading:
    state.internalTransferReducer.fetchInternalTransfersLoading || false,
  internalTransfers: state.internalTransferReducer.internalTransfers || [],
  page: state.internalTransferReducer.page || 1,
  internalTransfersTotalDocs:
    state.internalTransferReducer.internalTransfersTotalDocs || 0,
  totalPages: state.internalTransferReducer.totalPages || 0,
  hasNextPage: state.internalTransferReducer.hasNextPage,
  hasPrevPage: state.internalTransferReducer.hasPrevPage,
  limit: state.internalTransferReducer.limit,
  nextPage: state.internalTransferReducer.nextPage,
  pagingCounter: state.internalTransferReducer.pagingCounter,
  prevPage: state.internalTransferReducer.prevPage,
  depositsPermissions: state.Profile.depositsPermissions || {},
  tradingAccounts: state.tradingAccountReducer.tradingAccounts,
  addInternalTransferLoading:
    state.internalTransferReducer.addInternalTransferLoading,
  internalTransferPermissions: state.Profile.depositsPermissions,
  totalDocs: state.internalTransferReducer.totalDocs || 0,
});
export default connect(mapStateToProps, null)(withTranslation()(MT4Groups));
