import React, { useCallback, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import AsyncAvFieldSelect from "../../../../components/Common/AsyncAvFieldSelect";


const SearchableSymbolGroupsDropdown = (props) => {

  const {
    label = "Agent",
    placeholder = "Search",
    isRequired = false,
    name = "agent",
    isDisabled = false,
    defaultOptions = [],
    value = null,
    clientData = null,
    onChange = () => { },
    isMulti = false,
  } = props;

  const symbolsOptions = [
    {
      value: "Group1",
      label: "Group1"
    },
    {
      value: "Group2",
      label: "Group2"
    },
    {
      value: "Group3",
      label: "Group3"
    },
  ];

  // const [agentOptions, setAgentOptions] = React.useState(defaultOptions);
  // const { userData } = useSelector((state) => ({
  //   userData: state.Profile.userData,
  // }));

  // useEffect(() => {
  //   if (clientData && clientData.length > 0) {
  //     setAgentOptions(clientData.map((user) => ({
  //       value: user._id,
  //       label: user.firstName + " " + user.lastName + " - " + user.roleId.title
  //     })));
  //   }
  // }, [clientData]);


  return (
    <AsyncAvFieldSelect 
      name={name}
      options={symbolsOptions}
      // label={props.t(label)}
      errorMessage={props.t("Symbol Group is required")}
      defaultOptions={symbolsOptions || defaultOptions}
      value={value ? value : clientData?.agent?._id ? {
        label : `${clientData.agent.firstName} ${clientData.agent.lastName}`,
        value : `${clientData.agent._id}`
      } : ""}
      defaultValue={value ? value : clientData?.agent?._id ? {
        label : `${clientData.agent.firstName} ${clientData.agent.lastName}`,
        value : `${clientData.agent._id}`
      } : ""}
      isRequired={isRequired}
      placeholder={placeholder}
      isSearchable={true}
      backspaceRemovesValue={true}
      onChange={onChange}
      isMulti={isMulti}
      isDisabled={isDisabled}
      disabled={isDisabled}
    />
  );
};


const mapStateToProps = (state) => ({
  agents: state.usersReducer.salesAgent,
  agentsLoading: state.usersReducer.loading,
});
export default connect(mapStateToProps, null)(withTranslation()(SearchableSymbolGroupsDropdown));