import { CHANGE_SYMBOL_END, CHANGE_SYMBOL_START } from "../symbols/actionTypes";
import {
  FETCH_REBATE_RULES_REQUESTED,
  FETCH_REBATE_RULES_SUCCESS,
  FETCH_REBATE_RULES_FAIL,

  SYNC_SYMBOL_REQUESTED,
  SYNC_SYMBOL_SUCCESS,
  SYNC_SYMBOL_FAIL,

  ADD_REBATE_RULE_REQUESTED,
  ADD_REBATE_RULE_SUCCESS,
  ADD_REBATE_RULE_FAIL,
  ADD_REBATE_RULE_CLEAR,
  ADD_REBATE_RULE_ERROR_CLEAR,
  APPROVE_INTERNAL_TRANSFER,
  REJECT_INTERNAL_TRANSFER,
  DELETE_REBATE_RULE_START,
  DELETE_REBATE_RULE_END,
  EDIT_REBATE_RULES_START,
  EDIT_REBATE_RULES_DONE,
  EDIT_REBATE_RULES_CLEAR,
  CHANGE_REBATE_RULES_START,
  CHANGE_REBATE_RULES_END
} from "./actionTypes";


// fetch internal transfers
export const fetchRebateRules = (params = {}) => {
  return {
    type: FETCH_REBATE_RULES_REQUESTED,
    payload: params
  };
};
export const fetchRebateRulesSuccess = (data) => {
  return {
    type: FETCH_REBATE_RULES_SUCCESS,
    payload: data
  };
};
export const fetchRebateRulesFail = (error) => {
  return {
    type: FETCH_REBATE_RULES_FAIL,
    payload: { error }
  };
};

// add internal transfer
export const syncSymbolRequest = (params = {}) => {
  return {
    type: SYNC_SYMBOL_REQUESTED,
    payload: params
  };
};
export const syncSymbolRequestsuccess = (data) => {
  return {
    type: SYNC_SYMBOL_SUCCESS,
    payload: data
  };
};
export const syncSymbolRequestFail = (error) => {
  return {
    type: SYNC_SYMBOL_FAIL,
    payload: { error }
  };
};

export const changeRebateRulesStart = (params = {}) => {
  return {
    type: CHANGE_REBATE_RULES_START,
    payload: params
  };
};

export const changeRebateRulesEnd = (data) => {
  return {
    type: CHANGE_REBATE_RULES_END,
    payload: data
  };
};
// add REBATE_RULE
export const addRebateRulesRequest = (params = {}) => {
  return {
    type: ADD_REBATE_RULE_REQUESTED,
    payload: params
  };
};
export const addRebateRulesSuccess = (data) => {
  return {
    type: ADD_REBATE_RULE_SUCCESS,
    payload: data
  };
};
export const addRebateRulesFail = (error) => {
  return {
    type: ADD_REBATE_RULE_FAIL,
    payload: { error }
  };
};
export const addRebateRulesClear = (data) => {
  return {
    type: ADD_REBATE_RULE_CLEAR,
    payload: data
  };
};
export const addRebateRulesErrorClear = () => {
  return {
    type: ADD_REBATE_RULE_ERROR_CLEAR
  };
};


export const deleteRebateRulesStart = (params = {}) => {
  return {
    type: DELETE_REBATE_RULE_START,
    payload: params
  };
};

export const deleteRebateRulesEnd = (data) => {
  return {
    type: DELETE_REBATE_RULE_END,
    payload: data
  };
};

export const editRebateRules = (params = {})=>{
  return {
    type:EDIT_REBATE_RULES_START,
    payload: params
  };
};
export const editRebateRulesDone = (data)=>{
  return {
    type:EDIT_REBATE_RULES_DONE,
    payload: data
  };
};
export const editRebateRulesClear = (data)=>{
  return {
    type:EDIT_REBATE_RULES_CLEAR,
    payload: data
  };
};


export const approveInternalTransfer = (payload) =>{
  return {
    type: APPROVE_INTERNAL_TRANSFER,
    payload: payload
  };
};

export const rejectInternalTransfer = (payload) =>{
  return {
    type: REJECT_INTERNAL_TRANSFER,
    payload: payload
  };
};