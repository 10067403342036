import { 
  useDispatch, 
  connect, 
  useSelector 
} from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledAlert,
  Label,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import AvFieldSelect from "components/Common/AvFieldSelect";
import { pullMTGroupsRequest } from "store/rebateSettings/groups/actions";
function SymbolsAdd(props){
  const [addModal, setAddModal] = useState(false);
  const dispatch = useDispatch();
  const toggleAddModal = ()=>{
    setAddModal(preValue => !preValue);
  };
  const [chosenServers, setChosenServersServers] = useState([]);
  const [selectedServer, setSelectedServer] = useState(null);
  const servers = useSelector((state) => state?.MTServer?.servers);
  const { create } = props.dictionariesPermissions;
  useEffect(()=>{
    if (!props.addGroupsLoading && addModal){
      setAddModal(false);
    }
  }, [props.addGroupsLoading]);

  const selectedServers = (platform, type) => {
    if (servers?.docs) {
      return servers?.docs.filter((server) => server.platform === platform && server.type === type).map((server) => {
        return {
          label: server.name,
          value: server._id
        };
      });
    }
    return [];
  };

  useEffect(() => {
    const res = selectedServers(props.platform, "LIVE");
    setChosenServersServers(() => res);
  }, [servers, props.platform]);

  return (
    <React.Fragment >
      <Link to="#"  className={`btn btn-primary ${!create ? "d-none" : ""}`} onClick={toggleAddModal}><i className="bx bx-plus me-1"></i>{props.t("Pull MT Group")}</Link>
      <Modal isOpen={addModal} toggle={toggleAddModal} centered={true}>
        <ModalHeader toggle={toggleAddModal} tag="h4">
          {props.t("Pull MT Group")}
        </ModalHeader>
        <ModalBody >
          <AvForm
            className='p-4'
            onValidSubmit={(e, v) => {
              e.preventDefault();
              dispatch(pullMTGroupsRequest(v));
            }}
          > 
            <AvFieldSelect
              name="serverId"
              label="Server"
              placeholder="Select Server"
              options={chosenServers.map((type) => ({
                value: type?.value,
                label: type?.label,
              }))}
              value={selectedServer || ""}
              onChange={(e) => setSelectedServer(e)}
              validate={{
                required: {
                  value: true,
                  errorMessage: "Server is required",
                },
              }}
            />
              
            
            <div className='text-center pt-3 p-2'>
              <Button disabled = {props.disableAddButton} type="submit" color="primary" className="">
                {props.disableAddButton ? "Loading..." : props.t("Pull")}
              </Button>
            </div>
          </AvForm>
          {props.error && <UncontrolledAlert color="danger">
            <i className="mdi mdi-block-helper me-2"></i>
            {props.editError}
          </UncontrolledAlert>}
          
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}
const mapStateToProps = (state)=>({
  id :state.groupReducer.id,
  error:state.groupReducer.error,
  addGroupsLoading: state.groupReducer.addGroupsLoading,
  showAddSuccessMessage: state.groupReducer.showAddSuccessMessage,
  dictionariesPermissions : state.Profile.dictionariesPermissions || {},
  disableAddButton : state.groupReducer.disableAddButton
});
export default connect(mapStateToProps, null)(withTranslation()(SymbolsAdd));