/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import { 
  useDispatch, 
  connect, 
  useSelector 
} from "react-redux";
// eslint-disable-next-line object-curly-newline
import { Row, Col, Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import SearchBar from "components/Common/SearchBar";
import CustomPagination from "components/Common/CustomPagination";
// eslint-disable-next-line object-curly-newline
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import TableLoader from "components/Common/TableLoader";
import Notification from "components/Common/Notification";
import logo from "assets/images/logo-sm.svg";
import { withTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import SymbolsAdd from "./SymbolsAdd";
import SymbolsEdit from "./SymbolsEdit";
import DeleteStatus from "./deleteStatus";
import { fetchGroups } from "store/rebateSettings/groups/actions";

function MT4Groups(props) {
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedSymbol, setSelectedSymbol] = useState();
  const [editModal, setEditModal] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [deletedId, setDeletedId] = useState("");
  const [editedItem, setEditedItem] = useState({});

  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState("");
  const [showNotication, setShowNotifaction] = useState(false);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [selectedFilter, setSelectedFilter] = useState("ALL");
  const { userData } = useSelector((state) => ({
    userData: state.Profile,
  }));
  const permissions = userData?.roleId?.permissions;
  const { groupSymbols } = permissions;
  const deleteSymbol = () => {
    console.log("deleted");
  };
  const columns = [
    // {
    //   dataField: "checkbox",
    //   text: (
    //     <input
    //       type="checkbox"
    //       id="check-all-deposits"
    //       onChange={() =>
    //         checkAllBoxes("check-all-deposits", ".deposit-checkbox")
    //       }
    //     />
    //   ),
    // },
    // {
    //   dataField: "recordNumber",
    //   text: props.t("Record Number"),
    // },
    {
      dataField: "groupName",
      text: props.t("Group Name"),
    },
    {
      dataField: "symbol",
      text: props.t("Symbol"),
      formatter: (val) => <span>{val?.symbols?.map((symbol, i) => i === val?.symbols?.length - 1 ? `${symbol?.symbolName}` : `${symbol?.symbolName} -`)}</span>,
    },
    (groupSymbols?.edit || groupSymbols?.delete ) &&
    {
      dataField: "",
      isDummyField: true,
      editable: false,
      text: "Action",
      formatter: (team) => (
        <div className="text-center">
          {groupSymbols?.edit && (
            <Link className={"text-success"} to="#">
              <i
                className="mdi mdi-pencil font-size-18"
                id="edittooltip"
                onClick={() => {
                  console.log("sdsdsds");
                  setEditModal(true);
                  setEditedItem(team);
                }}
              ></i>
            </Link>
          )}
          {groupSymbols?.delete && (
            <Link className={"text-danger"} to="#">
              <i
                className="mdi mdi-delete font-size-18"
                id="deletetooltip"
                onClick={() => {
                  setDeletedId(team?._id);
                  setDeleteStatus(true);
                }}>
              </i>
            </Link>
          )}
        </div>
      ),
    },
  ];


  const handleSearchInput = (e) => {
    setSearchInput(e.target.value);
  };
  const initFilteredValues = {
    customerId: "",
    filterDate: {
      fromDate: "",
      toDate: "",
    },
    status: query.get("status") || "",
    currency: "",
    amount: "",
    fee: "",
    tradingAccountFrom: "",
    tradingAccountTo: "",
    agent: "",
  };
  const [filteredValues, setFilteredValues] = useState(initFilteredValues);
  const filterChangeHandler = (filteredValuesData) => {
    setFilteredValues(filteredValuesData);
  };
  const loadGroups = (page, limit) => {
    if (searchInput && searchInput.length > 3) {
      dispatch(
        fetchGroups({
          limit,
          page,
          filteredValues,
          searchText: searchInput,
          type: props.type
        })
      );
    } else {
      dispatch(
        fetchGroups({
          limit,
          page,
          filteredValues,
          type: props.type
        })
      );
    }
  };

  const closeNotifaction = () => {
    setShowNotifaction(false);
  };

  useEffect(() => {
    loadGroups(1, sizePerPage);
  }, [
    props.type,
    sizePerPage,
    1,
    filteredValues,
    searchInput,
    selectedFilter,
  ]);

  return (
    <React.Fragment>
      <Notification
        onClose={closeNotifaction}
        body={props.t("The Group has been updated successfully")}
        show={showNotication}
        header={props.t("Group Update")}
        logo={logo}
      />
      <Row>
        <Col className="col-12">
          <Card>
            <CardHeader className="d-flex flex-column gap-3 ">
              <div className="d-flex justify-content-between align-items-center">
                <CardTitle className="color-primary">
                  {props.t(
                    `${props.type} Groups(${props.groupsTotalDocs})`
                  )}
                  <FeatherIcon
                    icon="refresh-cw"
                    className="icon-lg ms-2"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      loadGroups(1, sizePerPage);
                    }}
                  />
                </CardTitle>
              </div>
              <div className="d-flex flex-row align-items-center justify-content-between gap-2">
                <div className="d-flex flex-row align-items-center">
                  <SearchBar
                    handleSearchInput={handleSearchInput}
                    placeholder={props.t("Search By Group")}
                  />
                  {/* <Filter
                    filterChangeHandler={filterChangeHandler}
                    filteredValues={filteredValues}
                  /> */}
                </div>
                {groupSymbols?.add && (
                  <div className="d-flex flex-row align-items-center justify-content-between">
                    <SymbolsAdd type={props.type} />
                  </div>
                )}
              </div>
            </CardHeader>
            <CardBody>
              {groupSymbols?.view ? (
                <div className="table-rep-plugin">
                <div
                  className="table-responsive mb-0"
                  data-pattern="priority-columns"
                >
                  <Table id="tech-companies-1" className="table  table-hover ">
                    <Thead className="text-center table-light">
                      <Tr>
                        {columns.map((column, index) =>
                          <Th data-priority={index} key={index}><span className="color-primary">{column.text}</span></Th>
                        )}
                      </Tr>
                    </Thead>
                    {props?.groupsTotalDocs === 0 ? (
                      <Tbody>
                        {props?.fetchGroupsLoading && (
                          <TableLoader colSpan={4} />
                        )}
                        {!props?.fetchGroupsLoading && (
                          <>
                            <Tr>
                              <Td
                                colSpan={"100%"}
                                className="fw-bolder text-center"
                                st
                              >
                                <h3 className="fw-bolder text-center">
                                  No records
                                </h3>
                              </Td>
                            </Tr>
                          </>
                        )}
                      </Tbody>
                    ) : (
                      <Tbody
                        style={{
                          fontSize: "12px",
                          textAlign: "center",
                          textTransform: "capitalize",
                        }}
                      >
                        {props?.fetchGroupsLoading && <TableLoader colSpan={4} />}
                        {!props?.fetchGroupsLoading &&
                          props?.groups.map((row, rowIndex) => (
                            <Tr key={rowIndex}>
                              {columns.map((column, index) => (
                                <Td
                                  key={`${rowIndex}-${index}`}
                                  className="pt-4"
                                >
                                  {column.formatter
                                    ? column.formatter(row, rowIndex)
                                    : row[column.dataField]}
                                </Td>
                              ))}
                            </Tr>
                          ))}
                      </Tbody>
                    )}
                  </Table>
                  <CustomPagination
                    {...props}
                    setSizePerPage={setSizePerPage}
                    sizePerPage={sizePerPage}
                    onChange={loadGroups}
                    docs={props.groups}
                  />
                </div>
              </div>  
              ) : (
                <h1 className="text-center">You Do Not Have The Required Permissions</h1>

              )}
              
            </CardBody>
          </Card>
        </Col>
      </Row>
      {deleteStatus && <DeleteStatus deletedId={deletedId} show={deleteStatus} setSwitchStatus={setDeleteStatus} text="symbol groups" type={props.type} delteSuccess={props?.deleting} />}
      {editModal && <SymbolsEdit show={editModal} setEditModal={setEditModal} editedItem={editedItem} type={props.type} />}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  fetchGroupsLoading:
    state.groupReducer.fetchGroupsLoading || false,
  groups: state.groupReducer.groups || [],
  page: state.groupReducer.page || 1,
  groupsTotalDocs:
    state.groupReducer.groupsTotalDocs || 0,
  totalPages: state.groupReducer.totalPages || 0,
  deleting: state.groupReducer.deleting,
  hasNextPage: state.groupReducer.hasNextPage,
  hasPrevPage: state.groupReducer.hasPrevPage,
  limit: state.groupReducer.limit,
  nextPage: state.groupReducer.nextPage,
  pagingCounter: state.groupReducer.pagingCounter,
  prevPage: state.groupReducer.prevPage,
  depositsPermissions: state.Profile.depositsPermissions || {},
  tradingAccounts: state.tradingAccountReducer.tradingAccounts,
  addGroupsLoading:
    state.groupReducer.addGroupsLoading,
  groupPermissions: state.Profile.depositsPermissions,
  totalDocs: state.groupReducer.totalDocs || 0,
});
export default connect(mapStateToProps, null)(withTranslation()(MT4Groups));
