import { AvForm } from "availity-reactstrap-validation";
import AvFieldSelect from "components/Common/AvFieldSelect";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { cpUrl, jwtSecret } from "content";
import jwt from "jsonwebtoken";

import QRCode from "qrcode.react";
import {
  Button,
  Col,
  Modal, ModalBody, ModalHeader, Row,
} from "reactstrap";
import { fetchRebateRules } from "store/rebateSettings/rebateRules/actions";
import { showSuccessNotification } from "store/actions";
import SearchableRebateRulesDropdown from "pages/RebateSettings/rebateRules/SearchableComponents/SearchableRebateRulesDropdown";

const MT5 = ({ show, toggle, selectedPromoLink, isQr, setIsQr }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchRebateRules({}));
  }, []);

  const rebateRules = useSelector((state) => state.RebateRulesReducer.rebateRules);
  const filteredRebateRules = rebateRules?.filter(item => item.accountTypeId?._id === selectedPromoLink.accountType?._id && item.status === true);
  const { userData } = useSelector((state) => state.Profile);
  const [selectedRebates, setSelectedRebates] = useState([]);


  useEffect(() => {
    setSelectedRebates([]);
  }, [show]);

  const handleRebateChange = (e, rebate) => {
    const { _id, promotionOptionalRebateValue } = rebate;
    // const promotionOptionalRebateValueData = e.map((item) => parseInt(item.value));
    const selectedValue = parseFloat(e.value) || 0;
    setSelectedRebates(prevState => {
      return [...prevState, { 
        rebateId: _id,
        rebateValue: selectedValue,
        rebateName: rebate?.name,
        minRebateValue: Math.min(...rebate?.tier1RebateRules || 0),
        maxRebateValue: Math.max(...rebate?.tier1RebateRules || 0),
      }];
    });
  };

  return (
    <div className="container">
      {filteredRebateRules.reduce((rows, rebate, index) => {
        if (index % 2 === 0) {
          rows.push([rebate]); // Start a new row
        } else {
          rows[rows.length - 1].push(rebate); // Add to the current row
        }
        return rows;
      }, []).map((row, rowIndex) => (
        <div className="row my-3" key={rowIndex}>
          {row.map((rebate) => (
            <div className="col-md-6 my-3" key={rebate._id}>
              <SearchableRebateRulesDropdown
                name="promotionOptionalRebateValue"
                type="text"
                errorMessage="Rebate is required"
                validate={{ required: { value: true } }}
                label={rebate.name}
                value={[]}
                isPromote={true}
                defaultOptions={rebate?.promotionOptionalRebateValue.map((option) => ({
                  label: option,
                  value: option
                }))}
                isMulti={false}
                onChange={(e) => { handleRebateChange(e, rebate) }}
              />
            </div>
          ))}
        </div>
      ))}
    </div>

    
  );
};

export default MT5;
