import React, { useEffect, useState } from "react";
import {
  useDispatch, connect, useSelector
} from "react-redux";
import { Link, useLocation } from "react-router-dom";

import {
  Row, Col, Card, CardBody, CardTitle, CardHeader, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from "reactstrap";

import {
  Table, Thead, Tbody, Tr, Th, Td
} from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import {
  fetchIbs, fetchSwaps, ibRequestApprove, ibRequestReject,
  swapRequestReject,
} from "store/requests/actions";
import CustomPagination from "components/Common/CustomPagination";
import TableLoader from "components/Common/TableLoader";
import { captilazeFirstLetter } from "common/utils/manipulateString";
import { withTranslation } from "react-i18next";
import Badge from "components/Common/Badge";
import { MetaTags } from "react-meta-tags";
import formatDate from "helpers/formatDate";
import FeatherIcon from "feather-icons-react";
import useModal from "hooks/useModal";
import {
  fetchAccountTypes, fetchProducts
} from "store/actions";
import { fetchClientDetails } from "store/client/actions";
import RequestFilter from "../RequestFilter";
import SwapApprovalModal from "./SwapApproveModal";
import LoginsModal from "./loginsModal";
import RejectionModal from "./SwapRejectionModal";
import SearchBar from "components/Common/SearchBar";
function SwapFreeRequests(props) {
  const [swapRequest, setSwapRequest] = useState(null);
  const [viewAccounts, toggleViewAccounts] = useModal();
  const [modalAccounts, setModalAccounts] = useState([]);
  const [searchInputText, setSearchInputText] = useState("");
  const handleSearchInput = (e) => setSearchInputText(e.target.value);

  const columns = [
    {
      dataField: "recordId",
      text: props.t("Request Id"),
    },
    {
      dataField: "processedBy",
      text: props.t("Processed By"),
      formatter: (val) => {
        return (
          <div>
            <span className="no-italics" style={{ fontWeight: "bold" }}>
              {val.processedBy
                ? `${captilazeFirstLetter(
                  val.processedBy.firstName
                )} ${captilazeFirstLetter(val.processedBy.lastName)}`
                : ""}
            </span>
          </div>
        );
      },
    },
    {
      dataField: "createdAt",
      text: props.t("Date"),
      formatter: (val) => formatDate(val.createdAt)
    },
    {
      dataField: "requestType",
      text: props.t("Request Type"),
      formatter: (val) => val.type
    },
    {
      dataField: "customerId",
      text: props.t("Client"),
      formatter: (val) => {
        return (
          <div>
            <Link
              to={{
                pathname: `/clients/${val?.customerId?._id}/profile`,
                state: { clientId: val.customerId }
              }}>
              <i className="no-italics fw-bold">{val.customerId ? `${captilazeFirstLetter(val.customerId.firstName)} ${captilazeFirstLetter(val.customerId.lastName)}` : ""}</i>
            </Link>
          </div>
        );
      }
    },
    {
      dataField: "customerId",
      text: props.t("Client Country"),
      formatter: (val) => val.content.accounts?.[0].customerId.country || "-",
    },
    {
      dataField: "accountDetails",
      text: "Account Details",
      formatter: (item) => (
        <Link to="#" className="mdi mdi-eye font-size-18 py-0"
          onClick={()=>{
            setModalAccounts(item.content.accounts);
            toggleViewAccounts();
          }}
        >
        </Link>
      )
    },
    {
      dataField: "agent",
      text: "Agent",
      formatter: (val) =>
        val?.agent ? `${val?.customerId?.agent?.firstName} ${val?.customerId?.agent?.lastName}` : "-",
    },
    {
      dataField: "reason",
      text: props.t("Reason"),
      formatter: (val) => val?.content?.reason || "-"
    },
    {
      dataField: "status",
      text: props.t("Status"),
      formatter: (user) => (
        <Badge status={user.status}/>
      ),
    },
    {
      dataField: "",
      isDummyField: true,
      editable: false,
      text: props.t("Actions"),
      formatter: (item) => (
        <UncontrolledDropdown
          disabled={item?.status === "APPROVED" || item?.status === "REJECTED"}
        >
          <DropdownToggle tag="i" className="text-muted" style={{ cursor: "pointer" }}>
            <i
              className="mdi mdi-dots-horizontal font-size-18"
              style={{ color: item?.status === "APPROVED" || item?.status === "REJECTED" ? "lightgray" : "rgb(66, 65, 65)" }}
            />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            {item?.status === "PENDING" && item.customerId?.stages?.kycApproved && (
              <DropdownItem onClick={() => {
                setSwapRequest(item);
                swapApprove(item);
              }} href="#">{props.t("Approve")}</DropdownItem>
            )}
            <DropdownItem onClick={() => { 
              setSwapRequest(item);
              swapRejected(item); 
            }} href="#">{props.t("Reject")}</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      )
    },
  ];
  const [sizePerPage, setSizePerPage] = useState(10);
  const [show, toggle] = useModal();
  const [showReject, toggleReject] = useModal();
  const [parentId, setParentId] = useState(null);
  const [requestId, setRequestId] = useState("");

  useEffect(() => {
    dispatch(fetchAccountTypes({
      type: "LIVE",
    }));
    dispatch(fetchProducts());
  }, []);

  // get query parameters from url
  const search = useLocation().search;
  const query = new URLSearchParams(search);

  const initFilteredValues =  {
    customerId: "",
    filterDate: {
      fromDate: "",
      toDate: ""
    },
    status: query.get("status") || "",
    kyc: "",
    agent: "",
    ...query,
  };

  const [filteredValues, setFilteredValues] = useState(initFilteredValues);
  const filterChangeHandler = (filteredValuesData) => {
    setFilteredValues(filteredValuesData);
  };

  const swapApprove = (val) => {
    dispatch(fetchClientDetails(val.customer._id));
    setRequestId(val._id);
    toggle();
  };

  const dispatch = useDispatch();

  useEffect(() => {
    loadSwaps(1, sizePerPage);
  }, [sizePerPage, 1, props.isApproveOrReject, filteredValues, props.clearingCounter, searchInputText]);

  const loadSwaps = (page, limit) => {
    if (searchInputText !== "" && searchInputText.length >= 3) {
      const searchText = searchInputText.split("@").shift();
      dispatch(
        fetchSwaps({
          limit,
          page,
          searchText,
          filteredValues: filteredValues,
        })
      );
    } else if (searchInputText === "") {
      dispatch(fetchSwaps({
        page,
        limit,
        filteredValues
      })); 
    }
  };

  const swapRejected = (val) => {
    dispatch(fetchClientDetails(val.customer._id));
    setRequestId(val._id);
    toggleReject();
  };

  
  const { loading, clearingCounter } = useSelector(state=> state.requestReducer);

  useEffect(()=>{
    if (clearingCounter > 0 && show){
      toggle();
    }
  }, [clearingCounter]);
  
  return (
    <React.Fragment>
      <div className="page-content">  
        <MetaTags>
          <title>
            Swap Free Requests
          </title>
        </MetaTags>
        <div className="container-fluid">
          <h2> Swap Free Requests </h2>
          <Row>
            <Col className="col-12">
              <Card>
                <CardHeader className="d-flex justify-content-between  align-items-center">
                  <div>
                    <CardTitle className="color-primary">Swap Free Requests ({props.totalDocs})
                      <FeatherIcon
                        icon="refresh-cw"
                        className="icon-lg ms-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => { loadSwaps(1, sizePerPage) }}
                      />
                    </CardTitle>
                    <SearchBar
                      handleSearchInput={handleSearchInput}
                      placeholder={props.t("Search By Client")}
                    />
                  </div>
                  <RequestFilter filterChangeHandler={filterChangeHandler} filteredValues={filteredValues} />
                </CardHeader>
                <CardBody>
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table  table-hover "
                      >
                        <Thead className="text-center table-light" >
                          <Tr>
                            {columns.map((column, index) =>
                              <Th data-priority={index} key={index}><span className="color-primary">{column.text}</span></Th>
                            )}
                          </Tr>
                        </Thead>
                        <Tbody className="text-center" style={{
                          fontSize: "12px",
                          textAlign: "center",
                          textTransform: "capitalize"
                        }}>
                          {props.loading && <TableLoader colSpan={4} />}
                          {!props.loading && props.totalDocs === 0 && 
                            <Tr>
                              <Td colSpan={columns.length}>
                                <h3 className="fw-bolder text-center">
                                  No records
                                </h3>
                              </Td>
                            </Tr>
                          }
                          {!props.loading && props.docs.map((row, rowIndex) =>
                            <Tr key={rowIndex}>
                              {columns.map((column, index) =>
                                <Td key={`${rowIndex}-${index}`}>
                                  {column.formatter ? column.formatter(row, rowIndex) : row[column.dataField]}
                                </Td>
                              )}
                            </Tr>
                          )}
                        </Tbody>
                      </Table>
                      <CustomPagination
                        {...props}
                        setSizePerPage={setSizePerPage}
                        sizePerPage={sizePerPage}
                        onChange={loadSwaps}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* {<DeleteModal loading={props.deleteLoading} onDeleteClick={deleteRole} show={deleteModal } onCloseClick={()=>{setDeleteUserModal(false)}} />} */}
          {show && <SwapApprovalModal show={show} swapRequest={swapRequest} toggle={toggle} parentId={parentId} requestId={requestId}></SwapApprovalModal>}
          {showReject && <RejectionModal show={showReject} swapRequest={swapRequest} toggle={toggleReject} parentId={parentId} requestId={requestId}></RejectionModal>}
          <LoginsModal show={viewAccounts} toggle={toggleViewAccounts} accounts={modalAccounts} swapRequest={swapRequest} />
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  loading: state.requestReducer.loading || false,
  docs: state.requestReducer.docs || [],
  changeStatusLoading: state.requestReducer.changeStatusLoading,
  changeStatusLoadingIndex: state.requestReducer.changeStatusLoadingIndex,
  page: state.requestReducer.page || 1,
  totalDocs: state.requestReducer.totalDocs || 0,
  totalPages: state.requestReducer.totalPages || 0,
  hasNextPage: state.requestReducer.hasNextPage,
  hasPrevPage: state.requestReducer.hasPrevPage,
  limit: state.requestReducer.limit,
  nextPage: state.requestReducer.nextPage,
  pagingCounter: state.requestReducer.pagingCounter,
  prevPage: state.requestReducer.prevPage,
  deleteLoading: state.requestReducer.deleteLoading,
  deleteClearingCounter: state.requestReducer.deleteClearingCounter,
  requestsPermissions: state.Profile.requestsPermissions || {},
  isApproveOrReject: state.requestReducer.isApproveOrReject,
  clearingCounter: state.requestReducer.clearingCounter,
  // requestResponseMessage:state.depositReducer.depositResponseMessage

});
export default connect(mapStateToProps, null)(withTranslation()(SwapFreeRequests));
