import { AvForm } from "availity-reactstrap-validation";
import AvFieldSelect from "components/Common/AvFieldSelect";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { cpUrl, jwtSecret } from "content";
import jwt from "jsonwebtoken";

import QRCode from "qrcode.react";
import {
  Button,
  Col,
  Modal, ModalBody, ModalHeader, Row,
} from "reactstrap";
import { fetchRebateRules } from "store/rebateSettings/rebateRules/actions";
import { showSuccessNotification } from "store/actions";
import SearchableRebateRulesDropdown from "../rebateRules/SearchableComponents/SearchableRebateRulesDropdown";

const RebateBenchMarkModal = ({ show, toggle, selectedPromoLink, isQr, setIsQr }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectedRebates, setSelectedRebates] = useState([]);
  const [generatedPromoQr, setGeneratedPromoQr] = useState(false);
  const [generatedPromoQrLink, setGeneratedPromoQrLink] = useState("");

  function toggleFn() {
    toggle();
    setGeneratedPromoQr(false);
    setGeneratedPromoQrLink("");
    setIsQr(false);
  }

  useEffect(() => {
    dispatch(fetchRebateRules({}));
  }, []);

  const rebateRules = useSelector((state) => state.RebateRulesReducer.rebateRules);
  const filteredRebateRules = rebateRules?.filter(item => {
    let isExisted = false;
    item?.mtGroupId?.forEach(mtGroup => {
      if (selectedPromoLink?.mtGroups?.find(group => String(group?._id) === String(mtGroup?._id))) {
        isExisted = true;
      }
    });
    // return item.accountTypeId?._id === selectedPromoLink.accountType?._id && item.status === true;
    return isExisted && item.status === true;
  });
  const { userData } = useSelector((state) => state.Profile);

  useEffect(() => {
    setSelectedRebates([]);
  }, [show]);

  const handleRebateChange = (e, rebate) => {
    const { _id, promotionOptionalRebateValue } = rebate;
    // const promotionOptionalRebateValueData = e.map((item) => parseInt(item.value));
    const selectedValue = parseFloat(e.value) || 0;
    setSelectedRebates(prevState => {
      return [...prevState, { 
        rebateId: _id,
        rebateValue: selectedValue,
        rebateName: rebate?.name,
        minRebateValue: Math.min(...rebate?.tier1RebateRules || 0),
        maxRebateValue: Math.max(...rebate?.tier1RebateRules || 0),
      }];
    });
  };

  const handleSubmit = (e, v) => {
    e.preventDefault();
    const rebateRefString = JSON.stringify(selectedRebates);
    const secret = jwtSecret;
    const hashedRebate = jwt.sign({ rebateRefString }, secret, { expiresIn: "1h" });
    navigator.clipboard.writeText(`${cpUrl}/register/client/ib?rebateRef=${hashedRebate}&promotionLink=${selectedPromoLink._id}&salesRef=${userData.userId}`);
    dispatch(showSuccessNotification("Link copied to clipboard"));
    toggleFn();
  };

  const handleGenereateQr = (e, v) => {
    e.preventDefault();
    const rebateRefString = JSON.stringify(selectedRebates);
    const secret = jwtSecret;
    const hashedRebate = jwt.sign({ rebateRefString }, secret, { expiresIn: "1h" });
    setGeneratedPromoQrLink(`${cpUrl}/register/client/ib?rebateRef=${hashedRebate}&promotionLink=${selectedPromoLink._id}&salesRef=${userData.userId}`);
    // navigator.clipboard.writeText(`${cpUrl}/register/client/ib?rebateRef=${hashedRebate}&promotionLink=${selectedPromoLink._id}&salesRef=${userData.userId}`);
    // dispatch(showSuccessNotification("Link copied to clipboard"));
    setGeneratedPromoQr(true);
    // toggle();
  };
  return (
    <Modal isOpen={show} toggle={toggleFn} centered={true} scrollable={true} size="lg">
      <ModalHeader toggle={toggleFn} tag="h4">
        {t("Rebate Benchmark Configuration")}
      </ModalHeader>
      <ModalBody>
        {
          (!generatedPromoQr) ?
            <AvForm className="p-4" onValidSubmit={isQr ? handleGenereateQr : handleSubmit}>
              {filteredRebateRules?.map((rebate) => (
                <div className="my-3" key={rebate._id}>
                  <div className="my-3">
                    <SearchableRebateRulesDropdown
                      name="promotionOptionalRebateValue"
                      type="text"
                      errorMessage="Rebate is required"
                      validate={{ required: { value: true } }}
                      label={rebate.name}
                      isPromote={true}
                      defaultOptions={
                        !selectedPromoLink?.default
                          ? (rebate?.promotionOptionalRebateValue?.length > 0 
                            ? rebate.promotionOptionalRebateValue.map((option) => ({
                              label: option,
                              value: option,
                            }))
                            : [{ 
                              label: "0",
                              value: 0 
                            }]
                          )
                          : [{
                            label: "0",
                            value: 0 
                          }]
                      }
                      
                      isMulti={false}
                      onChange={(e) => { handleRebateChange(e, rebate) }}
                      closeMenuOnSelect={true}
                    />
                  </div>
                </div>
              ))}
              <div className="text-center pt-3 p-2">
                <Button disabled={selectedRebates.length === 0} type="submit" color="primary">
                  {t("Generate")}
                </Button>
              </div>
            </AvForm>
            :
            <div className="d-flex align-items-center justify-content-center">
              <QRCode
                value={generatedPromoQrLink}
                size={300}
                level="H"
                includeMargin={true}
              />
            </div>
        }
      </ModalBody>
    </Modal>
  );
};

export default RebateBenchMarkModal;
