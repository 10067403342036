import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Label,
  Spinner,
  Button,
  
} from "reactstrap";
import { editClientDetails, fetchClientDetails } from "store/client/actions";
import AvFieldSelect from "components/Common/AvFieldSelect";
import { AvForm } from "availity-reactstrap-validation";
import { updateCustomerSwapType } from "apis/client";
import { showErrorNotification, showSuccessNotification } from "store/actions";
const SWAP_TYPES = ["SWAP", "SWAP_FREE"];
function SwapType(props){
  const [type, setType] = useState();
  const [open, setOpen] = useState(false);
  const [addModal, setAddUserModal] = useState(false);
  const { editSuccess, updating } = useSelector(state=>state.clientReducer);
  const clientDetails = props.clientDetails;
  const clientId = props.clientId;
  const dispatch = useDispatch();
  const toggleModal = ()=>{
    setOpen(!open);
  };

  const toggleAddModal = () => {
    setAddUserModal(!addModal);
  };

  useEffect(() => {
    if (addModal) {
      setAddUserModal(false);
    }
  }, [editSuccess]);

  useEffect(()=>{
    if (open && editSuccess)
      setTimeout(() => {
        toggleModal();
      }, 800);
  }, [editSuccess]);
  const handleChangeClientSwapType = (event, values) => {
    event.preventDefault();
    const customerSwapType = values.customerSwapType; 

    updateCustomerSwapType({
      clientId, 
      customerSwapType
    }).then(() => {
      dispatch(showSuccessNotification("Customer Swap Updated Successfully"));
      dispatch(fetchClientDetails(clientId));
    }).catch((e) => {
      dispatch(showErrorNotification(e.message));
    });
  };

  return (
    <React.Fragment>
      <button 
        type="button" 
        className="btn btn-primary waves-effect waves-light w-100 me-1"
        onClick={toggleAddModal}
      >
        Swap Type      </button>
      <Modal isOpen={addModal} toggle={toggleAddModal} centered={true}>
        <ModalHeader toggle={toggleAddModal}>
        Swap Type 
        </ModalHeader>
        <ModalBody>
          <AvForm
            className='p-4'
            onValidSubmit={(e, v) => {
              handleChangeClientSwapType(e, v);
            }}
          >
            <AvFieldSelect
              name="customerSwapType"
              label="Swap Type"
              placeholder="Select Type"
              options={SWAP_TYPES.map((type) => ({
                value: type,
                label: type
              }))}
              value={clientDetails?.customerSwapType}
              onChange={(e) => setType(e)}
              validate={{
              }}
            />     
            <div className="text-center p-2">
              <Button type="submit" color="primary">Update</Button>
            </div>
          </AvForm>

        </ModalBody>
      </Modal>
    </React.Fragment>
    
   
  );

}

export default SwapType;