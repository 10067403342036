// fetch forex WITHDRAWAL
export const FETCH_FOREX_WITHDRAWALS_REQUESTED = "FETCH_FOREX_WITHDRAWALS_REQUESTED";
export const FETCH_FOREX_WITHDRAWALS_SUCCESS = "FETCH_FOREX_WITHDRAWALS_SUCCESS";
export const FETCH_FOREX_WITHDRAWALS_FAIL = "FETCH_FOREX_WITHDRAWALS_FAIL";

// fetch forex WITHDRAWAL
export const FETCH_WITHDRAWAL_FREE_MARGIN_REQUESTED = "FETCH_WITHDRAWAL_FREE_MARGIN_REQUESTED";
export const FETCH_WITHDRAWAL_FREE_MARGIN_SUCCESS = "FETCH_WITHDRAWAL_FREE_MARGIN_SUCCESS";
export const FETCH_WITHDRAWAL_FREE_MARGIN_FAIL = "FETCH_WITHDRAWAL_FREE_MARGIN_FAIL";

export const ADD_FOREX_WITHDRAWAL_REQUESTED = "ADD_FOREX_WITHDRAWAL_REQUESTED";
export const ADD_FOREX_WITHDRAWAL_SUCCESS = "ADD_FOREX_WITHDRAWAL_SUCCESS";
export const ADD_FOREX_WITHDRAWAL_FAIL = "ADD_FOREX_WITHDRAWAL_FAIL";
export const ADD_FOREX_WITHDRAWAL_CLEAR = "ADD_FOREX_WITHDRAWAL_CLEAR";
export const ADD_FOREX_WITHDRAWAL_ERROR_CLEAR = "ADD_FOREX_WITHDRAWAL_ERROR_CLEAR";
export const APPROVE_FOREX_WITHDRAWAL = "APPROVE_FOREX_WITHDRAWAL";
export const APPROVE_FOREX_WITHDRAWAL_SUCCESS = "APPROVE_FOREX_WITHDRAWAL_SUCCESS";
export const REJECT_FOREX_WITHDRAWAL = "REJECT_FOREX_WITHDRAWAL";
export const REJECT_FOREX_WITHDRAWAL_SUCCESS = "REJECT_FOREX_WITHDRAWAL_SUCCESS";
export const ON_HOLD_FOREX_WITHDRAWAL = "ON_HOLD_FOREX_WITHDRAWAL";
export const ON_HOLD_FOREX_WITHDRAWAL_SUCCESS = "ON_HOLD_FOREX_WITHDRAWAL_SUCCESS";

export const RETURN_FOREX_WITHDRAWAL = "RETURN_FOREX_WITHDRAWAL";
export const RETURN_FOREX_WITHDRAWAL_SUCCESS = "RETURN_FOREX_WITHDRAWAL_SUCCESS";