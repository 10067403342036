import { connect, useSelector } from "react-redux";
import {
  Button,
  UncontrolledAlert,
  Col,
  Row,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody
} from "reactstrap";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import React, {
  useState,
  useEffect,
} from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import ClientDropDown from "components/Common/Filters/ClientDropDown";
import FromToDate from "components/Common/Filters/FromToDate";
import SearchableAgentDropdown from "components/Common/SearchableAgentDropdown";
import AvFieldSelect from "components/Common/AvFieldSelect";

function Filter(props) {
  const [openCanvas, setOpenCanvas] = useState(false);
  const [accountTypesOptions, setAccountTypesOptions] = useState([]);
  const [selectedServer, setSelectedServer] = useState(null);


  const initValues = {
    accountType: "",
    status: "",
    mtServer: "",
  };

  const mtServerOptions = useSelector((state) => state?.MTServer?.servers?.docs.map((server) => ({
    label: server.name,
    value: server
  })));

  const accountTypes = useSelector((state) =>
    state?.tradingAccountReducer?.accountTypes?.map((accountType) => ({
      label: accountType.title,
      value: accountType,
    }))
  );
  useEffect(() => {
    if (selectedServer) {
      const filteredAccountTypes = accountTypes.filter((type) => type.value.platform === selectedServer.platform);
      setAccountTypesOptions(filteredAccountTypes);
    } else {
      setAccountTypesOptions(accountTypes);
    }

  }, [selectedServer]);

  const { filteredValues = initValues } = props;
  const [values, setValues] = useState(filteredValues);

  const [filtered, setFilter] = useState(false);
  useEffect(() => {
    let f = JSON.stringify(initValues) === JSON.stringify(values);
    setFilter(!f);
  }, [values]);

  const statusOptions = ["Enabled", "Disabled"];

  const serverChangeHandler = (obj) => {
    setSelectedServer(obj);
    setValues({
      ...values,
      mtServer: obj._id
    });
  };
  const typeChangeHandler = (e) => {
    setValues({
      ...values,
      accountType: e._id
    });
  };

  const resetFilter = () => {
    setValues(initValues);
    // props.filteredValues = initValues;
    props.filterChangeHandler(initValues);
  };


  const toggleCanvas = () => {
    setOpenCanvas(!openCanvas);
  };

  useEffect(() => {
    if (!props.showAddSuccessMessage && openCanvas) {
      setOpenCanvas(false);
    }
  }, [props.showAddSuccessMessage]);

  return (
    <React.Fragment >
      <Button 
        color="primary" 
        className={"btn btn-primary"} 
        onClick={toggleCanvas}>
        <i className="bx bx-filter me-1" />
        {filtered ? props.t("Update Filter") : props.t("Advance Filter")}
      </Button>
      <Offcanvas
        isOpen={openCanvas}
        direction="end"
        scrollable
        toggle={toggleCanvas}
      >
        <OffcanvasHeader toggle={toggleCanvas}
          tag="h4">
          {props.t("Advance Filter")}
        </OffcanvasHeader>
        <OffcanvasBody>
          <AvForm
            className='p-4'
            onValidSubmit={(e, v) => {
              props.filterChangeHandler(values);
              setOpenCanvas(false);
            }}
          >
            <Row>
              <Col md="12">
                <div className="my-3">
                  <AvFieldSelect
                    name="mtServer"
                    type="text"
                    errorMessage={props.t("MT Server is required")}
                    label={props.t("MT Server")}
                    options={mtServerOptions}
                    onChange={serverChangeHandler}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <div className="my-3">
                  <AvFieldSelect
                    name="accountType"
                    type="text"
                    errorMessage={props.t("ٌAccount Type is required")}
                    label={props.t("Account Type")}
                    options={accountTypesOptions}
                    onChange={typeChangeHandler}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <div className="mb-3">
                  <AvField type="select" name="status" label={props.t("Status")} value={props.filteredValues?.status || values.status}
                    onChange={(e) => {
                      setValues({
                        ...values,
                        status: e.target["value"]
                      });
                    }}
                  >
                    <option key={""} value={""}>
                      {props.t("All")}
                    </option>
                    {statusOptions.map((item) => {
                      return (
                        <option key={item} value={item}>
                          {props.t(item)}
                        </option>
                      );
                    })}
                  </AvField>
                </div>
              </Col>
            </Row>

            <div className="row">
              <div className="col-sm-12 text-center">
                <Button disabled={!filtered} type="submit" color="primary" className="btn btn-primary btn-md center-block">
                  {props.t("Filter")}
                </Button>
                {" "}
                {" "}
                <Button onClick={resetFilter} color="primary" type="reset" className="btn btn-danger btn-md center-block">
                  {props.t("Reset")}
                </Button>
              </div>
            </div>

          </AvForm>
          {props.error && <UncontrolledAlert color="danger">
            <i className="mdi mdi-block-helper me-2"></i>
            {props.t(props.error)}
          </UncontrolledAlert>}
        </OffcanvasBody>
      </Offcanvas>
    </React.Fragment>
  );
}
const mapStateToProps = (state) => ({
  error: state.clientReducer.error,
  clientPermissions: state.Profile.clientPermissions,
  showAddSuccessMessage: state.clientReducer.showAddSuccessMessage,
  disableAddButton: state.clientReducer.disableAddButton,
  clients: state.clientReducer.clients,
  leads: state.leadReducer.leads,
});
export default connect(mapStateToProps, null)(withTranslation()(Filter));