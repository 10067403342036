import React, { useState, useEffect } from "react";
import {
  useDispatch, useSelector
} from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { withTranslation } from "react-i18next";
// import { deleteGroupStart, fetchGroups } from "store/rebateSettings/groups/actions";
import { deletePromoStart, fetchPromoStart } from "store/actions";

function deleteStatus({ deletedId, setSwitchStatus, t, setIsLoading, isLoading, show, text, type, delteSuccess, promoId, dispatch }) {
  // const dispatch = useDispatch();

  const toggleModal = () => {
    setSwitchStatus(preValue => !preValue);
  };
  const loadPromotionLinks = async (page, limit) => {
    await dispatch(fetchPromoStart({
      page,
      limit,
    }));
  };

  useEffect(()=>{
    loadPromotionLinks(1, 1000000);
  }, [dispatch, isLoading]);

  useEffect(()=>{
    if (delteSuccess){
      toggleModal();
      dispatch(
        fetchPromoStart({
          limit: 10,
          page: 1,
        })
      );
    }
  }, [delteSuccess]);
  return (
    <React.Fragment >
      {/* <button
        type="button"
        className="btn btn-primary waves-effect waves-light w-100 me-1"
        onClick={toggleModal}
      >
        {t("Confirm Prompt")}
      </button> */}
      <Modal isOpen={show} toggle={toggleModal} centered={true}>
        <ModalHeader toggle={toggleModal} tag="h4">
          {t("Confirm Prompt")}
        </ModalHeader>
        <ModalBody className="text-center" >
          <h5>{t("Are you sure about deleting this PromoLink ?")}</h5>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" color="danger"
            disabled={isLoading}
            onClick={async() => {
              setIsLoading(true);
              await dispatch(deletePromoStart({ id: promoId }));
              setIsLoading(false);
              toggleModal();
            }}>
            {t("Confirm")}
          </Button>
          <Button type="submit" color="primary"
            onClick={() => {
              toggleModal();
            }}>
            {t("Cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

export default withTranslation()(deleteStatus);