import {
  FETCH_GROUPS_REQUESTED,
  FETCH_GROUPS_SUCCESS,
  FETCH_GROUPS_FAIL,

  SYNC_SYMBOL_REQUESTED,
  SYNC_SYMBOL_SUCCESS,
  SYNC_SYMBOL_FAIL,

  ADD_GROUP_REQUESTED,
  ADD_GROUP_SUCCESS,
  ADD_GROUP_FAIL,
  ADD_GROUP_CLEAR,
  ADD_GROUP_ERROR_CLEAR,
  APPROVE_INTERNAL_TRANSFER,
  REJECT_INTERNAL_TRANSFER,
  DELETE_GROUP_START,
  DELETE_GROUP_END,
  EDIT_GROUPS_START,
  EDIT_GROUPS_DONE,
  EDIT_GROUPS_CLEAR,
  PULL_MTGROUPS_REQUESTED,
  PULL_MTGROUPS_SUCCESS,
  PULL_MTGROUPS_FAIL,
  FETCH_MTGROUPS_REQUESTED,
  FETCH_MTGROUPS_SUCCESS,
  FETCH_MTGROUPS_FAIL,
  ADJUST_MTGROUPS_REQUESTED,
  ADJUST_MTGROUPS_SUCCESS,
  ADJUST_MTGROUPS_FAIL,
  FETCH_ACCOUNT_MTGROUPS_FAIL,
  FETCH_ACCOUNT_MTGROUPS_SUCCESS,
  FETCH_ACCOUNT_MTGROUPS_REQUESTED
} from "./actionTypes";


// fetch internal transfers
export const fetchGroups = (params = {}) => {
  return {
    type: FETCH_GROUPS_REQUESTED,
    payload: params
  };
};
export const fetchGroupsSuccess = (data) => {
  return {
    type: FETCH_GROUPS_SUCCESS,
    payload: data
  };
};
export const fetchGroupsFail = (error) => {
  return {
    type: FETCH_GROUPS_FAIL,
    payload: { error }
  };
};

// add internal transfer
export const syncSymbolRequest = (params = {}) => {
  return {
    type: SYNC_SYMBOL_REQUESTED,
    payload: params
  };
};
export const syncSymbolRequestsuccess = (data) => {
  return {
    type: SYNC_SYMBOL_SUCCESS,
    payload: data
  };
};
export const syncSymbolRequestFail = (error) => {
  return {
    type: SYNC_SYMBOL_FAIL,
    payload: { error }
  };
};


// fetch mt groups
export const fetchAccountMtGroups = (params = {}) => {
  return {
    type: FETCH_ACCOUNT_MTGROUPS_REQUESTED,
    payload: params
  };
};
export const fetchAccountMtGroupsSuccess = (data) => {
  return {
    type: FETCH_ACCOUNT_MTGROUPS_SUCCESS,
    payload: data
  };
};
export const fetchAccountMtGroupsFail = (error) => {
  return {
    type: FETCH_ACCOUNT_MTGROUPS_FAIL,
    payload: { error }
  };
};

// fetch mt groups
export const fetchMtGroups = (params = {}) => {
  return {
    type: FETCH_MTGROUPS_REQUESTED,
    payload: params
  };
};
export const fetchMtGroupsSuccess = (data) => {
  return {
    type: FETCH_MTGROUPS_SUCCESS,
    payload: data
  };
};
export const fetchMtGroupsFail = (error) => {
  return {
    type: FETCH_MTGROUPS_FAIL,
    payload: { error }
  };
};
export const adjustMtGroupsRequest = (params = {}) => {
  return {
    type: ADJUST_MTGROUPS_REQUESTED,
    payload: params
  };
};
export const adjustMtGroupsSuccess = (data) => {
  return {
    type: ADJUST_MTGROUPS_SUCCESS,
    payload: data
  };
};
export const adjustMtGroupsFail = (error) => {
  return {
    type: ADJUST_MTGROUPS_FAIL,
    payload: { error }
  };
};

export const pullMTGroupsRequest = (params = {}) => {
  return {
    type: PULL_MTGROUPS_REQUESTED,
    payload: params
  };
};
export const pullMTGroupsRequestsuccess = (data) => {
  return {
    type: PULL_MTGROUPS_SUCCESS,
    payload: data
  };
};
export const pullMTGroupsRequestFail = (error) => {
  return {
    type: PULL_MTGROUPS_FAIL,
    payload: { error }
  };
};

// add group
export const addGroupRequest = (params = {}) => {
  return {
    type: ADD_GROUP_REQUESTED,
    payload: params
  };
};
export const addGroupsuccess = (data) => {
  return {
    type: ADD_GROUP_SUCCESS,
    payload: data
  };
};
export const addGroupFail = (error) => {
  return {
    type: ADD_GROUP_FAIL,
    payload: { error }
  };
};
export const addGroupClear = (data) => {
  return {
    type: ADD_GROUP_CLEAR,
    payload: data
  };
};
export const addGroupErrorClear = () => {
  return {
    type: ADD_GROUP_ERROR_CLEAR
  };
};


export const deleteGroupStart = (params = {}) => {
  return {
    type: DELETE_GROUP_START,
    payload: params
  };
};

export const deleteGroupEnd = (data) => {
  return {
    type: DELETE_GROUP_END,
    payload: data
  };
};

export const editGroups = (params = {})=>{
  return {
    type:EDIT_GROUPS_START,
    payload: params
  };
};
export const editGroupsDone = (data)=>{
  return {
    type:EDIT_GROUPS_DONE,
    payload: data
  };
};
export const editGroupsClear = (data)=>{
  return {
    type:EDIT_GROUPS_CLEAR,
    payload: data
  };
};


export const approveInternalTransfer = (payload) =>{
  return {
    type: APPROVE_INTERNAL_TRANSFER,
    payload: payload
  };
};

export const rejectInternalTransfer = (payload) =>{
  return {
    type: REJECT_INTERNAL_TRANSFER,
    payload: payload
  };
};