// DeleteServerModal.js
import React from "react";
import { useDispatch } from "react-redux";
import {
  Modal, Button, ModalHeader, ModalBody 
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { deleteServerStart, fetchServerStart } from "store/actions";

function DeleteServerModal({ showDelete, toggle, serverId }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleDelete = () => {
    // console.log("Delete Server ID", serverId);
    dispatch(deleteServerStart({ id: serverId }));
    dispatch(fetchServerStart({
      page: 1,
      limit: 10 
    })); // Adjust pagination as needed
    dispatch(fetchServerStart({
      page: 1,
      limit: 10 
    })); // Adjust pagination as needed
    toggle(); // Close modal after deletion
  };

  return (
    <Modal isOpen={showDelete} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>{t("Confirm Delete")}</ModalHeader>
      <ModalBody>
        <p>{t("Are you sure you want to delete this server?")}</p>
        <div className="text-center">
          <Button color="danger" onClick={handleDelete}>
            {t("Delete")}
          </Button>
          <Button color="secondary" onClick={toggle} className="ms-2">
            {t("Cancel")}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default DeleteServerModal;
