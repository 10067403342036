export const checkAllBoxes = (selectAllCheckBoxId, checkedBoxesClass) => {
  const selectAll = document.getElementById(selectAllCheckBoxId);
  const checkboxes = document.querySelectorAll(checkedBoxesClass);

  // Toggle the state of the "Select All" checkbox
  const isChecked = selectAll.checked;

  // Set the state of all checkboxes to match the "Select All" checkbox
  checkboxes.forEach((checkbox) => {
    checkbox.checked = isChecked;
  });
};
