import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert
} from "reactstrap";
import LayuiTransfer from "components/Common/LayuiTransfer";
import { fetchAccountMtGroups, fetchMtGroups } from "store/rebateSettings/groups/actions";
import { useDispatch, useSelector } from "react-redux";
import { updateAccountType } from "store/actions";

const AdjustMTGroups = ({ show, setIbBinding, selectedGroup, platform }) => {
  console.log("Platform: ", platform);
  const dispatch = useDispatch();
  const [mtgroupssOptions, setMtgroupssOptions] = useState([]);
  const [error, setError] = useState("");
  const { groups: mtGroups } = useSelector((state) => state.groupReducer.mtGroups);
  const { editedSuccess } = useSelector((state) => state.tradingAccountReducer);
  const { accountTypes } = useSelector((state) => state.tradingAccountReducer);

  useEffect(() => {
    if (mtGroups) {
      const mtgroupssOptions = mtGroups.filter((group) => {
        const isEnabled = group.status === "Enabled";
        const matchesPlatform = group.accountTypePlatform === platform; // Ensure platform matching
        const isNotAssignedToOther = !group.isAddedToAccountType ||
          (group.accountGroupType === selectedGroup?.title); // Ensure it's either unassigned or assigned to the selected group

        return isEnabled && matchesPlatform && isNotAssignedToOther;
      }).map((mtgroup) => {
        return {
          accountGroupType: mtgroup?.accountGroupType,
          value: mtgroup?._id,
          title: mtgroup?.mtGroupName,
        };
      });

      console.log("Filtered MT Groups: ", mtgroupssOptions); // Debugging output
      setMtgroupssOptions(mtgroupssOptions);
    }
  }, [mtGroups, platform, selectedGroup?._id]);

  useEffect(() => {
    if (mtgroupssOptions) {
      const data = mtGroups.filter(
        (group) => group.isAddedToAccountType && group.accountGroupType === selectedGroup?.title
      )?.map((group) => group?._id);
      setSelectedData(data);
    }
  }, [mtgroupssOptions, platform, selectedGroup?._id]);

  const [selectedData, setSelectedData] = useState([]);

  const handleTransferChange = (newSelectedData) => {
    // Check if any of the selected groups are already assigned to other account types
    const conflictingGroups = mtGroups.filter(group =>
      newSelectedData.includes(group._id) &&
      group.isAddedToAccountType &&
      group.accountGroupType !== selectedGroup?.title
    );

    if (conflictingGroups.length > 0) {
      setError(`The following MT groups are already assigned to other account types: ${conflictingGroups.map(g => g.mtGroupName).join(", ")
      }`);
      return;
    }

    setError("");
    setSelectedData(newSelectedData);
  };

  const toggleModal = () => {
    setError("");
    setIbBinding((prev) => !prev);
  };

  const adjustMtGroups = () => {
    // Final validation before saving
    const conflictingGroups = mtGroups.filter(group =>
      selectedData.includes(group._id) &&
      group.isAddedToAccountType &&
      group.accountGroupType !== selectedGroup?.title
    );

    if (conflictingGroups.length > 0) {
      setError("Cannot save. Some MT groups are already assigned to other account types.");
      return;
    }

    dispatch(updateAccountType(selectedGroup._id, {
      mtGroups: selectedData
    }));
  };

  useEffect(() => {
    editedSuccess && show && toggleModal();
  }, [editedSuccess]);

  useEffect(() => {
    dispatch(
      fetchMtGroups({
        limit: 1000,
        page: 1,
      })
    );
  }, []);

  return (
    <div>
      <Modal isOpen={show} toggle={toggleModal} centered size="lg">
        <ModalHeader toggle={toggleModal}>Adjust The MT group account type</ModalHeader>
        <ModalBody>
          {error && <Alert color="danger">{error}</Alert>}
          <div className="d-flex justify-content-center">
            <LayuiTransfer
              data={mtgroupssOptions}
              selectedData={selectedData}
              onChange={handleTransferChange}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={adjustMtGroups} disabled={!!error}>
            Confirm
          </Button>
          <Button color="secondary" onClick={toggleModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AdjustMTGroups;
