// fetch GROUPS
export const FETCH_GROUPS_REQUESTED = "FETCH_GROUPS_REQUESTED";
export const FETCH_GROUPS_SUCCESS = "FETCH_GROUPS_SUCCESS";
export const FETCH_GROUPS_FAIL = "FETCH_GROUPS_FAIL";
// sync GROUPS
export const SYNC_SYMBOL_REQUESTED = "SYNC_SYMBOL_REQUESTED";
export const SYNC_SYMBOL_SUCCESS = "SYNC_SYMBOL_SUCCESS";
export const SYNC_SYMBOL_FAIL = "SYNC_SYMBOL_FAIL";

export const PULL_MTGROUPS_REQUESTED = "PULL_MTGROUPS_REQUESTED";
export const PULL_MTGROUPS_SUCCESS = "PULL_MTGROUPS_SUCCESS";
export const PULL_MTGROUPS_FAIL = "PULL_MTGROUPS_FAIL";

export const FETCH_MTGROUPS_REQUESTED = "FETCH_MTGROUPS_REQUESTED";
export const FETCH_MTGROUPS_SUCCESS = "FETCH_MTGROUPS_SUCCESS";
export const FETCH_MTGROUPS_FAIL = "FETCH_MTGROUPS_FAIL";

export const FETCH_ACCOUNT_MTGROUPS_REQUESTED = "FETCH_ACCOUNT_MTGROUPS_REQUESTED";
export const FETCH_ACCOUNT_MTGROUPS_SUCCESS = "FETCH_ACCOUNT_MTGROUPS_SUCCESS";
export const FETCH_ACCOUNT_MTGROUPS_FAIL = "FETCH_ACCOUNT_MTGROUPS_FAIL";

export const ADJUST_MTGROUPS_REQUESTED = "ADJUST_MTGROUPS_REQUESTED";
export const ADJUST_MTGROUPS_SUCCESS = "ADJUST_MTGROUPS_SUCCESS";
export const ADJUST_MTGROUPS_FAIL = "ADJUST_MTGROUPS_FAIL";

export const ADD_GROUP_REQUESTED = "ADD_GROUP_REQUESTED";
export const ADD_GROUP_SUCCESS = "ADD_GROUP_SUCCESS";
export const ADD_GROUP_FAIL = "ADD_GROUP_FAIL";
export const ADD_GROUP_CLEAR = "ADD_GROUP_CLEAR";
export const ADD_GROUP_ERROR_CLEAR = "ADD_GROUP_ERROR_CLEAR";

export const DELETE_GROUP_START = "DELETE_GROUP_START";
export const DELETE_GROUP_END = "DELETE_GROUP_END";

export const EDIT_GROUPS_START = "EDIT_GROUPS_START";
export const EDIT_GROUPS_DONE = "EDIT_GROUPS_DONE";
export const EDIT_GROUPS_CLEAR = "EDIT_GROUPS_CLEAR";

export const APPROVE_INTERNAL_TRANSFER = "APPROVE_INTERNAL_TRANSFER";
export const APPROVE_INTERNAL_TRANSFER_SUCCESS = "APPROVE_INTERNAL_TRANSFER_SUCCESS";
export const REJECT_INTERNAL_TRANSFER = "REJECT_INTERNAL_TRANSFER";
export const REJECT_INTERNAL_TRANSFER_SUCCESS = "REJECT_INTERNAL_TRANSFER_SUCCESS";