import * as axiosHelper from "./api_helper";

export const getAudits = async ({ payload }) => {
  const data = await axiosHelper.get("/audit", {
    params: payload,
  });
  if (data.isError){
    throw new Error(data.isError);
  }

  return data;
};

export const updateAudit = async ({ payload }) => {
  const data = await axiosHelper.patch("/audit", payload);
  if (data.isError){
    throw new Error(data.isError);
  }

  return data;
};

export const getAuditByCustomerId = async ({ customerId }) => {
  const data = await axiosHelper.get(`/audit/customer/${customerId}`);
  if (data.isError){
    throw new Error(data.message);
  }

  return data;
};

export const getCRMDuplicates = async ({ customerId }) => {
  const data = await axiosHelper.get(`/audit/duplicates/${customerId}`);
  if (data.isError){
    throw new Error(data.message);
  }

  return data;
};

export const updateAccountRequest = async ({ payload }) => {
  const data = await axiosHelper.patch("/audit/account-request", payload);
  if (data.isError){
    throw new Error(data.isError);
  }

  return data;
};