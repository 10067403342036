import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal, Button,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import {
  AvForm, AvField, AvCheckboxGroup, AvCheckbox
} from "availity-reactstrap-validation";
import Loader from "components/Common/Loader";
import useModal from "hooks/useModal";
import AvFieldSelect from "components/Common/AvFieldSelect";
import validatePositiveInputs from "helpers/validatePositiveInputs";
import { addAccountType, fetchServerStart } from "store/actions";

const PLATFORMS = ["MT5", "MT4"];
const TYPES = ["LIVE", "DEMO", "IB", "MAM", "INVESTOR"];
const LEVERAGE = [1, 25, 50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 2000];
const CURRENCIES = ["USD", "EUR", "GBP", "EGP"];

function AddAccountType(props) {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  console.log("FROM ACC TYPE: ", state);
  const [showModal, toggleModal] = useModal(false);
  const [leverageOptions, setLeverageOptions] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [type, setType] = useState();
  const [platform, setPlatform] = useState();
  const [chosenServers, setChosenServersServers] = useState([]);
  const [selectedServer, setSelectedServer] = useState(null);

  const create = useSelector((state) => state.Profile?.AccTypesPermissions?.create);
  const { submitting, error } = useSelector((state) => state.tradingAccountReducer);

  useEffect(() => {
    (!submitting && !error && showModal) && toggleModal();
  }, [submitting]);
  useEffect(() => {
    dispatch(fetchServerStart());
  }, []);
  const isMamPam = ["MAM", "INVESTOR"].includes(type);

  useEffect(() => {
    setSelectedServer(null);
  }, [type, platform]);

  const handleSubmit = (e, v) => {
    if (type !== "MAM") {
      if (v.visibility.length > 0) {
        // convert visibility array to boolean properties
        v.visibility = v.visibility.reduce((acc, curr) => {
          acc[curr] = true;
          return acc;
        }, {});
        v = {
          ...v,
          ...v.visibility,
        };
      }
      delete v.visibility;
      // add groupPath to currencies array
      v.currencies = v.currencies.reduce((acc, curr) => {
        acc.push({
          currency: curr,
          groupPath: v[`${curr}GroupPath`],
        });
        delete v[`${curr}GroupPath`];
        return acc;
      }, []);
      dispatch(addAccountType({
        ...v,
        platform: type === "INVESTOR" ? "MT5" : v.platform,
      }));
    } else {
      v.currencies = v.currencies.reduce((acc, curr) => {
        if (type === "MAM") {
          acc.push({
            isMaster: true,
            currency: curr,
            groupPath: v[`${curr}MasterGroupPath`],
          }, {
            isMaster: false,
            currency: curr,
            groupPath: v[`${curr}FeeGroupPath`],
          });
          delete v[`${curr}MasterGroupPath`];
          delete v[`${curr}FeeGroupPath`];
          return acc;
        } else {
          acc.push({
            isMaster: false,
            currency: curr,
            groupPath: v[`${curr}GroupPath`],
          });
          delete v[`${curr}GroupPath`];
          return acc;
        }
      }
      , []);
      dispatch(addAccountType({
        ...v,
        forCrm: true,
        forCp: false,
        platform: "MT5"
      }));
    }
  };
  const servers = useSelector((state) => state?.MTServer?.servers);
  const selectedServers = (platform, type) => {
    if (servers?.docs) {
      return servers?.docs.filter((server) => server.platform === platform && server.type === type).map((server) => {
        return {
          label: server.name,
          value: server._id
        };
      });
    }
    return [];
  };
  console.log("SERVERS ACC TYPES: ", servers?.docs);
  useEffect(() => {
    const res = selectedServers(platform, type);
    setChosenServersServers(() => res);
    console.log("RES: ", res);
  }, [platform, type]);

  return (
    <>
      <Link
        to="#"
        className={`btn btn-primary ${!create ? "d-none" : ""}`}
        onClick={() => {
          setType(null);
          setPlatform(null);
          setLeverageOptions([]);
          setCurrencies([]);
          setSelectedServer(null);
          toggleModal();
        }}
      >
        <i className="bx bx-plus me-1"></i> Add New Type
      </Link>
      <Modal isOpen={showModal} toggle={toggleModal} centered={true}>
        <ModalHeader toggle={toggleModal} tag="h4">
          Add New Type
        </ModalHeader>
        <ModalBody>
          <AvForm
            className="px-4"
            onValidSubmit={(e, v) => {
              console.log("SUBMIT: ", v);

              handleSubmit(e, v);
            }}
          >
            <AvField
              name="title"
              label="Title"
              placeholder="Select Title"
              type="text"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Title is required",
                },
              }}
            />
            <AvFieldSelect
              name="type"
              label="Type"
              placeholder="Select Type"
              options={TYPES.map((type) => ({
                value: type,
                label: type,
              }))}
              value={type}
              onChange={(e) => {
                //setSelectedServer(null);
                setType(e);
              }}
              validate={{
                required: {
                  value: true,
                  errorMessage: "Type is required",
                },
              }}
            />
            {!isMamPam && (
              <AvFieldSelect
                name="platform"
                label="Platform"
                placeholder="Select Platform"
                onChange={(e) => {
                  console.log("PLATFORM: ", e);
                  setPlatform(e);
                }}
                options={PLATFORMS.map((type) => ({
                  value: type,
                  label: type,
                }))}
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Platform is required",
                  },
                }}
              />
            )}
            {platform && type && (
              <AvFieldSelect
                name="serverId"
                label="Server"
                placeholder="Select Server"
                options={chosenServers.map((type) => ({
                  value: type?.value,
                  label: type?.label,
                }))}
                value={selectedServer || ""}
                onChange={(e) => setSelectedServer(e)}
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Server is required",
                  },
                }}
              />
            )}
            <AvFieldSelect
              name="leverages"
              label="Leverage Options"
              placeholder="Select Leverage Options"
              ismulti="true"
              options={LEVERAGE.map((type) => ({
                value: type,
                label: type,
              }))}
              onChange={(e) => {
                setLeverageOptions(e);
              }}
              validate={{
                required: {
                  value: true,
                  errorMessage: "Leverage options is required",
                },
              }}
            />
            <AvFieldSelect
              name="defaultLeverage"
              label="Default Leverage"
              placeholder="Select Default Leverage"
              options={leverageOptions.map((type) => ({
                value: type,
                label: type,
              }))}
              validate={{
                required: {
                  value: true,
                  errorMessage: "Default leverage is required",
                },
              }}
            />
            <AvField
              name="sequence"
              label="Sequence"
              placeholder="Select Sequence"
              type="number"
              min="0"
              onKeyPress={(e) => {
                validatePositiveInputs(e);
              }}
              // validate={{
              //   required: {
              //     value: true,
              //     errorMessage: "Sequence is required"
              //   }
              // }}
            />
            <AvFieldSelect
              name="currencies"
              label="Currencies"
              placeholder="Select Currencies"
              ismulti="true"
              options={CURRENCIES.map((type) => ({
                value: type,
                label: type,
              }))}
              onChange={(e) => {
                setCurrencies(e);
              }}
              validate={{
                required: {
                  value: true,
                  errorMessage: "Currencies is required",
                },
              }}
            />
            {currencies.length > 0 &&
              currencies.map((currency) =>
                type === "MAM" ? (
                  <>
                    <AvField
                      key={currency}
                      name={`${currency}MasterGroupPath`}
                      label={`${currency} Master Group Path`}
                      placeholder={`Select ${currency} Master group path`}
                      type="text"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: `${currency} master group path is required`,
                        },
                      }}
                    />
                    <AvField
                      key={currency}
                      name={`${currency}FeeGroupPath`}
                      label={`${currency} Fee Group Path`}
                      placeholder={`Select ${currency} Fee group path`}
                      type="text"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: `${currency} Fee group path is required`,
                        },
                      }}
                    />
                  </>
                ) : (
                  <>
                    <AvField
                      key={currency}
                      name={`${currency}GroupPath`}
                      label={`${currency} Group Path`}
                      placeholder={`Select ${currency} group path`}
                      type="text"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: `${currency} group path is required`,
                        },
                      }}
                    />
                  </>
                )
              )}
            {type !== "MAM" && (
              <>
                <AvCheckboxGroup inline name="visibility">
                  <AvCheckbox label="For CRM" value="forCrm" />
                  <AvCheckbox label="For CP" value="forCp" />
                </AvCheckboxGroup>
              </>
            )}
            <div className="text-center mt-3 p-2">
              {submitting ? (
                <Loader />
              ) : (
                <Button
                  disabled={props.addLoading}
                  type="submit"
                  color="primary"
                >
                  Add
                </Button>
              )}
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
    </>
  );
}

export default AddAccountType;