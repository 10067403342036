import React, { useEffect, useState } from "react";
import { 
  useDispatch, 
  connect, 
  useSelector 
} from "react-redux";
import MetaTags from "react-meta-tags";
import { fetchDictionaryStart } from "store/dictionary/actions";
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import ClientTransactions from "./crypto/ClientTransactions";
import ForexClientTransactions from "./forex/ForexClientTransactions";
import { useParams } from "react-router-dom";
import ClientInternalTransfers from "./InternalTransfer/ClientInternalTransfers";
import ClientCredit from "./Credit/ClientCredit";
import WithdrawalForexFundingDepartmentInitial from "pages/Transactions/Forex/withdrawals/forexFundingSteps/WithdrawalForexFundingDepartmentInitial";
import WithdrawalForexRiskDepartmentReview from "pages/Transactions/Forex/withdrawals/forexFundingSteps/WithdrawalForexRiskDepartmentReview";
import WithdrawalForexFundingDepartmentDeduction from "pages/Transactions/Forex/withdrawals/forexFundingSteps/WithdrawalForexFundingDepartmentDeduction";
import WithdrawalForexWithdrawalRemittance from "pages/Transactions/Forex/withdrawals/forexFundingSteps/WithdrawalForexWithdrawalRemittance";

function ClientTransactionsIndex(props) {
  const { clientId } = useParams();
  const dispatch = useDispatch();
  useState(() => {
    dispatch(fetchDictionaryStart());
  }, []);
  const { profileMetaInfo = {} } = useSelector((state) => state.Profile);

  const { 
    fundingDepartmentInitialPermissions,
    riskDepartmentReviewPermissions,
    fundingDepartmentDeductionPermissions, 
    withdrawalRemittancePermissions, 
  } = useSelector((state) => (state.Profile || {}));

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };
  const toggleFunding = (tab) => {
    if (activeFundingTab !== tab) {
      setactiveFundingTab(tab);
    }
  };

  const loadTabs = ({
    transactions: { depositDetails: { crypto, forex } = {} } = {},
    clientId,
  }) => [
    {
      tabId: "1",
      navLinkName: "Wallets",
      component: <ClientTransactions clientId={clientId} />,
      hidden: !forex,
    },
    {
      tabId: "2",
      navLinkName: "Forex",
      component: <ForexClientTransactions clientId={clientId} />,
      hidden: !forex,
    },
    {
      tabId: "3",
      navLinkName: "Internal Transfer",
      component: <ClientInternalTransfers clientId={clientId} />,
      hidden: !forex,
    },
    {
      tabId: "4",
      navLinkName: "Credit",
      component: <ClientCredit clientId={clientId} />,
      hidden: !forex,
    },
  ];

  const tabs = loadTabs({ 
    ...profileMetaInfo, 
    clientId 
  }).filter((item) => !item.hidden);
  const [activeTab, setactiveTab] = useState(
    tabs.length > 0 ? tabs[0].tabId : "1"
  );

  
  const [activeFundingTab, setactiveFundingTab] = useState("fundingDepartmentInitial");

  const loadForexFundingStepsTabs = () => [
    fundingDepartmentInitialPermissions?.get && {
      tabId: "fundingDepartmentInitial",
      navLinkName: "Funding Department Initial",
      component: <WithdrawalForexFundingDepartmentInitial fundingStep={activeFundingTab} clientId={clientId} />,
    },
    riskDepartmentReviewPermissions?.get && {
      tabId: "riskDepartmentReview",
      navLinkName: "Risk Department Review",
      component: <WithdrawalForexRiskDepartmentReview fundingStep={activeFundingTab} clientId={clientId} />,
    },
    fundingDepartmentDeductionPermissions?.get  && {
      tabId: "fundingDepartmentDeduction",
      navLinkName: "Funding Department Deduction",
      component: <WithdrawalForexFundingDepartmentDeduction fundingStep={activeFundingTab} clientId={clientId} />,
    },
    withdrawalRemittancePermissions?.get && {
      tabId: "withdrawalRemittance",
      navLinkName: "Withdrawal Remittance",
      component: <WithdrawalForexWithdrawalRemittance fundingStep={activeFundingTab} clientId={clientId} />,
    },
  ];

  const forexFundingTabs = loadForexFundingStepsTabs()?.filter(tab=>tab);
  useEffect(()=> {
    if (fundingDepartmentInitialPermissions?.get) {
      setactiveFundingTab("fundingDepartmentInitial");
    } else if (riskDepartmentReviewPermissions?.get) {
      setactiveFundingTab("riskDepartmentReview");
    } else if (fundingDepartmentDeductionPermissions?.get) {
      setactiveFundingTab("fundingDepartmentDeduction");
    } else if (withdrawalRemittancePermissions?.get) {
      setactiveFundingTab("withdrawalRemittance");
    } else {
      setactiveFundingTab("");
    }
  }, [fundingDepartmentInitialPermissions, riskDepartmentReviewPermissions, fundingDepartmentDeductionPermissions, withdrawalRemittancePermissions]);
  

  return (
    <React.Fragment>
      <MetaTags>
        <title >Transactions</title>
      </MetaTags>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <Nav tabs>
                {tabs.map((tabItem) => (
                  <>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === tabItem.tabId,
                        })}
                        onClick={() => {
                          toggle(tabItem.tabId);
                        }}
                      >
                        {tabItem.navLinkName}
                      </NavLink>
                    </NavItem>
                  </>
                ))}
              </Nav>
              {/* {
                activeTab === "2" &&
                  <Nav tabs>
                    {forexFundingTabs?.map((tabItem) => (
                      <>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeFundingTab === tabItem?.tabId,
                            })}
                            onClick={() => {
                              toggleFunding(tabItem?.tabId);
                            }}
                          >
                            {tabItem?.navLinkName}
                          </NavLink>
                        </NavItem>
                      </>
                    ))}
                  </Nav>
              } */}
              <TabContent activeTab={activeTab} className="p-3 text-muted">
                {tabs.map((tabItem) => (
                  <>
                    <TabPane tabId={tabItem.tabId}>
                      <Row>
                        <Col sm="12">{tabItem.component}</Col>
                      </Row>
                    </TabPane>
                  </>
                ))}
              </TabContent>
              

              {/* {
                activeTab === "2" &&
                  <TabContent activeTab={activeFundingTab} className="p-3 text-muted">
                    {forexFundingTabs.map((tabItem) => (
                      <>
                        <TabPane tabId={tabItem.tabId}>
                          <Row>
                            <Col sm="12">{tabItem.component}</Col>
                          </Row>
                        </TabPane>
                      </>
                    ))}
                  </TabContent>
              } */}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
const mapStateToProps = (state) => ({
  loading: state.dictionaryReducer.loading || false,
  dictionary: state.dictionaryReducer.dictionary || [],
  error: state.dictionaryReducer.error,
});
export default connect(mapStateToProps, null)(ClientTransactionsIndex);
