import React from "react";
import { useTranslation } from "react-i18next";
import {
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import {
  Col,
  Modal, ModalBody, ModalHeader, Row,
} from "reactstrap";

const RebateRulesModal = ({ show, toggle, viewModal }) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={show} toggle={toggle} centered={true} scrollable={true} size="lg">
      <ModalHeader toggle={toggle} tag="h4">
        {t("Rebate Rules")}
      </ModalHeader>
      <ModalBody >
        <Row className="mb-3">
          <Col>
            <Table className="table table-hover text-center">
              <Thead>
                <Tr>
                  <Th>{"MT Group"}</Th>
                  <Th>{"Statement Methods"}</Th>
                  <Th>{"Statement Unit"}</Th>
                  <Th>{"Tier 1 IB Rebate Rules"}</Th>
                  <Th>{"Promotion Optional Rebate Value"}</Th>
                  <Th>{"Status"}</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td className="pt-4">{viewModal?.mtGroupId?.mtGroupName}</Td>
                  <Td className="pt-4">{viewModal?.statementMethod}</Td>
                  <Td className="pt-4">{viewModal?.statementUnit}</Td>
                  <Td className="pt-4">{viewModal?.tier1RebateRules?.map((rule) => `${rule} -`)}</Td>
                  <Td className="pt-4">{viewModal?.promotionOptionalRebateValue}</Td>
                  <Td className="pt-4">
                    {viewModal?.status ? <span className="fw-bold text-success">Enabled</span> : <span className="fw-bold text-danger">Disabled</span>}
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Col>
        </Row>
      </ModalBody>
    </Modal >
  );
};

export default RebateRulesModal;
