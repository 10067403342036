import React, { memo, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Alert,
  Col, Label, Row
} from "reactstrap";
import { Accordion } from "react-bootstrap";
import { startCase } from "lodash";

function AddSubIbAgreement({
  agg, isValid, setIsValid, setPayload
}) {
  const { accountTypes } = useSelector((state) => state.tradingAccountReducer);
  const [errorMessage, setErrorMessage] = React.useState("");

  
  const getAccountTypeName = (id, accountTypes) => {
    const accountType = accountTypes.find((accountType) => accountType._id === id);
    return accountType?.title;
  };

  const [agreement, setAgreement] = React.useReducer(
    (state, action) => {
      switch (action.type) {
        case "SET":
          return action.payload;
        case "SET_TOTAL_REBATE":
          return {
            ...state,
            totals: state.totals.map((total) => {
              if (total.accountTypeId === action.payload.accountTypeId) {
                return {
                  ...total,
                  rebate: parseInt(action.payload.rebate),
                };
              }
              return total;
            }),
          };
        case "SET_TOTAL_COMMISSION":
          return {
            ...state,
            totals: state.totals.map((total) => {
              if (total.accountTypeId === action.payload.accountTypeId) {
                return {
                  ...total,
                  commission: parseInt(action.payload.commission),
                };
              }
              return total;
            }),
          };
        case "UPDATE_ACCOUNT_REBATE":
          return {
            ...state,
            members: state?.members?.map((member) => {
              if (member?.customerId?._id === action.payload?.customerId) {
                return {
                  ...member,
                  values: member?.values?.map((value) => {
                    if (value?.accountTypeId === action.payload?.accountTypeId) {
                      return {
                        ...value,
                        rebate: action.payload?.rebate,
                        products: Object.keys(value?.products ?? {})?.reduce((acc, prod) => {
                          acc[prod] = {
                            ...value?.products[prod],
                            rebate: action.payload?.rebate,
                          };
                          return acc;
                        }, {}),
                      };
                    }
                    return value;
                  }),
                };
              }
              return member;
            }),
          };
        case "UPDATE_ACCOUNT_COMMISSION":
          return {
            ...state,
            members: state?.members?.map((member) => {
              if (member?.customerId?._id === action.payload?.customerId) {
                return {
                  ...member,
                  values: member?.values?.map((value) => {
                    if (value?.accountTypeId === action.payload?.accountTypeId) {
                      return {
                        ...value,
                        commission: action.payload?.commission,
                        products: Object.keys(value?.products ?? {})?.reduce((acc, prod) => {
                          acc[prod] = {
                            ...value?.products[prod],
                            commission: action.payload?.commission,
                          };
                          return acc;
                        }, {}),
                      };
                    }
                    return value;
                  }),
                };
              }
              return member;
            }),
          };
        case "UPDATE_PRODUCT_REBATE":
          return {
            ...state,
            members: state?.members?.map((member) => {
              if (member?.customerId?._id === action.payload?.customerId) {
                return {
                  ...member,
                  values: member?.values?.map((value) => {
                    if (value?.accountTypeId === action.payload?.accountTypeId) {
                      const newProducts = Object.keys(value?.products ?? {})?.reduce((acc, prod) => {
                        if (prod === action.payload?.productId) {
                          acc[prod] = {
                            ...value?.products[prod],
                            rebate: action.payload?.rebate,
                          };
                        } else {
                          acc[prod] = value?.products[prod];
                        }
                        return acc;
                      }, {});
                      return {
                        ...value,
                        products: newProducts,
                      };
                    }
                    return value;
                  }),
                };
              }
              return member;
            }),
          };
        case "UPDATE_PRODUCT_COMMISSION":
          return {
            ...state,
            members: state?.members?.map((member) => {
              if (member?.customerId?._id === action.payload?.customerId) {
                return {
                  ...member,
                  values: member?.values?.map((value) => {
                    if (value?.accountTypeId === action.payload?.accountTypeId) {
                      const newProducts = Object.keys(value?.products ?? {})?.reduce((acc, prod) => {
                        if (prod === action.payload?.productId) {
                          acc[prod] = {
                            ...value?.products[prod],
                            rebate: action.payload?.rebate,
                          };
                        } else {
                          acc[prod] = value?.products[prod];
                        }
                        return acc;
                      }, {});
                      return {
                        ...value,
                        products: newProducts,
                      };
                    }
                    return value;
                  }),
                };
              }
              return member;
            }),
          };
        default:
          return state;
      }
    },
    agg
  );

  useEffect(() => {
    // check if all the values are filled in total and every member rebate & commission plus their products rebate and commission
    const isAgreementValid = agreement?.totals?.every((total) => total.rebate && total.commission) &&
    agreement?.members?.every((member) => member.values.every((value) => value.rebate && value.commission &&
        Object.values(value.products).every((product) => product.rebate && product.commission)));
    if (!isAgreementValid) {
      setIsValid(false);
      return setErrorMessage("Please fill all the values");
    }
    // check if if total rebate and commission is greater than or equal to sum of each member rebate and commission
    const isTotalValid = agreement?.totals?.every((total) => {
      const totalRebate = agreement?.members?.reduce((acc, member) => {
        const memberRebate = member?.values?.filter(
          (value) => value.accountTypeId === total.accountTypeId
        ).reduce((acc, value) => parseInt(acc) + parseInt(value.rebate), 0);
        return acc + memberRebate;
      }, 0);
      const totalCommission = agreement?.members?.reduce((acc, member) => {
        const memberCommission = member?.values?.filter(
          (value) => value.accountTypeId === total.accountTypeId
        ).reduce((acc, value) => parseInt(acc) + parseInt(value.commission), 0);
        return acc + memberCommission;
      }, 0);
      return total.rebate >= totalRebate && total.commission >= totalCommission;
    });

    if (!isTotalValid) {
      setIsValid(false);
      return setErrorMessage("Total rebate and commission should be greater than or equal to sum of each member rebate and commission");
    }

    // check if each members rebate and commission is greater than or greatest of their products rebate and commission
    const isMemberValid = agreement?.members?.every((member) => {
      const isMemberRebateValid = member?.values?.every((value) => {
        const memberRebate = Object.values(value?.products ?? {})?.find((product) => {
          return product.rebate > value.rebate;
        });
        return !memberRebate;
      });
      const isMemberCommissionValid = member?.values?.every((value) => {
        const memberCommission = Object.values(value?.products ?? {})?.find((product) => {
          return product.commission > value.commission;
        });
        return !memberCommission;
      });
      return isMemberRebateValid && isMemberCommissionValid;
    });
    if (!isMemberValid) {
      setIsValid(false);
      return setErrorMessage("Each members account rebate and commission should be greater than or equal to max of their products rebate and commission");
    }

    setIsValid(true);
    setErrorMessage("");
  }, [agreement]);

  useEffect(() => {
    if (isValid) {
      setPayload({
        type: "SUB_SHARED",
        payload: agreement
      });
    }
  }, [isValid]);


  return (
    <div className="my-2">
      <Row>
        <Col md={3}>
          <div>
            <Label className="mb-0">Title</Label>
            <input
              type="text"
              className="form-control"
              placeholder="title"
              value={agreement?.title}
            />
          </div>
        </Col>
        <Col md={9}>
          <div>
            <h4 className="w-100 text-center">Totals</h4>
          </div>
          <div className="my-2">
            {agreement?.totals?.map((total, idx) =>
              <Row key={idx} className="my-1 align-items-center">
                <Col md={4} className="d-flex ">
                  <h6>{getAccountTypeName(total.accountTypeId, accountTypes)}</h6>
                </Col>
                <Col md={4} className="my-2">
                  <Label className="mb-0">Rebate</Label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Total Rebate"
                    value={total.rebate}
                    min={0}
                    onChange={(e) => setAgreement({
                      type: "SET_TOTAL_REBATE",
                      payload: {
                        accountTypeId: total.accountTypeId,
                        rebate: e.target.value,
                      },
                    })}
                  />
                </Col>
                <Col md={4} className="my-1">
                  <Label className="mb-0">Commission</Label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Total Commission"
                    value={total.commission}
                    min={0}
                    onChange={(e) => setAgreement({
                      type: "SET_TOTAL_COMMISSION",
                      payload: {
                        accountTypeId: total.accountTypeId,
                        commission: e.target.value,
                      },
                    })}
                  />
                </Col>
              </Row>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        {agreement?.members?.map((member) =>
          <Col md={12} key={`${member.customerId._id}`}>
            <Accordion alwaysOpen>
              <Accordion.Item eventKey={member.customerId?._id}>
                <Accordion.Header>
                  {member.customerId.firstName} {member.customerId.lastName}
                </Accordion.Header>
                <Accordion.Body>
                  <Accordion>
                    {member?.values.map((account, index) =>
                      <Accordion.Item eventKey={index} key={`${member.customerId?._id}#${account._id}`}>
                        <Accordion.Header>
                          <Row className="w-100 d-flex justify-content-between">
                            <Col md={4} className="d-flex align-items-center">
                              <h6>{getAccountTypeName(account.accountTypeId, accountTypes)}</h6>
                            </Col>
                            <Col md={4} >
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Total Rebate"
                                value={account.rebate}
                                min={0}
                                onChange={(e) => setAgreement({
                                  type: "UPDATE_ACCOUNT_REBATE",
                                  payload: {
                                    customerId: member.customerId._id,
                                    accountTypeId: account.accountTypeId,
                                    rebate: e.target.value,
                                  },
                                })}
                              />
                            </Col>
                            <Col md={4} >
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Total Commission"
                                value={account.commission}
                                min={0}
                                onChange={(e) => setAgreement({
                                  type: "UPDATE_ACCOUNT_COMMISSION",
                                  payload: {
                                    customerId: member.customerId._id,
                                    accountTypeId: account.accountTypeId,
                                    commission: e.target.value,
                                  },
                                })}
                              />
                            </Col>
                          </Row>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div>
                            {account.products && Object.entries(account.products).map(([key, value]) =>
                              <Row key={`${member.customerId?._id}#${account._id}#${key}`} className="my-1">
                                <Col md={4}>
                                  {startCase(key)}
                                </Col>
                                <Col md={4} >
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Rebate"
                                    value={value.rebate}
                                    min={0}
                                    onChange={(e) => setAgreement({
                                      type: "UPDATE_PRODUCT_REBATE",
                                      payload: {
                                        customerId: member.customerId._id,
                                        accountTypeId: account.accountTypeId,
                                        productId: key,
                                        rebate: e.target.value,
                                      },
                                    })}
                                  />
                                </Col>
                                <Col md={4}>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Commission"
                                    value={value.commission}
                                    min={0}
                                    onChange={(e) => setAgreement({
                                      type: "UPDATE_PRODUCT_COMMISSION",
                                      payload: {
                                        customerId: member.customerId._id,
                                        accountTypeId: account.accountTypeId,
                                        productId: key,
                                        commission: e.target.value,
                                      },
                                    })}
                                  />
                                </Col>
                              </Row>
                            )}
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    )} 
                  </Accordion>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        )}
      </Row>
      {
        !isValid && (
          <Alert color="danger"  className="my-3">
            {errorMessage}
          </Alert>
        )
      }
    </div>
  );
}

export default memo(AddSubIbAgreement);