import React, { useState, useEffect } from "react";
import {
  useDispatch, useSelector
} from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import { adjustMtGroupsRequest, deleteGroupStart } from "store/rebateSettings/groups/actions";
import { changeSymbolStart, fetchSymbols } from "store/rebateSettings/symbols/actions";
import { changeRebateRulesStart, fetchRebateRules } from "store/rebateSettings/rebateRules/actions";
import { fetchAccountTypes, updateAccountType } from "store/actions";

function switchStatus({ switchedId, setSwitchStatus, t, type, selectedMTGroup, show, text, selectedGroup, accountType }) {
  const dispatch = useDispatch();
  const { editedSuccess } = useSelector((state) => state.tradingAccountReducer);
  const { addSuccess } = useSelector((state) => state.groupReducer);
  const toggleModal = () => {
    setSwitchStatus(preValue => !preValue);
  };

  useEffect(()=>{
    if (( editedSuccess || addSuccess ) && show){
      toggleModal();
      dispatch(
        fetchAccountTypes({
          limit: 100000,
          page: 1,
          platform: type,
        })
      );
    }
  }, [editedSuccess, addSuccess]);

  return (
    <React.Fragment >
      <Modal isOpen={show} toggle={toggleModal} centered={true}>
        <ModalHeader toggle={toggleModal} tag="h4">
          {t("Confirm Prompt")}
        </ModalHeader>
        <ModalBody className="text-center" >
          {accountType ? (
            <h5>
              {t(
                `Whether to ${accountType.enabled ? "deactivate" : "activate"} account type?`
              )}
            </h5>
          ) : selectedMTGroup ? (
            <h5>
              {t(
                `Whether to ${selectedMTGroup.status ? "deactivate" : "activate"} mt group?`
              )}
            </h5>
          ) : (
            <h5>{t(`Whether to Switch the status of ${text}?`)}</h5>
          )}

        </ModalBody>
        <ModalFooter>
          <Button type="submit" color="primary"
            onClick={() => {
              if (text === "mt group") {
                dispatch(adjustMtGroupsRequest({
                  id: selectedMTGroup?._id,
                  status: selectedMTGroup.status ===  "Enabled" ? "Disabled" : "Enabled"
                }));
              } else {
                dispatch(updateAccountType(selectedGroup?._id, { enabled: !selectedGroup?.enabled }));
              }
            }}>
            {t("Yes")}
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

export default withTranslation()(switchStatus);