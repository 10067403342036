import {
  ADD_SERVER_START,
  ADD_SERVER_END,
  GET_SERVER_START,
  GET_SERVER_END,
  EDIT_SERVER_START,
  EDIT_SERVER_END,
  DELETE_SERVER_START,
  DELETE_SERVER_END,
} from "./actionTypes";

export const fetchServerStart = (params = {}) => {
  return {
    type: GET_SERVER_START,
    payload: params
  };
};
export const fetchServerEnd = (data) => {
  return {
    type: GET_SERVER_END,
    payload: data
  };
};

// export const fetchRemindersStart = (params = {}) => {
//   return {
//     type: GET_REMINDERS_START,
//     payload: params
//   };
// };
// export const fetchRemindersEnd = (data) => {
//   return {
//     type: GET_REMINDERS_END,
//     payload: data
//   };
// };

// export const fetchNotesStart = (params = {}) => {
//   return {
//     type: GET_NOTES_START,
//     payload: params
//   };
// };
// export const fetchNotesEnd = (data) => {
//   return {
//     type: GET_NOTES_END,
//     payload: data
//   };
// };

// export const fetchRemarksStart = (params = {}) => {
//   return {
//     type: GET_REMARKS_START,
//     payload: params
//   };
// };
// export const fetchRemarksEnd = (data) => {
//   return {
//     type: GET_REMARKS_END,
//     payload: data
//   };
// };

export const addServerStart = (params = {}) => {
  return {
    type: ADD_SERVER_START,
    payload: params
  };
};
export const addServerEnd = (data) => {
  return {
    type: ADD_SERVER_END,
    payload: data
  };
};

// export const addReminderStart = (params = {}) => {
//   return {
//     type: ADD_REMINDER_START,
//     payload: params
//   };
// };
// export const addReminderEnd = (data) => {
//   return {
//     type: ADD_REMINDER_END,
//     payload: data
//   };
// };
// export const addNoteStart = (params = {}) => {
//   return {
//     type: ADD_NOTE_START,
//     payload: params
//   };
// };
// export const addNoteEnd = (data) => {
//   return {
//     type: ADD_NOTE_END,
//     payload: data
//   };
// };
// export const addRemarkStart = (params = {}) => {
//   return {
//     type: ADD_REMARK_START,
//     payload: params
//   };
// };
// export const addRemarkEnd = (data) => {
//   return {
//     type: ADD_REMARK_END,
//     payload: data
//   };
// };

// export const addTodoClear = (data) => {
//   return {
//     type: ADD_TODO_CLEAR,
//     payload: data
//   };
// };

export const deleteServerStart = (params = {}) => {
  return {
    type: DELETE_SERVER_START,
    payload: params
  };
};

export const deleteServerEnd = (data) => {
  return {
    type: DELETE_SERVER_END,
    payload: data
  };
};

// export const clearError = () => {
//   return {
//     type: MODAL_CLEAR_ERROR,
//     payload: ""
//   };
// };


export const editServerStart = (params = {})=>{
  return {
    type:EDIT_SERVER_START,
    payload:params
  };
};

export const editServerEnd = (params = {})=>{
  return {
    type:EDIT_SERVER_END,
    payload:params
  };
};