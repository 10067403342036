import { useDispatch, connect } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledAlert,
  Label,
  Input,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { addNewItem } from "store/dictionary/actions";
import CustomCreatableSelect from "components/Common/CustomCreatableSelect";
import SearchableSymbolGroupsDropdown from "./SearchableComponents/SearchableSymbolGroupsDropdown";
import AvFieldSelect from "components/Common/AvFieldSelect";
function SymbolsAdd(props){
  const [addModal, setAddModal] = useState(false);
  const dispatch = useDispatch();
  const toggleAddModal = ()=>{
    setAddModal(preValue => !preValue);
  };
  const { create } = props.dictionariesPermissions;
  useEffect(()=>{
    if (!props.showAddSuccessMessage && addModal){
      setAddModal(false);
    }
  }, [props.showAddSuccessMessage]);
  return (
    <React.Fragment >
      <Link to="#"  className={`btn btn-primary ${!create ? "d-none" : ""}`} onClick={toggleAddModal}><i className="bx bx-plus me-1"></i>{props.t("Trading Volume Rebate Settings-Add")}</Link>
      <Modal isOpen={addModal} toggle={toggleAddModal} centered={true} size="lg">
        <ModalHeader toggle={toggleAddModal} tag="h4">
          {props.t("Trading Volume Rebate Settings-Add")}
        </ModalHeader>
        <ModalBody >
          <AvForm
            className='p-4'
            onValidSubmit={(e, v) => {
              e.preventDefault();
              dispatch(addNewItem(props.id, v));
            }}
          > 
            <div className="mb-3">
              <AvField
                name="ruleName"
                label={props.t("Rule Name")}
                placeholder={props.t("Enter Rule Name")}
                type="text"
                errorMessage={props.t("Enter valid Rule Name")}
                validate={{ required: { value: true } }}
              />
            </div>

            <div className="my-3">
              <AvFieldSelect
                name="rulesApply"
                type="text"
                errorMessage={props.t(
                  "Statement Method is required"
                )}
                label={props.t("Rules Apply")}
                options={[]}
              />
            </div>
            <div className="my-3">
              <AvFieldSelect
                name="symbolGroup"
                type="text"
                errorMessage={props.t(
                  "ٌSymbol Group is required"
                )}
                label={props.t("Symbol Group")}
                options={[]}
              />
            </div>
            <div className="my-3">
              <AvFieldSelect
                name="accountGroup"
                type="text"
                errorMessage={props.t(
                  "ٌAccount Group is required"
                )}
                label={props.t("Account Group")}
                options={[]}
              />
            </div>
            <div className="my-3">
              <AvFieldSelect
                name="accountType"
                type="text"
                errorMessage={props.t(
                  "ٌAccount Type is required"
                )}
                label={props.t("Account Type")}
                options={[]}
              />
            </div>
            <div className="my-3">
              <AvFieldSelect
                name="mtGroup"
                type="text"
                errorMessage={props.t(
                  "ٌMT Group is required"
                )}
                label={props.t("MT Group")}
                options={[]}
              />
            </div>
            <div className="my-3">
              <AvFieldSelect
                name="spreadRebateGroup"
                type="text"
                errorMessage={props.t(
                  "ٌSpread Rebate Group is required"
                )}
                label={props.t("Spread Rebate Group")}
                options={[]}
              />
            </div>
            <div className="my-3">
              <AvFieldSelect
                name="statementMethod"
                type="text"
                errorMessage={props.t(
                  "Statement Method is required"
                )}
                label={props.t("Statement Method")}
                options={[]}
              />
            </div>
            <div className="mt-3">
              <AvFieldSelect
                name="statementUnit"
                type="text"
                errorMessage={props.t(
                  "Statement Unit is required"
                )}
                label={props.t("Statement Unit")}
                options={[]}
              />
            </div>
            <div className="mt-3">
              <AvFieldSelect
                name="promotionOptionalRebateValue"
                type="text"
                errorMessage={props.t(
                  "Promotion Optional Rebate Value is required"
                )}
                label={props.t("Promotion Optional Rebate Value")}
                options={[]}
              />
            </div>
            <div className="mt-3">
              <Label>Tier 1 IB Rebate Rules</Label>
              <CustomCreatableSelect
                isMulti
                isClearable
                disableDeleteButton={false}
                disableCreateButton={false}
                placeholder="Please enter Tier 1 IB Rebate Rules"
                // dispatchState={(passedData) =>
                //   dispatch({
                //     type: "UPDATE_SWAP_FREE_GROUPS",
                //     key: "swapFreeGroups",
                //     swapFreeGroups: passedData,
                //   })
                // }
                // value={swapFreeGroups}
              />
            </div>
            <div className="mt-3">
              <AvField
                name="sorting"
                label={props.t("Sorting")}
                placeholder={props.t("Enter Sorting")}
                type="text"
                errorMessage={props.t("Enter valid Sorting")}
                validate={{ required: { value: true } }}
              />
            </div>
            <div className="my-3">
              <AvFieldSelect
                name="status"
                type="text"
                errorMessage={props.t(
                  "Status is required"
                )}
                label={props.t("Status")}
                options={[]}
              />
            </div>
              
            
            <div className='text-center pt-3 p-2'>
              <Button disabled = {props.disableAddButton} type="submit" color="primary" className="">
                {props.t("Add")}
              </Button>
            </div>
          </AvForm>
          {props.error && <UncontrolledAlert color="danger">
            <i className="mdi mdi-block-helper me-2"></i>
            {props.editError}
          </UncontrolledAlert>}
          
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}
const mapStateToProps = (state)=>({
  id :state.dictionaryReducer.id,
  error:state.dictionaryReducer.error,
  showAddSuccessMessage: state.dictionaryReducer.showAddSuccessMessage,
  dictionariesPermissions : state.Profile.dictionariesPermissions || {},
  disableAddButton : state.dictionaryReducer.disableAddButton
});
export default connect(mapStateToProps, null)(withTranslation()(SymbolsAdd));