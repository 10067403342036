import React, { useEffect, useRef } from "react";
import "layui-src/dist/css/layui.css";
import "layui-src";

const LayuiTransfer = ({ data, selectedData, onChange }) => {
  const transferRef = useRef(null);

  useEffect(() => {
    const layui = window.layui;

    layui.use(["transfer"], function () {
      const transfer = layui.transfer;

      transfer.render({
        elem: transferRef.current,
        data: data,
        value: selectedData,
        title: ["Available", "Selected"],
        showSearch: true,
        text: {
          none: "No data", 
          searchNone: "No match found"
        },
        onchange: function (obj, index) {
          // index 0 means moving from available to selected
          // index 1 means moving from selected to available
          if (index === 0) {
            // When moving from available to selected, merge with existing selected
            const newSelectedData = obj.map(item => item.value);
            const combinedData = Array.from(new Set([...selectedData, ...newSelectedData]));
            onChange(combinedData);
          } else {
            // When moving from selected to available, remove only selected items
            const selectedToRemove = obj.map(item => item.value);
            const remainingSelected = selectedData.filter(id => !selectedToRemove.includes(id));
            onChange(remainingSelected);
          }
        }
      });

      const searchInputs = transferRef.current.querySelectorAll(".layui-transfer-search input");
      searchInputs.forEach(input => {
        input.placeholder = "Search...";
      });
    });
  }, [data, selectedData, onChange]);

  return (
    <>
      <div ref={transferRef}></div>
      <style>{`
      .layui-transfer-box .layui-transfer-active {
        background-color: #426A9E !important; /* Change green selection to blue */
        color: white !important;
      }

      .layui-transfer-box {
        width: 300px !important;
      }

      .layui-transfer-active .layui-btn {
        background-color: #426A9E;
        border-color: #426A9E;
        color: white;
        width: 100%;
      }

      .layui-form-checked[lay-skin=primary] i {
        background-color: #426A9E !important; /* Change checkbox color to blue */
        border-color: #426A9E !important;
        color: white !important;
      }
      
      .layui-transfer-btn-disabled {
        background-color: #cccccc !important;
        color: #999999 !important;
      }
    `}</style>
    </>
  );
};

export default LayuiTransfer;
