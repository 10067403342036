import React, { useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import { startCase } from "lodash";
import Badge from "components/Common/Badge";
import moment from "moment";
const getFundingStepKey = {
  fundingDepartmentInitial: "Funding Department Initial",
  riskDepartmentReview: "Risk Department Review",
  fundingDepartmentDeduction: "Funding Department Deduction",
  withdrawalRemittance: "Withdrawal Remittance",
};
function HistoryDetailsModal(props) {
  const { transaction, history, open, onClose } = props;
  const baseUrl = process.env.REACT_APP_API_CRM_DOMAIN;
  console.log("TRANSACTION: ", transaction);
  return (
    <React.Fragment>
      <div>
        <Modal isOpen={open} toggle={onClose} centered={true} size={"lg"}>
          <ModalHeader toggle={onClose} tag="h4">
            {props.t("History Details")}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col xs="6">
                <p className="mb-1">
                  <strong>{startCase("ID")}</strong>
                </p>
                <p className="">{transaction?.recordId}</p>
              </Col>
              <Col xs="6">
                <p className="mb-1">
                  <strong>{startCase("Overall Status")}</strong>
                </p>
                <Badge status={transaction?.status}></Badge>
              </Col>
              <Col xs="6">
                <p className="mb-1">
                  <strong>{startCase("Current Step")}</strong>
                </p>
                <p className="">
                  {getFundingStepKey?.[`${transaction?.fundingSteps?.currentStep}`]}
                </p>
              </Col>
            </Row>
            <hr />
            {history &&
              history
                ?.sort((a, b) => new Date(b.date) - new Date(a.date))
                ?.map((item, index) => {
                  // Find the first accepted item in fundingDepartmentInitial stage
                  const firstAcceptedItem = history
                    .slice()
                    .reverse()
                    .find(h => 
                      h.step === "fundingDepartmentInitial" && 
                      h.status === "APPROVED"
                    );
                  
                  let duration = "-";

                  if (firstAcceptedItem) {
                    const startDate = moment(firstAcceptedItem?.date);
                    const endDate = moment(item?.date);
                    
                    // Ensure both dates are valid
                    if (startDate.isValid() && endDate.isValid()) {
                      const diffInMs = endDate.diff(startDate);
                      
                      if (diffInMs >= 0) {
                        const durationObj = moment.duration(diffInMs);
                        
                        const days = Math.floor(durationObj.asDays());
                        const hours = durationObj.hours();
                        const minutes = durationObj.minutes();
                        const seconds = durationObj.seconds();
                        
                        const durationParts = [];
                        
                        if (days > 0) durationParts.push(`${days}d`);
                        if (hours > 0) durationParts.push(`${hours}h`);
                        if (minutes > 0) durationParts.push(`${minutes}m`);
                        if (seconds > 0) durationParts.push(`${seconds}s`);
                        
                        duration = durationParts.length > 0 ? durationParts.join(" ") : "0s";
                      } else {
                        duration = item.step === "fundingDepartmentInitial" ? "0s" : "Awaiting acceptance";
                      }
                    } else {
                      duration = "Invalid date";
                    }
                  } else if (item.step === "fundingDepartmentInitial") {
                    duration = "Initial stage";
                  } else {
                    duration = "Awaiting initial approval";
                  }

                  return (
                    <React.Fragment key={item?._id}>
                      {history.length - index} - 
                      <Row>
                        <Col xs="6">
                          <p className="mb-1">
                            <strong>{startCase("Step")}</strong>
                          </p>
                          <p className="">
                            {getFundingStepKey?.[`${item?.step}`]}
                          </p>
                        </Col>
                        <Col xs="6">
                          <p className="mb-1">
                            <strong>{startCase("Status")}</strong>
                          </p>
                          <p className="text-muted">
                            <Badge status={item?.status}></Badge>
                          </p>
                        </Col>
                        <Col xs="6">
                          <p className="mb-1">
                            <strong>{startCase("Date")}</strong>
                          </p>
                          <p className="text-muted">
                            {moment(item?.date).format("DD-MM-YYYY HH:mm:ss")}
                          </p>
                        </Col>
                        <Col xs="6">
                          <p className="mb-1">
                            <strong>{startCase("processedBy")}</strong>
                          </p>
                          <p className="text-muted">
                            {item?.processedByDetails?.firstName} {item?.processedByDetails?.lastName}
                          </p>
                        </Col>
                        <Col xs="12">
                          <p className="mb-1">
                            <strong>{startCase("Note")}</strong>
                          </p>
                          <p className="text-muted">{item?.note}</p>
                        </Col>
                        <Col xs="12">
                          <p className="mb-1">
                            <strong>{startCase("Processing Duration")}</strong>
                          </p>
                          <p className="text-muted">{duration}</p>
                        </Col>
                      </Row>
                      <hr />
                    </React.Fragment>
                  );
                })}
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
}
export default withTranslation()(HistoryDetailsModal);