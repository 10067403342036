import {
  ADD_SERVER_START,
  ADD_SERVER_END,
  GET_SERVER_START,
  GET_SERVER_END,
  EDIT_SERVER_START,
  EDIT_SERVER_END,
  DELETE_SERVER_START,
  DELETE_SERVER_END,
} from "./actionTypes";

const INIT_STATE = {
  loading: false,
  servers: { docs: [] },
  //reminders: { docs: [] },
  // notes: { docs: [] },
  // remarks: { docs: [] },
  // clearingCounter: 0,
  // deletingClearCounter: 0,
};

const MTServer = (state = INIT_STATE, action) => {
  let docs = state.list && state.list.docs || [];
  switch (action.type) {
    case GET_SERVER_START:
    // case GET_REMINDERS_START:
    // case GET_NOTES_START:
    // case GET_REMARKS_START:
      return {
        ...state,
        loading: true,
      };
    case GET_SERVER_END:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        servers: action.payload.data
      };
    // case GET_REMINDERS_END:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.payload.error,
    //     reminders: action.payload.data,
    //   };
    // case GET_NOTES_END:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.payload.error,
    //     notes: action.payload.data,
    //   };
    // case GET_REMARKS_END:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.payload.error,
    //     remarks: action.payload.data,
    //   };
    case ADD_SERVER_START:
    // case ADD_REMINDER_START:
    // case ADD_NOTE_START:
    // case ADD_REMARK_START:
      return {
        ...state,
        adding: true,
      };
    case ADD_SERVER_END:
      if (action.payload.id) {
        docs = state.servers.docs.map(obj => {
          if (obj._id === action.payload.id) {
            return {
              ...obj,
              ...action.payload.data,
            };
          }
          return obj;
        });
      }
      if (action.payload.data && !action.payload.id) {
        docs = [action.payload.data, ...state.servers.docs];
      }
      return {
        ...state,
        adding: false,
        addError: action.payload.error,
        servers: {
          ...state.servers,
          docs,
        },
      };
    // case ADD_REMINDER_END:
    //   if (action.payload.id) {
    //     docs = state.reminders.docs.map(obj => {
    //       if (obj._id === action.payload.id) {
    //         return {
    //           ...obj,
    //           ...action.payload.data,
    //         };
    //       }
    //       return obj;
    //     });
    //   }
    //   if (action.payload.data && !action.payload.id) {
    //     docs = [action.payload.data, ...state.reminders.docs];
    //   }
    //   return {
    //     ...state,
    //     adding: false,
    //     addError: action.payload.error,
    //     reminders: {
    //       ...state.reminders,
    //       docs,
    //     },
    //   };
    // case ADD_NOTE_END:
    //   if (action.payload.id) {
    //     docs = state.notes.docs.map(obj => {
    //       if (obj._id === action.payload.id) {
    //         return {
    //           ...obj,
    //           ...action.payload.data,
    //         };
    //       }
    //       return obj;
    //     });
    //   }
    //   if (action.payload.data && !action.payload.id) {
    //     docs = [action.payload.data, ...state.notes.docs];
    //   }
    //   return {
    //     ...state,
    //     adding: false,
    //     addError: action.payload.error,
    //     notes: {
    //       ...state.notes,
    //       docs,
    //     },
    //   };
    // case ADD_REMARK_END:
    //   if (action.payload.id) {
    //     docs = state.remarks.docs.map(obj => {
    //       if (obj._id === action.payload.id) {
    //         return {
    //           ...obj,
    //           ...action.payload.data,
    //         };
    //       }
    //       return obj;
    //     });
    //   }
    //   if (action.payload.data && !action.payload.id) {
    //     docs = [action.payload.data, ...state.remarks.docs];
    //   }
    //   return {
    //     ...state,
    //     adding: false,
    //     addError: action.payload.error,
    //     remarks: {
    //       ...state.remarks,
    //       docs,
    //     },
    //   };
    // case ADD_TODO_CLEAR:
    //   return {
    //     ...state,
    //     clearingCounter: state.clearingCounter + 1
    //   };
    case DELETE_SERVER_START:
      return {
        ...state,
        deleting: true,
      };
    case DELETE_SERVER_END:
      return {
        ...state,
        deleting: false,
        //deletingClearCounter: state.deletingClearCounter + 1,
      };
    // case MODAL_CLEAR_ERROR:
    //   return {
    //     ...state,
    //     addError: ""
    //   };
    case EDIT_SERVER_START:
      return {
        ...state,
        editDone: true,
      };
    case EDIT_SERVER_END:
      return {
        ...state,
        editDone: false,
      };
    default:
      return { ...state };
  }
};

export default MTServer;
