import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Select from "react-select";

const SearchableRebateRulesDropdown = (props) => {
  const {
    label = "Rebate Rule",
    placeholder = "Search",
    isRequired = false,
    name = "rebateRule",
    isDisabled = false,
    defaultOptions = [],
    value = null,
    clientData = null,
    onChange = () => { },
    isMulti = true,
    isPromote = false,
    closeMenuOnSelect = true,
  } = props;

  // Transform options to the correct format if needed
  const formattedOptions = defaultOptions.map(option => ({
    label: option.label || option.mtGroupName || option.name || "",
    value: option.value || option._id || option.id || "",
  }));

  // Custom filter function that handles special characters and partial matches
  const customFilterOption = (option, inputValue) => {
    if (!inputValue) return true;
    
    const searchValue = inputValue.toLowerCase().trim();
    const optionLabel = (option.label || "").toLowerCase();
    
    // Simple partial match
    return optionLabel.includes(searchValue);
  };

  return (
    <div>
      {label && <label>{props.t(label)}</label>}
      {isRequired && <span style={{color: "red"}}> * </span>}
      <Select
        name={name}
        options={formattedOptions}
        value={!isPromote ? value?.map((item) => ({
          label: item.mtGroupName || item.label || "",
          value: item._id || item.value || "",
        })) : value?.map((val) => ({
          label: val,
          value: val,
        }))}
        defaultValue={!isPromote ? value?.map((item) => ({
          label: item.mtGroupName || item.label || "",
          value: item._id || item.value || "",
        })) : value?.map((val) => ({
          label: val,
          value: val,
        }))}
        isRequired={isRequired}
        placeholder={placeholder}
        isSearchable={true}
        isClearable={true}
        onChange={onChange}
        isMulti={isMulti}
        isDisabled={isDisabled}
        menuPortalTarget={document.body}
        closeMenuOnSelect={closeMenuOnSelect}
        filterOption={customFilterOption}
        styles={{
          control: (base) => ({
            ...base,
            minHeight: "38px",
          }),
          menu: (base) => ({
            ...base,
            overflowY: "auto",
            zIndex: 9999,
          }),
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
          }),
        }}
      />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    dictionariesPermissions: state.Profile.dictionariesPermissions,
  };
};

export default connect(mapStateToProps, null)(withTranslation()(SearchableRebateRulesDropdown));