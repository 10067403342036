import { approveKyc } from "apis/client";
import classNames from "classnames";
import formatDate from "helpers/formatDate";
import React, {
  useState,
  useCallback,
  useEffect,
} from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  Table,
  TabPane,
  Badge,
  Spinner,
  Card,
} from "reactstrap";
import FeatherIcon from "feather-icons-react";
import usePermissions from "routes/permissions";
import { showErrorNotification, showSuccessNotification } from "store/actions";
import { startCase } from "lodash";
import { getAuditByCustomerId } from "apis/audit";
import CRMDuplicates from "./CRMDuplicates";
import { useParams } from "react-router-dom";

const AuditFlow = () => {
  const { clientId } = useParams();
  const [dataDiff, setDataDiff] = useState(null);
  const [activeTab, setActiveTab] = useState("1");
  const [isApprovingKyc, setIsApprovingKyc] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAudit, setSelectedAudit] = useState(null);
  const [error, setError] = useState(null);

  const { auditPermissions } = usePermissions();

  const dispatch = useDispatch();
  
  const toggle = useCallback((tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  }, [activeTab]);

  const approveKycForClient = useCallback(async (clientId) => {
    setIsApprovingKyc(true);
    try {
      const { status } = await approveKyc(clientId);
      if (status) {
        dispatch(showSuccessNotification("KYC approved successfully"));
      }
    } catch (error) {
      dispatch(showErrorNotification(error.message));
      console.error("Error approving KYC:", error);
    } finally {
      setIsApprovingKyc(false);
    }
  }, [dispatch]);

  const loadAudit = async (clientId) => {
    setIsLoading(true);
    setError(null);
    try {
      const { result, status } = await getAuditByCustomerId({
        customerId: clientId,
      });
      if (status) {
        setSelectedAudit(result);
      } else {
        setError("Failed to load audit data. Please try again.");
      }
    } catch (error) {
      setSelectedAudit(null);
      setError("An error occurred while loading audit data. Please try again or contact admin.");
      dispatch(showErrorNotification(error.message));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (clientId) {
      loadAudit(clientId);
    }
  }, [clientId]);

  const renderDataDiffModal = () => (
    <Modal isOpen={!!dataDiff} toggle={() => setDataDiff(null)} size="lg">
      <ModalHeader toggle={() => setDataDiff(null)}>Data Diff</ModalHeader>
      <ModalBody>
        <Table responsive bordered striped hover>
          <thead>
            <tr>
              <th>Field</th>
              <th>Old Value</th>
              <th>New Value</th>
            </tr>
          </thead>
          <tbody>
            {dataDiff && Object.keys(dataDiff.old).map((key) => (
              <tr key={key}>
                <td>{startCase(key)}</td>
                <td>{JSON.stringify(dataDiff.old[key])}</td>
                <td>{JSON.stringify(dataDiff.new[key])}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </ModalBody>
    </Modal>
  );
  // const renderDataDiffModal = () => (
  //   <Table responsive bordered striped hover>
  //     <thead>
  //       <tr>
  //         <th>Field</th>
  //         <th>Old Value</th>
  //         <th>New Value</th>
  //       </tr>
  //     </thead>
  //     <tbody>
  //       {dataDiff && Object.keys(dataDiff.old).map((key) => (
  //         <tr key={key}>
  //           <td>{startCase(key)}</td>
  //           <td>{JSON.stringify(dataDiff.old[key])}</td>
  //           <td>{JSON.stringify(dataDiff.new[key])}</td>
  //         </tr>
  //       ))}
  //     </tbody>
  //   </Table>
  // );

  if (isLoading) {
    return (
      // <Modal isOpen={true} centered toggle={toggleModal} size="xl">
      <div className="text-center">
        <Spinner color="primary" />
        <p className="mt-2">Loading audit data...</p>
      </div>
      // </Modal>
    );
  }

  return (
    // <Modal isOpen={true} toggle={toggleModal} size="xl">
    // <ModalHeader toggle={toggleModal}>Audit Updates</ModalHeader>
    <Card className="mb-3 px-3">
      <div className="d-flex justify-content-end mb-3">
        {!selectedAudit?.customerId?.stages?.kycApproved && selectedAudit?.status === "DUPLICATE" && 
          auditPermissions.update && (
          <Button
            color="primary"
            onClick={() => approveKycForClient(selectedAudit.customerId._id)}
            disabled={isApprovingKyc}
          >
            {isApprovingKyc ? "Approving..." : "Approve KYC"}
          </Button>
        )}
      </div>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classNames({ active: activeTab === "1" })}
            onClick={() => {
              toggle("1");
            }}
          >
            Audit Updates
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classNames({ active: activeTab === "2" })}
            onClick={() => {
              toggle("2");
            }}
          >
            Questionnaires
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classNames({ active: activeTab === "3" })}
            onClick={() => {
              toggle("3");
            }}
          >
            CRM Duplicates
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          {(selectedAudit && !error) ? <>
            
            <Table responsive bordered striped hover className="mt-3">
              <thead>
                <tr>
                  <th>Timestamp</th>
                  <th>Status</th>
                  <th>Updated By</th>
                  <th>User ID</th>
                  <th>Remarks</th>
                  <th>Details</th>
                </tr>
              </thead>
              <tbody>
                {selectedAudit
                  .updates
                  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                  .map((update, index) => (
                    <tr key={index}>
                      <td>{formatDate(update.createdAt)}</td>
                      <td>
                        <Badge color="info">{update.status}</Badge>
                      </td>
                      <td>{update.updatedBy}</td>
                      <td>
                        {update.userId?._id
                          ? `${update.userId.firstName} ${update.userId.lastName}`
                          : "-"}
                      </td>
                      <td>{update.remarks}</td>
                      <td>
                        {update.newData && update.oldData && (
                          <Button
                            color="link"
                            className="p-0"
                            onClick={() =>
                              setDataDiff({
                                new: update.newData,
                                old: update.oldData,
                              })
                            }
                          >
                            <FeatherIcon icon="eye" className="icon-sm" />
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </> : <>
            <div className="d-flex flex-column align-items-center justify-content-center py-5">
              <FeatherIcon icon="alert-triangle" size={48} className="text-warning mb-3" />
              <h4 className="text-center mb-3">No Audit Data Found</h4>
              <p className="text-muted text-center mb-4">
                {error || "We couldn't find any audit data for this selection. Please try again or contact an administrator for assistance."}
              </p>
              <Button color="primary" onClick={() => loadAudit(clientId)}>
                <FeatherIcon icon="refresh-cw" size={16} className="me-2" />
          Retry
              </Button>
            </div>
          </>}
        </TabPane>
        <TabPane tabId="2">
          {(selectedAudit && selectedAudit?.questionnaire &&
          selectedAudit?.questionnaire?.length > 0) ? (
              selectedAudit?.questionnaire?.map((questionnaire) =>
                questionnaire?.questions?.map((question, qIndex) => (
                  <div key={qIndex} className="card my-3 p-3">
                    <h6 className="mb-2">
                    Q{qIndex + 1}: {question?.question}
                    </h6>
                    <p className="mb-0">
                      <strong>A: </strong>
                      {question?.answer}
                    </p>
                  </div>
                ))
              )
            ) : (
              <div className="text-center my-3">
                <p>No questionnaires found</p>
              </div>
            )}
        </TabPane>
        <TabPane tabId="3">
          <CRMDuplicates clientId={clientId} />
        </TabPane>
      </TabContent>
      {renderDataDiffModal()}
    </Card>
    // {/* </Modal> */}
  );
};

export default AuditFlow;
