import React, { useEffect, useState } from "react";
import { 
  connect, useSelector 
} from "react-redux";
import MetaTags from "react-meta-tags";
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  Row,
  TabContent,
  TabPane,
  NavLink,
} from "reactstrap";
import classNames from "classnames";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import MT5Groups from "./MT5Groups";
import MT4Groups from "./MT4Groups";

function SalesPromotionsIndex(){
  const { profileMetaInfo = {} } = useSelector((state) => state.Profile);
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const tab = query.get("tab");
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      query.set("tab", tab);
    }
  };
  const { userData } = useSelector((state) => ({
    userData: state.Profile,
  }));
  const permissions = userData?.roleId?.permissions;
  const loadTabs = ({
    transactions: { depositDetails: {  forex } = {} } = {},
  }) => [
    {
      tabId: "1",
      navLinkName: "Agent Promotion Links",
      component: <MT5Groups />,
      // hidden: !forex,
    },
    {
      tabId: "2",
      navLinkName: "Direct Client Promotion Links",
      component: <MT4Groups />,
      // hidden: !forex,
    },
  ];

  const tabs = loadTabs(profileMetaInfo).filter((tab) => !tab.hidden);
  const [activeTab, setActiveTab] = useState("1");
  useEffect(() => {
    if (tabs.length > 0) {
      setActiveTab(tabs[0].tabId);
    }
    if (tab) {
      setActiveTab(tab);
    }
  }, [profileMetaInfo]);
  return (
    <React.Fragment>
      <MetaTags>
        <title>
          Sales Promotions Links
        </title>
      </MetaTags>
      <div className="page-content" style={{zoom: 0.8}}> 
        <div className="container-fluid">
          {/* <h2>Rebate Rules</h2> */}
          {permissions?.salesPromotionLinks?.view ? (
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <Nav tabs>
                      {tabs?.map((tabItem) => (
                        <NavItem key={tabItem.navLinkName}>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classNames({
                              active: activeTab === tabItem.tabId,
                            })}
                            onClick={() => {
                              toggle(tabItem.tabId);
                            }}
                          >
                            {tabItem.navLinkName}
                          </NavLink>
                        </NavItem>
                      ))}
                    </Nav>
                    <TabContent activeTab={activeTab} className="p-3 text-muted">
                      {tabs.map((tabItem) => (
                        <>
                          <TabPane tabId={tabItem.tabId}>
                            <Row>
                              <Col sm="12">{tabItem.component}</Col>
                            </Row>
                          </TabPane>
                        </>
                      ))}
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>

          ) : (
            <h1 className="text-center" style={{marginTop: "20%"}}>You Do Not Have The Required Permissions</h1>              
          )}
          
        </div>
      </div>
    </React.Fragment>
  );
}
const mapStateToProps = (state)=>({
  loading: state.dictionaryReducer.loading || false,
  dictionary: state.dictionaryReducer.dictionary || [],
  error : state.dictionaryReducer.error,
});
export default connect(mapStateToProps, null)(SalesPromotionsIndex);