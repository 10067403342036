import {
  call,
  put,
  takeEvery,
  delay
} from "redux-saga/effects";
import { 
  FETCH_REBATE_RULES_REQUESTED,
  ADD_REBATE_RULE_REQUESTED,
  DELETE_REBATE_RULE_START,
  EDIT_REBATE_RULES_START,
  CHANGE_REBATE_RULES_START
} from "./actionTypes";
import {

  fetchRebateRulesSuccess,
  fetchRebateRulesFail,
  editRebateRulesDone,
  editRebateRulesClear,
  addRebateRulesSuccess,
  deleteRebateRulesEnd,
  addRebateRulesClear,
  addRebateRulesFail,
  changeRebateRulesEnd
} from "./actions";
import * as rebateSettingsApis from "apis/rebateSettings";
import { showErrorNotification, showSuccessNotification } from "store/notifications/actions";

function * fetchRebateRules(params){
  try {
    const data = yield call(rebateSettingsApis.getRebateRules, params);
    yield put(fetchRebateRulesSuccess(data));
  } catch (err){
    yield put(fetchRebateRulesFail(err.message));
  }
}

// function * syncSymbols(params){
//   try {
//     const { payload } = params;
//     const data = yield call(rebateSettingsApis.syncSymbols, payload);
//     // if (payload.accountType === "ibAccount"){
//     //   data = yield call(rebateSettingsApis.postIbInternalTransfer, values);
//     // } else data = yield call(rebateSettingsApis.postInternalTransfer, values);
//     yield put(addSymbolsuccess(data));
//     yield put(showSuccessNotification("Symbol Synced Successfully"));
//     yield put(addREBATE_RULEClear());
//   } catch (err){
//     yield put(showErrorNotification(err.message));
//     yield put(addREBATE_RULEFail(err.message));
//     yield delay(5000);
//     yield put(addREBATE_RULEErrorClear());
//   }
// }

function * addRebateRules(params){
  try {
    const { payload } = params;
    const data = yield call(rebateSettingsApis.addRebateRules, payload);
    // if (payload.accountType === "ibAccount"){
    //   data = yield call(rebateSettingsApis.postIbInternalTransfer, values);
    // } else data = yield call(rebateSettingsApis.postInternalTransfer, values);
    yield put(addRebateRulesSuccess(data));
    yield put(showSuccessNotification("Rebate Rule Added Successfully"));
    yield put(addRebateRulesClear());
  } catch (err){
    yield put(showErrorNotification(err.message));
    yield put(addRebateRulesFail(err.message));
    yield delay(5000);
    yield put(addRebateRulesClear());
  }
}

function* deleteRebateRules(params) {
  try {
    yield call(rebateSettingsApis.deleteRebateRules, params.payload);
    yield put(deleteRebateRulesEnd({ id: params.payload }));
    yield put(showSuccessNotification("Rebate Rule Deleted successfully"));
  }
  catch (error) {
    yield put(deleteRebateRulesEnd({ error }));
    yield put(showErrorNotification(error.message));
  }
}

function * editRebateRules(params){
  try {
    yield call(rebateSettingsApis.editRebateRules, params);
    // const { result } = data;
    yield put(editRebateRulesDone({
      result: params.payload.values,
      id: params.payload.id  
    }));
    yield put(showSuccessNotification("Rebate Rule updated successfully"));
    // yield delay(2000);
    yield put(editRebateRulesClear());
  }
  catch (error){
    yield put(showErrorNotification(error.message));
    yield put(addRebateRulesFail(error.message));
    yield delay(5000);
    yield put(addRebateRulesClear());
  } 
}

function* changeRebateRulesStatus(params) {
  try {
    yield call(rebateSettingsApis.changeRebateRulesStatus, params.payload);
    yield put(changeRebateRulesEnd({ id: params.payload }));
    yield put(showSuccessNotification("Symbol status changed successfully"));
    yield put(editRebateRulesClear());
  }
  catch (error) {
    yield put(changeRebateRulesEnd({ error }));
    yield put(showErrorNotification(error.message));
  }
}

function * RebateRulesSaga(){
  yield takeEvery(FETCH_REBATE_RULES_REQUESTED, fetchRebateRules);
  yield takeEvery(CHANGE_REBATE_RULES_START, changeRebateRulesStatus);
  yield takeEvery(ADD_REBATE_RULE_REQUESTED, addRebateRules);
  yield takeEvery(DELETE_REBATE_RULE_START, deleteRebateRules);
  yield takeEvery(EDIT_REBATE_RULES_START, editRebateRules);
}
export default RebateRulesSaga;