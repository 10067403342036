/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import {
  useDispatch, connect, useSelector 
} from "react-redux";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
// eslint-disable-next-line object-curly-newline
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import FeatherIcon from "feather-icons-react";
// import { Link } from "react-router-dom";

// i18n
import { withTranslation } from "react-i18next";
import CustomPagination from "components/Common/CustomPagination";
import TableLoader from "components/Common/TableLoader";
import { fetchTradingAccounts, updatePermission } from "store/actions";
import CreateTradingAccount from "components/Common/CreateTradingAccount";
import { useParams } from "react-router-dom";
import formatDate from "helpers/formatDate";
// import DeleteModal from "components/Common/DeleteModal";
import ChangeAccess from "../Profile/QuickActions/tradingAccounts/ChangeAccess";
import LinkMT5 from "../Profile/QuickActions/tradingAccounts/LinkMT5";
import ChangePassword from "../Profile/QuickActions/tradingAccounts/ChangePassword";
import ChangeType from "../Profile/QuickActions/tradingAccounts/ChangeType";
import ChangeLeverage from "../Profile/QuickActions/tradingAccounts/ChangeLeverage";
import useModal from "hooks/useModal";
import Positions from "./Positions";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import CreateMT5 from "../Profile/QuickActions/tradingAccounts/CreateMT5";
import { checkAllBoxes } from "common/utils/checkAllBoxes";

function Accounts(props) {
  const [sizePerPage, setSizePerPage] = useState(10);
  const [showModal, setShowModal] = useModal();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [accountId, setAccountId] = useState(null);
  const [accountPlatform, setAccountPlatform] = useState(null);
  const { clientId } = useParams();
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const hasNoDemoAccounts = selectedAccounts?.every(account => account.type !== "DEMO");
  const [isAllChecked, setIsAllChecked] = useState(false);
  const { userData } = useSelector((state) => ({
    userData: state.Profile.userData,
  }));
  const [showAccessModal, setShowAccessModal] = useModal();
  const [showLinkModal, setShowLinkModal] = useModal();
  const [showPassModal, setShowPassModal] = useModal();
  const [showTypeModal, setShowTypeModal] = useModal();
  const [showLeverageModal, setShowLeverageModal] = useModal();
  const { userData: usrData } = useSelector((state) => ({
    userData: state.Profile,
  }));
  const permissions = usrData?.roleId?.permissions;
  const {tradingAccount} = permissions;

  useEffect(() => {
    if (isAllChecked) {
      setSelectedAccounts(props.accounts);
    } else {
      setSelectedAccounts([]);
    }
  }, [isAllChecked]);

  const columns = [
    {
      dataField: "checkbox",
      text: (
        <div className="d-flex align-items-center justify-content-center">
          {/* <i className="mdi mdi-circle font-size-11 me-2" style={{ color: "#c3c3c3" }}></i> */}
          <input
            type="checkbox"
            id="select-all-clients"
            onChange={(e) => {
              checkAllBoxes("select-all-clients", ".client-checkbox");
              setSelectedAccounts(() => {
                if (e.target.checked) {
                  setIsAllChecked(true);
                } else if (!e.target.checked) {
                  setIsAllChecked(false);
                }
              });
            }}
          />
        </div>
      ),
      formatter: (val) => (
        <div className="d-flex align-items-center justify-content-center">
          {/* <i
          className="mdi mdi-circle font-size-11 me-2"
          style={{
            color: enableCallStatusColors && callStatusColors[val.callStatus] ? callStatusColors[val.callStatus] : "#f3f3f3",
          }}></i> */}
          <input
            type="checkbox"
            onChange={(e) =>
              setSelectedAccounts((preValue) => {
                if (e.target.checked) {
                  return [val, ...preValue];
                } else if (!e.target.checked) {
                  return preValue.filter((acc) => acc._id !== val._id);
                }
              })
            }
            className="client-checkbox"
          />
        </div>
      ),
    },
    {
      dataField: "login",
      text: props.t("Login"),
      formatter: (val) => (
        <a
          className="fw-bold"
          style={{ cursor: "pointer" }}
          onClick={() => { setAccountId(val._id); setAccountPlatform(val.platform)}}
        >
          {val?.login || ""}
        </a>
      ),
    },
    {
      dataField: "accountTypeId",
      text: props.t("Account Type"),
      formatter: (val) => val?.accountTypeId?.title ?? val.title ?? "",
    },
    {
      dataField: "accountTypeGroup",
      text: props.t("Account Group"),
      formatter: (val) => val?.accountTypeId?.groups ? val?.swapType === "SWAP" ?   val?.accountTypeId.groups?.swapGroups[0] : val?.accountTypeId.groups?.swapFreeGroups[0] : val?.accountTypeId?.currencies?.[0]?.groupPath,
    },
    {
      dataField: "accountSwapType",
      text: props.t("S/SF"),
      formatter: (val) => val?.swapType || "-",
    },
    {
      dataField: "accountTypeId",
      text: props.t("Platform"),
      formatter: (val) => val?.accountTypeId?.platform ?? val.platform ?? "",
    },
    {
      dataField: "accountTypeId",
      text: props.t("Type"),
      formatter: (val) => val?.accountTypeId?.type ?? val.type ?? "",
    },
    {
      dataField: "currency",
      text: props.t("Currency"),
    },
    {
      dataField: "MarginLeverage",
      text: props.t("Leverage"),
    },
    {
      dataField: "Balance",
      text: props.t("Balance"),
    },
    {
      dataField: "Credit",
      text: props.t("Credit"),
    },
    {
      dataField: "Equity",
      text: props.t("Equity"),
      formatter: (val) => isNaN(val.Equity) ? "0.00" : parseFloat(val.Equity).toFixed(2),
    },
    {
      dataField: "Margin",
      text: props.t("Margin"),
      formatter: (val) => isNaN(val.Margin) ? "0.00" : parseFloat(val.Margin).toFixed(2),
    },
    {
      dataField: "MarginLevel",
      text: props.t("Margin Level"),
      formatter: (val) => isNaN(val.MarginLevel) ? "0.00" : parseFloat(val.MarginLevel).toFixed(2),
    },
    {
      dataField: "MarginFree",
      text: props.t("Margin Free"),
      formatter: (val) => isNaN(val.MarginFree) ? "0.00" : parseFloat(val.MarginFree).toFixed(2),
    },
    {
      dataField: "createdAt",
      text: props.t("Date Created"),
      formatter: (val) => formatDate(val.createdAt),
    },
    {
      dataField: "createdAt",
      text: props.t("SP"),
      formatter: (val) => {
        console.log(val);
        if (!val?.masters) return "-";
        return val?.masters?.map((sp)=> {
          return <>
            <Link
              to={`/clients/${sp?.customerId?._id}/profile`}
              className="fw-bold"
              style={{ cursor: "pointer" }}
            >
              {sp?.customerId?.firstName} {sp?.customerId?.lastName} , {" "}
            </Link>
          </>;
        });
      }
    },
    {
      dataField: "dropdown",
      text: props.t("Actions"),
      formatter: (val) => (
        <UncontrolledDropdown>
          <DropdownToggle tag="i" style={{ cursor: "pointer" }}>
            <i
              className="mdi mdi-dots-horizontal font-size-18"
              onClick={() => {
                setSelectedAccount(val);
              }}
            />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem onClick={setShowTypeModal} href="#">
              {props.t("Trading Account Type")}
            </DropdownItem>
            <DropdownItem onClick={setShowPassModal} href="#">
              {props.t("Reset Trading Account")}
            </DropdownItem>
            <DropdownItem onClick={setShowAccessModal} href="#">
              {props.t("Change Access")}
            </DropdownItem>
            <DropdownItem onClick={setShowLeverageModal} href="#">
              {props.t("Change Leverage")}
            </DropdownItem>
            {(val.accountTypeId?.type === "LIVE" || val?.type === "LIVE") && (
              <>
                <DropdownItem onClick={() => {
                  dispatch(updatePermission({
                    _id: val._id,
                    type: "WITHDRAWAL",
                    disable: !val.withdrawalDisabled
                  }));
                }} href="#">
                  {props.t(`${selectedAccount?.withdrawalDisabled ? "Enable" : "Disable"} Withdrawal`)}
                </DropdownItem>
                <DropdownItem onClick={() => {
                  dispatch(updatePermission({
                    _id: val._id,
                    type: "DEPOSIT",
                    disable: !val.depositDisabled
                  }));
                }} href="#">
                  {props.t(`${selectedAccount?.depositDisabled ? "Enable" : "Disable"} Deposit`)}
                </DropdownItem>
              </>
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
  ];
  const dispatch = useDispatch();
  const fetchData = async (page) => {
    dispatch(
      fetchTradingAccounts({
        page: page || 1,
        limit: sizePerPage,
        customerId: clientId,
      })
    );
  };

  useEffect(() => {
    fetchData(1);
  }, [sizePerPage, clientId]);

  useEffect(() => {
    if (props.deletingClearCounter > 0 && showDeleteModal) {
      setShowDeleteModal(false);
    }
  }, [props.deletingClearCounter]);

  return (
    <React.Fragment>
      <div className="">
        <div className="container-fluid">
        <Row>
            <Col className="col-12">
              {
              tradingAccount.get ?
                <Card>
                <CardHeader className="d-flex justify-content-between align-items-center gap-2">
                  <CardTitle className="color-primary">
                    {props.t("Trading Accounts")} ({props.totalDocs})
                    <FeatherIcon
                      icon="refresh-cw"
                      className="icon-lg ms-2"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        fetchData(props.currentPage);
                      }}
                    />
                  </CardTitle>
                  {tradingAccount.create &&
                    <Row className="align-items-center justify-content-start gap-3">
                      <Col xs="auto">
                        {selectedAccounts?.length > 0 && hasNoDemoAccounts && (
                          <UncontrolledDropdown>
                            <DropdownToggle tag="button" style={{ 
                              cursor: "pointer",
                               border: "none"
                              }}>
                              <button className="btn btn-primary">
                                {"Transactions Actions"}
                              </button>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                              <DropdownItem
                                onClick={() => {
                                  dispatch(updatePermission({
                                    tradingAccountIds: selectedAccounts,
                                    type: "WITHDRAWAL",
                                    disable: false,
                                  }));
                                }}
                                href="#"
                              >
                                {props.t("Enable Withdrawal")}
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => {
                                  dispatch(updatePermission({
                                    tradingAccountIds: selectedAccounts,
                                    type: "DEPOSIT",
                                    disable: false,
                                  }));
                                }}
                                href="#"
                              >
                                {props.t("Enable Deposit")}
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => {
                                  dispatch(updatePermission({
                                    tradingAccountIds: selectedAccounts,
                                    type: "WITHDRAWAL",
                                    disable: true,
                                  }));
                                }}
                                href="#"
                              >
                                {props.t("Disable Withdrawal")}
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => {
                                  dispatch(updatePermission({
                                    tradingAccountIds: selectedAccounts,
                                    type: "DEPOSIT",
                                    disable: true,
                                  }));
                                }}
                                href="#"
                              >
                                {props.t("Disable Deposit")}
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        )}
                      </Col>
                      
                        <Col xs="auto" style={{ minWidth: "100px" }}>
                          <LinkMT5 show={showLinkModal} toggle={setShowLinkModal} customerId={clientId} />
                        </Col>
                      
                        <Col xs="auto" style={{ minWidth: "300px" }}>
                          <CreateMT5
                            show={showModal}
                            toggle={setShowModal}
                            customerId={clientId}
                            modalOpenBtnLabel={"Create Trading Account"}
                          />
                        </Col>
                    </Row>}

                  {/* <DeleteModal
                    loading={props.deleteLoading}
                    onDeleteClick={deleteRole}
                    show={showDeleteModal }
                    onCloseClick={()=>{setShowDeleteModal(false)}}
                  /> */}
                </CardHeader>
                <CardBody>
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                      style={{
                        overflow: "visible",
                        overflowX: "auto",
                      }}
                    >
                      <Table
                        id="tech-companies-1"
                        className="table  table-hover"
                      >
                        <Thead className="text-center table-light">
                          <Tr>
                            {tradingAccount.actions ?
                             columns.map((column, index) =>
                              <Th data-priority={index} key={index}>
                                <span className="color-primary">{column.text}</span>
                              </Th>
                            ) :
                            columns.filter((column) => column.text !== "Actions").map((column, index) =>
                              <Th data-priority={index} key={index}>
                                <span className="color-primary">{column.text}</span>
                              </Th>
                            )
                          }
                          </Tr>
                        </Thead>
                        <Tbody>
                          {props.fetchAllTradingAccountsLoading && <TableLoader colSpan={4} />}                            
                          {!props.fetchAllTradingAccountsLoading &&
                            props.accounts.length === 0 && (
                              <>
                                <Tr>
                                  <Td
                                    colSpan={"100%"}
                                    className="fw-bolder text-center"
                                    st="true"
                                  >
                                    <h3 className="fw-bolder text-center">
                                      {props.t("No records")}
                                    </h3>
                                  </Td>
                                </Tr>
                              </>
                            )}
                          {!props.fetchAllTradingAccountsLoading &&
                            props.accounts.length !== 0 &&
                            props.accounts.map((row, rowIndex) => (
                              <Tr key={rowIndex}>
                                {tradingAccount.actions ? columns.map((column, index) => (
                                  <Td
                                    key={`${rowIndex}-${index}`}
                                    className="text-center"
                                  >
                                    {column.formatter
                                      ? column.formatter(row, rowIndex)
                                      : row[column.dataField]}
                                  </Td>
                                ))
                                :
                                columns.filter((column) => column.text !== "Actions").map((column, index) => (
                                  <Td
                                    key={`${rowIndex}-${index}`}
                                    className="text-center"
                                  >
                                    {column.formatter
                                      ? column.formatter(row, rowIndex)
                                      : row[column.dataField]}
                                  </Td>
                                ))
                              }
                              </Tr>
                            ))}
                        </Tbody>
                      </Table>
                      <CustomPagination
                        {...props.pagination}
                        docs={props.accounts}
                        setSizePerPage={setSizePerPage}
                        sizePerPage={sizePerPage}
                        onChange={fetchData}
                      />
                    </div>
                  </div>
                </CardBody>
                <hr className="my-2" />
                <Positions accountId={accountId} accountPlatform={accountPlatform} />
                </Card>
                : <div className="text-center">
                  <h2>{props.t("You Do Not Have The Required Permission")}</h2>
                </div>
              }
            </Col>
          </Row>
        </div>
      </div>
      <div className="d-none">
        <ChangePassword
          show={showPassModal}
          toggle={setShowPassModal}
          customerId={clientId}
          selectedAcc={selectedAccount}
        />
        <ChangeType
          show={showTypeModal}
          toggle={setShowTypeModal}
          customerId={clientId}
          selectedAcc={selectedAccount}
        />
        <ChangeAccess
          show={showAccessModal}
          toggle={setShowAccessModal}
          customerId={clientId}
          selectedAccount={selectedAccount}
        />
        <ChangeLeverage
          show={showLeverageModal}
          toggle={setShowLeverageModal}
          customerId={clientId}
          selectedAccount={selectedAccount}
        />
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  clientDetails: state.clientReducer.clientDetails || {},
  accounts:
    (state.tradingAccountReducer.accounts &&
      state.tradingAccountReducer.accounts.docs) ||
    [],
  totalDocs:
    (state.tradingAccountReducer.accounts &&
      state.tradingAccountReducer.accounts.totalDocs) ||
    0,
  currentPage:
    (state.tradingAccountReducer.accounts &&
      state.tradingAccountReducer.accounts.page) ||
    1,
  pagination: state.tradingAccountReducer.accounts || {},
  loading: state.tradingAccountReducer.loading,
  fetchAllTradingAccountsLoading:
    state.tradingAccountReducer.fetchAllTradingAccountsLoading,
});

export default connect(mapStateToProps, null)(withTranslation()(Accounts));
