/** Api Success and fail */
export const GET_SERVER_START = "GET_SERVER_START";
export const GET_SERVER_END = "GET_SERVER_END";


export const ADD_SERVER_START = "ADD_SERVER_START";
export const ADD_SERVER_END = "ADD_SERVER_END";


export const DELETE_SERVER_START = "DELETE_SERVER_START";
export const DELETE_SERVER_END = "DELETE_SERVER_END";


export const EDIT_SERVER_START = "EDIT_SERVER_START";
export const EDIT_SERVER_END = "EDIT_SERVER_END";