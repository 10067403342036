
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal, Button, ModalHeader, ModalBody, Label, 
  Input
} from "reactstrap";
import Select from "react-select";
import { 
  AvForm, 
  AvField, 
  AvCheckboxGroup, 
  AvCheckbox 
} from "availity-reactstrap-validation";
import Loader from "components/Common/Loader";
import AvFieldSelect from "components/Common/AvFieldSelect";
import validatePositiveInputs from "helpers/validatePositiveInputs";

import { fetchServerStart, updateAccountType } from "store/actions";
import { convertToInvestorFail } from "store/client/actions";

const PLATFORMS = ["MT5", "MT4"];
const TYPES = ["LIVE", "DEMO", "IB", "MAM", "INVESTOR"];
const LEVERAGE = [
  1, 25, 50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 2000,
];
const CURRENCIES = ["USD", "EUR", "GBP", "EGP"];

function EditingAccountGroupType({ isOpen, onClose, selectedAccountType, t, selectedGroup }) {
  // Changed props names
  const dispatch = useDispatch();
  const { updating, error } = useSelector(
    (state) => state.tradingAccountReducer
  );

  // State management
  const [leverageOptions, setLeverageOptions] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [type, setType] = useState("");
  const [platform, setPlatform] = useState("");
  const [servers, setServers] = useState([]);
  const [filteredServers, setFilteredServers] = useState([]);
  const [currentSelectedServer, setCurrentSelectedServer] = useState(null);
  const [serverError, setServerError] = useState(false);


  const [isDefault, setIsDefault] = useState(false);
  console.log(selectedAccountType);

  const allServersFromStore = useSelector(
    (state) => state?.MTServer?.servers?.docs
  );

  const handleCheckboxChange = (event) => {
    setIsDefault(event.target.checked); // Convert the value to a boolean
  };

  // Reset states when modal opens
  useEffect(() => {
    console.log("selectedGroup", selectedGroup, selectedAccountType);
    if (selectedAccountType, selectedGroup) {
      console.log("selectedGroup111");
      let currenciesModified = selectedGroup?.currencies?.find(cu=> cu?.groupPath === selectedAccountType?.mtGroupName);
      setCurrencies(currenciesModified?.currency);
    }
  }, [selectedAccountType, selectedGroup]);
  useEffect(() => {
    if (isOpen && selectedAccountType) {
      setType(selectedAccountType.type || "");
      setPlatform(selectedAccountType.platform || "");
      setCurrentSelectedServer(selectedAccountType.serverId || null);
      setLeverageOptions(selectedAccountType.leverages || []);
      setIsDefault(selectedAccountType?.isDefault);
    }
  }, [isOpen, selectedAccountType]);

  // Initial server fetch
  useEffect(() => {
    if (isOpen) {
      dispatch(fetchServerStart());
    }
  }, [dispatch, isOpen]);

  // Update servers when store changes
  useEffect(() => {
    if (allServersFromStore?.length > 0) {
      setServers(allServersFromStore);
    }
  }, [allServersFromStore]);

  // Filter servers based on type and platform
  useEffect(() => {
    if (type && platform && servers.length > 0) {
      const newFilteredServers = servers
        .filter(
          (item) =>
            item?.platform === platform &&
            item?.type === type &&
            item?._id &&
            item?.name
        )
        .map((item) => ({
          value: item._id,
          label: item.name,
        }));

      setFilteredServers(newFilteredServers);

      // Reset selected server if it's no longer in filtered options
      if (
        !newFilteredServers.some(
          (server) => server.value === currentSelectedServer
        )
      ) {
        setCurrentSelectedServer(null);
        setServerError(true);
      }
    } else {
      setFilteredServers([]);
    }
  }, [type, platform, servers, currentSelectedServer]);

  // Handle successful update
  useEffect(() => {
    if (!updating && !error && isOpen) {
      onClose();
    }
  }, [updating]);

  const handleSubmit = (e, v) => {
    let currenciesModified = selectedGroup?.currencies?.filter(cu=> cu?.currency !== currencies)
      ?.filter(c => c?.groupPath !== selectedAccountType?.mtGroupName)?.map(curr=> {
        return {
          mtGroup: curr?.mtGroup,
          currency: curr?.currency,
          groupPath: curr?.groupPath
        };
      });
    currenciesModified = [
      ...currenciesModified,
      {
        mtGroup: selectedAccountType?._id,
        currency: currencies,
        groupPath: selectedAccountType?.mtGroupName
      }
    ];
    const formData = {
      ...v,
      accountTypeId: selectedGroup?._id,
      isDefault: isDefault,
      currencies:currenciesModified,
      groupPath: selectedAccountType.mtGroupName,
      accountGroupTypeTitle: selectedAccountType?.accountGroupType
    };


    dispatch(updateAccountType(selectedAccountType._id, formData));
  };

  const isMamPam = ["MAM", "INVESTOR"].includes(type);

  return (
    <Modal isOpen={isOpen} toggle={onClose} centered={true}>
      <ModalHeader toggle={onClose}>Edit Account Type</ModalHeader>
      <ModalBody>
        <AvForm className="px-4" onValidSubmit={handleSubmit}>
          <AvFieldSelect
            name="currencies"
            label="Currencies"
            placeholder="Select Currencies"
            ismulti={false}
            options={CURRENCIES.map((c) => ({
              value: c,
              label: c 
            }))}
            onChange={(values) => setCurrencies(values)}
            value={currencies}
            validate={{
              required: {
                value: true,
                errorMessage: "Currencies are required",
              },
            }}
          />

          <AvField
            name={"currencies.groupPath"}
            label={"Default Group Path"}
            placeholder={"Enter Default group path"}
            type="text"
            value={selectedAccountType?.mtGroupName}
            disabled
          />

          <div className="text-center mt-4">
            {updating ? (
              <Loader />
            ) : (
              <Button
                type="submit"
                color="primary"
                disabled={updating}
              >
                Save Changes
              </Button>
            )}
          </div>
        </AvForm>
      </ModalBody>
    </Modal>
  );
}

export default EditingAccountGroupType;