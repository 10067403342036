import React, { useEffect, useState } from "react";
import {
  useDispatch, connect, useSelector
} from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  Row, Col, Card, CardBody, CardTitle, CardHeader, Input, Spinner, Label, Button,
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap";
import {
  Table, Thead, Tbody, Tr, Th, Td
} from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import FeatherIcon from "feather-icons-react";

// i18n 
import { withTranslation } from "react-i18next";
import { fetchClientWallets, changeStatus } from "store/wallet/list/action";
import CustomPagination from "components/Common/CustomPagination";
import TableLoader from "components/Common/TableLoader";
import ClientAddWallet from "./ClientAddWallet";
import DeleteModal from "components/Common/DeleteModal";
import WalletEditModal from "./WalletEditModal";
import QrPukModal from "./QrPukModal";
import { captilazeFirstLetter } from "common/utils/manipulateString";
import {
  AvField, AvForm, AvRadio, AvRadioGroup
} from "availity-reactstrap-validation";
import { convertRebate } from "apis/wallet";
import { showErrorNotification } from "store/actions";
import usePermissions from "routes/permissions";
// import { editBankAccount } from "store/bankAccount/actions";

function ClientWallets(props) {
  const { clientId } = useParams();
  const [sizePerPage, setSizePerPage] = useState(50);
  const [deleteModal, setDeleteModal] = useState(false);
  const [walletEditModal, setWalletEditModal] = useState(false);
  const [pukModal, setPukEditModal] = useState(false);
  const [convertModal, setConvertModal] = useState(false);
  const [selectedWallet, setSelectedWallet] = useState(null);
  const [puk, setPuk] = useState("");
  const dispatch = useDispatch();
  
  const isLeadPage = props?.isLead;
  
  const { userData: usrData } = useSelector((state) => ({
    userData: state.Profile,
  }));
  const { clientDetails } = useSelector((state) => ({
    clientDetails: state.clientReducer.clientDetails,
  }));
  const permissions = usrData?.roleId?.permissions;
  const { ibWalletPermissions } = usePermissions();
  const wallets = permissions?.wallets;
  const [isChanged, setIsChanged] = useState(false);

  const loadClientWalletDetails = (page, sizePerPage) => {
    dispatch(fetchClientWallets({
      belongsTo: clientId,
      page,
      limit: sizePerPage,
    }));
  };
  useEffect(() => {
    loadClientWalletDetails(1, sizePerPage);
    return () => {
      setIsChanged(false);
    };
  }, [props.addClearingCounter, sizePerPage, isChanged]);
  const updateStatus = (event, item, index, key) => { 
    dispatch(changeStatus(item._id, key, !item?.[key], index));
    event.preventDefault();
    setIsChanged(true);
  };
  const pukHandeler = (puk) => {
    setPuk(puk);
    setPukEditModal(true);
    // console.log(puk);
  };

  const handleConvert = (wallet) => {
    setSelectedWallet(wallet);
    setConvertModal(true);
  };

  const columns = [ 
    {
      dataField: "walletId",
      text: props.t("Wallet ID"),
      formatter: (item) => item._id,
    },
    {
      dataField: "asset",
      text: props.t("Currency"),
      formatter: (item) => (
        captilazeFirstLetter(item.asset)
      )
    },
    {
      dataField: "amount",
      text: props.t("Available Balance"),
      formatter: (item) => (
        item.amount === " " ? "-" : parseFloat(item.amount)
      )
    },
    {
      dataField: "isCrypto",
      text: props.t("Wallet Type"),
      formatter: (item) => (
        item.isCrypto ? props.t("Crypto wallet") : props.t("Traditional wallet")
      )
    },
    // {
    //   dataField: "address",
    //   text: props.t("Address"),
    //   formatter: (item) => {
    //     if (item.isCrypto)
    //       return (
    //         <Link to="#" onClick={() => { pukHandeler({
    //           networkDetails: item?.assetId?.networks,
    //           networks: item?.networks,
    //         }); }}>
    //           <FeatherIcon icon="eye" />
    //         </Link>
    //       );
    //     else
    //       return "System Wallet";
    //     // return <i className="mdi mdi-close-circle-outline font-size-22" style={{ color: "red" }}></i>;
    //   },
    // },
    {
      dataField: "freezeAmount",
      text: props.t("On-hold Amount"),
      formatter: (item) => (
        item.freezeAmount === " " ? "N/A" : parseFloat(item.freezeAmount)
      )
    },
    {
      dataField: "pendingAmount",
      text: props.t("Pending Commission"),
      formatter: (item) => (
        item.pending === "" ? "N/A" : parseFloat(item?.pending || 0)
      )
    },
    {
      dataField: "active",
      text: props.t("Status"),
      formatter: (item, index) => (
        <div className="d-flex gap-3 justify-content-center">
          {(props.changeStatusLoading && props.changeStatusLoadingIndex === index) ? <React.Fragment>
            <Spinner className="ms-2" color="primary" />
          </React.Fragment> : <React.Fragment>
            <Input
              checked={item.active}
              type="checkbox"
              onChange={(e) => { updateStatus(e, item, index, "active") }}
              id={`${item.id}-status`}
              switch="none"
            />
            <Label className="me-1" htmlFor={`${item.id}-status`} data-on-label="" data-off-label=""></Label>
          </React.Fragment>}
        </div>

      ),
    },
    {
      dataField: "depositDisabled",
      text: props.t("Deposits"),
      formatter: (item, index) => (
        <div className="d-flex gap-3 justify-content-center">
          {(props.changeStatusLoading && props.changeStatusLoadingIndex === index) ? <React.Fragment>
            <Spinner className="ms-2" color="primary" />
          </React.Fragment> : <React.Fragment>
            <Input
              checked={!item.depositDisabled}
              type="checkbox"
              onChange={(e) => { updateStatus(e, item, index, "depositDisabled") }}
              id={`${item.id}-deposit`}
              switch="none"
              disabled={!item.active}
            />
            <Label className="me-1" htmlFor={`${item.id}-deposit`} data-on-label="" data-off-label=""></Label>
          </React.Fragment>}
        </div>
      ),
    },
    {
      dataField: "withdrawalDisabled",
      text: props.t("Withdrawals"),
      formatter: (item, index) => (
        <div className="d-flex gap-3 justify-content-center">
          {(props.changeStatusLoading && props.changeStatusLoadingIndex === index) ? <React.Fragment>
            <Spinner className="ms-2" color="primary" />
          </React.Fragment> : <React.Fragment>
            <Input
              checked={!item.withdrawalDisabled}
              type="checkbox"
              onChange={(e) => { updateStatus(e, item, index, "withdrawalDisabled") }}
              id={`${item.id}-withdraw`}
              switch="none"
              disabled={!item.active}
            />
            <Label className="me-1" htmlFor={`${item.id}-withdraw`} data-on-label="" data-off-label=""></Label>
          </React.Fragment>}
        </div>

      ),
    },
  ];

  const columnsIb = [ 
    {
      dataField: "walletId",
      text: props.t("Wallet ID"),
      formatter: (item) => item._id,
    },
    {
      dataField: "asset",
      text: props.t("Currency"),
      formatter: (item) => (
        captilazeFirstLetter(item.asset)
      )
    },
    {
      dataField: "amount",
      text: props.t("Available Balance"),
      formatter: (item) => (
        item.amount === " " ? "-" : parseFloat(item.amount)
      )
    },
    {
      dataField: "isCrypto",
      text: props.t("Wallet Type"),
      formatter: (item) => (
        item.isCrypto ? props.t("Crypto wallet") : props.t("Traditional wallet")
      )
    },
    // {
    //   dataField: "address",
    //   text: props.t("Address"),
    //   formatter: (item) => {
    //     if (item.isCrypto)
    //       return (
    //         <Link to="#" onClick={() => { pukHandeler({
    //           networkDetails: item?.assetId?.networks,
    //           networks: item?.networks,
    //         }); }}>
    //           <FeatherIcon icon="eye" />
    //         </Link>
    //       );
    //     else
    //       return "System Wallet";
    //     // return <i className="mdi mdi-close-circle-outline font-size-22" style={{ color: "red" }}></i>;
    //   },
    // },
    {
      dataField: "freezeAmount",
      text: props.t("On-hold Amount"),
      formatter: (item) => (
        item.freezeAmount === " " ? "N/A" : parseFloat(item.freezeAmount)
      )
    },
    {
      dataField: "pendingAmount",
      text: props.t("Pending Commission"),
      formatter: (item) => (
        item.pending === "" ? "N/A" : parseFloat(item?.pending || 0)
      )
    },
    {
      dataField: "active",
      text: props.t("Status"),
      formatter: (item, index) => (
        <div className="d-flex gap-3 justify-content-center">
          {(props.changeStatusLoading && props.changeStatusLoadingIndex === index) ? <React.Fragment>
            <Spinner className="ms-2" color="primary" />
          </React.Fragment> : <React.Fragment>
            <Input
              checked={item.active}
              type="checkbox"
              onChange={(e) => { updateStatus(e, item, index, "active") }}
              id={`${item.id}-status`}
              switch="none"
            />
            <Label className="me-1" htmlFor={`${item.id}-status`} data-on-label="" data-off-label=""></Label>
          </React.Fragment>}
        </div>

      ),
    },
    {
      dataField: "depositDisabled",
      text: props.t("Deposits"),
      formatter: (item, index) => (
        <div className="d-flex gap-3 justify-content-center">
          {(props.changeStatusLoading && props.changeStatusLoadingIndex === index) ? <React.Fragment>
            <Spinner className="ms-2" color="primary" />
          </React.Fragment> : <React.Fragment>
            <Input
              checked={!item.depositDisabled}
              type="checkbox"
              onChange={(e) => { updateStatus(e, item, index, "depositDisabled") }}
              id={`${item.id}-deposit`}
              switch="none"
              disabled={!item.active}
            />
            <Label className="me-1" htmlFor={`${item.id}-deposit`} data-on-label="" data-off-label=""></Label>
          </React.Fragment>}
        </div>
      ),
    },
    {
      dataField: "withdrawalDisabled",
      text: props.t("Withdrawals"),
      formatter: (item, index) => (
        <div className="d-flex gap-3 justify-content-center">
          {(props.changeStatusLoading && props.changeStatusLoadingIndex === index) ? <React.Fragment>
            <Spinner className="ms-2" color="primary" />
          </React.Fragment> : <React.Fragment>
            <Input
              checked={!item.withdrawalDisabled}
              type="checkbox"
              onChange={(e) => { updateStatus(e, item, index, "withdrawalDisabled") }}
              id={`${item.id}-withdraw`}
              switch="none"
              disabled={!item.active}
            />
            <Label className="me-1" htmlFor={`${item.id}-withdraw`} data-on-label="" data-off-label=""></Label>
          </React.Fragment>}
        </div>

      ),
    },
    {
      dataField: "transferPendingRebate",
      text: props.t("Convert Pending Rebate"),
      formatter: (item) => (
        item?.isIb && ibWalletPermissions.update ? <Button color="primary" size="sm" onClick={() => handleConvert(item)}>
          {props.t("Convert")}
        </Button> : <span>-</span>
      ),
    },
  ];

  return (
    <React.Fragment>
      <div className="">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              {wallets?.get ?
                <Card>
                  <CardHeader className="d-flex justify-content-between  align-items-center">
                    <CardTitle className="color-primary">
                      {props.t("Client Wallets")} ({props.totalWalletDocs})
                      <FeatherIcon
                        icon="refresh-cw"
                        className="icon-lg ms-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => { loadClientWalletDetails(1, sizePerPage) }}
                      />
                    </CardTitle>
                    {wallets?.create && <ClientAddWallet clientId={clientId} /> }
                  </CardHeader>
                  <CardBody>
                    <div className="table-rep-plugin">
                      <div
                        className="table-responsive mb-0"
                        data-pattern="priority-columns"
                      >
                        <Table
                          id="tech-companies-1"
                          className="table  table-hover "
                        >
                          <Thead className="text-center table-light" >
                            <Tr>
                              { wallets?.actions ?
                                columns.map((column, index) =>
                                  <Th data-priority={index} key={index}>
                                    <span className="color-primary">{column.text}</span>
                                  </Th>
                                )
                                :
                                columns.filter((column) => column.text !== "Actions").map((column, index) =>
                                  <Th data-priority={index} key={index}>
                                    <span className="color-primary">{column.text}</span>
                                  </Th>
                                )
                              }
                            </Tr>
                          </Thead>
                          {
                            props.totalWalletDocs === 0
                              ?
                              <Tbody>
                                {props.loading && <TableLoader colSpan={4} />}                            
                                {!props.loading &&
                                  <>
                                    <Tr>
                                      <Td colSpan={"100%"} className="fw-bolder text-center" st>
                                        <h3 className="fw-bolder text-center">{props.t("No records")}</h3>
                                      </Td>
                                    </Tr>
                                  </>
                                }
                              </Tbody>
                              :
                              <Tbody className="text-center">
                                {props.loading && <TableLoader colSpan={4} />}
                                {(!props.loading && props.docs) && props?.docs?.filter(doc=> !doc?.isIb)?.map((row, rowIndex) =>
                                  <Tr key={rowIndex}>
                                    { wallets?.actions ?
                                      columns.map((column, index) =>
                                        <Td key={`${rowIndex}-${index}`}>
                                          {column.formatter ? column.formatter(row, rowIndex) : row[column.dataField]}
                                        </Td>
                                      )
                                      :
                                      columns.filter((column) => column.text !== "Actions").map((column, index) =>
                                        <Td key={`${rowIndex}-${index}`}>
                                          {column.formatter ? column.formatter(row, rowIndex) : row[column.dataField]}
                                        </Td>
                                      )
                                    }
                                  </Tr>
                                )}
                              </Tbody>
                          }
                        </Table>
                        <CustomPagination
                          {...props.pagination}
                          setSizePerPage={setSizePerPage}
                          sizePerPage={sizePerPage}
                          onChange={loadClientWalletDetails}
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
                : <div className="text-center">
                  <h2>{props.t("You Do Not Have The Required Permission")}</h2>
                </div>
              
              }
            </Col>
          </Row>
          {
            clientDetails?.fx?.isIb &&
              <Row>
                <Col className="col-12">
                  {wallets?.get ?
                    <Card>
                      <CardHeader className="d-flex justify-content-between  align-items-center">
                        <CardTitle className="color-primary">
                          {props.t("IB Wallets")} ({1})
                          <FeatherIcon
                            icon="refresh-cw"
                            className="icon-lg ms-2"
                            style={{ cursor: "pointer" }}
                            onClick={() => { loadClientWalletDetails(1, sizePerPage) }}
                          />
                        </CardTitle>
                        {/* {wallets?.create && <ClientAddWallet clientId={clientId} /> } */}
                      </CardHeader>
                      <CardBody>
                        <div className="table-rep-plugin">
                          <div
                            className="table-responsive mb-0"
                            data-pattern="priority-columns"
                          >
                            <Table
                              id="tech-companies-1"
                              className="table  table-hover "
                            >
                              <Thead className="text-center table-light" >
                                <Tr>
                                  { wallets?.actions ?
                                    columnsIb.map((column, index) =>
                                      <Th data-priority={index} key={index}>
                                        <span className="color-primary">{column.text}</span>
                                      </Th>
                                    )
                                    :
                                    columnsIb.filter((column) => column.text !== "Actions").map((column, index) =>
                                      <Th data-priority={index} key={index}>
                                        <span className="color-primary">{column.text}</span>
                                      </Th>
                                    )
                                  }
                                </Tr>
                              </Thead>
                              {
                                props.totalWalletDocs === 0
                                  ?
                                  <Tbody>
                                    {props.loading && <TableLoader colSpan={4} />}                            
                                    {!props.loading &&
                                      <>
                                        <Tr>
                                          <Td colSpan={"100%"} className="fw-bolder text-center" st>
                                            <h3 className="fw-bolder text-center">{props.t("No records")}</h3>
                                          </Td>
                                        </Tr>
                                      </>
                                    }
                                  </Tbody>
                                  :
                                  <Tbody className="text-center">
                                    {props.loading && <TableLoader colSpan={4} />}
                                    {(!props.loading && props.docs) && props?.docs?.filter(doc=> doc?.isIb)?.map((row, rowIndex) =>
                                      <Tr key={rowIndex}>
                                        { wallets?.actions ?
                                          columnsIb.map((column, index) =>
                                            <Td key={`${rowIndex}-${index}`}>
                                              {column.formatter ? column.formatter(row, rowIndex) : row[column.dataField]}
                                            </Td>
                                          )
                                          :
                                          columnsIb.filter((column) => column.text !== "Actions").map((column, index) =>
                                            <Td key={`${rowIndex}-${index}`}>
                                              {column.formatter ? column.formatter(row, rowIndex) : row[column.dataField]}
                                            </Td>
                                          )
                                        }
                                      </Tr>
                                    )}
                                  </Tbody>
                              }
                            </Table>
                            <CustomPagination
                              {...props.pagination}
                              setSizePerPage={setSizePerPage}
                              sizePerPage={sizePerPage}
                              onChange={loadClientWalletDetails}
                            />
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                    : <div className="text-center">
                      <h2>{props.t("You Do Not Have The Required Permission")}</h2>
                    </div>
                  
                  }
                </Col>
              </Row>
          }
          <QrPukModal
            open={pukModal}
            puk={puk}
            onClose={() => { setPukEditModal(false) }}
          />
          {<DeleteModal
            loading={props.deleteLoading}
            // onDeleteClick={deleteBankAccountFunction} 
            show={deleteModal}
            onCloseClick={() => { setDeleteModal(false) }}
          />}
          {<WalletEditModal
            open={walletEditModal}
            // selectedBankAccount={selectedBankAccount} 
            onClose={() => { setWalletEditModal(false) }}
          // bankAccountUpdateHandler={bankAccountUpdateHanlder} 
          />}
          {convertModal && <ConvertModal
            open={convertModal}
            wallet={selectedWallet}
            onClose={() => {
              setConvertModal(false);
              loadClientWalletDetails(1, sizePerPage);
            }}
          />}
        </div>
      </div>
    </React.Fragment>
  );
}

function ConvertModal({ open, wallet, onClose }) {
  const [loading, setLoading] = useState(false);
  const [convertType, setConvertType] = useState("pending");
  const [amount, setAmount] = useState("");

  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await convertRebate({
        walletId: wallet._id,
        amount: amount,
        type: convertType,
      });
      onClose();
    } catch (error) {
      dispatch(showErrorNotification(error.message));
      console.error("Error converting wallet:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={open} toggle={onClose}>
      <ModalHeader toggle={onClose}>Convert Wallet</ModalHeader>
      <ModalBody>
        <AvForm onValidSubmit={handleSubmit}>
          <AvField
            name="walletId"
            type="hidden"
            value={wallet._id}
          />
          <AvField
            name="pendingBalance"
            label="Pending Balance"
            type="number"
            value={wallet?.pending || "0"}
            disabled
          />
          <AvField
            name="availableBalance"
            label="Available Balance"
            type="number"
            value={wallet?.amount || "0"}
            disabled
          />
          <div className="d-flex gap-3">
            <Label>Convert Type</Label>
            <AvRadioGroup
              name="convertType"
              label=""
              value={convertType}
              onChange={(e) => setConvertType(e.target.value)}
              required
            >
              <AvRadio label="Convert Pending to Available" value="pending" />
              <AvRadio label="Convert Available to Pending" value="useable" />
              <AvRadio label="Forfeit Pending Commission" value="forfeit-pending" />
              <AvRadio label="Forfeit Available Balance" value="forfeit-available" />
            </AvRadioGroup>
          </div>
          <AvField
            name="amount"
            label="Amount to Convert/Forfeit"
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            validate={{
              required: {
                value: true,
                errorMessage: "Please enter an amount"
              },
              min: {
                value: 0,
                errorMessage: "Amount must be greater than 0"
              },
              max: {
                value: convertType === "pending" ? wallet?.pending : wallet?.amount,
                errorMessage: `Amount exceeds ${convertType === "pending" ? "pending" : "available"} balance`
              }
            }}
          />
          <Button color="primary" type="submit" disabled={loading}>
            {loading ? "Converting..." : "Convert"}
          </Button>
        </AvForm>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  loading: state.walletReducer.wallet.loading,
  error: state.walletReducer.wallet.error,
  errorDetails: state.walletReducer.wallet.errorDetails,
  success: state.walletReducer.wallet.success,
  addClearingCounter: state.walletReducer.wallet.addClearingCounter,
  changeStatusLoading: state.walletReducer.wallet.changeStatusLoading,
  changeStatusLoadingIndex: state.walletReducer.wallet.changeStatusLoadingIndex,
  docs: state.walletReducer.wallet.docs,
  totalWalletDocs: state.walletReducer.wallet.totalWalletDocs,
  pagination: state.walletReducer.wallet.pagination,
});

export default connect(mapStateToProps, null)(withTranslation()(ClientWallets));
