import React, { useState, useEffect } from "react";
import {
  useDispatch, useSelector
} from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import { deleteAccountType } from "store/actions";
import { fetchAccountRequests } from "store/requests/actions";
import { fetchMtGroups } from "store/rebateSettings/groups/actions";

function deleteStatus({ clientId, setSwitchStatus, t, type, switchStatus, show, text, selectedGroup, delteSuccess }) {
  const dispatch = useDispatch();

  const toggleModal = () => {
    setSwitchStatus(false);
  };

  useEffect(()=>{
    if (delteSuccess){
      toggleModal();
      dispatch(
        fetchMtGroups({
          limit: 10,
          page: 1,
          type,
          accountGroupType: selectedGroup && selectedGroup.title 
        })
      );
    }
  }, [delteSuccess]);
  return (
    <React.Fragment >
      {/* <button
        type="button"
        className="btn btn-primary waves-effect waves-light w-100 me-1"
        onClick={toggleModal}
      >
        {t("Confirm Prompt")}
      </button> */}
      <Modal isOpen={show} toggle={toggleModal} centered={true}>
        <ModalHeader toggle={toggleModal} tag="h4">
          {t("Confirm Prompt")}
        </ModalHeader>
        <ModalBody className="text-center" >
          <h5>{t(`Whether to delete the ${text}?`)}</h5>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" color="primary"
            onClick={() => {
              dispatch(deleteAccountType(selectedGroup._id));
            }}>
            {t("Yes")}
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

export default withTranslation()(deleteStatus);