import React, { useState, useEffect } from "react";
import {
  useDispatch, useSelector
} from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import { adjustMtGroupsRequest, deleteGroupStart } from "store/rebateSettings/groups/actions";
import { changeSymbolStart, fetchSymbols } from "store/rebateSettings/symbols/actions";
import { changeRebateRulesStart, fetchRebateRules } from "store/rebateSettings/rebateRules/actions";
import { fetchAccountTypes, updateAccountType } from "store/actions";

function DefaultMTGroup({ switchedId, setDefaultMTGroup, t, type, selectedMTGroup, show, text, selectedGroup, accountTypeId }) {
  const dispatch = useDispatch();
  const { editedSuccess } = useSelector((state) => state.tradingAccountReducer);
  const { addSuccess } = useSelector((state) => state.groupReducer);
  const toggleModal = () => {
    setDefaultMTGroup(preValue => !preValue);
  };

  useEffect(()=>{
    if (( editedSuccess || addSuccess ) && show){
      toggleModal();
      dispatch(
        fetchAccountTypes({
          limit: 100000,
          page: 1,
          platform: type,
        })
      );
    }
  }, [editedSuccess, addSuccess]);

  return (
    <React.Fragment >
      <Modal isOpen={show} toggle={toggleModal} centered={true}>
        <ModalHeader toggle={toggleModal} tag="h4">
          {t("Confirm Prompt")}
        </ModalHeader>
        <ModalBody className="text-center" >
          <h5>
            {t(
              "Whether to make this mt group default?"
            )}
          </h5>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" color="primary"
            onClick={() => {
              dispatch(updateAccountType(accountTypeId?._id, { defaultMTGroup: selectedGroup?._id}));
            }}>
            {t("Yes")}
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

export default withTranslation()(DefaultMTGroup);