/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import { 
  useDispatch, 
  connect, 
  useSelector 
} from "react-redux";
// eslint-disable-next-line object-curly-newline
import { Row, Col, Card, CardBody, CardHeader, CardTitle, Nav, NavItem, NavLink } from "reactstrap";
import TableLoader from "components/Common/Loader";
import CustomPagination from "components/Common/CustomPagination";
// eslint-disable-next-line object-curly-newline
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import WithDrawForm from "../WithDrawForm";
import {
  fetchWithdrawalsStart,
  withdrawalOnHold,
  withdrawApproveStart,
  withdrawRejectStart,
} from "store/transactions/withdrawal/action";
import SearchBar from "components/Common/SearchBar";
// import Notification from "components/Common/Notification";
// import logo from "../../../assets/images/logo-sm.svg";
import { withTranslation } from "react-i18next";
import { checkAllBoxes } from "common/utils/checkAllBoxes";
import { Link } from "react-router-dom";
import { captilazeFirstLetter } from "common/utils/manipulateString";
import Badge from "components/Common/Badge";
import CustomDropDown from "components/Common/CustomDropDown";
import formatDate from "helpers/formatDate";
import FeatherIcon from "feather-icons-react";
import Filter from "../DepositFilter";
import ReceiptModal from "../../Forex/ReceiptModal";
import TransactionForexModal from "components/Common/TransactionForexModal";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import classNames from "classnames";
import Withdrawal from "../Withdrawal";
import HistoryDetailsModal from "pages/Transactions/Forex/HistoryDetailsModal";
import { getTheStatus } from "pages/Transactions/Forex/withdrawals/forexFundingSteps/WithdrawalForexFundingDepartmentDeduction";
import TransactionModal from "components/Common/TransactionModal";
import { onHoldFxWithdrawal } from "store/forexTransactions/withdrawals/actions";

const getFundingStepStatusKey = {
  PENDING: "PENDING",
  APPROVED: "Risk-Pass",
  REJECTED: "Risk-Fail",
  RETURNED: "RETURNED",
  ON_HOLD: "Processing",
};
const getFundingStepKey = {
  fundingDepartmentInitial: "Fund-I-",
  riskDepartmentReview: "Risk-",
  fundingDepartmentDeduction: "Fund-D-",
  withdrawalRemittance: "Remit-",
};
function WithdrawalRiskDepartmentReview(props) {
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState("");
  const [sizePerPage, setSizePerPage] = useState(10);
  // const [showNotication, setShowNotifaction] = useState(false);
  const [detailsModal, setDetailsModal] = useState(false);
  const [selectedContent, setSelectedContent] = useState("");
  const [selected, setSelected] = useState("LIVE");
  const [btnprimary1, setBtnprimary1] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const [selectedWithdraw, setSelectedWithdraw] = useState({});
  const [rejectModal, setRejectModal] = useState(false);
  const [historyDetailsModal, setHistoryDetailsModal] = useState(false);
  const [historySelectedContent, sethistorySelectedContent] = useState([]);
  const [onHoldModal, setOnHoldModal] = useState(false);
  const usersWalletPermissions = useSelector((state) => state.Profile.permissions?.usersWallets);
  const loadTabs = () => [
    {
      tabId: "customers",
      navLinkName: "Customers",
      // component: <Withdrawal walletType="customers" />,
    },
    {
      tabId: "users",
      navLinkName: "Users",
      hidden: !usersWalletPermissions.get || props.isClient
      // component: <Withdrawal walletType="users" />,
    },
  ];
  
  const tabs = loadTabs().filter((item) => !item.hidden);
  const [walletType, setalletType] = useState("customers");

  const toggle = (tab) => {
    if (walletType !== tab) {
      setalletType(tab);
    }
  };

  const handleSearchInput = (e) => {
    setSearchInput(e.target.value);
  };
  // const columns = [
  //   // {
  //   //   dataField: "checkbox",
  //   //   text: (
  //   //     <input
  //   //       type="checkbox"
  //   //       id="check-all-withdrawals"
  //   //       onChange={() =>
  //   //         checkAllBoxes("check-all-withdrawals", ".withdraw-checkbox")
  //   //       }
  //   //     />
  //   //   ),
  //   // },
  //   {
  //     dataField: "recordId",
  //     text: props.t("Transaction Id"),
  //   },
  //   {
  //     dataField: "createdAt",
  //     text: props.t("Date"),
  //     formatter: (val) => formatDate(val.createdAt),
  //   },
  //   {
  //     dataField: "customerId",
  //     text: props.t("Client"),
  //     formatter: (val) => {
  //       return (
  //         <div>
  //           <Link
  //             to={{
  //               pathname: val.customerId
  //                 ? `/clients/${val.customerId._id}/profile`
  //                 : "",
  //               state: { clientId: val.customerId ? val.customerId._id : "" },
  //             }}
  //           >
  //             <i className="no-italics">
  //               {" "}
  //               {val.customerId
  //                 ? `${captilazeFirstLetter(
  //                     val.customerId.firstName
  //                   )} ${captilazeFirstLetter(val.customerId.lastName)}`
  //                 : ""}
  //             </i>
  //           </Link>
  //         </div>
  //       );
  //     },
  //   },
  //   {
  //     dataField: "processedBy",
  //     text: props.t("Processed By"),
  //     formatter: (val) => {
  //       return (
  //         <div>
  //           <span className="no-italics" style={{ fontWeight: "bold" }}>
  //             {val.processedBy
  //               ? `${captilazeFirstLetter(
  //                   val.processedBy.firstName
  //                 )} ${captilazeFirstLetter(val.processedBy.lastName)}`
  //               : val.gateway === "ASIA_BANKS" ? "By System" : ""}
  //           </span>
  //         </div>
  //       );
  //     },
  //   },
  //   {
  //     dataField: "gateway",
  //     text: props.t("Gateway"),
  //     formatter: (val) =>
  //       val.gateway === "WIRE_TRANSFER"
  //         ? "Wire"
  //         : val.gateway?.split("_").join(" ")?.toLowerCase(),
  //   },
  //   walletType === "users" && 
  //   {
  //     dataField: "fromCurrency",
  //     text: props.t("From Currency"),
  //   },
  //   {
  //     dataField: "currency",
  //     text: props.t("Currency"),
  //   },
  //   {
  //     dataField: "reason",
  //     text: "Reason",
  //     formatter: (val) =>
  //       val.reason ? (
  //         <div data-title={val.reason}>
  //           {val.reason.length > 20
  //             ? `${val.reason.slice(0, 20)}...`
  //             : val.reason}
  //         </div>
  //       ) : (
  //       <>{val?.note ?? " - "}</>
  //       ),
  //   },
  //   walletType === "users" && 
  //   {
  //     dataField: "convertedFromAmount",
  //     text: props.t("From Amount"),
  //     formatter: (val) => val?.convertedFromAmount?.$numberDecimal || val?.convertedFromAmount || "",
  //   },
  //   {
  //     dataField: "amount",
  //     text: props.t("Amount"),
  //     formatter: (val) => val?.amount?.$numberDecimal || val?.amount || "",
  //   },
  //   {
  //     dataField: "status",
  //     text: props.t("Status"),
  //     formatter: (item) => <Badge status={item.status}></Badge>,
  //   },
  //   {
  //     dataField: "dropdown",
  //     isDummyField: true,
  //     editable: false,
  //     text: props.t("Actions"),
  //   },
  //   {
  //     dataField: "",
  //     isDummyField: true,
  //     editable: false,
  //     text: props.t("Details"),
  //     formatter: (val) => (
  //       <div className="text-center">
  //         <Link
  //           className={`${
  //             ["CRYPTO", "BLOCKCHAIN", "WIRE_TRANSFER", "PERFECT_MONEY"].includes(val.gateway)
  //               ? "text-success"
  //               : "text-muted"
  //           }`}
  //           to="#"
  //         >
  //           <i
  //             className="mdi mdi-eye font-size-20"
  //             id="edittooltip"
  //             onClick={() => {
  //               if (val.gateway === "BLOCKCHAIN") {
  //                 setDetailsModal(true);
  //                 setSelectedContent({
  //                   network:
  //                     `${val?.rawData?.data?.item?.network} ${val?.rawData?.data?.item?.blockchain}` ||
  //                     "-",
  //                   referenceId: val?.rawData?.referenceId || "-",
  //                   amount: val?.amount?.$numberDecimal || val?.amount || "-",
  //                   address: val?.rawData?.data?.item?.address || "-",
  //                   confirmations:
  //                     val?.rawData?.data?.item?.currentConfirmations || "-",
  //                   requiredConfirmations:
  //                     val?.rawData?.data?.item?.targetConfirmations || "-",
  //                   transactionId:
  //                     val?.rawData?.data?.item?.transactionId || "-",
  //                 });
  //               } else if (val.gateway == "WIRE_TRANSFER") {
  //                 setDetailsModal(true);
  //                 setSelectedContent({
  //                   ...val?.content,
  //                 });
  //               } else if (val.gateway == "CRYPTO") {
  //                 setDetailsModal(true);
  //                 setSelectedContent({
  //                   ...val?.content,
  //                 });
  //               } else if (val.gateway == "PERFECT_MONEY") {
  //                 setDetailsModal(true);
  //                 setSelectedContent({
  //                   ...val?.content,
  //                 });
  //               }
  //             }}
  //           ></i>
  //         </Link>
  //       </div>
  //     ),
  //   },
  // ];

  
  const columns = [
    // {
    //   dataField: "checkbox",
    //   text: (
    //     <input
    //       type="checkbox"
    //       id="check-all-deposits"
    //       onChange={() =>
    //         checkAllBoxes("check-all-deposits", ".deposit-checkbox")
    //       }
    //     />
    //   ),
    // },
    {
      dataField: "recordId",
      text: props.t("Transaction Id"),
    },
    {
      dataField: "createdAt",
      text: props.t("Date"),
      formatter: (val) => formatDate(val.createdAt),
    },
    {
      dataField: "customerId",
      text: props.t("Client"),
      formatter: (val) => {
        return (
          <div>
            <Link
              to={{
                pathname: `/clients/${val?.customerId?._id}/profile`,
                state: { clientId: val.customerId },
              }}
            >
              <span className="no-italics" style={{ fontWeight: "bold" }}>
                {val.customerId
                  ? `${captilazeFirstLetter(
                      val.customerId.firstName
                    )} ${captilazeFirstLetter(val.customerId.lastName)}`
                  : ""}
              </span>
            </Link>
          </div>
        );
      },
    },
    {
      dataField: "processedBy",
      text: props.t("Processed By"),
      formatter: (val) => {
        return (
          <div>
            <span className="no-italics" style={{ fontWeight: "bold" }}>
              {val?.fundingSteps?.riskDepartmentReviewStatus?.processedBy?.[0]
                ? `${captilazeFirstLetter(
                    val?.fundingSteps?.riskDepartmentReviewStatus?.processedBy?.[0]?.firstName
                  )} ${captilazeFirstLetter(val?.fundingSteps?.riskDepartmentReviewStatus?.processedBy?.[0]?.lastName)}`
                : val.gateway === "ASIA_BANKS" ? "By System" : ""}
            </span>
          </div>
        );
      },
    },
    {
      dataField: "gateway",
      text: props.t("Gateway"),
      formatter: (val) =>
        val.gateway == "WIRE_TRANSFER"
          ? "Wire"
          : val.gateway?.split("_").join(" ")?.toLowerCase(),
    },
    // {
    //   dataField: "tradingAccount",
    //   text: props.t("Trading Account"),
    //   formatter: (item) => item.tradingAccountId?.login,
    // },
    // {
    //   dataField: "",
    //   isDummyField: true,
    //   editable: false,
    //   text: props.t("Trading Account Details"),
    //   formatter: (val) => (
    //     <div className="text-center">
    //       <Link
    //         className={`${
    //           ["CRYPTO", "BLOCKCHAIN", "WIRE_TRANSFER"].includes(val.gateway)
    //             ? "text-success"
    //             : "text-muted"
    //         }`}
    //         to="#"
    //       >
    //         <i
    //           className="mdi mdi-eye font-size-20"
    //           id="edittooltip"
    //           onClick={() => {
    //             setTradingAccountDetailsModal(true);
    //             setTransactionItem(val);
    //             if (val.gateway === "BLOCKCHAIN") {
    //               setSelectedContent({
    //                 network:
    //                   `${val?.rawData?.data?.item?.network} ${val?.rawData?.data?.item?.blockchain}` ||
    //                   "-",
    //                 referenceId: val?.rawData?.referenceId || "-",
    //                 amount: val?.amount?.$numberDecimal || val?.amount || "-",
    //                 address: val?.rawData?.data?.item?.address || "-",
    //                 confirmations:
    //                   val?.rawData?.data?.item?.currentConfirmations || "-",
    //                 requiredConfirmations:
    //                   val?.rawData?.data?.item?.targetConfirmations || "-",
    //                 transactionId:
    //                   val?.rawData?.data?.item?.transactionId || "-",
    //               });
    //             } else if (val.gateway == "WIRE_TRANSFER") {
    //               setSelectedContent({
    //                 ...val?.content,
    //               });
    //             } else if (val.gateway == "CRYPTO") {
    //               setSelectedContent({
    //                 ...val?.content,
    //               });
    //             } else if (val.gateway == "PERFECT_MONEY") {
    //               setSelectedContent({
    //                 ...val?.content,
    //               });
    //             }
    //             setSelectedId(val?._id);
    //             setTradingAccountSelectedContent({
    //                 balance: val?.balance?.$numberDecimal || val?.balance || "-",
    //                 equity: val?.equity?.$numberDecimal || val?.equity || "-",
    //                 margin: val?.margin?.$numberDecimal || val?.margin || "-",
    //                 freeMargin: val?.freeMargin?.$numberDecimal || val?.freeMargin || "-",
    //               });
    //               setCustomerDetailsContent({
    //                 customer: `${captilazeFirstLetter(val.customerId.firstName)} ${captilazeFirstLetter(val.customerId.lastName)}`,
    //                 tradingAccount: val?.tradingAccountId?.login,
    //                 amount: val.amount,
    //               });
    //           }}
    //         ></i>
    //       </Link>
    //     </div>
    //   ),
    // },
    {
      dataField: "",
      isDummyField: true,
      editable: false,
      text: props.t("History"),
      formatter: (val) => (
        <div className="text-center">
          <Link
            className={`${
              ["CRYPTO", "BLOCKCHAIN", "WIRE_TRANSFER", "BANK", "USDT"].includes(val.gateway)
                ? "text-success"
                : "text-muted"
            }`}
            to="#"
          >
            <i
              className="mdi mdi-eye font-size-20"
              id="edittooltip"
              onClick={() => {
                setHistoryDetailsModal(true);
                sethistorySelectedContent({
                    history: val?.fundingSteps?.history,
                    transaction: val,
                  });
              }}
            ></i>
          </Link>
        </div>
      ),
    },
    // {
    //   dataField: "note",
    //   text: props.t("Note"),
    //   formatter: (val) =>
    //     val.reason ? (
    //       <div data-title={val.reason}>
    //         {val.reason.length > 20
    //           ? `${val.reason.slice(0, 20)}...`
    //           : val.reason}
    //       </div>
    //     ) : (
    //     <>{val?.fundingSteps?.fundingDepInitialStatus?.note ?? " - "}</>
    //     ),
    // },
    {
      dataField: "paid",
      text: props.t("Paid"),
      formatter: (val) =>{
        return val?.paid?.$numberDecimal ?? val?.paid;
      }
    },
    {
      dataField: "fee",
      text: props.t("Fee"),
      formatter: (val) =>{
        return val?.fee?.$numberDecimal ?? val?.fee;
      }
    },
    walletType === "users" && 
    {
      dataField: "fromCurrency",
      text: props.t("From Currency"),
    },
    walletType === "users" && 
    {
      dataField: "convertedFromAmount",
      text: props.t("From Amount"),
      formatter: (val) => val?.convertedFromAmount?.$numberDecimal || val?.convertedFromAmount || "",
    },
    {
      dataField: "amount",
      text: props.t("Amount"),
      formatter: (val) =>{
        return val?.amount?.$numberDecimal ?? val?.amount;
      }
    },
    // {
    //   dataField: "salesRep",
    //   text: props.t("Sales Rep"),
    // },
    {
      dataField: "status",
      text: props.t("Step Status"),
      // formatter: (val) => <Badge isCentered={true} status={val?.fundingSteps?.fundingDepInitialStatus?.status}></Badge>,
      formatter: (val) => <Badge isCentered={true} status={`${getFundingStepStatusKey?.[`${val?.fundingSteps?.riskDepartmentReviewStatus?.status}`]}`}></Badge>,
      // formatter: (val) => <Badge isCentered={true} status={`${getTheStatus(val?.status, val?.fundingSteps?.currentStep)}`}></Badge>,
    },
    // {
    //   dataField: "currentStep",
    //   text: props.t("Current Step"),
    //   formatter: (val) => getFundingStepKey?.[`${val?.fundingSteps?.currentStep}`],
    // },
    
    {
      dataField: "status",
      text: props.t("Overall Status"),
      // formatter: (val) => <Badge isCentered={true} status={`${getFundingStepKey?.[`${val?.fundingSteps?.currentStep}`]}${val?.status}`}></Badge>,
      formatter: (val) => <Badge isCentered={true} status={`${getTheStatus(val?.status, val?.fundingSteps?.currentStep)}`}></Badge>,
    },
    walletType === "customers" && 
    {
      dataField: "agent",
      text: props.t("Sales"),
      formatter: (val) => val?.agentInfo && `${val?.agentInfo?.firstName} ${val?.agentInfo?.lastName}`,
    },
    walletType === "customers" && 
    {
      dataField: "manager",
      text: props.t("Manager"),
      formatter: (val) => val?.managerInfo && `${val?.managerInfo?.firstName} ${val?.managerInfo?.lastName}`,
    },
    {
      dataField: "dropdown",
      isDummyField: true,
      editable: false,
      text: props.t("Actions"),
    },
    // {
    //   dataField: "",
    //   isDummyField: true,
    //   editable: false,
    //   text: props.t("Details"),
    //   formatter: (val) => (
    //     <div className="text-center">
    //       <Link
    //         className={`${
    //           ["CRYPTO", "BLOCKCHAIN", "WIRE_TRANSFER"].includes(val.gateway)
    //             ? "text-success"
    //             : "text-muted"
    //         }`}
    //         to="#"
    //       >
    //         <i
    //           className="mdi mdi-eye font-size-20"
    //           id="edittooltip"
    //           onClick={() => {
    //             if (val.gateway === "BLOCKCHAIN") {
    //               setDetailsModal(true);
    //               setSelectedContent({
    //                 network:
    //                   `${val?.rawData?.data?.item?.network} ${val?.rawData?.data?.item?.blockchain}` ||
    //                   "-",
    //                 referenceId: val?.rawData?.referenceId || "-",
    //                 amount: val?.amount?.$numberDecimal || val?.amount || "-",
    //                 address: val?.rawData?.data?.item?.address || "-",
    //                 confirmations:
    //                   val?.rawData?.data?.item?.currentConfirmations || "-",
    //                 requiredConfirmations:
    //                   val?.rawData?.data?.item?.targetConfirmations || "-",
    //                 transactionId:
    //                   val?.rawData?.data?.item?.transactionId || "-",
    //               });
    //             } else if (val.gateway == "WIRE_TRANSFER") {
    //               setDetailsModal(true);
    //               setSelectedContent({
    //                 ...val?.content,
    //               });
    //             } else if (val.gateway == "CRYPTO") {
    //               setDetailsModal(true);
    //               setSelectedContent({
    //                 ...val?.content,
    //               });
    //             }
    //           }}
    //         ></i>
    //       </Link>
    //     </div>
    //   ),
    // },
  ];

  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const initFilteredValues = {
    customerId: "",
    filterDate: {
      fromDate: "",
      toDate: "",
    },
    status: query.get("status") || "",
    currency: "",
    amount: "",
    gateway: "",
    agent: "",
  };
  const [filteredValues, setFilteredValues] = useState(initFilteredValues);
  const filterChangeHandler = (filteredValuesData) => {
    setFilteredValues(filteredValuesData);
  };

  
  const loadWithdrawals = (page, limit) => {
    const customerIdObj = props?.clientId ? { customerId: props?.clientId } : {};
    if (searchInput !== "" && searchInput.length >= 3) {
      dispatch(
        fetchWithdrawalsStart({
          page,
          limit,
          type: selected,
          searchText: searchInput,
          filteredValues,
          walletType,
          fundingStep: props.fundingStep,
          ...customerIdObj,
        })
      );
    } else if (searchInput === "") {
      dispatch(
        fetchWithdrawalsStart({
          page,
          limit,
          type: selected,
          filteredValues,
          walletType,
          fundingStep: props.fundingStep,
          ...customerIdObj,
        })
      );
    }
  };
  const withdrawApprove = (withdraw) => {
    dispatch(
      withdrawApproveStart({
        id: withdraw._id,
        customerId: withdraw?.customerId?._id,
        amount: withdraw.amount,
        note: withdraw.note,
        walletType,
        fundingStep: "riskDepartmentReview",
      })
    );
    setApproveModal(false);
  };
  const withdrawReject = (withdraw) => {
    dispatch(
      withdrawRejectStart({
        id: withdraw._id,
        customerId: withdraw?.customerId?._id,
        note: withdraw.note,
        walletType,
        fundingStep: "riskDepartmentReview",
      })
    );
    setRejectModal(false);
  };

  
  const withdrawOnHold = (withdraw) => {
    dispatch(
      withdrawalOnHold({
        id: withdraw._id,
        customerId: withdraw.customerId._id,
        note: withdraw.note,
        walletType,
        fundingStep: "riskDepartmentReview",
      })
    );
    setOnHoldModal(false);
  };


  useEffect(() => {
    loadWithdrawals(1, sizePerPage);
  }, [
    sizePerPage,
    1,
    searchInput,
    selected,
    props.withdrawalAddLoading,
    props.withdrawResponseMessage,
    props.withdrawChangeStatusSuccess,
    filteredValues,
    walletType,
    props.fundingStep,
  ]);
  
  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <Nav tabs>
              {tabs.map((tabItem) => (
                <>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classNames({
                        active: walletType === tabItem.tabId,
                      })}
                      onClick={() => {
                        toggle(tabItem.tabId);
                      }}
                    >
                      {tabItem.navLinkName}
                    </NavLink>
                  </NavItem>
                </>
              ))}
            </Nav>

            <CardHeader className="d-flex flex-column gap-3 ">
              <div className="d-flex justify-content-between align-items-center">
                <CardTitle className="color-primary">
                  {props.t(`Risk Department Review(${props.totalDocs})`)}
                  <FeatherIcon
                    icon="refresh-cw"
                    className="icon-lg ms-2"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      loadWithdrawals(1, sizePerPage);
                    }}
                  />
                </CardTitle>
              </div>
              <div className="d-flex flex-row align-items-center justify-content-between gap-2">
                <div className="d-flex flex-row align-items-center">
                  <SearchBar
                    handleSearchInput={handleSearchInput}
                    placeholder={props.t("Enter Transaction Id")}
                  />
                  {/* <div >
                    <Dropdown
                      isOpen={btnprimary1}
                      toggle={() => setBtnprimary1(!btnprimary1)}
                      style={{
                        marginRight: 10,
                      }}
                    >
                      <DropdownToggle tag="button" className="btn btn-primary">
                        {selected} <i className="mdi mdi-chevron-down" />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem value="LIVE" onClick={(e)=>{setSelected(e.target.value)}}>LIVE</DropdownItem>
                        <DropdownItem value="DEMO" onClick={(e)=>{setSelected(e.target.value)}}>DEMO</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div> */}
                  <Filter
                    filterChangeHandler={filterChangeHandler}
                    filteredValues={filteredValues}
                  />
                </div>
                {walletType === "customers" && (
                  <div className="d-flex flex-row align-items-center justify-content-between">
                    <WithDrawForm />
                  </div>
                )}
              </div>
            </CardHeader>

            <CardBody>
              <div className="table-rep-plugin">
                <div
                  className="table-responsive mb-0"
                  data-pattern="priority-columns"
                >
                  <Table id="tech-companies-1" className="table  table-hover ">
                    <Thead className="text-center table-light">
                      <Tr>
                        {columns.map((column, index) =>
                          <Th data-priority={index} key={index}><span className="color-primary">{column.text}</span></Th>
                        )}
                      </Tr>
                    </Thead>
                    {props.loading && (
                      <Tr className="w-100 d-flex">
                        <TableLoader colSpan={4} />
                      </Tr>
                    )}
                    {props.totalDocs === 0 ? (
                      <Tbody>
                        {!props.loading && (
                          <>
                            <Tr>
                              <Td
                                colSpan={"100%"}
                                className="fw-bolder text-center"
                                st
                              >
                                <h3 className="fw-bolder text-center">
                                  No records
                                </h3>
                              </Td>
                            </Tr>
                          </>
                        )}
                      </Tbody>
                    ) : (
                      <Tbody
                        style={{
                          fontSize: "12px",
                          textAlign: "center",
                          textTransform: "capitalize",
                        }}
                      >
                        {!props.loading &&
                          props.withdrawals.map((row, rowIndex) => (
                            <Tr key={rowIndex}>
                              {columns.map((column, index) => (
                                <Td
                                  key={`${rowIndex}-${index}`}
                                  className="pt-4"
                                >
                                  {column.dataField === "checkbox" ? (
                                    <input
                                      className="deposit-checkbox"
                                      type="checkbox"
                                    />
                                  ) : (
                                    ""
                                  )}
                                  {column.formatter
                                    ? column.formatter(row, rowIndex)
                                    : row[column.dataField]}
                                  {column.dataField === "dropdown" && (
                                    <CustomDropDown
                                      permission={
                                        props?.riskDepartmentReviewPermissions?.update
                                      }
                                      status={row?.fundingSteps?.riskDepartmentReviewStatus?.status}
                                      approveText="Risk Pass"
                                      rejectText="Risk Reject"
                                      onholdText="Risk Hold"
                                      approve={() => {
                                        setApproveModal(true);
                                        setSelectedWithdraw(row);
                                      }}
                                      reject={() =>{
                                        setRejectModal(true);
                                        setSelectedWithdraw(row);
                                      }}
                                      onHold={() =>{
                                        setOnHoldModal(true);
                                        setSelectedWithdraw(row);
                                      }}
                                    />
                                  )}
                                </Td>
                              ))}
                            </Tr>
                          ))}
                      </Tbody>
                    )}
                  </Table>
                  <CustomPagination
                    {...props}
                    setSizePerPage={setSizePerPage}
                    sizePerPage={sizePerPage}
                    onChange={loadWithdrawals}
                    docs={props.withdrawals}
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <HistoryDetailsModal
        open={historyDetailsModal}
        history={historySelectedContent?.history}
        transaction={historySelectedContent?.transaction}
        onClose={() => setHistoryDetailsModal(false)}
      />
      <ReceiptModal
        open={detailsModal}
        content={{
          type: "json",
          content: selectedContent,
        }}
        onClose={() => setDetailsModal(false)}
      />
      {approveModal && <TransactionForexModal
        show={approveModal}
        title="Approve Withdraw"
        submitButton="Approve"
        showAmount={false}
        toggle={()=>{
          setApproveModal(!approveModal);
        }}
        t={props.t}
        onSubmit={withdrawApprove}
        data={selectedWithdraw}
      >
        </TransactionForexModal>}
      {rejectModal && <TransactionForexModal
        show={rejectModal}
        title="Reject Withdraw"
        submitButton="Reject"
        showAmount={false}
        toggle={()=>{
        setRejectModal(!rejectModal);
      }}
        t={props.t}
        onSubmit={withdrawReject}
        data={selectedWithdraw}
    >
      </TransactionForexModal>}
      
      {onHoldModal && <TransactionForexModal
        show={onHoldModal}
        title="On Hold Withdraw"
        submitButton="On Hold"
        fundingStep="riskDepartmentReview"
        showAmount={false}
        toggle={()=>{
          setOnHoldModal(!onHoldModal);
        }}
        t={props.t}
        onSubmit={withdrawOnHold}
        data={selectedWithdraw}
      >
        </TransactionForexModal>}
    </React.Fragment>
  );
}
const mapStateToProps = (state) => ({
  loading: state.withdrawalReducer.loading || false,
  withdrawals: state.withdrawalReducer.withdrawals || [],
  page: state.withdrawalReducer.page || 1,
  totalDocs: state.withdrawalReducer.totalDocs || 0,
  totalPages: state.withdrawalReducer.totalPages || 0,
  withdrawalAddLoading: state.withdrawalReducer.withdrawalAddLoading,
  addLoading: state.withdrawalReducer.addLoading,
  hasNextPage: state.withdrawalReducer.hasNextPage,
  hasPrevPage: state.withdrawalReducer.hasPrevPage,
  limit: state.withdrawalReducer.limit,
  nextPage: state.withdrawalReducer.nextPage,
  pagingCounter: state.withdrawalReducer.pagingCounter,
  prevPage: state.withdrawalReducer.prevPage,
  withdrawalsPermissions: state.Profile.withdrawalsPermissions || {},
  withdrawResponseMessage: state.withdrawalReducer.withdrawResponseMessage,
  riskDepartmentReviewPermissions: state.Profile.riskDepartmentReviewPermissions || {},
  withdrawChangeStatusSuccess:
    state.withdrawalReducer.withdrawChangeStatusSuccess,
});
export default connect(mapStateToProps, null)(withTranslation()(WithdrawalRiskDepartmentReview));
