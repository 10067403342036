import React, { useState, useEffect } from "react";
import {
  useDispatch, connect,
  useSelector
} from "react-redux";
import {
  Modal, ModalHeader,
  ModalBody,
  Row, Col, Button, Input, Label
} from "reactstrap";
import {
  AvForm, AvField, AvRadio, AvRadioGroup
} from "availity-reactstrap-validation";
import { withTranslation } from "react-i18next";

import { createTradingAccount } from "store/tradingAccounts/actions";
import { ACCOUNT_TYPES } from "common/data/trading-account";
import LEVERAGES from "constants/accountType";

function CreateMT5 (props) {
  const dispatch = useDispatch();
  const [type, setType] = useState(null);
  const [filteredAccountTypes, setFilteredAccountTypes] = useState([]);
  const [accountCurrency, setSAccountCurrency] = useState([]);
  const [accountLeverages, setAccountLeverages] = useState([]);
  const [defaultLeverage, setDefaultLeverage] = useState("");
  const [selectedPlatform, setSelectedPlatform] = useState("");

  const {
    clientDetails,
  } = useSelector((state) => ({
    clientDetails: state.clientReducer.clientDetails,
  }));
  
  const [platformAccountTypes, setPlatformAccountTypes] = useState([]);
  const [selectedPromo, setSelectedPromo] = useState(null);
  const [promoLinkPlatforms, setPromoLinkPlatforms] = useState(null);

  useEffect(() => {
    if (accountLeverages?.length <= 0) {
      setAccountLeverages(LEVERAGES);
    }
  }, [accountLeverages]);

  
  useEffect(() => {
    setPlatformAccountTypes(filteredAccountTypes?.filter((account) => account.platform === selectedPlatform));
  }, [selectedPlatform]);
  useEffect(() => {
    if (clientDetails?.promoLink) {
      let platformsSet = new Set();
      clientDetails?.promoLink?.forEach(promo => {
        platformsSet.add(promo?.accountType?.platform);
      });
      setPromoLinkPlatforms([...platformsSet]);
    }
  }, [clientDetails?.promoLink, selectedPlatform]);


  const { create = true } = props.tradingAccountPermission;
  
  const accountTypes = props.accountTypes;
  const platforms = accountTypes.length > 0 && [...new Set(accountTypes.map(item => item.platform))];

  // filter accounts based on account's type (live/demo)
  useEffect(() => {
    setFilteredAccountTypes(accountTypes?.filter((at) => at.type === type?.toUpperCase() && at.platform === selectedPlatform));
  }, [accountTypes, type, selectedPlatform]);

  const handleCreateAccount = (e, values) => {
    dispatch(createTradingAccount(values));
  };

  useEffect(()=>{
    if (props.createCounter > 0 && props.show) {
      props.toggle();
    }
  }, [props.createCounter]);

  useEffect(()=>{
    console.log("accountLeverages", accountLeverages, LEVERAGES);
  }, [accountLeverages, LEVERAGES]);

  return (
    <React.Fragment >
      <button className={`btn btn-primary ${!create ? "d-none" : ""}`} onClick={props.toggle}>
        {props.modalOpenBtnLabel ? <i className="bx bx-plus"></i> : null}
        {props.modalOpenBtnLabel ? props.modalOpenBtnLabel : "Create Trading Account"}</button>
      <Modal isOpen={props.show} toggle={props.toggle} centered={true}>
        <ModalHeader toggle={props.toggle} tag="h4">
          {props.t("Create Trading Account")}
        </ModalHeader>
        <ModalBody >
          <AvForm onValidSubmit={handleCreateAccount}>
            <Row>
              <Col className="col-12 mb-3 d-none">
                <AvField
                  name="customerId"
                  type="hidden"
                  value={props.customerId}/>
              </Col>
            </Row>
            <Row className="border rounded-3 p-3">
              {/* {platforms.length > 0 && (
                <Row className="border rounded-3 p-3 mt-3">
                  <h5>{props.t("Platforms")}</h5>
                  {platforms.map((platform) => (
                    <Col key={platform} md="6" className="gy-3">
                      <div className="custom-radio" onClick={() => setSelectedPlatform(platform)}>
                        <Input
                          className="mt-0 me-2"
                          id={platform}
                          name="platform"
                          type="radio"
                          checked={selectedPlatform === platform} 
                          value={platform}
                          onChange={() => setSelectedPlatform(platform)}
                        />
                        <Label check for={platform}>
                          {props.t(platform)}
                        </Label>
                      </div>
                    </Col>
                  ))}
                </Row>
              )} */}
              {platforms &&
              <Row className="border rounded-3 p-3 gx-1">
                <h5>{props.t("Platforms")}</h5>
                {/* {clientDetails?.promoLink && Object.entries(clientDetails.promoLink).length > 0 ? (
                  <Col key={clientDetails?.promoLink?.accountType?.platform} md="6" className="gy-3 px-2">
                    <div className="d-flex align-items-center border rounded-3 p-2 bg-light">
                      <Input
                        className="mt-0 me-2"
                        id={clientDetails?.promoLink?.accountType?.platform}
                        name="platform"
                        type="radio"
                        value={clientDetails?.promoLink?.accountType?.platform}
                        onClick={() => {
                          setSelectedPlatform(clientDetails?.promoLink?.accountType?.platform);
                        }}
                      />
                      <Label check for={clientDetails?.promoLink?.accountType?.platform}>
                        {props.t(clientDetails?.promoLink?.accountType?.platform)}
                      </Label>
                    </div>
                  </Col> */}
                  
                {clientDetails?.promoLink && promoLinkPlatforms?.length > 0 ? (
                  promoLinkPlatforms?.map((promo) => (
                    <Col key={promo} md="6" className="gy-3 px-2">
                      <div className="d-flex align-items-center border rounded-3 p-2 bg-light">
                        <Input
                          className="mt-0 me-2"
                          id={promo}
                          name="platform"
                          type="radio"
                          value={promo}
                          onClick={() => {
                            setSelectedPlatform(promo);
                          }}
                        />
                        <Label check for={promo}>
                          {props.t(promo)}
                        </Label>
                      </div>
                    </Col>
                  ))
                ) : (
                  platforms.map((platform) => (
                    <Col key={platform} md="6" className="gy-3 px-2">
                      <div className="d-flex align-items-center border rounded-3 p-2 bg-light">
                        <Input
                          className="mt-0 me-2"
                          id={platform}
                          name="platform"
                          type="radio"
                          value={platform}
                          onClick={() => {
                            setSelectedPlatform(platform);
                          }}
                        />
                        <Label check for={platform}>
                          {props.t(platform)}
                        </Label>
                      </div>
                    </Col>
                  ))
                )}
              </Row>
              }
              <h5>{props.t("Type")}</h5>
              <Col md="6" className="gy-3">
                <div className="custom-radio"
                  onClick={() => {
                    setType(ACCOUNT_TYPES.LIVE);
                    document.getElementById(ACCOUNT_TYPES.LIVE).click();
                  }}>
                  <Input
                    className="mt-0 me-2"
                    id={ACCOUNT_TYPES.LIVE}
                    name="type"
                    type="radio"
                    value={ACCOUNT_TYPES.LIVE}
                  >
                  </Input>
                  <Label check for={ACCOUNT_TYPES.LIVE}>
                    {props.t(ACCOUNT_TYPES.LIVE)}
                  </Label>
                </div>
              </Col>
              <Col md="6" className="gy-3">
                <div className="custom-radio"
                  onClick={() => {
                    setType(ACCOUNT_TYPES.DEMO);
                    document.getElementById(ACCOUNT_TYPES.DEMO).click();
                  }}>                  <Input
                    className="mt-0 me-2"
                    id={ACCOUNT_TYPES.DEMO}
                    name="type"
                    type="radio"
                    value={ACCOUNT_TYPES.DEMO}
                  >
                  </Input>
                  <Label check for={ACCOUNT_TYPES.DEMO}>
                    {props.t(ACCOUNT_TYPES.DEMO)}
                  </Label>
                </div>
              </Col>
              {selectedPlatform === "MT5" && (
                <Col md="6" className="gy-3">
                  <div className="custom-radio"
                    onClick={() => {
                      setType(ACCOUNT_TYPES.INVESTOR);
                      document.getElementById(ACCOUNT_TYPES.INVESTOR).click();
                    }}>                  <Input
                      className="mt-0 me-2"
                      id={ACCOUNT_TYPES.INVESTOR}
                      name="type"
                      type="radio"
                      value={ACCOUNT_TYPES.INVESTOR}
                    >
                    </Input>
                    <Label check for={ACCOUNT_TYPES.INVESTOR}>
                      {props.t(ACCOUNT_TYPES.INVESTOR)}
                    </Label>
                  </div>
                </Col>
              )}
            </Row>

            {/* {type && <AvRadioGroup name="accountTypeId" required errorMessage={props.t("Select Account Type")}>
              <Row className="border rounded-3 p-3 mt-3">
                <h5>{props.t("Account Type")}</h5>
                {filteredAccountTypes.length > 0 && filteredAccountTypes.map((type) =>
                  <Col key={type._id} md="6" className="gy-3">
                    <div className="custom-radio"
                      onClick={() => {
                        setSAccountCurrency(type.currencies);
                        if (type?.leverages) {
                          setAccountLeverages(type.leverages);
                          if (type?.defaultLeverage) {
                            setDefaultLeverage(`${type.defaultLeverage}`);
                          } 
                          else {
                            setDefaultLeverage(`${type.leverages[0]}`);
                          }
                        } else {
                          setAccountLeverages(LEVERAGES);
                          setDefaultLeverage(`${LEVERAGES[0]}`);
                        
                        }
                        
                        document.getElementById(`radio-accountTypeId-${type._id}`).click();
                      }}>
                      <AvRadio label={props.t(type.title)} value={type._id}/>
                    </div>
                  </Col>
                )}
              </Row>
              
            </AvRadioGroup>
            } */}
            {/* {selectedPlatform && (
              <AvRadioGroup name="accountTypeId" className="border rounded-3 mt-3" required>
                <Row className="p-3">
                  <h5>{props.t("Account Type")}</h5>
                  {clientDetails?.promoLink && Object.entries(clientDetails.promoLink).length > 0 ? (
                    <Col key={clientDetails?.promoLink?.accountType?._id} md="6" className="gy-3 mt-3">
                      <div className="d-flex align-items-center border rounded-3 p-2 bg-light">
                        <AvRadio
                          label={props.t(clientDetails?.promoLink?.accountType?.title)}
                          value={clientDetails?.promoLink?.accountType?._id}
                          onClick={() => {
                            setSAccountCurrency(clientDetails?.promoLink?.accountType?.currencies);
                          }}
                        />
                      </div>
                    </Col>
                  ) : (
                    filteredAccountTypes.length > 0 &&
                    filteredAccountTypes.map((type) => (
                      <Col key={type._id} md="6" className="gy-3 mt-3">
                        <div className="d-flex align-items-center border rounded-3 p-2 bg-light">
                          <AvRadio
                            label={props.t(type.title)}
                            value={type._id}
                            onClick={() => {
                              setSAccountCurrency(type.currencies);
                            }}
                          />
                        </div>
                      </Col>
                    ))
                  )}
                </Row>
              </AvRadioGroup>
            )} */}
            
            {selectedPlatform && (
              <AvRadioGroup name="accountTypeId" className="border rounded-3 mt-3" required>
                <Row className="p-3">
                  <h5>{props.t("Account Type")}</h5>
                  {selectedPlatform && clientDetails?.promoLink?.length > 0 ? (
                    clientDetails?.promoLink?.map((promo) => (
                      promo?.accountType?.platform === selectedPlatform && (
                        <Col key={promo?.accountType?._id} md="6" className="gy-3 mt-3">
                          <div className="d-flex align-items-center border rounded-3 p-2 bg-light">
                            <AvRadio
                              label={props.t(promo?.accountType?.title)}
                              value={promo?.accountType?._id}
                              onClick={() => {
                                setSAccountCurrency(promo?.accountType?.currencies);
                                setSelectedPromo(promo);
                              }}
                            />
                          </div>
                        </Col>
                      )
                    ))
                  ) : (
                    filteredAccountTypes.length > 0 &&
                            filteredAccountTypes.map((type) => (
                              <Col key={type._id} md="6" className="gy-3 mt-3">
                                <div className="d-flex align-items-center border rounded-3 p-2 bg-light">
                                  <AvRadio
                                    label={props.t(type.title)}
                                    value={type._id}
                                    onClick={() => {
                                      setSAccountCurrency(type.currencies);
                                    }}
                                  />
                                </div>
                              </Col>
                            ))
                  )}
                </Row>
              </AvRadioGroup>
            )}
            {/* {accountCurrency.length > 0 &&
            <AvRadioGroup name="currency" required errorMessage={props.t("Select Currency")}>
              <Row className="border rounded-3 p-3">
                <h5 className="mb-3">{props.t("Account Currency")}</h5>
                {
                  clientDetails?.promoLink ?
                    <Col md="6" className="gy-3" >
                      <div className="custom-radio"
                        onClick={() => {
                          document.getElementById("radio-currency-usd").click();
                        }} >
                        <AvRadio label="USD" value="USD" id="radio-currency-usd"/>
                      </div>
                    </Col>
                    :
                    accountCurrency.map((currency) =>
                      <Col key={currency.currency} md="6" className="gy-3" >
                        <div className="custom-radio"
                          onClick={() => {
                            document.getElementById(`radio-currency-${currency.currency}`).click();
                          }} >
                          <AvRadio label={props.t(currency.currency)} value={currency.currency} />
                        </div>
                      </Col>
                    )
                }
              </Row>
            </AvRadioGroup>} */}
            
            <Row className="border rounded-3 p-3 mt-3 gx-1">
              <h5 className="mb-3">{props.t("Select Leverage")}</h5>
              <AvField type="select" name="leverage" value={"1"} required>
                {selectedPromo && Object.entries(selectedPromo).length > 0 ? (
                  selectedPromo?.accountType?.leverages.map((leverage) => (
                    <option key={leverage} value={leverage}>{`1:${leverage}`}</option>
                  ))
                ) : (
                  filteredAccountTypes?.[0]?.leverages.map((leverage) => (
                    <option key={leverage} value={leverage}>{`1:${leverage}`}</option>
                  ))
                )}
              </AvField>
            </Row>
            {/* <Row className="border rounded-3 p-3 mt-3">
              <h5 className="mb-3">{props.t("Select Leverage")}</h5>
              <AvField type="select" name="leverage" value={defaultLeverage} required>
                {accountLeverages.map((leverage) =>
                  <option key={leverage} value={leverage}>1:{leverage}</option>
                )}
              </AvField>
            </Row> */}
            <Row>
              <Col>
                <div className="text-end">
                  <Button disabled={props.creating} type="submit" color="primary" className="">
                    Create
                  </Button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  accountTypes: state.tradingAccountReducer.accountTypes || [],
  tradingAccountPermission: state.Profile.todosPermissions || {},
  createCounter: state.tradingAccountReducer.createCounter || 0,
  creating: state.tradingAccountReducer.creating || false,
});
export default connect(mapStateToProps, null)(withTranslation()(CreateMT5));
