import {
  useDispatch, connect, useSelector
} from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledAlert,
  Label,
  Input,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { addNewItem } from "store/dictionary/actions";
import AvFieldSelect from "components/Common/AvFieldSelect";
import { v4 as uuidv4 } from "uuid";
import {
  editPromoStart, fetchAccountTypes, fetchPromoStart, fetchServerStart
} from "store/actions";
import { fetchMtGroups } from "store/rebateSettings/groups/actions";
import ReactSelect from "react-select";

function PromotionLinksEdit(props) {
  const ALL_PAGES = 20;

  const { setEditModal, selectedPromo, dispatch, isLoading, setIsLoading } = props;

  const [addModal, setAddModal] = useState(false);
  const [selectedMTGroups, setSelectedMTGroups] = useState([]);
  const [mtgroupssOptions, setMtgroupssOptions] = useState([]);
  const [isApplicableToAm, setIsApplicableToAm] = useState(selectedPromo.applicableToAm);
  const [isDefault, setIsDefault] = useState(selectedPromo.default);
  const [isMustBeBound, setIsMustBeBound] = useState(selectedPromo.mustBeBound);
  const [status, setStatus] = useState(selectedPromo.status);
  const [promoCode, setPromoCode] = useState("");
  const [selectedServer, setSelectedServer] = useState(null);
  const [selectedAccountType, setSelectedAccountType] = useState(null);
  const [selectedAccountTypeTitle, setSelectedAccountTypeTitle] = useState(null);

  const toggleEditModal = () => {
    props.setEditModal(preValue => !preValue);
  };
  const { create } = props.dictionariesPermissions;
  useEffect(() => {
    if (!props.showAddSuccessMessage && addModal) {
      setAddModal(false);
    }
  }, [props.showAddSuccessMessage]);

  useEffect(() => {
    loadServers(1, ALL_PAGES);
    loadAccountTypes();
    loadMTGroups();
    loadPromotionLinks(1, ALL_PAGES);
  }, []);

  useEffect(() => {
    loadPromotionLinks(1, ALL_PAGES);
  }, [dispatch, isLoading]);

  useEffect(() => {
    if (!props.showAddSuccessMessage && addModal) {
      setAddModal(false);
    }
  }, [props.showAddSuccessMessage]);


  useEffect(() => {
    if (props?.mtGroups) {
      const mtgroupssOptions = props?.mtGroups?.filter((group) => group.accountGroupType === selectedAccountTypeTitle?.title)?.map((mtgroup) => {
        return {
          value: mtgroup?._id,
          label: mtgroup?.mtGroupName,
        };
      });
      setMtgroupssOptions(mtgroupssOptions);
    }
  }, [props.mtGroups, selectedAccountTypeTitle]);


  useEffect(() => {
    setSelectedMTGroups(selectedPromo?.mtGroups?.filter((group) => group.status === "Enabled")?.map((group) => ({
      label: group.mtGroupName,
      value: group._id,
    })));
  }, []);


  // To be extracted and added to Util module later
  const generateRandomPromoCode = () => {
    return uuidv4().replace(/-/g, "").substring(0, 15);
  };


  const loadMTGroups = async (page = 1, limit = 100000) => {
    await dispatch(
      fetchMtGroups({
        limit,
        page,
        // type: props?.type,
      })
    );
  };
  // To be extracted and added to api Calling module later
  const loadServers = async (page, limit) => {
    await dispatch(
      fetchServerStart({
        page,
        limit,
      })
    );
  };

  // To be extracted and added to api Calling module later
  const loadAccountTypes = async () => {
    await dispatch(fetchAccountTypes());
  };

  // To be extracted and added to api Calling module later
  const loadPromotionLinks = async (page, limit) => {
    await dispatch(fetchPromoStart());
  };

  // Generate options for MT Server
  const mtServerOptions = useSelector((state) => state?.MTServer?.servers?.docs.map((server) => ({
    label: server?.name,
    value: server?._id
  })));

  const servers = useSelector((state) => state?.MTServer?.servers?.docs);

  useEffect(() => {
    setSelectedServer(selectedPromo?.mtServer);
    setSelectedAccountType(selectedPromo?.accountType?._id);
    setSelectedAccountTypeTitle(selectedPromo?.accountType);
  }, []);

  // Generate options for Account Type
  const accountTypeOptions = useSelector((state) =>
    state?.tradingAccountReducer?.accountTypes?.filter((type) => type?.enabled && type.platform === selectedServer?.platform)?.map((accountType) => ({
      label: accountType.title,
      value: accountType._id,
    }))
  );
  const accounts =  useSelector((state) => state?.tradingAccountReducer?.accountTypes);

  // handle MT Groups change
  const handleMTGroupChange = (value) => {
    setSelectedMTGroups(value);
  };

  const handleEditPromoSubmit = (e, v) => {
    e.preventDefault();
    setIsLoading(true);
    console.log("add Promo Values: ", v);
    dispatch(editPromoStart({
      ...v,
      id: selectedPromo?._id
    }));
    setIsLoading(false);
    toggleEditModal();
  };

  return (
    <React.Fragment >
      <Link to="#" className={`btn btn-primary ${!create ? "d-none" : ""}`} onClick={toggleEditModal}><i className="bx bx-plus me-1"></i>{props.t("Edit Promotion Link")}</Link>
      <Modal isOpen={props.show} toggle={toggleEditModal} centered={true} size="lg">
        <ModalHeader toggle={toggleEditModal} tag="h4">
          {props.t("Edit Promotion Link")}
        </ModalHeader>
        <ModalBody >
          <AvForm
            className='p-4'
            onValidSubmit={(e, v) => {
              e.preventDefault();
              console.log(selectedMTGroups);
              v.mtGroups = selectedMTGroups.map((group) => group.value);
              v.applicableToAm = isApplicableToAm;
              v.default = isDefault;
              v.mustBeBound = isMustBeBound;
              v.status = status;
              // dispatch(editPromoStart(props.id, v));
              console.log("Edit Values: ", v);
              handleEditPromoSubmit(e, v);
            }}
          >
            <div className="my-3">
              <AvField
                name="title"
                label={props.t("Link Title")}
                placeholder={props.t("Enter Link Title")}
                type="text"
                value={selectedPromo?.title || ""}
                errorMessage={props.t("Enter valid Link Title")}
                validate={{ required: { value: true } }}
              />
            </div>
            <div className="my-3">
              <div className="d-flex align-items-center justify-content-between flex-row">
                <AvField
                  name="promoCode"
                  label={props.t("Promotion Code")}
                  placeholder={props.t("Generate New Promotion Code")}
                  type="text"
                  disabled
                  value={promoCode || selectedPromo?.promoCode}
                  errorMessage={props.t("Promotion Code is required")}
                  validate={{ required: { value: true } }}
                />
                {/* <Button
                  color="primary"
                  className="me-2"
                  onClick={() => {
                    // const promoCode = generateRandomPromoCode();
                    setPromoCode(() => generateRandomPromoCode());
                  }}
                >
                  <strong className="text-primary">Generate </strong>
                </Button> */}
              </div>
            </div>
            <div className="my-3">
              <AvFieldSelect
                name="mtServer"
                type="text"
                errorMessage={props.t(
                  "MT Server is required"
                )}
                value={selectedPromo?.mtServer?._id || ""}
                label={props.t("MT Server")}
                options={mtServerOptions}
                onChange={(e) => {
                  setSelectedAccountType(null);
                  setSelectedServer(servers.find((server) => server?._id === e));
                }}
              />
            </div>
            <div className="my-3">
              <AvFieldSelect
                name="accountType"
                type="text"
                errorMessage={props.t(
                  "ٌAccount Type is required"
                )}
                value={selectedAccountType || ""}
                label={props.t("Account Type")}
                options={accountTypeOptions}
                onChange={(e) => setSelectedAccountTypeTitle(accounts.find((account) => account._id === e))}
              />
            </div>
            <div className="mb-3">
              <div className="d-felx justify-content-center align-items-center">
                <Label className="me-2 mb-2" for="mtGroup">MT Groups</Label>
                <ReactSelect
                  isMulti
                  options={mtgroupssOptions}
                  onChange={handleMTGroupChange}
                  name="mtGroup"
                  label={props.t("MT Group")}
                  placeholder={props.t("Enter MT Group")}
                  type="text"
                  defaultValue={selectedPromo?.mtGroups?.map((group) => ({
                    label: group.mtGroupName,
                    value: group._id,
                  }))}
                  errorMessage={props.t("Enter valid MT Group")}
                  validate={{ required: { value: true } }}
                />
              </div>
            </div>
            <div className="my-3">
              <AvFieldSelect
                name="defaultMTGroup"
                type="text"
                errorMessage={props.t(
                  "ٌDefault MT Group is required"
                )}
                value={selectedPromo?.defaultMTGroup?._id}
                label={props.t("Default MT Group")}
                options={selectedMTGroups}
              />
            </div>
            <div className="d-flex align-items-center mb-3">
              <h6 className="mb-0 me-3" style={{ width: "200px" }}>Is it applicable to AM</h6>
              <div className="d-flex align-items-center">
                <Label className="me-2 mb-0" for="applicableToAMYes">Yes</Label>
                <Input
                  type="radio"
                  id="applicableToAMYes"
                  name="applicableToAM"
                  value="yes"
                  className="me-3"
                  checked={isApplicableToAm === true}
                  onChange={(e) => setIsApplicableToAm(true)}
                />
                <Label className="me-2 mb-0" for="applicableToAMNo">No</Label>
                <Input
                  type="radio"
                  id="applicableToAMNo"
                  name="applicableToAM"
                  value="no"
                  checked={isApplicableToAm === false}
                  onChange={(e) => setIsApplicableToAm(false)}
                />
              </div>
            </div>

            <div className="d-flex align-items-center mb-3">
              <h6 className="mb-0 me-3" style={{ width: "200px" }}>Default</h6>
              <div className="d-flex align-items-center">
                <Label className="me-2 mb-0" for="defaultYes">Yes</Label>
                <Input
                  type="radio"
                  id="defaultYes"
                  name="default"
                  value="yes"
                  className="me-3"
                  checked={isDefault === true}
                  onChange={(e) => setIsDefault(true)}
                />
                <Label className="me-2 mb-0" for="defaultNo">No</Label>
                <Input
                  type="radio"
                  id="defaultNo"
                  name="default"
                  value="no"
                  checked={isDefault === false}
                  onChange={(e) => setIsDefault(false)}
                />
              </div>
            </div>

            <div className="d-flex align-items-center mb-3">
              <h6 className="mb-0 me-3" style={{ width: "200px" }}>Must be bound</h6>
              <div className="d-flex align-items-center">
                <Label className="me-2 mb-0" for="mustBeBoundYes">Yes</Label>
                <Input
                  type="radio"
                  id="mustBeBoundYes"
                  name="mustBeBound"
                  value="yes"
                  className="me-3"
                  checked={isMustBeBound === true}
                  onChange={(e) => setIsMustBeBound(true)}
                />
                <Label className="me-2 mb-0" for="mustBeBoundNo">No</Label>
                <Input
                  type="radio"
                  id="mustBeBoundNo"
                  name="mustBeBound"
                  value="no"
                  checked={isMustBeBound === false}
                  onChange={(e) => setIsMustBeBound(false)}
                />
              </div>
            </div>

            <div className="d-flex align-items-center mb-3">
              <h6 className="mb-0 me-3" style={{ width: "200px" }}>Status</h6>
              <div className="d-flex align-items-center">
                <Label className="me-2 mb-0" for="statusEnabled">Enabled</Label>
                <Input
                  type="radio"
                  id="statusEnabled"
                  name="status"
                  value="enabled"
                  className="me-3"
                  checked={status === "Enabled"}
                  onChange={(e) => setStatus("Enabled")}
                />
                <Label className="me-2 mb-0" for="statusDisabled">Disabled</Label>
                <Input
                  type="radio"
                  id="statusDisabled"
                  name="status"
                  value="disabled"
                  checked={status === "Disabled"}
                  onChange={(e) => setStatus("Disabled")}
                />
              </div>
            </div>


            <div className='text-center pt-3 p-2'>
              <Button disabled={props.disableAddButton} type="submit" color="primary" className="">
                {props.t("Edit")}
              </Button>
            </div>
          </AvForm>
          {props.error && <UncontrolledAlert color="danger">
            <i className="mdi mdi-block-helper me-2"></i>
            {props.editError}
          </UncontrolledAlert>}

        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}
const mapStateToProps = (state) => ({
  id: state.dictionaryReducer.id,
  error: state.dictionaryReducer.error,
  showAddSuccessMessage: state.dictionaryReducer.showAddSuccessMessage,
  dictionariesPermissions: state.Profile.dictionariesPermissions || {},
  disableAddButton: state.dictionaryReducer.disableAddButton,
  mtGroups: state.groupReducer.mtGroups?.groups,
  promoLinks: state.Promo?.promos?.docs || [],
  isLoading: state.Promo?.promo?.fetchPromoLoading,
  promoError: state.Promo?.promo?.promoError
});
export default connect(mapStateToProps, null)(withTranslation()(PromotionLinksEdit));