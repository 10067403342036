import React, {
  useCallback,
  useEffect,
  useState
} from "react";
import PropTypes from "prop-types";

const propTypes = {
  nodeData: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  structure: PropTypes.object.isRequired
};

const getType = (nodeData, id) => {
  if (nodeData._id === id) return "Selected";
  if (nodeData.level === -1) return "Master";
  if (nodeData?.fx?.isIb) return "IB";
  return "Client";
};

const MyNode = ({ nodeData, onSelect, id }) => {
  const selectNode = () => {
    onSelect(nodeData);
  };
  const [tradingAccounts, setTradingAccounts] = useState([]);

  useEffect(() => {
    if (nodeData.fx) {
      setTradingAccounts([
        ...nodeData?.fx?.liveAcc,
        ...nodeData?.fx?.demoAcc,
      ]);
    }
  }, [nodeData]);

  const getClientType = useCallback(() => getType(nodeData, id), [nodeData, id]);

  return (
    <div className="node-card" onClick={selectNode} >
      <div className={`node-card-container ${getClientType()}`}>
        <div className="node-card-header" style={{
          fontSize: 12,
          lineHeight: 1
        }}>
          {(`${nodeData.firstName} ${nodeData.lastName}`).length > 15 ? (
            <>
              {/* should 13 characters */}
              {nodeData.firstName.slice(0, 13)}...
            </>
          ) : (
            <>
              {nodeData.firstName} {nodeData.lastName}
            </>
          )}
        </div>
        <div className="node-card-body" style={{height: "50px"}}>
          {/* show only 3 accounts */}
          {tradingAccounts.slice(0, 3).join(", ")}
        </div>
      </div>
    </div>
  );
};

MyNode.propTypes = propTypes;

export default MyNode;
