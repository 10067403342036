// fetch symbols
export const FETCH_SYMBOLS_REQUESTED = "FETCH_SYMBOLS_REQUESTED";
export const FETCH_SYMBOLS_SUCCESS = "FETCH_SYMBOLS_SUCCESS";
export const FETCH_SYMBOLS_FAIL = "FETCH_SYMBOLS_FAIL";
// sync symbols
export const SYNC_SYMBOL_REQUESTED = "SYNC_SYMBOL_REQUESTED";
export const SYNC_SYMBOL_SUCCESS = "SYNC_SYMBOL_SUCCESS";
export const SYNC_SYMBOL_FAIL = "SYNC_SYMBOL_FAIL";

export const ADD_INTERNAL_TRANSFER_REQUESTED = "ADD_INTERNAL_TRANSFER_REQUESTED";
export const ADD_INTERNAL_TRANSFER_SUCCESS = "ADD_INTERNAL_TRANSFER_SUCCESS";
export const ADD_INTERNAL_TRANSFER_FAIL = "ADD_INTERNAL_TRANSFER_FAIL";
export const ADD_INTERNAL_TRANSFER_CLEAR = "ADD_INTERNAL_TRANSFER_CLEAR";
export const ADD_INTERNAL_TRANSFER_ERROR_CLEAR = "ADD_INTERNAL_TRANSFER_ERROR_CLEAR";

export const CHANGE_SYMBOL_START = "CHANGE_SYMBOL_START";
export const CHANGE_SYMBOL_END = "CHANGE_SYMBOL_END";

export const APPROVE_INTERNAL_TRANSFER = "APPROVE_INTERNAL_TRANSFER";
export const APPROVE_INTERNAL_TRANSFER_SUCCESS = "APPROVE_INTERNAL_TRANSFER_SUCCESS";
export const REJECT_INTERNAL_TRANSFER = "REJECT_INTERNAL_TRANSFER";
export const REJECT_INTERNAL_TRANSFER_SUCCESS = "REJECT_INTERNAL_TRANSFER_SUCCESS";