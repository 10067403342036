/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import {
  useDispatch,
  connect,
  useSelector
} from "react-redux";
// eslint-disable-next-line object-curly-newline
import { Row, Col, Card, CardBody, CardHeader, CardTitle, Nav, NavItem, NavLink } from "reactstrap";
import TableLoader from "components/Common/Loader";
import CustomPagination from "components/Common/CustomPagination";
// eslint-disable-next-line object-curly-newline
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import WithDrawForm from "./WithDrawForm";
import {
  fetchWithdrawalsStart,
  withdrawApproveStart,
  withdrawRejectStart,
} from "store/transactions/withdrawal/action";
import SearchBar from "components/Common/SearchBar";
// import Notification from "components/Common/Notification";
// import logo from "../../../assets/images/logo-sm.svg";
import { withTranslation } from "react-i18next";
import { checkAllBoxes } from "common/utils/checkAllBoxes";
import { Link } from "react-router-dom";
import { captilazeFirstLetter } from "common/utils/manipulateString";
import Badge from "components/Common/Badge";
import CustomDropDown from "components/Common/CustomDropDown";
import formatDate from "helpers/formatDate";
import FeatherIcon from "feather-icons-react";
import Filter from "./DepositFilter";
import ReceiptModal from "./ReceiptModal";
import DetailsModal from "pages/Transactions/Crypto/DetailsModal";
import TransactionModal from "components/Common/TransactionModal";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import classNames from "classnames";
import usePermissions from "routes/permissions";

function Withdrawal(props) {
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState("");
  const [sizePerPage, setSizePerPage] = useState(10);
  // const [showNotication, setShowNotifaction] = useState(false);
  const [detailsModal, setDetailsModal] = useState(false);
  const [selectedContent, setSelectedContent] = useState("");
  const [selected, setSelected] = useState("LIVE");
  const [btnprimary1, setBtnprimary1] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const [selectedWithdraw, setSelectedWithdraw] = useState({});
  const [rejectModal, setRejectModal] = useState(false);
  // const { userData: usrData } = useSelector((state) => ({
  //   userData: state.Profile,
  // }));
  const { myWalletsPermissions } = usePermissions();

  const loadTabs = () => [
    {
      tabId: "customers",
      navLinkName: "Deposits",
      component: <Withdrawal walletType="customers" />,
    },
    {
      tabId: "users",
      navLinkName: "Withdrawals",
      component: <Withdrawal walletType="users" />,
    },
  ];
  
  const tabs = loadTabs().filter((item) => !item.hidden);
  const [walletType, setalletType] = useState("users");

  const toggle = (tab) => {
    if (walletType !== tab) {
      setalletType(tab);
    }
  };

  const handleSearchInput = (e) => {
    setSearchInput(e.target.value);
  };
  const columns = [
    // {
    //   dataField: "checkbox",
    //   text: (
    //     <input
    //       type="checkbox"
    //       id="check-all-withdrawals"
    //       onChange={() =>
    //         checkAllBoxes("check-all-withdrawals", ".withdraw-checkbox")
    //       }
    //     />
    //   ),
    // },
    {
      dataField: "recordId",
      text: props.t("Transaction Id"),
    },
    {
      dataField: "createdAt",
      text: props.t("Date"),
      formatter: (val) => formatDate(val.createdAt),
    },
    {
      dataField: "customerId",
      text: props.t("Client"),
      formatter: (val) => {
        return (
          <div>
            <Link
              to={{
                pathname: val.customerId
                  ? `/clients/${val.customerId._id}/profile`
                  : "",
                state: { clientId: val.customerId ? val.customerId._id : "" },
              }}
            >
              <i className="no-italics">
                {" "}
                {val.customerId
                  ? `${captilazeFirstLetter(
                      val.customerId.firstName
                    )} ${captilazeFirstLetter(val.customerId.lastName)}`
                  : ""}
              </i>
            </Link>
          </div>
        );
      },
    },
    {
      dataField: "processedBy",
      text: props.t("Processed By"),
      formatter: (val) => {
        return (
          <div>
            <span className="no-italics" style={{ fontWeight: "bold" }}>
              {val.processedBy
                ? `${captilazeFirstLetter(
                    val.processedBy.firstName
                  )} ${captilazeFirstLetter(val.processedBy.lastName)}`
                : val.gateway === "ASIA_BANKS" ? "By System" : ""}
            </span>
          </div>
        );
      },
    },
    {
      dataField: "gateway",
      text: props.t("Gateway"),
      formatter: (val) =>
        val.gateway === "WIRE_TRANSFER"
          ? "Wire"
          : val.gateway?.split("_").join(" ")?.toLowerCase(),
    },
    walletType === "users" && 
    {
      dataField: "fromCurrency",
      text: props.t("From Currency"),
    },
    {
      dataField: "currency",
      text: props.t("Currency"),
    },
    {
      dataField: "reason",
      text: "Reason",
      formatter: (val) =>
        val.reason ? (
          <div data-title={val.reason}>
            {val.reason.length > 20
              ? `${val.reason.slice(0, 20)}...`
              : val.reason}
          </div>
        ) : (
        <>{val?.note ?? " - "}</>
        ),
    },
    walletType === "users" && 
    {
      dataField: "convertedFromAmount",
      text: props.t("From Amount"),
      formatter: (val) => val?.convertedFromAmount?.$numberDecimal || val?.convertedFromAmount || "",
    },
    {
      dataField: "amount",
      text: props.t("Amount"),
      formatter: (val) => val?.amount?.$numberDecimal || val?.amount || "",
    },
    {
      dataField: "status",
      text: props.t("Status"),
      formatter: (item) => <Badge status={item.status}></Badge>,
    },
    // {
    //   dataField: "dropdown",
    //   isDummyField: true,
    //   editable: false,
    //   text: props.t("Actions"),
    // },
    {
      dataField: "",
      isDummyField: true,
      editable: false,
      text: props.t("Details"),
      formatter: (val) => (
        <div className="text-center">
          <Link
            className={`${
              ["CRYPTO", "BLOCKCHAIN", "WIRE_TRANSFER"].includes(val.gateway)
                ? "text-success"
                : "text-muted"
            }`}
            to="#"
          >
            <i
              className="mdi mdi-eye font-size-20"
              id="edittooltip"
              onClick={() => {
                if (val?.walletId){
                  setDetailsModal(true);
                  setSelectedContent({
                    amount: val?.convertedFromAmount?.$numberDecimal,
                    currency: val?.currency,
                    freezeAmount: val?.wallet?.freezeAmount,
                    walletId: val?.walletId,
                    belongsTo: val?.wallet?.belongsTo,
                  });
                }
                if (val.gateway === "BLOCKCHAIN") {
                  setDetailsModal(true);
                  setSelectedContent({
                    network:
                      `${val?.rawData?.data?.item?.network} ${val?.rawData?.data?.item?.blockchain}` ||
                      "-",
                    referenceId: val?.rawData?.referenceId || "-",
                    amount: val?.amount?.$numberDecimal || val?.amount || "-",
                    address: val?.rawData?.data?.item?.address || "-",
                    confirmations:
                      val?.rawData?.data?.item?.currentConfirmations || "-",
                    requiredConfirmations:
                      val?.rawData?.data?.item?.targetConfirmations || "-",
                    transactionId:
                      val?.rawData?.data?.item?.transactionId || "-",
                  });
                } else if (val.gateway == "WIRE_TRANSFER") {
                  setDetailsModal(true);
                  setSelectedContent({
                    ...val?.content,
                  });
                } else if (val.gateway == "PERFECT_MONEY") {
                  setDetailsModal(true);
                  setSelectedContent({
                    ...val?.content,
                  });
                } else if (val.gateway == "CRYPTO") {
                  setDetailsModal(true);
                  setSelectedContent({
                    ...val?.content,
                  });
                }
              }}
            ></i>
          </Link>
        </div>
      ),
    },
  ];

  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const initFilteredValues = {
    customerId: "",
    filterDate: {
      fromDate: "",
      toDate: "",
    },
    status: query.get("status") || "",
    currency: "",
    amount: "",
    gateway: "",
    agent: "",
  };
  const [filteredValues, setFilteredValues] = useState(initFilteredValues);
  const filterChangeHandler = (filteredValuesData) => {
    setFilteredValues(filteredValuesData);
  };

  
  const loadWithdrawals = (page, limit) => {
    if (searchInput !== "" && searchInput.length >= 3) {
      dispatch(
        fetchWithdrawalsStart({
          page,
          limit,
          type: selected,
          searchText: searchInput,
          filteredValues,
          customerId: props.customerId,
          walletType: "users",
        })
      );
    } else if (searchInput === "") {
      dispatch(
        fetchWithdrawalsStart({
          page,
          limit,
          type: selected,
          filteredValues,
          customerId: props.customerId,
          walletType: "users",
        })
      );
    }
  };
  const withdrawApprove = (withdraw) => {
    dispatch(
      withdrawApproveStart({
        id: withdraw._id,
        customerId: withdraw.customerId._id,
        amount: withdraw.amount,
        note: withdraw.note,
        walletType,
      })
    );
    setApproveModal(false);
  };
  const withdrawReject = (withdraw) => {
    dispatch(
      withdrawRejectStart({
        id: withdraw._id,
        customerId: withdraw.customerId._id,
        note: withdraw.note,
        walletType,
      })
    );
    setRejectModal(false);
  };

  useEffect(() => {
    loadWithdrawals(1, sizePerPage);
  }, [
    sizePerPage,
    1,
    searchInput,
    selected,
    props.withdrawResponseMessage,
    props.withdrawChangeStatusSuccess,
    filteredValues,
    walletType,
  ]);
  
  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            
            {/* <Nav tabs>
              {tabs.map((tabItem) => (
                <>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classNames({
                        active: walletType === tabItem.tabId,
                      })}
                      onClick={() => {
                        toggle(tabItem.tabId);
                      }}
                    >
                      {tabItem.navLinkName}
                    </NavLink>
                  </NavItem>
                </>
              ))}
            </Nav> */}

            <CardHeader className="d-flex flex-column gap-3 ">
              <div className="d-flex justify-content-between align-items-center">
                <CardTitle className="color-primary">
                  {props.t(`Withdrawals(${props.totalDocs})`)}
                  <FeatherIcon
                    icon="refresh-cw"
                    className="icon-lg ms-2"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      loadWithdrawals(1, sizePerPage);
                    }}
                  />
                </CardTitle>
              </div>
              <div className="d-flex flex-row align-items-center justify-content-between gap-2">
                <div className="d-flex flex-row align-items-center">
                  <SearchBar
                    handleSearchInput={handleSearchInput}
                    placeholder={props.t("Enter Transaction Id")}
                  />
                  {/* <div >
                    <Dropdown
                      isOpen={btnprimary1}
                      toggle={() => setBtnprimary1(!btnprimary1)}
                      style={{
                        marginRight: 10,
                      }}
                    >
                      <DropdownToggle tag="button" className="btn btn-primary">
                        {selected} <i className="mdi mdi-chevron-down" />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem value="LIVE" onClick={(e)=>{setSelected(e.target.value)}}>LIVE</DropdownItem>
                        <DropdownItem value="DEMO" onClick={(e)=>{setSelected(e.target.value)}}>DEMO</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div> */}
                  {
                    !props?.customerId &&
                      <Filter
                        filterChangeHandler={filterChangeHandler}
                        filteredValues={filteredValues}
                      />
                  }
                </div>
                {/* {myWalletsPermissions.create && (
                  <div className="d-flex flex-row align-items-center justify-content-between">
                    <WithDrawForm />
                  </div>
                )} */}
              </div>
            </CardHeader>

            <CardBody>
              <div className="table-rep-plugin">
                <div
                  className="table-responsive mb-0"
                  data-pattern="priority-columns"
                >
                  <Table id="tech-companies-1" className="table  table-hover ">
                    <Thead className="text-center table-light">
                      <Tr>
                        {columns.map((column, index) =>
                          <Th data-priority={index} key={index}><span className="color-primary">{column.text}</span></Th>
                        )}
                      </Tr>
                    </Thead>
                    {props.loading && (
                      <Tr className="w-100 d-flex">
                        <TableLoader colSpan={4} />
                      </Tr>
                    )}
                    {props.totalDocs === 0 ? (
                      <Tbody>
                        {!props.loading && (
                          <>
                            <Tr>
                              <Td
                                colSpan={"100%"}
                                className="fw-bolder text-center"
                                st
                              >
                                <h3 className="fw-bolder text-center">
                                  No records
                                </h3>
                              </Td>
                            </Tr>
                          </>
                        )}
                      </Tbody>
                    ) : (
                      <Tbody
                        style={{
                          fontSize: "12px",
                          textAlign: "center",
                          textTransform: "capitalize",
                        }}
                      >
                        {!props.loading &&
                          props.withdrawals.map((row, rowIndex) => (
                            <Tr key={rowIndex}>
                              {columns.map((column, index) => (
                                <Td
                                  key={`${rowIndex}-${index}`}
                                  className="pt-4"
                                >
                                  {column.dataField === "checkbox" ? (
                                    <input
                                      className="withdraw-checkbox"
                                      type="checkbox"
                                    />
                                  ) : (
                                    ""
                                  )}
                                  {column.formatter
                                    ? column.formatter(row, rowIndex)
                                    : row[column.dataField]}
                                  {/* {column.dataField === "dropdown" && (
                                    <CustomDropDown
                                      permission={
                                        props.withdrawalsPermissions.actions
                                      }
                                      status={row.status}
                                      approve={() => {
                                        setApproveModal(true);
                                        setSelectedWithdraw(row);
                                      }}
                                      reject={() =>{
                                        setRejectModal(true);
                                        setSelectedWithdraw(row);
                                      }}
                                    />
                                  )} */}
                                </Td>
                              ))}
                            </Tr>
                          ))}
                      </Tbody>
                    )}
                  </Table>
                  <CustomPagination
                    {...props}
                    setSizePerPage={setSizePerPage}
                    sizePerPage={sizePerPage}
                    onChange={loadWithdrawals}
                    docs={props.withdrawals}
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <DetailsModal
        open={detailsModal}
        rawData={selectedContent}
        onClose={() => setDetailsModal(false)}
      />
      {approveModal && <TransactionModal
        show={approveModal}
        title="Approve Withdraw"
        submitButton="Approve"
        showAmount={true}
        toggle={()=>{
          setApproveModal(!approveModal);
        }}
        t={props.t}
        onSubmit={withdrawApprove}
        data={selectedWithdraw}
      >
        </TransactionModal>}
      {rejectModal && <TransactionModal
        show={rejectModal}
        title="Reject Withdraw"
        submitButton="Reject"
        showAmount={false}
        toggle={()=>{
        setRejectModal(!rejectModal);
      }}
        t={props.t}
        onSubmit={withdrawReject}
        data={selectedWithdraw}
    >
      </TransactionModal>}
    </React.Fragment>
  );
}
const mapStateToProps = (state) => ({
  loading: state.withdrawalReducer.loading || false,
  withdrawals: state.withdrawalReducer.withdrawals || [],
  page: state.withdrawalReducer.page || 1,
  totalDocs: state.withdrawalReducer.totalDocs || 0,
  totalPages: state.withdrawalReducer.totalPages || 0,
  hasNextPage: state.withdrawalReducer.hasNextPage,
  hasPrevPage: state.withdrawalReducer.hasPrevPage,
  limit: state.withdrawalReducer.limit,
  nextPage: state.withdrawalReducer.nextPage,
  pagingCounter: state.withdrawalReducer.pagingCounter,
  prevPage: state.withdrawalReducer.prevPage,
  withdrawalsPermissions: state.Profile.withdrawalsPermissions || {},
  withdrawResponseMessage: state.withdrawalReducer.withdrawResponseMessage,
  withdrawChangeStatusSuccess:
    state.withdrawalReducer.withdrawChangeStatusSuccess,
});
export default connect(mapStateToProps, null)(withTranslation()(Withdrawal));
