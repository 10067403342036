import React, { useEffect, useState } from "react";
import {
  connect,
  useDispatch
} from "react-redux";
import { CardBody } from "reactstrap";
import LinkClient from "../LinkClient";
import LinkIb from "../LinkIb";
import UnLinkIb from "../unLinkIb";
import UnLinkClients from "../unLinkClients";
import ConvertToIb from "../ConvertToIb";
import AddSharedAgreement from "../AddSharedAgreement";
import AddMasterAgreement from "../AddMasterAgreement";
import ChangeCommisionModal from "../ChangeCommisionModal";
import { fetchClientWallets } from "store/wallet/actions";
import TransferToIB from "../TransferToIB";

const IbQuickActions = (props) => {
  const {
    clientDetails,
    clientId,
    ibAgrementPermissions,
    clientPermissions,
  } = props;
  const dispatch = useDispatch();
  const [changeCommisionModal, setChangeCommisionModal] = useState(false);
  const loadClientWalletDetails = () => {
    dispatch(fetchClientWallets({
      belongsTo: clientId,
    }));
  };
  useEffect(() => {
    loadClientWalletDetails();
  }, []);
  return (
    <CardBody className="quick-actions-card">
      <p className="quick-actions-heading">IB</p>
      <div className="btn-container">
        {clientDetails.fx?.isIb && clientPermissions?.transferIbToAnotherLevel && <TransferToIB clientId={clientId} />}
        {clientDetails.fx?.isIb && ibAgrementPermissions.update && <LinkClient clientId={clientId} />}
        {ibAgrementPermissions.update &&
          <LinkIb
            clientId={clientId}
            isClient={clientDetails.fx?.isClient}
            isIb={clientDetails.fx?.isIb}
            isLead={clientDetails.isLead}
          />
        }
        {clientDetails.parentId?._id && ibAgrementPermissions.update && <UnLinkIb
          link={clientDetails.parentId}
          clientId={clientId}
          isLead={clientDetails.isLead}
        />}
        {clientDetails.fx?.isIb && <UnLinkClients clientId={clientId} />}
        {clientDetails.fx?.isIb && ibAgrementPermissions?.create &&
          <>
            <AddSharedAgreement clientId={clientId} />
            <AddMasterAgreement clientId={clientId} />
          </>}
        {!clientDetails.fx?.isIb && ibAgrementPermissions.update && (
          <ConvertToIb
            convertToIbDetails={props?.convertToIb}
            id={props?.clientDetails?._id}
            kyc={props?.clientDetails?.stages?.kycApproved}
            isLead={props.clientDetails.isLead}
            isIb={props.clientDetails.fx?.isIb}
          />
        )}
        {clientDetails.fx?.isIb && (
          <ChangeCommisionModal
            open={changeCommisionModal}
            wallets={props.wallets}
            setChangeCommisionModal={setChangeCommisionModal}
            onClose={() => {
              setChangeCommisionModal(false);
            }}
          />
        )}
      </div>
    </CardBody>
  );
};

const mapStateToProps = (state) => ({
  ibAgrementPermissions: state.Profile.ibAgrementPermissions || {},
  wallets: state.walletReducer.wallet.docs,
});

export default connect(mapStateToProps, null)(IbQuickActions);