import React, { useEffect, useState } from "react";
import { 
  useDispatch, 
  connect, 
  useSelector 
} from "react-redux";
import MetaTags from "react-meta-tags";
import { fetchDictionaryStart } from "store/dictionary/actions";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { useParams } from "react-router-dom";
import DepositTable from "../forex/DepositTable";
import WithdrawalFundingDepartmentInitial from "pages/Transactions/Crypto/walletFundingSteps/WithdrawalFundingDepartmentInitial";
import WithdrawalRiskDepartmentReview from "pages/Transactions/Crypto/walletFundingSteps/WithdrawalRiskDepartmentReview";
import WithdrawalFundingDepartmentDeduction from "pages/Transactions/Crypto/walletFundingSteps/WithdrawalFundingDepartmentDeduction";
import WithdrawalWithdrawalRemittance from "pages/Transactions/Crypto/walletFundingSteps/WithdrawalWithdrawalRemittance";

function ClientTransactions(props) {
  const { clientId } = useParams();
  const dispatch = useDispatch();
  const [btnprimary1, setBtnprimary1] = useState(false);
  const [selectedTransactionType, setSelectedTransactionType] = useState("Withdrawal");
  const transactionTypes = ["Withdrawal", "Deposit"];
  useState(() => {
    dispatch(fetchDictionaryStart());
  }, []);

  const { 
    fundingDepartmentInitialPermissions,
    riskDepartmentReviewPermissions,
    fundingDepartmentDeductionPermissions, 
    withdrawalRemittancePermissions, 
  } = useSelector((state) => (state.Profile || {}));

  const toggleFunding = (tab) => {
    if (activeFundingTab !== tab) {
      setactiveFundingTab(tab);
    }
  };
  const changeTransactionTypeHandler = (e) => {
    setSelectedTransactionType(e.target.innerText);
  };
  
  const [activeFundingTab, setactiveFundingTab] = useState("fundingDepartmentInitial");

  const loadWalletFundingStepsTabs = () => [
    {
      tabId: "fundingDepartmentInitial",
      navLinkName: "Funding Department Initial",
      component: activeFundingTab === "fundingDepartmentInitial" ? <WithdrawalFundingDepartmentInitial fundingStep={activeFundingTab} clientId={clientId} isClient={true} /> : <></>,
    },
    {
      tabId: "riskDepartmentReview",
      navLinkName: "Risk Department Review",
      component: activeFundingTab === "riskDepartmentReview" ? <WithdrawalRiskDepartmentReview fundingStep={activeFundingTab} clientId={clientId} isClient={true} /> : <></>,
    },
    {
      tabId: "fundingDepartmentDeduction",
      navLinkName: "Funding Department Deduction",
      component: activeFundingTab === "fundingDepartmentDeduction" ? <WithdrawalFundingDepartmentDeduction fundingStep={activeFundingTab} clientId={clientId} isClient={true} /> : <></>,
    },
    {
      tabId: "withdrawalRemittance",
      navLinkName: "Withdrawal Remittance",
      component: activeFundingTab === "withdrawalRemittance" ? <WithdrawalWithdrawalRemittance fundingStep={activeFundingTab} clientId={clientId} isClient={true} /> : <></>,
    },
  ];

  const walletundingTabs = loadWalletFundingStepsTabs();
  useEffect(()=> {
    if (fundingDepartmentInitialPermissions?.get) {
      setactiveFundingTab("fundingDepartmentInitial");
    } else if (riskDepartmentReviewPermissions?.get) {
      setactiveFundingTab("riskDepartmentReview");
    } else if (fundingDepartmentDeductionPermissions?.get) {
      setactiveFundingTab("fundingDepartmentDeduction");
    } else if (withdrawalRemittancePermissions?.get) {
      setactiveFundingTab("withdrawalRemittance");
    } else {
      setactiveFundingTab("");
    }
  }, [fundingDepartmentInitialPermissions, riskDepartmentReviewPermissions, fundingDepartmentDeductionPermissions, withdrawalRemittancePermissions]);
  

  return (
    <React.Fragment>
      <MetaTags>
        <title >Transactions</title>
      </MetaTags>
      <Row>
        <Col className="col-12">
          <Card>
            <CardHeader className="d-flex justify-content-between  align-items-center">
              <CardTitle className="color-primary">
                {selectedTransactionType}
              </CardTitle>
              <Dropdown
                isOpen={btnprimary1}
                toggle={() => {setBtnprimary1(!btnprimary1)}}
              >
                <DropdownToggle tag="button" className="btn btn-primary">
                  {selectedTransactionType} <i className="mdi mdi-chevron-down" />
                </DropdownToggle>
                <DropdownMenu>
                  {transactionTypes.map((transactionType) => (
                    <DropdownItem 
                      key={transactionTypes.indexOf(transactionType)}
                      onClick={(e) => {changeTransactionTypeHandler(e)}}
                    >
                      {transactionType}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </CardHeader>
            {
              selectedTransactionType === "Withdrawal" ?
                <CardBody>
                  <Nav tabs>
                    {walletundingTabs?.map((tabItem) => (
                      <>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeFundingTab === tabItem?.tabId,
                            })}
                            onClick={() => {
                              toggleFunding(tabItem?.tabId);
                            }}
                          >
                            {tabItem?.navLinkName}
                          </NavLink>
                        </NavItem>
                      </>
                    ))}
                  </Nav>
                  <TabContent activeTab={activeFundingTab} className="p-3 text-muted">
                    {walletundingTabs.map((tabItem) => (
                      <>
                        <TabPane tabId={tabItem.tabId}>
                          <Row>
                            <Col sm="12">{tabItem.component}</Col>
                          </Row>
                        </TabPane>
                      </>
                    ))}
                  </TabContent>
                </CardBody>
                : <DepositTable clientId={clientId} />
            }
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
const mapStateToProps = (state) => ({
  loading: state.dictionaryReducer.loading || false,
  dictionary: state.dictionaryReducer.dictionary || [],
  error: state.dictionaryReducer.error,
});
export default connect(mapStateToProps, null)(ClientTransactions);
