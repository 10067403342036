// import React, { useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   Modal, Button, ModalHeader, ModalBody, 
//   Label
// } from "reactstrap";
// import Select from "react-select";
// import { AvForm, AvField } from "availity-reactstrap-validation";
// import Loader from "components/Common/Loader";
// import AvFieldSelect from "components/Common/AvFieldSelect";
// import validatePositiveInputs from "helpers/validatePositiveInputs";

// import { fetchServerStart, updateAccountType } from "store/actions";

// const PLATFORMS = ["MT5", "MT4"];
// const TYPES = ["LIVE", "DEMO", "IB", "MAM", "INVESTOR"];
// const LEVERAGE = [
//   1, 25, 50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 2000,
// ];
// const CURRENCIES = ["USD", "EUR", "GBP", "EGP"];

// function EditAccountType({ show, toggle, selectedAccountType, t }) {
//   const dispatch = useDispatch();
//   const { updating, error } = useSelector(
//     (state) => state.tradingAccountReducer
//   );
//   const [leverageOptions, setLeverageOptions] = useState([]);
//   const [currencies, setCurrencies] = useState([]);
//   const [type, setType] = useState(selectedAccountType?.type);
//   const [platform, setPlatform] = useState(selectedAccountType?.platform);
//   const [servers, setServers] = useState([]);
//   const [filteredServers, setFilteredServers] = useState([]);
//   const [currentSelectedServer, setCurrentSelectedServer] = useState(
//     selectedAccountType?.serverId
//   );
//   const [serverError, setServerError] = useState(false);

//   const allServersFromStore = useSelector((state) => state?.MTServer?.servers?.docs);

//   useEffect(() => {
//     dispatch(fetchServerStart());
//   }, []);
  
//   useEffect(() => {
//     if (allServersFromStore && allServersFromStore.length > 0) {
//       setServers(allServersFromStore);
//     }
//   }, [allServersFromStore]);
  
//   console.log("ALL SERVERS EDIT: ", servers);

//   useEffect(() => {
//     if (selectedAccountType) {
//       setCurrencies(
//         selectedAccountType.currencies.map((currency) => currency.currency)
//       );
//     }
//   }, [selectedAccountType]);

//   useEffect(() => {
//     const newFilteredServers = servers
//       ?.filter(
//         (item) =>
//           item?.platform === platform &&
//          item?.type === type &&
//          item?._id &&
//          item?.name
//       )
//       .map((item) => ({
//         value: item._id,
//         label: item.name,
//       }));

//     setFilteredServers(() => [...newFilteredServers]);

//     // Reset selected server if it is no longer in filtered options
//     if (
//       !newFilteredServers.some(
//         (server) => server.value === currentSelectedServer
//       )
//     ) {
//       setCurrentSelectedServer(null);
//     }
//   }, [type, platform, servers]);

//   useEffect(() => {
//     !updating && !error && show && toggle();
//   }, [updating]);

//   // console.log("TYPE EDIT: ", selectedAccountType);
//   // console.log("FILTERED SERVERS EDIT: ", filteredServers);
//   const handleSubmit = (e, v) => {
//     v.serverId = currentSelectedServer;
//     v.currencies = v.currencies.reduce((acc, curr) => {
//       acc.push({
//         currency: curr,
//         groupPath: v[`${curr}GroupPath`],
//       });
//       delete v[`${curr}GroupPath`];
//       return acc;
//     }, []);
//     dispatch(updateAccountType(selectedAccountType._id, v));
//   };

//   const isMamPam = ["MAM", "INVESTOR"].includes(selectedAccountType?.type);

//   return (
//     <>
//       <Modal isOpen={show} toggle={toggle} centered={true}>
//         <ModalHeader toggle={toggle} tag="h4">
//           Edit Account Type
//         </ModalHeader>
//         <ModalBody>
//           <AvForm
//             className="px-4"
//             onValidSubmit={(e, v) => {
//               handleSubmit(e, v);
//             }}
//           >
//             <AvField
//               name="title"
//               label="Title"
//               placeholder="Select Title"
//               type="text"
//               value={selectedAccountType?.title}
//               validate={{
//                 required: {
//                   value: true,
//                   errorMessage: "Title is required",
//                 },
//                 pattern: {
//                   value: "^[A-Za-z0-9 ]+$",
//                   errorMessage: "Title must not be empty",
//                 },
//               }}
//             />
//             <AvFieldSelect
//               name="platform"
//               label="Platform"
//               placeholder="Select Platform"
//               value={selectedAccountType?.platform}
//               disabled={isMamPam}
//               options={PLATFORMS.map((type) => ({
//                 value: type,
//                 label: type,
//               }))}
//               onChange={(e) => {
//                 console.log("PLATFORM CHANGES: ", e);
//                 setPlatform(e);
//               }}
//               validate={{
//                 required: {
//                   value: true,
//                   errorMessage: "Platform is required",
//                 },
//               }}
//             />
//             <AvFieldSelect
//               name="type"
//               label="Type"
//               placeholder="Select Type"
//               options={TYPES.map((type) => ({
//                 value: type,
//                 label: type,
//               }))}
//               onChange={(e) => {
//                 setType(e);
//                 //console.log("TYPE CHANGES: ", e);
//                 console.log("TYPE CHANGES: ", type);
//               }}
//               value={selectedAccountType?.type}
//               validate={{
//                 required: {
//                   value: true,
//                   errorMessage: "Type is required",
//                 },
//               }}
//             />

//             <div className="mb-3">
//               <Label>{t("Server")}</Label>
//               <Select
//                 isClearable={true}
//                 options={filteredServers.map((server) => ({
//                   label: server.name,
//                   value: server._id,
//                 }))}
//                 value={filteredServers.find(
//                   (server) => server.value === currentSelectedServer
//                 )}
//                 onChange={(option) => {
//                   setCurrentSelectedServer(option ? option.value : null);
//                   setServerError(!option);
//                 }}
//                 placeholder={t("Choose a Server")}
//               />
//               {serverError && (
//                 <div className="text-danger mt-2">
//                   {t("Server selection is required")}
//                 </div>
//               )}
//             </div>

//             <AvFieldSelect
//               name="leverages"
//               label="Leverage Options"
//               placeholder="Select Leverage Options"
//               ismulti={true}
//               value={selectedAccountType?.leverages}
//               options={LEVERAGE.map((type) => ({
//                 value: type,
//                 label: type,
//               }))}
//               onChange={(e) => {
//                 setLeverageOptions(e);
//               }}
//               validate={{
//                 required: {
//                   value: true,
//                   errorMessage: "Leverage options is required",
//                 },
//               }}
//             />
//             <AvFieldSelect
//               name="defaultLeverage"
//               label="Default Leverage"
//               placeholder="Select Default Leverage"
//               options={leverageOptions?.map((type) => ({
//                 value: type,
//                 label: type,
//               }))}
//               value={
//                 leverageOptions?.includes(selectedAccountType?.defaultLeverage)
//                   ? selectedAccountType?.defaultLeverage
//                   : null
//               }
//               validate={{
//                 required: {
//                   value: true,
//                   errorMessage: "Default leverage is required",
//                 },
//               }}
//             />
//             <AvField
//               name="sequence"
//               label="Sequence"
//               placeholder="Select Sequence"
//               type="number"
//               min="0"
//               onKeyPress={(e) => {
//                 validatePositiveInputs(e);
//               }}
//               value={selectedAccountType?.sequence}
//               validate={{
//                 required: {
//                   value: true,
//                   errorMessage: "Sequence is required",
//                 },
//                 min: {
//                   value: 0,
//                   errorMessage: "Sequence must be a positive number",
//                 },
//               }}
//             />
//             <AvFieldSelect
//               name="currencies"
//               label="Currencies"
//               placeholder="Select Currencies"
//               ismulti={true}
//               options={CURRENCIES.map((type) => ({
//                 value: type,
//                 label: type,
//               }))}
//               onChange={(e) => {
//                 setCurrencies(e);
//               }}
//               value={currencies}
//               validate={{
//                 required: {
//                   value: true,
//                   errorMessage: "Currencies is required",
//                 },
//               }}
//             />
//             {currencies.length > 0 &&
//               currencies.map((currency) => (
//                 <AvField
//                   key={currency}
//                   name={`${currency}GroupPath`}
//                   label={`${currency} Group Path`}
//                   placeholder={`Select ${currency} group path`}
//                   type="text"
//                   value={
//                     selectedAccountType?.currencies.find(
//                       (curr) => curr.currency === currency
//                     )?.groupPath
//                   }
//                 />
//               ))}
//             <div className="text-center mt-3 p-2">
//               {updating ? (
//                 <Loader />
//               ) : (
//                 <Button disabled={updating} type="submit" color="primary">
//                   Edit
//                 </Button>
//               )}
//             </div>
//           </AvForm>
//         </ModalBody>
//       </Modal>
//     </>
//   );
// }

// export default EditAccountType;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal, Button, ModalHeader, ModalBody, Label 
} from "reactstrap";
import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Loader from "components/Common/Loader";
import AvFieldSelect from "components/Common/AvFieldSelect";
import validatePositiveInputs from "helpers/validatePositiveInputs";

import { fetchServerStart, updateAccountType } from "store/actions";

const PLATFORMS = ["MT5", "MT4"];
const TYPES = ["LIVE", "DEMO", "IB", "MAM", "INVESTOR"];
const LEVERAGE = [
  1, 25, 50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 2000,
];
const CURRENCIES = ["USD", "EUR", "GBP", "EGP"];

function EditAccountType({ isOpen, onClose, selectedAccountType, t }) {
  // Changed props names
  const dispatch = useDispatch();
  const { updating, error } = useSelector(
    (state) => state.tradingAccountReducer
  );

  // State management
  const [leverageOptions, setLeverageOptions] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [type, setType] = useState("");
  const [platform, setPlatform] = useState("");
  const [servers, setServers] = useState([]);
  const [filteredServers, setFilteredServers] = useState([]);
  const [currentSelectedServer, setCurrentSelectedServer] = useState(null);
  const [serverError, setServerError] = useState(false);

  const allServersFromStore = useSelector(
    (state) => state?.MTServer?.servers?.docs
  );

  // Reset states when modal opens
  useEffect(() => {
    if (isOpen && selectedAccountType) {
      setType(selectedAccountType.type || "");
      setPlatform(selectedAccountType.platform || "");
      setCurrentSelectedServer(selectedAccountType.serverId || null);
      setCurrencies(
        selectedAccountType.currencies?.map((currency) => currency.currency)
      );
      setLeverageOptions(selectedAccountType.leverages || []);
    }
  }, [isOpen, selectedAccountType]);

  // Initial server fetch
  useEffect(() => {
    if (isOpen) {
      dispatch(fetchServerStart());
    }
  }, [dispatch, isOpen]);

  // Update servers when store changes
  useEffect(() => {
    if (allServersFromStore?.length > 0) {
      setServers(allServersFromStore);
    }
  }, [allServersFromStore]);

  // Filter servers based on type and platform
  useEffect(() => {
    if (type && platform && servers.length > 0) {
      const newFilteredServers = servers
        .filter(
          (item) =>
            item?.platform === platform &&
            item?.type === type &&
            item?._id &&
            item?.name
        )
        .map((item) => ({
          value: item._id,
          label: item.name,
        }));

      setFilteredServers(newFilteredServers);

      // Reset selected server if it's no longer in filtered options
      if (
        !newFilteredServers.some(
          (server) => server.value === currentSelectedServer
        )
      ) {
        setCurrentSelectedServer(null);
        setServerError(true);
      }
    } else {
      setFilteredServers([]);
    }
  }, [type, platform, servers, currentSelectedServer]);

  // Handle successful update
  useEffect(() => {
    if (!updating && !error && isOpen) {
      onClose();
    }
  }, [updating]);

  const handleSubmit = (e, v) => {
    if (!currentSelectedServer) {
      setServerError(true);
      return;
    }

    const formData = {
      ...v,
      serverId: currentSelectedServer,
      currencies: v.currencies.map((curr) => ({
        currency: curr,
        groupPath: v[`${curr}GroupPath`],
      })),
    };

    // Remove groupPath fields
    currencies.forEach((curr) => {
      delete formData[`${curr}GroupPath`];
    });

    dispatch(updateAccountType(selectedAccountType._id, formData));
  };

  const isMamPam = ["MAM", "INVESTOR"].includes(type);

  return (
    <Modal isOpen={isOpen} toggle={onClose} centered={true}>
      <ModalHeader toggle={onClose}>Edit Account Type</ModalHeader>
      <ModalBody>
        <AvForm className="px-4" onValidSubmit={handleSubmit}>
          <AvField
            name="title"
            label="Title"
            placeholder="Enter Title"
            type="text"
            value={selectedAccountType?.title}
            validate={{
              required: {
                value: true,
                errorMessage: "Title is required" 
              },
              pattern: {
                value: "^[A-Za-z0-9 ]+$",
                errorMessage:
                  "Title must contain only letters, numbers and spaces",
              },
            }}
          />

          <AvFieldSelect
            name="platform"
            label="Platform"
            placeholder="Select Platform"
            value={platform}
            disabled={isMamPam}
            options={PLATFORMS.map((p) => ({
              value: p,
              label: p 
            }))}
            onChange={(value) => {
              setPlatform(value);
              setCurrentSelectedServer(null);
            }}
            validate={{
              required: {
                value: true,
                errorMessage: "Platform is required" 
              },
            }}
          />

          <AvFieldSelect
            name="type"
            label="Type"
            placeholder="Select Type"
            value={type}
            options={TYPES.map((t) => ({
              value: t,
              label: t 
            }))}
            onChange={(value) => {
              setType(value);
              setCurrentSelectedServer(null);
            }}
            validate={{
              required: {
                value: true,
                errorMessage: "Type is required" 
              },
            }}
          />

          <div className="mb-3">
            <Label>{t("Server")}</Label>
            <Select
              isClearable={true}
              options={filteredServers}
              value={filteredServers.find(
                (server) => server.value === currentSelectedServer
              )}
              onChange={(option) => {
                setCurrentSelectedServer(option ? option.value : null);
                setServerError(!option);
              }}
              placeholder={t("Choose a Server")}
              isDisabled={!type || !platform}
            />
            {serverError && (
              <div className="text-danger mt-2">
                {t("Server selection is required")}
              </div>
            )}
          </div>

          <AvFieldSelect
            name="leverages"
            label="Leverage Options"
            placeholder="Select Leverage Options"
            ismulti={true}
            value={leverageOptions}
            options={LEVERAGE.map((l) => ({
              value: l,
              label: l 
            }))}
            onChange={(values) => setLeverageOptions(values)}
            validate={{
              required: {
                value: true,
                errorMessage: "Leverage options are required",
              },
            }}
          />

          <AvFieldSelect
            name="defaultLeverage"
            label="Default Leverage"
            placeholder="Select Default Leverage"
            options={leverageOptions.map((l) => ({
              value: l,
              label: l 
            }))}
            value={selectedAccountType?.defaultLeverage}
            validate={{
              required: {
                value: true,
                errorMessage: "Default leverage is required",
              },
            }}
          />

          <AvField
            name="sequence"
            label="Sequence"
            placeholder="Enter Sequence"
            type="number"
            min="0"
            onKeyPress={validatePositiveInputs}
            value={selectedAccountType?.sequence}
            validate={{
              required: {
                value: true,
                errorMessage: "Sequence is required" 
              },
              min: {
                value: 0,
                errorMessage: "Sequence must be a positive number",
              },
            }}
          />

          <AvFieldSelect
            name="currencies"
            label="Currencies"
            placeholder="Select Currencies"
            ismulti={true}
            options={CURRENCIES.map((c) => ({
              value: c,
              label: c 
            }))}
            onChange={(values) => setCurrencies(values)}
            value={currencies}
            validate={{
              required: {
                value: true,
                errorMessage: "Currencies are required",
              },
            }}
          />

          {currencies.length > 0 &&
            currencies.map((currency) => (
              <AvField
                key={currency}
                name={`${currency}GroupPath`}
                label={`${currency} Group Path`}
                placeholder={`Enter ${currency} group path`}
                type="text"
                value={
                  selectedAccountType?.currencies.find(
                    (curr) => curr.currency === currency
                  )?.groupPath || ""
                }
              />
            ))}

          <div className="text-center mt-4">
            {updating ? (
              <Loader />
            ) : (
              <Button
                type="submit"
                color="primary"
                disabled={updating || serverError}
              >
                Save Changes
              </Button>
            )}
          </div>
        </AvForm>
      </ModalBody>
    </Modal>
  );
}

export default EditAccountType;