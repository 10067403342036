import React, { useState, useEffect } from "react";
import {
  useDispatch, useSelector,
} from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Container
} from "reactstrap";
import { convertToIB } from "store/client/actions";
import { withTranslation } from "react-i18next";
import Loader from "components/Common/TableLoader";
import {
  createSharedIbAgreement, fetchAgreements, fetchPredefinedAgreements 
} from "store/actions";
import Select from "react-select";
import AgreementDetails from "pages/ClientDetail/Partnership/AgreementDetails";
import PromoLinkDropDown from "components/Common/PromoLinkDropDown";

function SelectAgreement(props) {
  const [filteredAgreements, setFilteredAgreements] = useState([]);
  const [selectedAgreement, setSelectedAgreement] = useState(null);
  const dispatch = useDispatch();
  const { layoutMode } = useSelector(state => state.Layout);
  const { accountTypes = [] } = useSelector((state) => state?.tradingAccountReducer);
  const clientDetails = useSelector((state) => state.clientReducer?.clientDetails);
  const { agreements } = useSelector(state => state.predefinedIbAgrements);
  const { agreements: originalAgreements, submitting, clearingCounter } = useSelector((state) => state.ibAgreements);
  const customStyles = {
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#495057",
      padding: 0,
      paddingRight: "5px",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none"
    }),
    control: (provided) => {
      if (layoutMode === "dark") {
        return {
          ...provided,
          backgroundColor: "#19283B",
          border: 0,
          boxShadow: "0 0.125rem 0.25rem #0B182F",
          color: "#adb5bd",
          height: "100%",
        };
      }
      return {
        ...provided,
        height: "100%",
      };
    },
    menu: (provided) => ({
      ...provided,
      backgroundColor: layoutMode === "dark" ? "#242632" : "white",
      color: layoutMode === "dark" ? "#adb5bd" : "#495057",
      zIndex: 3,
    }),
    option: (provided, state) => ({
      ...provided,
      display: state.isDisabled ? "none" : "flex",
      flexDirection: "row",
      alignItems: "center",
      color: layoutMode === "dark" ? "#adb5bd" : "#495057",
    }),
    singleValue: (provided) => {
      return {
        ...provided,
        flexDirection: "row",
        alignItems: "center",
        color: layoutMode === "dark" ? "#adb5bd" : "#495057",
      };
    },
  };
  const loadPredefinedAgrements = (page, limit) => {
    dispatch(fetchPredefinedAgreements({
      page,
      limit
    }));
  };
  useEffect(()=>{
    loadPredefinedAgrements(1, 100);
    dispatch(fetchAgreements({ customerId: clientDetails.parentId._id }));
  }, []);

  const [open, setOpen] = useState(0);
  const toggleTabs = (index) => {
    if (open === index) {
      setOpen();
    } else {
      setOpen(index);
    }
  };

  useEffect(() => {
    if (clientDetails)
      if (clientDetails.parentId && clientDetails.parentId.level) {
        const agrement = agreements.docs.filter(ag => ag.levels === clientDetails.parentId.level);
        setFilteredAgreements(agrement);
        if (selectedAgreement && originalAgreements.length > 0)
          adjustAgrementCustomerIds(selectedAgreement, originalAgreements[0]);
      } else {
        const agrement = agreements.docs.filter(ag => ag.levels === 1);
        setFilteredAgreements(agrement);
        if (selectedAgreement)
          adjustAgrementCustomerIds(selectedAgreement, { members:[] });
      }
  }, [clientDetails, agreements, selectedAgreement, originalAgreements]);

  function adjustAgrementCustomerIds(predefined, original) {
    predefined?.members?.sort((a, b) => a.level - b.level);
    original?.members?.sort((a, b) => a.level - b.level);
    const predefinedMembers = predefined?.members;
    const originalMembers = original?.members;
    if (original && original.length === 0){
      predefinedMembers[0].customerId = {
        "fx": clientDetails.fx,
        "_id": clientDetails._id,
        "firstName": clientDetails.firstName,
        "lastName": clientDetails.lastName
      };
    }
    for (let i = 0; i < predefinedMembers.length; i++) {
      if (i === predefinedMembers.length - 1){
        predefinedMembers[i].customerId = {
          "fx": clientDetails.fx,
          "_id": clientDetails._id,
          "firstName": clientDetails.firstName,
          "lastName": clientDetails.lastName
        };
      } else {
        if (originalMembers && originalMembers.length > 0)
          predefinedMembers[i].customerId = originalMembers[i].customerId;
      }
    }
  }

  const addAgrement = () => {
    const members = [];
    for (let i = 0; i < selectedAgreement.members.length; i++) {
      const member = selectedAgreement.members[i];
      members.push({
        customerId: member.customerId._id,
        level: member.level,
        values: member.values
      });
    }
    const agrement = {
      title: selectedAgreement.title,
      totals: selectedAgreement.totals,
      members
    };    
    dispatch(
      createSharedIbAgreement(agrement)
    );
  };

  // useEffect(()=>{
  //   if (clearingCounter > 0){
  //     toggle();
  //   }
  // }, [clearingCounter]);

  return <> 
    <Select
      styles={customStyles}
      name="predefinedAgreements"
      options={filteredAgreements.map(agreement => {
        return {
          label: agreement.title,
          value: agreement,
        };
      }
      )}
      onChange={(e) => {
        setSelectedAgreement(e.value);
        props.setSelectedAgreement(e.value);
      }}
    />
    <div className="mt-3">
      {selectedAgreement && <AgreementDetails agreement={selectedAgreement} accountTypes={accountTypes} />}
    </div>
  </>;
}
function ConvertToIB({ isIb, isLead, t, kyc, id, convertToIbDetails }) {
  const {
    loading,
  } = convertToIbDetails;
  const dispatch = useDispatch();

  const [linkClientModal, setLinkClientModal] = useState(false);
  const [selectedAgreement, setSelectedAgreement] = useState(null);
  const [promoLinkData, setPromoLinkData] = useState({});

  const toggleModal = () => {
    setLinkClientModal(!linkClientModal);
  };

  useEffect(() => {
    if (convertToIbDetails.clear && linkClientModal){
      toggleModal();
    }
  }, [convertToIbDetails.clear]);

  const handleConfirm = () => {
    // const members = [];
    // for (let i = 0; i < selectedAgreement.members.length; i++) {
    //   const member = selectedAgreement.members[i];
    //   members.push({
    //     customerId: member.customerId._id,
    //     level: member.level,
    //     values: member.values
    //   });
    // }
    // const agrement = {
    //   title: selectedAgreement.title,
    //   totals: selectedAgreement.totals,
    //   members
    // };    
    dispatch(convertToIB({
      id,
      promotionLink: promoLinkData.promotionLink,
      rebateRef: promoLinkData.rebateRef,
    }));
  };

  return (
    <React.Fragment >
      <button
        type="button"
        disabled={isLead}
        className="btn btn-primary waves-effect waves-light w-100 me-1"
        onClick={toggleModal}
      >
        {t("Convert To IB")}
      </button>
      <Modal isOpen={linkClientModal} toggle={toggleModal} centered={true}>
        <ModalHeader toggle={toggleModal}>
          {t("Convert Client To IB")}
        </ModalHeader>
        <ModalBody >
          <Container>
            <Row className="mb-3">
              {
                isIb ? (
                  <p>{t("This client is already an ib!")}</p>
                ) : (
                  kyc 
                    ? <Col md="12">
                      <div className="mb-3">
                        <PromoLinkDropDown
                          setPromoLinkData={setPromoLinkData}
                        />
                      </div>
                    </Col> : <Col md="12">
                      <p>{t("Please approve the kyc before converting the client to IB!")}</p>
                    </Col>
                )
              }
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggleModal} color="secondary">
            {t("No")}
          </Button>
          {loading ? <Loader/> : (
            <Button onClick={handleConfirm} color="primary" disabled={isIb || !kyc || !promoLinkData}>
              {t("Yes")}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </React.Fragment >
  );
}

export default withTranslation()(ConvertToIB);