import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Alert,
} from "reactstrap";
import { fetchConversionRates } from "store/actions";
import AvFieldSelect from "./AvFieldSelect";
import { CONFIRMATION_EMAILS } from "common/data/dropdowns";

function TransactionForexModal({ show, toggle, onSubmit, showAmount, title, data, isAmountDisabled, submitButton, step, ...props }) {
  const dispatch = useDispatch();
  const [amount, setAmount] = useState(0);
  const [convertedAmount, setConvertedAmount] = useState(0);
  const [fees, setFees] = useState(0);
  const [toCurrency, setToCurrency] = useState(0);
  const [confirmationEmail, setConfirmationEmail] = useState("");
  const { 
    loading,
    conversionRates,
    pagination,
    clearingCounter,
  } = useSelector((state) => state.conversionRatesReducer);
  
  useEffect(()=>{
    loadConversionRates(1, 100000);
  }, [clearingCounter]);

  const loadConversionRates = (page, limit) => {
    dispatch(fetchConversionRates({
      page,
      limit
    }));
  };
  useEffect(()=>{
    if (data.amount) {
      setAmount(data?.amount?.$numberDecimal ?? data?.amount);
      setConvertedAmount(data?.amount?.$numberDecimal ?? data?.amount);
    }
  }, [data]);
  useEffect(()=>{
    const finalAmount = toCurrency ? ((amount - (fees || 0)) * parseFloat(conversionRates?.find(c => c.baseCurrency === "USD" && c.targetCurrency === toCurrency)?.value))?.toFixed(2)
      : amount - fees;
    setConvertedAmount(isNaN(finalAmount) ? amount : finalAmount );
  }, [fees, amount, toCurrency]);
  return (<>
    <Modal isOpen={show} toggle={toggle} centered={true}>
      <ModalHeader toggle={toggle} tag="h4">
        {props.t(title)}
      </ModalHeader>
      <ModalBody>
        <AvForm
          className='p-4'
          onValidSubmit={(e, v) => {
            data.amount = v.amount ? parseFloat(v.amount) : data.amount;
            data.fee = fees || 0;
            data.convertedCurrency = toCurrency || "";
            data.convertedAmount = convertedAmount || "";
            data.note = v.note;
            if (step === "fundingDepartmentInitial") {
              data.confirmationEmail = confirmationEmail;
            }
            onSubmit(data);
          }}
        >
          {showAmount && 
          <>
            <AvField
              name="amount"
              label={props.t("Initial Amount")}
              type="number"
              disabled
              defaultValue={data.amount.$numberDecimal ?? data.amount}
            />
            <AvField
              name="amount"
              label={props.t("Amount")}
              placeholder={props.t("Enter Amount")}
              type="number"
              errorMessage={props.t("Enter Valid Amount")}
              onChange={(e) => setAmount(e.target.value)}
              disabled={isAmountDisabled}
              validate = {{
                required: { value: true }
              }}
              defaultValue={data.amount.$numberDecimal ?? data.amount}
            />
            <AvField
              name="fees"
              label={props.t("Handing Fees")}
              placeholder={props.t("Enter Fees")}
              type="number"
              errorMessage={props.t("Enter Valid Fees")}
              onChange={(e) => setFees(e.target.value)}
              validate = {{
                required: { value: true }
              }}
              value={fees}
              defaultValue={0}
            /> 
            <AvFieldSelect
              name="toCurrency"
              label="Exchange Rate"
              placeholder="Select To Exchange Rate"
              options={conversionRates?.filter(rateItem => rateItem?.baseCurrency === (data?.content?.coin || "USD"))?.map((rate) => ({
                value: rate?.targetCurrency,
                label: rate?.targetCurrency
              }))}
              value={toCurrency}
              onChange={(e) => setToCurrency(e)}
              // validate={{
              //   required: {
              //     value: true,
              //     errorMessage: "Exchange Rate is required"
              //   }
              // }}
            />
            {
              <>
                <Alert
                  color="warning"
                >The Remittance Amount Is : {" "}
                  {convertedAmount}
                  {" "}{toCurrency ? toCurrency : ""}</Alert>
              </>
            }
          </>
          
          }
          <AvField
            name="note"
            label={props.t("Note")}
            placeholder={props.t("Enter Note")}
            type="text"
            errorMessage={props.t("Enter Valid Note")}
            validate = {{
              required: { value: true }
            }}
            defaultValue={data.note}
          />
          {step === "fundingDepartmentInitial" && (
            <AvFieldSelect
              name="confirmationEmail"
              label="Confirmation Email"
              placeholder="Select Confirmation Email"
              options={CONFIRMATION_EMAILS.map((email) => ({
                value: email.value,
                label: email.name
              }))}
              value={confirmationEmail}
              onChange={(e) => setConfirmationEmail(e)}
            />
          )}
          <div className='text-center'>
            <Button type="submit" color="primary" className="mx-2">
              {submitButton}
            </Button>
            <Button color="primary" className="mx-2" onClick={toggle}>
                Cancel
            </Button> 
          </div>
        </AvForm>
      </ModalBody>
    </Modal>
  </>);
}

export default TransactionForexModal;