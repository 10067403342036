import { useDispatch, connect } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledAlert,
  Label,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { addNewItem } from "store/dictionary/actions";
import CustomCreatableSelect from "components/Common/CustomCreatableSelect";
import SearchableSymbolGroupsDropdown from "./SearchableComponents/SearchableSymbolGroupsDropdown";
import { fetchSymbols } from "store/rebateSettings/symbols/actions";
import { editGroups, fetchGroups } from "store/rebateSettings/groups/actions";

function SymbolsEdit(props) {
  const [addModal, setAddModal] = useState(true);
  const [symboloptions, setSymboloptions] = useState([]);
  const [defaultSymboloptions, setDefaultSymboloptions] = useState([]);
  const [emptySymbolOptionsError, setEmptySymbolOptionsError] = useState(false);
  const [selectedSymbols, setSelectedSymbols] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const emptySymbolOptionsMessage = "Please select at least one symbol";
  const dispatch = useDispatch();
  const toggleAddModal = () => {
    props?.setEditModal((preValue) => !preValue);
    setAddModal((preValue) => !preValue);
  };
  const { create } = props.dictionariesPermissions;
  useEffect(() => {
    if (!props.showEditSuccessMessage && addModal) {
      // setAddModal(false);
      // toggleAddModal();
    }
  }, [props.showEditSuccessMessage]);

  useEffect(() => {
    dispatch(
      fetchSymbols({
        limit: 100000,
        page: 1,
        platform: props?.type,
      })
    );
  }, [props.type]);

  useEffect(() => {
    if (props?.symbols) {
      const defaultOptions = [];
      const symbolOptions = props?.symbols?.filter((symbol) => !symbol.groupId)?.map((symbol) => {
        const item = props?.editedItem?.symbols?.find(
          (editedSymbol) => editedSymbol?._id === symbol?._id
        );
        if (item) {
          defaultOptions?.push({
            value: item?._id,
            label: item?.symbolName,
          });
        }
        return {
          value: symbol?._id,
          label: symbol?.symbolName,
        };
      });
      setDefaultSymboloptions(defaultOptions);
      setSymboloptions(symbolOptions);
    }
  }, [props.symbols, props.editedItem]);

  useEffect(() => {
    if (props.showEditSuccessMessage && addModal) {
      toggleAddModal();
      dispatch(
        fetchGroups({
          limit: 10,
          page: 1,
          type: props.type,
        })
      );
    }
  }, [props.showEditSuccessMessage]);

  useEffect(() => {
    setSelectedSymbols(props?.editedItem?.symbols.map((symbol) => ({
      label: symbol.symbolName,
      value: symbol._id
    })));
    
  }, [props?.editedItem]);
  return (
    <React.Fragment>
      {/* <Link to="#"  className={`btn btn-primary ${!create ? "d-none" : ""}`} onClick={toggleAddModal}><i className="bx bx-plus me-1"></i>{props.t("Edit Group")} {props?.show ? 1 : 2}</Link> */}
      <Modal isOpen={props?.show} toggle={toggleAddModal} centered={true}>
        <ModalHeader toggle={toggleAddModal} tag="h4">
          {props.t("Update Group")}
        </ModalHeader>
        <ModalBody>
          <AvForm
            className="p-4"
            onValidSubmit={(e, v) => {
              setIsLoading(true);
              e.preventDefault();
              if (v?.symbols?.length === 0) {
                setEmptySymbolOptionsError(true);
                setIsLoading(false);
                setTimeout(() => {
                  setEmptySymbolOptionsError(false);
                }, 2000);
                return;
              }
              v.symbols = [
                ...selectedSymbols.map((item) => item.value),
              ];
              console.log("Edit Values: ", v);
              dispatch(
                editGroups({
                  ...v,
                  id: props?.editedItem?._id,
                  type: props.type,
                })
              );
              setIsLoading(false);
            }}
          >
            <div className="mb-3">
              <AvField
                name="groupName"
                label={props.t("Group Name")}
                placeholder={props.t("Enter Group Name")}
                type="text"
                value={props?.editedItem?.groupName}
                errorMessage={props.t("Enter valid Group Name")}
                validate={{ required: { value: true } }}
              />
            </div>

            <div className="my-3">
              <Label>Symbol Groups</Label>
              <SearchableSymbolGroupsDropdown
                isRequired={false}
                symboloptions={symboloptions}
                isMulti={true}
                value={selectedSymbols}
                defaultValue={props?.editedItem?.symbols.map((symbol) => ({
                  label: symbol.symbolName,
                  value: symbol._id
                }))}
                platform={props?.type}
                onChange={(e) => {
                  setSelectedSymbols(e);
                }}
                // validate={{
                //   required: {
                //     value: true,
                //     errorMessage: "Please select at least one symbol group",
                //   },
                // }}
                // onChange={(e) => {
                //   setValues({
                //     ...values,
                //     agent: e.map((item) => item.value).join(","),
                //   });
                // }}
              />
            </div>
            {emptySymbolOptionsError && (
              <div className="text-danger mb-2">
                {emptySymbolOptionsMessage}
              </div>
            )}
            <div className="text-center pt-3 p-2">
              <Button
                disabled={isLoading}
                type="submit"
                color="primary"
                className=""
              >
                {props.disableAddButton ? "Loading..." : props.t("Edit")}
              </Button>
            </div>
          </AvForm>
          {props.error && (
            <UncontrolledAlert color="danger">
              <i className="mdi mdi-block-helper me-2"></i>
              {props.editError}
            </UncontrolledAlert>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}
const mapStateToProps = (state) => ({
  id: state.groupReducer.id,
  error: state.groupReducer.error,
  showEditSuccessMessage: state.groupReducer.showEditSuccessMessage,
  dictionariesPermissions: state.Profile.dictionariesPermissions || {},
  disableAddButton: state.groupReducer.disableAddButton,
  editLoading: state.groupReducer.editLoading,
  addSuccess: state.groupReducer.addSuccess,
  symbols: state.symbolReducer.symbols || [],
});
export default connect(mapStateToProps, null)(withTranslation()(SymbolsEdit));
