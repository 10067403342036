import { changeCommisionAddingField } from "apis/wallet";
import { 
  AvForm, 
  AvRadio, 
  AvRadioGroup 
} from "availity-reactstrap-validation";
import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { 
  Button, 
  Label, 
  Modal, 
  ModalBody, 
  ModalHeader 
} from "reactstrap";
import { showErrorNotification, showSuccessNotification } from "store/actions";

function ChangeCommisionModal({ open, wallets, onClose, setChangeCommisionModal }) {
  const [loading, setLoading] = useState(false);
  const [commisionAddingField, setCommisionAddingField] = useState("pending");
  const wallet = wallets?.find((wallet) => wallet.isIb);

  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await changeCommisionAddingField({
        walletId: wallet._id,
        commisionAddingField,
      });
      dispatch(showSuccessNotification("Commission Status Changed Successfully!!"));
      onClose();
    } catch (error) {
      dispatch(showErrorNotification(error.message));
      console.error("Error converting wallet:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    setCommisionAddingField(wallet?.commisionAddingField || "amount");
  }, [wallet]);

  return (
    <React.Fragment>
      <button
        type="button"
        className="btn btn-primary waves-effect waves-light w-100 me-1"
        onClick={setChangeCommisionModal}
      >
        {"Change Commision Status"}
      </button>
      <Modal isOpen={open} toggle={onClose}>
        <ModalHeader toggle={onClose}>Change Commision Status</ModalHeader>
        <ModalBody>
          <AvForm onValidSubmit={handleSubmit}>
            
            <div className="d-flex gap-3">
              <Label>Commision Status</Label>
              <AvRadioGroup
                name="commisionAddingField"
                label=""
                value={commisionAddingField}
                onChange={(e) => setCommisionAddingField(e.target.value)}
                required
              >
                <AvRadio label="Amount" value="amount" />
                <AvRadio label="Pending Amount" value="pendingAmount" />
              </AvRadioGroup>
            </div>
            <Button color="primary" type="submit" disabled={loading}>
              {loading ? "Changeing..." : "Change"}
            </Button>
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}
export default withTranslation()(ChangeCommisionModal);