import {
  useDispatch,
  connect,
  useSelector
} from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledAlert,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { AvForm } from "availity-reactstrap-validation";
import { fetchSymbols, syncSymbolRequest } from "store/rebateSettings/symbols/actions";
import { fetchServerStart } from "store/actions";
import AvFieldSelect from "components/Common/AvFieldSelect";
function Sync(props){
  const [addModal, setAddModal] = useState(false);
  const [chosenServers, setChosenServersServers] = useState([]);
  const [selectedServer, setSelectedServer] = useState(null);

  const servers = useSelector((state) => state?.MTServer?.servers);
  const serverss = useSelector((state) => state?.MTServer);
  const selectedServers = (platform, type) => {
    if (servers?.docs) {
      return servers?.docs.filter((server) => server.platform === platform && server.type === type).map((server) => {
        return {
          label: server.name,
          value: server._id
        };
      });
    }
    return [];
  };
  useEffect(() => {
    const res = selectedServers(props.type, "LIVE");
    setChosenServersServers(() => res);
  }, [servers]);

  const dispatch = useDispatch();
  const toggleAddModal = ()=>{
    setAddModal(preValue => !preValue);
  };
  const { create } = props.dictionariesPermissions;
  useEffect(()=>{
    if (props.addSymbolSuccess && addModal){
      setAddModal(false);
      // dispatch(
      //   fetchSymbols({
      //     limit: 10,
      //     page: 1,
      //     type: props.type
      //   })
      // );
    }
  }, [props.addSymbolSuccess]);

  useEffect(() => {
    if (addModal === true) {
      dispatch(fetchServerStart());
    }
  }, [addModal]);

  return (
    <React.Fragment >
      <Link to="#"  className={`btn btn-primary ${!create ? "d-none" : ""}`} onClick={toggleAddModal}>{props.t("Sync")}</Link>
      <Modal isOpen={addModal} toggle={toggleAddModal} centered={true}>
        <ModalHeader toggle={toggleAddModal} tag="h4">
          {props.t("Sync")}
        </ModalHeader>
        <ModalBody >
          <AvForm
            className='p-4'
            onValidSubmit={(e, v) => {
              e.preventDefault();
              dispatch(syncSymbolRequest(v));
            }}
          > 
              
              
            <AvFieldSelect
              name="serverId"
              label="Server"
              placeholder="Select Server"
              options={chosenServers.map((type) => ({
                value: type?.value,
                label: type?.label,
              }))}
              value={selectedServer || ""}
              onChange={(e) => setSelectedServer(e)}
              validate={{
                required: {
                  value: true,
                  errorMessage: "Server is required",
                },
              }}
            />
            <div className='text-center pt-3 p-2'>
              <Button disabled = {props.disableAddButton} type="submit" color="primary" className="">
                {props.disableAddButton ? "Loading..." : props.t("Sync")}
              </Button>
            </div>
          </AvForm>
          {props.error && <UncontrolledAlert color="danger">
            <i className="mdi mdi-block-helper me-2"></i>
            {props.editError}
          </UncontrolledAlert>}
          
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}
const mapStateToProps = (state)=>({
  id :state.symbolReducer.id,
  error:state.symbolReducer.error,
  addSymbolSuccess: state.symbolReducer.addSymbolSuccess,
  showAddSuccessMessage: state.symbolReducer.showAddSuccessMessage,
  dictionariesPermissions : state.Profile.dictionariesPermissions || {},
  disableAddButton : state.symbolReducer.disableAddButton
});
export default connect(mapStateToProps, null)(withTranslation()(Sync));