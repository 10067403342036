import {
  call,
  put,
  takeEvery,
  delay
} from "redux-saga/effects";
import { 
  FETCH_GROUPS_REQUESTED,
  ADD_GROUP_REQUESTED,
  DELETE_GROUP_START,
  EDIT_GROUPS_START,
  PULL_MTGROUPS_REQUESTED,
  FETCH_MTGROUPS_REQUESTED,
  ADJUST_MTGROUPS_REQUESTED,
  FETCH_ACCOUNT_MTGROUPS_REQUESTED
} from "./actionTypes";
import {
  fetchGroupsSuccess,
  fetchGroupsFail,
  addGroupClear,
  addGroupFail,
  addGroupErrorClear,
  addGroupsuccess,
  deleteGroupEnd,
  editGroupsDone,
  editGroupsClear,
  fetchMtGroupsFail,
  fetchMtGroupsSuccess,
  pullMTGroupsRequest,
  pullMTGroupsRequestFail,
  fetchAccountMtGroupsSuccess,
  adjustMtGroupsFail,
  adjustMtGroupsSuccess,
  pullMTGroupsRequestsuccess
} from "./actions";
import * as rebateSettingsApis from "apis/rebateSettings";
import { showErrorNotification, showSuccessNotification } from "store/notifications/actions";

function * fetchGroups(params){
  try {
    const data = yield call(rebateSettingsApis.getGroups, params);
    yield put(fetchGroupsSuccess(data));
  } catch (err){
    yield put(fetchGroupsFail(err.message));
  }
}

function * fetchMtGroups(params){
  try {
    const data = yield call(rebateSettingsApis.getMtGroups, params);
    yield put(fetchMtGroupsSuccess(data));
  } catch (err){
    yield put(fetchMtGroupsFail(err.message));
  }
}

function * fetchAccountMtGroups(params){
  try {
    const data = yield call(rebateSettingsApis.getMtGroups, params);
    yield put(fetchAccountMtGroupsSuccess(data));
  } catch (err){
    yield put(fetchMtGroupsFail(err.message));
  }
}

function * adjustMtGroups(params){
  try {
    const data = yield call(rebateSettingsApis.adjustMtGroups, params);
    yield put(adjustMtGroupsSuccess(data));
    yield put(showSuccessNotification("Groups Adjusted Successfully"));
    yield put(addGroupClear());
  } catch (err){
    yield put(adjustMtGroupsFail(err.message));
    yield put(showErrorNotification(err.message || "Failed to adjust MT groups")); // Added this line
    yield put(addGroupErrorClear());
  }
}

function * pullMTGroups(params){
  try {
    const data = yield call(rebateSettingsApis.pullMTGroups, params);
    yield put(pullMTGroupsRequestsuccess(data));
    yield put(showSuccessNotification(`${data.result.length} Groups Synced Successfully`));
    yield put(addGroupClear());
  } catch (err){
    console.log("CALLED FROM Pull MT Grpups: ", err);
    yield put(showErrorNotification(err.message || "Failed to sync MT groups"));
    yield put(pullMTGroupsRequestFail(err.message));
    yield put(addGroupErrorClear());
  }
}


function * addGroup(params){
  try {
    const { payload } = params;
    const data = yield call(rebateSettingsApis.addGroup, payload);
    // if (payload.accountType === "ibAccount"){
    //   data = yield call(rebateSettingsApis.postIbInternalTransfer, values);
    // } else data = yield call(rebateSettingsApis.postInternalTransfer, values);
    yield put(addGroupsuccess(data));
    yield put(showSuccessNotification("Group Added Successfully"));
    yield put(addGroupClear());
  } catch (err){
    yield put(showErrorNotification(err.message));
    yield put(addGroupFail(err.message));
    yield delay(5000);
    yield put(addGroupErrorClear());
  }
}

function* deleteGroup(params) {
  try {
    yield call(rebateSettingsApis.deleteGroup, params.payload);
    yield put(deleteGroupEnd({ id: params.payload }));
    yield put(showSuccessNotification("Group Deleted successfully"));
  }
  catch (error) {
    yield put(deleteGroupEnd({ error }));
    yield put(showErrorNotification(error.message));
  }
}

function * editGroup(params){
  try {
    yield call(rebateSettingsApis.editGroup, params);
    // const { result } = data;
    yield put(editGroupsDone({
      result: params.payload.values,
      id: params.payload.id  
    }));
    yield put(showSuccessNotification("Group updated successfully"));
    // yield delay(2000);
    yield put(editGroupsClear());
  }
  catch (error){
    yield put(editGroupsDone({ error: error.message }));
    // yield delay(2000);
    // yield put(editRoleClear());
  } 

  
}

function * groupsSaga(){
  yield takeEvery(FETCH_GROUPS_REQUESTED, fetchGroups);
  yield takeEvery(FETCH_MTGROUPS_REQUESTED, fetchMtGroups);
  yield takeEvery(FETCH_ACCOUNT_MTGROUPS_REQUESTED, fetchAccountMtGroups);
  yield takeEvery(ADJUST_MTGROUPS_REQUESTED, adjustMtGroups);
  yield takeEvery(PULL_MTGROUPS_REQUESTED, pullMTGroups);
  yield takeEvery(ADD_GROUP_REQUESTED, addGroup);
  yield takeEvery(DELETE_GROUP_START, deleteGroup);
  yield takeEvery(EDIT_GROUPS_START, editGroup);
}
export default groupsSaga;