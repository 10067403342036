import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter 
} from "reactstrap";
import { withTranslation } from "react-i18next";
import {
  deleteGroupStart,
  fetchGroups,
} from "store/rebateSettings/groups/actions";
import { fetchRebateRules } from "store/rebateSettings/rebateRules/actions";

function deleteStatus({
  deletedId,
  setSwitchStatus,
  t,
  switchStatus,
  show,
  text,
  type,
  delteSuccess,
}) {
  const dispatch = useDispatch();
  const allRebateRules = useSelector(
    (state) => state?.RebateRulesReducer?.rebateRules
  );
  useEffect(() => {
    dispatch(
      fetchRebateRules({
        limit: 1000000000000000,
        page: 1,
        type,
      })
    );
  }, []);

  useEffect(() => {
    if (delteSuccess) {
      toggleModal();
      dispatch(
        fetchGroups({
          limit: 10,
          page: 1,
          type,
        })
      );
    }
  }, [delteSuccess]);

  const toggleModal = () => {
    setSwitchStatus((preValue) => !preValue);
  };
  console.log(
    "state: ",
    useSelector((state) => state)
  );
  const couldBeDeleted = () => {
    // console.log("deletedId: ", deletedId);

    // console.log("rebateRules: ", allRebateRules);
    const isParticipatedInRebateRule = allRebateRules?.some(
      (rebateRule) => rebateRule?.symbolGroupId?._id === deletedId
    );

    return !isParticipatedInRebateRule;
  };

  const handleSymbolGroupDelete = () => {
    if (couldBeDeleted()) {
      dispatch(deleteGroupStart({ id: deletedId }));
    }
  };

  return (
    <React.Fragment>
      {/* <button
        type="button"
        className="btn btn-primary waves-effect waves-light w-100 me-1"
        onClick={toggleModal}
      >
        {t("Confirm Prompt")}
      </button> */}
      <Modal isOpen={show} toggle={toggleModal} centered={true}>
        <ModalHeader toggle={toggleModal} tag="h4">
          {couldBeDeleted() ? t("Confirm Delete") : t("Warning")}
        </ModalHeader>
        <ModalBody className="text-center">
          {couldBeDeleted() ? (
            <h5>{t("Whether to delete this Symbol Group?")}</h5>
          ) : (
            <h5 className="text-danger">
              {t(
                "This symbol group is already binded to a rebate rule and cannot be deleted"
              )}
            </h5>
          )}
        </ModalBody>
        <ModalFooter>
          {couldBeDeleted() ? (
            <Button
              type="submit"
              color="primary"
              onClick={handleSymbolGroupDelete}
            >
              {t("Yes")}
            </Button>
          ) : (
            <Button type="submit" color="primary" onClick={toggleModal}>
              {t("Close")}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

export default withTranslation()(deleteStatus);
