/* eslint-disable object-property-newline */
export const YESNO = [
  {
    label: "YES",
    value: "yes" 
  },
  {
    label: "NO",
    value: "no" 
  },
];

export const TITLES = ["Mr", "Mrs", "Miss", "Ms", "Dr"];
export const ENTITY_TYPES = ["VU", "MU"];
export const BASE_EMAIL_FIELDS = [
  { label: "CP Url", value: "cpUrl", },
  { label: "CRM Url", value: "crmUrl", },
  { label: "Customer Support Name", value: "customerSupportName", },
  { label: "First Name", value: "firstName", },
  { label: "Last Name", value: "lastName", },
];
export const EMAIL_FIELDS = [
  ...BASE_EMAIL_FIELDS,
  { label: "Email", value: "email" },
  { label: "Amount", value: "amount" },
  { label: "Currency", value: "currency" },
  { label: "Reset Link", value: "resetLink" },
  { label: "Account Login", value: "login" },
  { label: "Account Login To", value: "loginTo" },
  { label: "Platform", value: "platform" },
  { label: "Password", value: "password" },
  { label: "Investor Password", value: "investorPassword" },
  { label: "Password Type", value: "type" },
  { label: "Gateway", value: "gateway" },
  { label: "Amount", value: "amount" },
  { label: "Rejection Reason", value: "rejectionReason" },
  { label: "PIN", value: "pin" },
  { label: "From Details", value: "from" },
  { label: "To Details", value: "to" },
  { label: "Platform Link", value: "platformLink" },
  { label: "Live Server", value: "liveServer" },
  { label: "Demo Server", value: "demoServer" },
  { label: "Funding Step", value: "fundingStep" },
  { label: "USDT Address", value: "usdtAddress" },
  { label: "Note", value: "note" },

];

export const TRADING_EXPERIENCE = [
  {
    name: "No Experience",
    value: "No Experience" 
  },
  {
    name: "Less Than 1 Year",
    value: "Less Than 1 Year" 
  },
  {
    name: "1-3 Years",
    value: "1-3 Years" 
  },
  {
    name: "3-5 Years",
    value: "3-5 Years" 
  },
  {
    name: "More Than 5 Years",
    value: "More Than 5 Years" 
  }
];

export const TRADING_GOAL = [
  {
    name: "Speculative Gains",
    value: "Speculative Gains" 
  },
  {
    name: "Hedging Against Other Investments",
    value: "Hedging Against Other Investments" 
  },
  {
    name: "Income Generation",
    value: "Income Generation" 
  },
  {
    name: "Diversiffication",
    value: "Diversiffication" 
  },
  {
    name: "Other (Please Specify)",
    value: "Other" 
  }
];

export const DEPOSIT_RANGE = [
  {
    name: "Below $2000",
    value: "Below $2000" 
  },
  {
    name: "$2001 - $5000",
    value: "$2001 - $5000" 
  },
  {
    name: "$5001 - $10,000",
    value: "$5001 - $10,000" 
  },
  {
    name: "$10,001 - $30,000",
    value: "$10,001 - $30,000" 
  },
  {
    name: "$30,001 - $50,000",
    value: "$30,001 - $50,000" 
  },
  {
    name: "$50,001 - above",
    value: "$50,001 - above" 
  },
];

export const TRADING_DURATION = [
  {
    name: "Short Term (up to 10 minutes)",
    value: "Short Term (up to 10 minutes)" 
  },
  {
    name: "Medium Term (typically a few days)",
    value: "Medium Term (typically a few days)" 
  },
  {
    name: "Long Term (more than a week)",
    value: "Long Term (more than a week)" 
  }
];
export const CONFIRMATION_EMAILS = [
  {
    name: "Confirmation of 5% Withdrawal Fee (Different Deposit and Withdrawal Currencies)",
    value: "Confirmation of 5% Withdrawal Fee" 
  },
  {
    name: "Withdrawal Fee Confirmation (Different USDT Network)",
    value: "Different USDT Network" 
  },
  {
    name: "Withdrawal Fee Confirmation",
    value: "Withdrawal Fee Confirmation" 
  },
  {
    name: "Inconsistent Deposit and Withdrawal Methods (Bank Transfer In, USDT Out)",
    value: "Bank Transfer In, USDT Out" 
  },
  {
    name: "Inconsistent Deposit and Withdrawal Methods (USDT In, Bank Transfer Out)",
    value: "USDT In, Bank Transfer Out" 
  },
  {
    name: "Withdrawal Confirmation: Credit/Bonus Cancellation",
    value: "Credit/Bonus Cancellation" 
  },
  {
    name: "Risk Warning for Withdrawal with Open Order(s)",
    value: "Risk Warning" 
  },
  {
    name: "Withdrawal Without Trading Actitvity",
    value: "Withdrawal Without Trading Actitvity" 
  },
  {
    name: "Multiple Withdrawal Requests Confirmation",
    value: "Multiple Withdrawal Requests Confirmation" 
  }
];