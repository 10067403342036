import { DELETE_TODO_END, DELETE_TODO_START } from "store/todos/actionTypes";
import { APPROVE_FOREX_DEPOSIT_SUCCESS } from "../deposits/actionTypes";
import {
  FETCH_GROUPS_REQUESTED,
  FETCH_GROUPS_SUCCESS,
  FETCH_GROUPS_FAIL,

  SYNC_SYMBOL_REQUESTED,
  SYNC_SYMBOL_SUCCESS,
  SYNC_SYMBOL_FAIL,
  
  ADD_GROUP_REQUESTED,
  ADD_GROUP_SUCCESS,
  ADD_GROUP_FAIL,
  ADD_GROUP_CLEAR,
  ADD_GROUP_ERROR_CLEAR,
  APPROVE_INTERNAL_TRANSFER,
  APPROVE_INTERNAL_TRANSFER_SUCCESS,
  REJECT_INTERNAL_TRANSFER,
  REJECT_INTERNAL_TRANSFER_SUCCESS,
  DELETE_GROUP_START,
  DELETE_GROUP_END,
  EDIT_GROUPS_START,
  EDIT_GROUPS_DONE,
  EDIT_GROUPS_CLEAR,
  FETCH_MTGROUPS_REQUESTED,
  FETCH_MTGROUPS_SUCCESS,
  FETCH_MTGROUPS_FAIL,
  FETCH_ACCOUNT_MTGROUPS_REQUESTED,
  FETCH_ACCOUNT_MTGROUPS_SUCCESS,
  FETCH_ACCOUNT_MTGROUPS_FAIL,
  PULL_MTGROUPS_REQUESTED,
  PULL_MTGROUPS_SUCCESS,
  PULL_MTGROUPS_FAIL,
  ADJUST_MTGROUPS_REQUESTED,
  ADJUST_MTGROUPS_FAIL,
  ADJUST_MTGROUPS_SUCCESS
} from "./actionTypes";

const initalState = {
  groups: [],
  fetchGroupsLoading: false,
  addGroupsLoading: false,
  editLoading: false,
  disableAddButton: false,
  showAddSuccessMessage: false,
  showEditSuccessMessage: false,
  error: "",
  modalClear: false,
  groupResponseMessage: "",
  deletingClearCounter: 0,
  editClearingCounter: 0,
  addGroupClearingCounter: 0,
  mtGroups: {
    groups: [],
    fetchGroupsLoading: false,
    addGroupsLoading: false,
    editLoading: false,
    disableAddButton: false,
    showAddSuccessMessage: false,
    showEditSuccessMessage: false,
    error: "",
    modalClear: false,
    groupResponseMessage: "",
    deletingClearCounter: 0,
    editClearingCounter: 0,
    addGroupClearingCounter: 0,
  }
};

const groupReducer = (state = initalState, action) => {
  switch (action.type){
    // fetch internal transfers
    case FETCH_GROUPS_REQUESTED:
      state = {
        ...state,
        fetchGroupsLoading: true
      };
      break;
    case FETCH_GROUPS_SUCCESS:
      state = {
        ...state,
        groups: [...action.payload.result.docs],
        groupsTotalDocs: action.payload.result.totalDocs,
        hasNextPage: action.payload.result.hasNextPage,
        hasPrevPage: action.payload.result.hasPrevPage,
        limit: action.payload.result.limit,
        nextPage: action.payload.result.nextPage,
        page: action.payload.result.page,
        pagingCounter: action.payload.result.pagingCounter,
        prevPage: action.payload.result.prevPage,
        totalPages: action.payload.result.totalPages,
        fetchGroupsLoading: false,  
        totalDocs: action.payload.result.totalDocs
      };
      break;
    case FETCH_GROUPS_FAIL:
      state = {
        ...state,
        fetchGroupsLoading: false,
        groupsError: action.payload
      };
      break;
      // fetch nt groups
    case FETCH_MTGROUPS_REQUESTED:
      state = {
        ...state,
        mtGroups : {
          ...state?.mtGroups,
          fetchGroupsLoading: true
        }
      };
      break;
    case FETCH_MTGROUPS_SUCCESS:
      state = {
        ...state,
        mtGroups: {
          ...state?.mtGroups,
          groups: [...action.payload.result.docs],
          pagination: {
            totalDocs: action.payload.result.totalDocs,
            hasNextPage: action.payload.result.hasNextPage,
            hasPrevPage: action.payload.result.hasPrevPage,
            limit: action.payload.result.limit,
            nextPage: action.payload.result.nextPage,
            page: action.payload.result.page,
            pagingCounter: action.payload.result.pagingCounter,
            prevPage: action.payload.result.prevPage,
            totalPages: action.payload.result.totalPages,
          },
          fetchGroupsLoading: false,
        },
      };
      break;  
    case FETCH_MTGROUPS_FAIL:
      state = {
        ...state,
        mtGroups : {
          ...state?.mtGroups,
          fetchGroupsLoading: false,
          groupsError: action.payload
        }
      };
      break;

    case FETCH_ACCOUNT_MTGROUPS_REQUESTED:
      state = {
        ...state,
        mtGroups : {
          ...state?.mtGroups,
          accountGroups: {
            ...state?.mtGroups,
            fetchGroupsLoading: true,
            groupsError: action.payload,
            pagination: {
              totalDocs: 0,
              hasNextPage: false,
              hasPrevPage: false,
              limit: 0,
              nextPage: 0,
              page: 0,
              pagingCounter: 0,
              prevPage: 0,
              totalPages: 0,
            }
          }
        }
      };
      break;
    case FETCH_ACCOUNT_MTGROUPS_SUCCESS:
      state = {
        ...state,
        mtGroups: {
          ...state?.mtGroups,
          accountGroups: {
            groups: [...action.payload.result.docs],
            pagination: {
              totalDocs: action.payload.result.totalDocs,
              hasNextPage: action.payload.result.hasNextPage,
              hasPrevPage: action.payload.result.hasPrevPage,
              limit: action.payload.result.limit,
              nextPage: action.payload.result.nextPage,
              page: action.payload.result.page,
              pagingCounter: action.payload.result.pagingCounter,
              prevPage: action.payload.result.prevPage,
              totalPages: action.payload.result.totalPages,
            },
            fetchGroupsLoading: false,
          },
        },
      };
      break;  
    case FETCH_ACCOUNT_MTGROUPS_FAIL:
      state = {
        ...state,
        addSuccess: false,
        mtGroups : {
          ...state?.mtGroups,
          accountGroups: {
            ...state?.mtGroups,
            fetchGroupsLoading: false,
            groupsError: action.payload
          }
        }
      };
      break;

    // Sync Groups
    case PULL_MTGROUPS_REQUESTED:
      state = {
        ...state,
        addGroupsLoading: true,
        disableAddButton: true,
      };
      break;
    case PULL_MTGROUPS_SUCCESS:
      state = {
        ...state,
        newSymbol: action.payload.result,
        showAddSuccessMessage: true,
        addSuccess: true,
        addGroupFail: false,
        disableAddButton: false,
      };
      break;
    case PULL_MTGROUPS_FAIL:
      state = {
        ...state,
        addSuccess: false,
        disableAddButton: false,
        addGroupFail: true,
        addGroupsLoading: false,
        addGroupFailDetails: action.payload.error
      };
      break;

    // Sync Groups
    case ADJUST_MTGROUPS_REQUESTED:
      state = {
        ...state,
        addSuccess: false,
        addGroupsLoading: true,
        disableAddButton: true,
      };
      break;
    case ADJUST_MTGROUPS_SUCCESS:
      state = {
        ...state,
        // newSymbol: action.payload.result,
        showAddSuccessMessage: true,
        addSuccess: true,
        addGroupFail: false,
        disableAddButton: false,
      };
      break;
    case ADJUST_MTGROUPS_FAIL:
      state = {
        ...state,
        addSuccess: false,
        disableAddButton: false,
        addGroupFail: true,
        addGroupsLoading: false,
        addGroupFailDetails: action.payload.error
      };
      break;

    // Sync Symbols
    case SYNC_SYMBOL_REQUESTED:
      state = {
        ...state,
        addGroupsLoading: true,
        disableAddButton: true,
      };
      break;
    case SYNC_SYMBOL_SUCCESS:
      state = {
        ...state,
        newSymbol: action.payload.result,
        showAddSuccessMessage: true,
        addSuccess: true,
        addGroupFail: false,
        disableAddButton: false,
      };
      break;
    case SYNC_SYMBOL_FAIL:
      state = {
        ...state,
        addSuccess: false,
        disableAddButton: false,
        addGroupFail: true,
        addGroupsLoading: false,
        addGroupFailDetails: action.payload.error
      };
      break;

    // add group
    case ADD_GROUP_REQUESTED:
      state = {
        ...state,
        addGroupsLoading: true,
        disableAddButton: true,
      };
      break;
    case ADD_GROUP_SUCCESS:
      state = {
        ...state,
        newGroup: action.payload.result,
        disableAddButton: false,
        addSuccess: true,
        addGroupFail: false
      };
      break;
    case ADD_GROUP_FAIL:
      state = {
        ...state,
        addSuccess: false,
        disableAddButton: false,
        addGroupFail: true,
        addGroupsLoading: false,
        addGroupFailDetails: action.payload.error
      };
      break;
    case ADD_GROUP_CLEAR:
      state = {
        ...state,
        addGroupsLoading: false,
        addGroupClearingCounter: state.addGroupClearingCounter + 1,
        addGroupFail: false,
        addSuccess: false,
        disableAddButton: false,
        modalClear: true
      };
      break;
    case ADD_GROUP_ERROR_CLEAR:
      state = {
        ...state,
        addGroupsLoading: false,
        addGroupFail: false,
        disableAddButton: false,
        addGroupFailDetails: null
      };
      break;

    case DELETE_GROUP_START:
      return {
        ...state,
        deleting: true,
      };
    case DELETE_GROUP_END:
      return {
        ...state,
        deleting: false,
        deletingClearCounter: state.deletingClearCounter + 1,
      };
      
    case EDIT_GROUPS_START:
      state = {
        ...state,
        editLoading: true,
        showEditSuccessMessage: false,
      };
      break;
    case EDIT_GROUPS_DONE:
      // eslint-disable-next-line no-case-declarations
      const { id, ...payload } = action.payload;
      state = {
        ...state,
        // docs: state.docs.map(obj => {
        //   if (obj._id === id) {
        //     return {
        //       ...obj,
        //       title: payload.result.title,
        //       permissions: payload.result.permissions,
        //     };
        //   } else {
        //     return obj;
        //   }
        // }),
        showEditSuccessMessage: true,
        editLoading: false,
        editResult: action.payload.result,
        editError: action.payload.error,
      };
      break;
    case EDIT_GROUPS_CLEAR:
      state = {
        ...state,
        showEditSuccessMessage: false,
        editLoading: false,
        editResult: null,
        editError: null,
        editClearingCounter: state.editClearingCounter + 1
      };
      break;
    case APPROVE_INTERNAL_TRANSFER: 
      state = {
        ...state,
      };
      break;
    case APPROVE_INTERNAL_TRANSFER_SUCCESS: 
      state = {
        ...state,
        internalTransfers: state.internalTransfers.map(d => {
          if (d._id === action.payload){
            d.status = "APPROVED";
          }
          return d;
        }),
      };
      break;
    case REJECT_INTERNAL_TRANSFER: 
      state = {
        ...state,
      };
      break;
    case REJECT_INTERNAL_TRANSFER_SUCCESS: 
      state = {
        ...state,
        internalTransfers: state.internalTransfers.map(d => {
          if (d._id === action.payload){
            d.status = "REJECTED";
          }
          return d;
        }),
      };
      break;
    default:
      state = { ...state };
  }
  return state;
};

export default groupReducer;