import { cpUrl, jwtSecret } from "content";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AvForm, AvField,
} from "availity-reactstrap-validation";
import {
  Row, Modal, ModalHeader, ModalBody,  Button,
} from "reactstrap";
import { withTranslation } from "react-i18next";

import {
  toggleCurrentModal, showSuccessNotification,
} from "store/actions";
import jwt from "jsonwebtoken";

const CreatePromotionLink = ({ isOpen, toggle, type, t }) => {
  const dispatch = useDispatch();
  const clientData = useSelector((state) => state.clientReducer?.clientDetails);

  const [selectedRebates, setSelectedRebates] = useState([]);
  useEffect(() => {
    setSelectedRebates([]);
  }, []);
  const handleRebateChange = (rebate, rebateValue) => {
    const { rebateId, rebateName, minRebateValue, rebateValue:rebateValueMax } = rebate;
    setSelectedRebates(prevState => {
      return [...prevState, { 
        rebateId,
        rebateValue,
        rebateName,
        minRebateValue,
        maxRebateValue: rebateValueMax,
      }];
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const selectedRebatesData = selectedRebates?.map(rebate=> {
      return {
        ...rebate,
        aggrementId: type?.agRef,
      };
    });
    const rebateRefString = JSON.stringify(selectedRebatesData);
    const secret = jwtSecret;
    const hashedRebate = jwt.sign({ rebateRefString }, secret, { expiresIn: "1h" });
    navigator.clipboard.writeText(`${cpUrl}/register/client/ib?rebateRef=${hashedRebate}&promotionLink=${type?.promoLink?._id}&parentRef=${clientData?.recordId}`);
    dispatch(showSuccessNotification("Link copied to clipboard"));
    toggle();
  };
  return (
    <Modal centered isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle} tag="h4" className="text-capitalize">
        {t(`Create ${type?.text}`)}
      </ModalHeader>
      <ModalBody className="px-4">
        <AvForm
          onValidSubmit={(e, v) => {
            handleSubmit(e);
          }}
        >
          {
            clientData?.rebateRef?.[type?.promoLink?._id] && JSON.parse(clientData?.rebateRef?.[type?.promoLink?._id])?.map((rebate) => (
              <>
                <Row className="border rounded-3 p-3 mt-3">
                  <h5 className="mb-3">{t(`Select ${rebate?.rebateName} Rebate`)}</h5>
                  <AvField 
                    onChange={(e) => handleRebateChange(rebate, Number(e.target.value))} 
                    type="select" 
                    name={`rebate${rebate?.rebateId}`} 
                    value="" 
                    required
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    {[...Array((Number(rebate?.rebateValue)))]?.map((_, index) => (
                      <option key={index} value={Number(rebate?.rebateValue) - index - 1}>
                        {`${Number(rebate?.rebateValue) - index - 1}`}
                      </option>
                    ))}
                  </AvField>
                </Row>
              </>
            ))
          }
          <div className="text-center mt-3 mb-1">
            <Button type="submit" className="color-bg-btn border-0 text-white w-25">{t("Submit")}</Button>
            <Button color="light" type="button" className="w-25 ms-3"
              onClick={() => dispatch(toggleCurrentModal(""))}
            >{t("Skip")}</Button>
          </div>
        </AvForm>
      </ModalBody>
    </Modal >
  );
};

export default withTranslation()(CreatePromotionLink);