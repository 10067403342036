import { Link as ReactLink } from "react-router-dom";
import React, { useMemo } from "react";
import ClientLink from "./ClientLink";
import { Button } from "reactstrap";


const NodeModal = ({ isOpen, onClose, data, clientId, structure }) => {
  const getHighest = (value) => {
    let rebate = 0,
      commission = 0;
    Object.keys(value).forEach((k) => {
      if (value[k].rebate && value[k].rebate > rebate) rebate = value[k].rebate;
      if (value[k].commission && value[k].commission > commission)
        commission = value[k].commission;
    });
    return {
      commission,
      rebate
    };
  };
  
  function getRebateCommission() {
    if (!data.children) return [];
    const model =
      structure.members &&
      structure.members
        .find((m) => m.customerId._id === data._id)
        ?.values.map((value) => ({
          label: value.accountTypeName,
          rebate: getHighest(value).rebate,
          commission: getHighest(value).commission
        }));

    return model || [];
  }

  const tradingAccounts = useMemo(() => {
    if (!data?.fx) return [];
    return [...data.fx.liveAcc, ...data.fx.demoAcc];
  }, [data]);

  if (!isOpen || !data) return null;


  return (
    <>
      <div className="node-backdrop" onClick={onClose}></div>
      <div className="node-modal">
        <div className="info">
          <h4 className="mb-0">
            <ClientLink id={data.id} firstName={`${data.firstName} ${data.lastName}`} redirect={true} />
          </h4>
          <small>{data.email}</small>
          {(!data.fx || (data.fx && tradingAccounts.length === 0)) &&
            <div className="nowrap mt-2">
              <ReactLink
                color="primary"
                className="highlight"
                to="#">
                No Accounts linked
              </ReactLink>
            </div>
          }
          {tradingAccounts && tradingAccounts.length > 0 && (
            <>
              <div className="nowrap mt-2">
                <ReactLink
                  color="primary"
                  className="highlight"
                  to="#">
                  Account
                </ReactLink>
              </div>
              <small>{tradingAccounts.join(", ")}</small>
            </>
          )}
          
        </div>
        <Button color="primary" onClick={onClose}>Close</Button>
      </div>
    </>
  );
};

export default NodeModal;
