/* eslint-disable object-property-newline */
import {
  takeEvery, put, call, delay
} from "redux-saga/effects";

import {
  ADD_SERVER_START,
  DELETE_SERVER_START,
  EDIT_SERVER_START,
  GET_SERVER_START
} from "./actionTypes";

import {
  addServerEnd,
  fetchServerEnd,
  editServerEnd,
  deleteServerEnd
} from "./actions";
import * as mtserverApi from "../../apis/mtServers";
import { showErrorNotification, showSuccessNotification } from "store/notifications/actions";

function* fetchServers(params) {
  try {
    const data = yield call(mtserverApi.getServers, params);
    yield put(fetchServerEnd({ data }));
  }
  catch (error) {
    yield put(fetchServerEnd({ error }));
  }
}

function* addServer(params) {
  try {
    const data = yield call(mtserverApi.addMtServer, params.payload);
    yield put(addServerEnd({ data: params.payload.id ? params.payload : data, id: params.payload.id }));
    if (params.payload.id) {
      yield put(showSuccessNotification("Server updated successfully"));
    } else {
      yield put(showSuccessNotification("Server added successfully"));
    }
    // yield put(addTodoClear());

  }
  catch (error) {
    yield put(addServerEnd({ error })); // Dispatch error state
    yield put(showErrorNotification("Can't add server with same name,address."));
    yield delay(2500); // Optional: you can choose to keep this or remove it
  }
}


function* deleteServer(params) {
  try {
    const platform = params.payload.platform;
    yield call(mtserverApi.deleteServer, params.payload);
    yield put(deleteServerEnd({ id: params.payload }));
    yield put(showSuccessNotification("Server deleted successfully"));
  }
  catch (error) {
    yield put(deleteServerEnd({ error }));
    yield put(showErrorNotification(error.message));
  }
}

function * editServer(params){
  try {
    const data = yield call(mtserverApi.editServer, params.payload);
    if (data){
      yield put(showSuccessNotification("Server updated successfully"));
      yield put(editServerEnd(data));
    }
  }
  catch (error){
    yield put(showErrorNotification(error.message));
  } 
}

function* MTServerSaga() {
  yield takeEvery(GET_SERVER_START, fetchServers);
  yield takeEvery(ADD_SERVER_START, addServer);
  yield takeEvery(DELETE_SERVER_START, deleteServer);
  yield takeEvery(EDIT_SERVER_START, editServer);

}

export default MTServerSaga;
