import React, { useState } from "react";
import AuditFlow from "pages/Audit/AuditFlow";

function CustomerKYC(props){
  const { clientId, sumsub } = props;
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  return (
    <React.Fragment>
      <button 
        type="button" 
        className="btn btn-primary waves-effect waves-light w-100 me-1"
        onClick={toggle}
      >
        KYC Logs
      </button>
      <button 
        type="button" 
        className="btn btn-primary waves-effect waves-light w-100 me-1"
        onClick={() => {
          window.open(`https://cockpit.sumsub.com/checkus#/applicant/${sumsub?.id}/client/basicInfo`, "_blank");
        }}
      >
        Open Sumsub
      </button>
      {
        open && <AuditFlow customerId={clientId} toggleModal={toggle} />
      }
    </React.Fragment>
  );

}

export default CustomerKYC;