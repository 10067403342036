import React, { useCallback, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import AsyncAvFieldSelect from "../../../../components/Common/AsyncAvFieldSelect";
import * as rebateSettingsApis from "apis/rebateSettings";
import { debounce } from "lodash";


const SearchableSymbolGroupsDropdown = (props) => {

  const {
    label = "Symbols",
    placeholder = "Search",
    isRequired = false,
    name = "symbols",
    isDisabled = false,
    defaultOptions = [],
    value = null,
    clientData = null,
    onChange = () => { },
    isMulti = false,
    closeMenuOnSelect = false,
    platform = "",
  } = props;

  const debouncedChangeHandler = useCallback(
    debounce((inputValue, cb) => {
      rebateSettingsApis.getSymbols({
        payload: {
          searchText: inputValue,
          page: 1,
          limit: 10,
          platform
        }
      }).then((data) => {
        return cb(data?.result?.docs.filter((symbol) => !symbol.groupId).map((group) => (
          {
            label : group.symbolName,
            value : group._id
          }
        )));
      });
    }, 1000), []);


  // const [agentOptions, setAgentOptions] = React.useState(defaultOptions);
  // const { userData } = useSelector((state) => ({
  //   userData: state.Profile.userData,
  // }));

  // useEffect(() => {
  //   if (clientData && clientData.length > 0) {
  //     setAgentOptions(clientData.map((user) => ({
  //       value: user._id,
  //       label: user.firstName + " " + user.lastName + " - " + user.roleId.title
  //     })));
  //   }
  // }, [clientData]);


  return (
    <AsyncAvFieldSelect 
      name={name}
      options={props?.symboloptions || []}
      // label={props.t(label)}
      errorMessage={props.t("Symbol is required")}
      defaultOptions={props?.symboloptions || defaultOptions}
      value={value?.map((val) => ({
        label: val.label,
        value: val.value
      }))}
      defaultValue={value?.map((val) => ({
        label: val.label,
        value: val.value
      }))}
      loadOptions={debouncedChangeHandler}
      isRequired={isRequired}
      placeholder={placeholder}
      isSearchable={true}
      backspaceRemovesValue={true}
      onChange={onChange}
      isMulti={isMulti}
      isDisabled={isDisabled}
      disabled={isDisabled}
      closeMenuOnSelect={closeMenuOnSelect}
    />
  );
};


const mapStateToProps = (state) => ({
  agents: state.usersReducer.salesAgent,
  agentsLoading: state.usersReducer.loading,
});
export default connect(mapStateToProps, null)(withTranslation()(SearchableSymbolGroupsDropdown));