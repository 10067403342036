import React from "react";

const getFundingStepKey = {
  fundingDepartmentInitial: "Fund-I-",
  riskDepartmentReview: "Risk-",
  fundingDepartmentDeduction: "Fund-D-",
  withdrawalRemittance: "Remit-",
};
export default function Badge({ status, isCentered }) {
  let badgeColor = "alert-";
  status = status?.indexOf("_") > -1 ? status?.replace("_", " ").toLowerCase() : status?.toLowerCase();
  switch (status) {
    case "completed":
    case "approved":
    case "deposit":
    case "success":
    case "remitted":
    case "APPROVED":
    case "pass":
    case "fund-pass":
    case "risk-pass":
    case "deduction-pass":
    case "fund-i-approved":
    case "risk-approved":
    case "fund-d-approved":
    case "remit-approved":
      badgeColor += "success";
      break;
    case "pending":
    case "processing":
    case "on hold":
    case "returned":
    case "RETURNED":
    case "PENDING":
    case "ON_HOLD":
    case "internal transfer":
    case "fund-i-pending":
    case "risk-pending":
    case "fund-d-pending":
    case "remit-pending":
    case "remittance in progress":
      badgeColor += "warning";
      break;
    case "failed":
    case "withdraw":
    case "rejected":
    case "fund-i-rejected":
    case "risk-rejected":
    case "fund-d-rejected":
    case "remit-rejected":
    case "fund-fail":
    case "risk-fail":
    case "deduction-fail":
      badgeColor += "danger";
      break;
    default:
      badgeColor += "light";
  }

  return (
    <span className={`w-md-50 py-0 alert text-capitalize d-flex mb-0 ${badgeColor} ${isCentered &&  "mx-auto"}`} 
      style={{ 
        minWidth: "max-content",
        maxWidth: "fit-content"
      }}
    >{status}</span>
  );
}