/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import { 
  useDispatch, 
  connect, 
  useSelector 
} from "react-redux";
// eslint-disable-next-line object-curly-newline
import { Row, Col, Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import SearchBar from "components/Common/SearchBar";
import CustomPagination from "components/Common/CustomPagination";
// eslint-disable-next-line object-curly-newline
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import TableLoader from "components/Common/TableLoader";
import Notification from "components/Common/Notification";
import logo from "assets/images/logo-sm.svg";
import { withTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import SymbolsAdd from "./SymbolsAdd";
import SymbolsEdit from "./SymbolsEdit";
import { fetchRebateRules } from "store/rebateSettings/rebateRules/actions";
import RebateRulesModal from "./RebateRulesModal";
import SwitchStatus from "./switchStatus";
import useModal from "hooks/useModal";
import DeleteStatus from "./deleteStatus";

function MTRebateRules(props) {
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const [editModal, setEditModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [switchStatus, setSwitchStatus] = useState(false);
  const [viewRules, toggleViewRules] = useModal();
  const [deletedId, setDeletedId] = useState("");
  const [editedItem, setEditedItem] = useState({});

  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState("");
  const [showNotication, setShowNotifaction] = useState(false);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [selectedFilter, setSelectedFilter] = useState("ALL");
  const [tier1RebateRules, setTier1RebateRules] = useState([]);
  const [promotionOptionalRebateValue, setPromotionOptionalRebateValue] = useState([]);
  const { userData } = useSelector((state) => ({
    userData: state.Profile,
  }));
  const permissions = userData?.roleId?.permissions;
  const { rebateRules } = permissions;
  const deleteSymbol = ()=>{
    console.log("deleted");
  };
  const columns = [
    // {
    //   dataField: "checkbox",
    //   text: (
    //     <input
    //       type="checkbox"
    //       id="check-all-deposits"
    //       onChange={() =>
    //         checkAllBoxes("check-all-deposits", ".deposit-checkbox")
    //       }
    //     />
    //   ),
    // },
    // {
    //   dataField: "recordNumber",
    //   text: props.t("Record Number"),
    // },
    {
      dataField: "name",
      text: props.t("Rule Name"),
    },
    {
      dataField: "symbolGroup",
      text: props.t("Group Name"),
      formatter: (val) => <span>{val?.symbolGroupId?.groupName}</span>
    },
    {
      dataField: "status",
      text: props.t("Status"),
      formatter: (val) => val?.status === true ? <span className="fw-bold text-success">Active</span> : <span className="fw-bold text-danger">Not Active</span>
    },
    (rebateRules?.edit || rebateRules?.delete ) && 
    {
      dataField: "",
      isDummyField: true,
      editable: false,
      text: "Action",
      formatter: (team) => (
        <div className="text-center">
          {rebateRules?.edit && (
            <>
              <Link className={"text-success mx-1"} to="#">
                <i
                  className="mdi mdi-eye font-size-18"
                  id="edittooltip"
                  onClick={() => {
                    setViewModal(team);
                    toggleViewRules();
                  }}
                ></i>
              </Link>
              <Link className={"text-success mx-1"} to="#">
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                  onClick={() => {
                    setEditedItem(team);
                    setEditModal(true);
                  }}
                ></i>
              </Link>
              <Link className={`${team.status ? "text-success" : "text-danger"} mx-1`} to="#">
                <i
                  className={`mdi ${team.status ? "mdi-lock-open-variant" : "mdi-lock"} font-size-18`}
                  id="edittooltip"
                  onClick={() => {
                    setDeletedId(team?._id);
                    setSwitchStatus(true);
                  }}
                ></i>
              </Link>
            </>
          )}
          {rebateRules?.delete && (
            <Link className={"text-danger mx-1"} to="#">
              <i
                className="mdi mdi-delete font-size-18"
                id="deletetooltip"
                onClick={() => { 
                  setDeletedId(team?._id);
                  setDeleteStatus(true);
                }}>
              
              </i>
            </Link>
          )}
          
        </div>
      ),
    },
  ];

  const handleSearchInput = (e) => {
    setSearchInput(e.target.value);
  };
  const initFilteredValues = {
    customerId: "",
    filterDate: {
      fromDate: "",
      toDate: "",
    },
    status: query.get("status") || "",
    currency: "",
    amount: "",
    fee: "",
    tradingAccountFrom: "",
    tradingAccountTo: "",
    agent: "",
  };
  const [filteredValues, setFilteredValues] = useState(initFilteredValues);
  const filterChangeHandler = (filteredValuesData) => {
    setFilteredValues(filteredValuesData);
  };
  const loadRebateRules = (page, limit) => {
    if (searchInput && searchInput.length > 3) {
      dispatch(
        fetchRebateRules({
          limit,
          page,
          filteredValues,
          searchText: searchInput,
          type: props.type
        })
      );
    } else {
      dispatch(
        fetchRebateRules({
          limit,
          page,
          filteredValues,
          type: props.type
        })
      );
    }
  };

  const closeNotifaction = () => {
    setShowNotifaction(false);
  };

  useEffect(() => {
    loadRebateRules(1, sizePerPage);
  }, [
    props.type,
    sizePerPage,
    1,
    filteredValues,
    searchInput,
    selectedFilter,
  ]);

  return (
    <React.Fragment>
      <Notification
        onClose={closeNotifaction}
        body={props.t("The Group has been updated successfully")}
        show={showNotication}
        header={props.t("Group Update")}
        logo={logo}
      />
      <Row>
        <Col className="col-12">
          <Card>
            <CardHeader className="d-flex flex-column gap-3 ">
              <div className="d-flex justify-content-between align-items-center">
                <CardTitle className="color-primary">
                  {props.t(
                    `${props?.type} Groups(${props?.rebateRulesTotalDocs})`
                  )}
                  <FeatherIcon
                    icon="refresh-cw"
                    className="icon-lg ms-2"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      loadRebateRules(1, sizePerPage);
                    }}
                  />
                </CardTitle>
              </div>
              <div className="d-flex flex-row align-items-center justify-content-between gap-2">
                <div className="d-flex flex-row align-items-center">
                  <SearchBar
                    handleSearchInput={handleSearchInput}
                    placeholder={props.t("Search By Group")}
                  />
                  {/* <Filter
                    filterChangeHandler={filterChangeHandler}
                    filteredValues={filteredValues}
                  /> */}
                </div>
                {rebateRules?.add && (
                  <div className="d-flex flex-row align-items-center justify-content-between">
                    <SymbolsAdd type={props.type} tier1RebateRules={tier1RebateRules} setTier1RebateRules={setTier1RebateRules} setPromotionOptionalRebateValue={setPromotionOptionalRebateValue} promotionOptionalRebateValue={promotionOptionalRebateValue} />
                  </div>
                )}
              </div>
            </CardHeader>
            <CardBody>
              {rebateRules?.view ? (
                <div className="table-rep-plugin">
                <div
                  className="table-responsive mb-0"
                  data-pattern="priority-columns"
                >
                  <Table id="tech-companies-1" className="table  table-hover ">
                    <Thead className="text-center table-light">
                      <Tr>
                        {columns.map((column, index) =>
                          <Th data-priority={index} key={index}><span className="color-primary">{column.text}</span></Th>
                        )}
                      </Tr>
                    </Thead>

                    {props?.rebateRulesTotalDocs === 0 ? (
                      <Tbody>
                        {props?.fetchRebateRulesLoading && (
                          <TableLoader colSpan={4} />
                        )}
                        {!props?.fetchRebateRulesLoading && (
                          <>
                            <Tr>
                              <Td
                                colSpan={"100%"}
                                className="fw-bolder text-center"
                                st
                              >
                                <h3 className="fw-bolder text-center">
                                  No records
                                </h3>
                              </Td>
                            </Tr>
                          </>
                        )}
                      </Tbody>
                    ) : (
                      <Tbody
                        style={{
                          fontSize: "12px",
                          textAlign: "center",
                          textTransform: "capitalize",
                        }}
                      >
                        {props?.fetchRebateRulesLoading && <TableLoader colSpan={4} />}
                        {!props?.fetchRebateRulesLoading &&
                          props?.rebateRules?.map((row, rowIndex) => (
                            <Tr key={rowIndex}>
                              {columns.map((column, index) => (
                                <Td
                                  key={`${rowIndex}-${index}`}
                                  className="pt-4"
                                >
                                  {column.formatter
                                    ? column.formatter(row, rowIndex)
                                    : row[column.dataField]}
                                </Td>
                              ))}
                            </Tr>
                          ))}
                      </Tbody>
                    )}
                  </Table>
                  <CustomPagination
                    {...props}
                    setSizePerPage={setSizePerPage}
                    sizePerPage={sizePerPage}
                    onChange={loadRebateRules}
                    docs={props.rebateRules}
                  />
                </div>
              </div>

              ) : (
                <h1 className="text-center">You Do Not Have The Required Permissions</h1>
              )}
            
            </CardBody>
          </Card>
        </Col>
      </Row>
      {editModal && <SymbolsEdit editedItem={editedItem} type={props.type} tier1RebateRules={tier1RebateRules} setTier1RebateRules={setTier1RebateRules} show={editModal} setEditModal={setEditModal} />}
      {switchStatus && <SwitchStatus show={switchStatus} switchedId={deletedId} setSwitchStatus={setSwitchStatus} text="Rebate Rules" type={props?.type} delteSuccess={props?.editSuccess}/>}
      {deleteStatus && <DeleteStatus deletedId={deletedId} show={deleteStatus} setSwitchStatus={setDeleteStatus} text="Rebate Rules" type={props?.type} delteSuccess={props?.deleting}/>}
      <RebateRulesModal show={viewRules} toggle={toggleViewRules} viewModal={viewModal} />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  fetchRebateRulesLoading:
    state.RebateRulesReducer.fetchRebateRulesLoading || false,
  rebateRules: state.RebateRulesReducer.rebateRules || [],
  page: state.RebateRulesReducer.page || 1,
  rebateRulesTotalDocs:
    state.RebateRulesReducer.rebateRulesTotalDocs || 0,
  totalPages: state.RebateRulesReducer.totalPages || 0,
  deleting: state.RebateRulesReducer.deleting,
  editSuccess: state.RebateRulesReducer.editSuccess,
  hasNextPage: state.RebateRulesReducer.hasNextPage,
  hasPrevPage: state.RebateRulesReducer.hasPrevPage,
  limit: state.RebateRulesReducer.limit,
  nextPage: state.RebateRulesReducer.nextPage,
  pagingCounter: state.RebateRulesReducer.pagingCounter,
  prevPage: state.RebateRulesReducer.prevPage,
  depositsPermissions: state.Profile.depositsPermissions || {},
  tradingAccounts: state.tradingAccountReducer.tradingAccounts,
  addRebateRulesLoading:
    state.RebateRulesReducer.addRebateRulesLoading,
  groupPermissions: state.Profile.depositsPermissions,
  totalDocs: state.RebateRulesReducer.totalDocs || 0,
});
export default connect(mapStateToProps, null)(withTranslation()(MTRebateRules));
