import React, { useEffect, useState } from "react";
import {
  useDispatch
} from "react-redux";
import { Link } from "react-router-dom";

import {
  Row, Col, Card, CardBody, CardTitle, CardHeader,
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";

import {
  Table, Thead, Tbody, Tr, Th, Td
} from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import CustomPagination from "components/Common/CustomPagination";
import TableLoader from "components/Common/TableLoader";
import { captilazeFirstLetter } from "common/utils/manipulateString";
import { useTranslation, withTranslation } from "react-i18next";
import IbWalletFilter from "./IbWalletFilters";
import { MetaTags } from "react-meta-tags";
import FeatherIcon from "feather-icons-react";
import {
  changeCommisionAddingField,
  changeGeneralRebeateConfigurationHour,
  convertRebate,
  getGeneralRebeateConfigurationHour,
  getIbWallets,
  updateIbWalletschedule
} from "apis/wallet";
import {
  AvField, AvForm, AvRadio, AvRadioGroup
} from "availity-reactstrap-validation";
import { showErrorNotification } from "store/actions";
import usePermissions from "routes/permissions";
import moment from "moment";
import Loader from "components/Common/Loader";
import AvFieldSelect from "components/Common/AvFieldSelect";
import { getStatement } from "apis/client";
import StatementDeals from "pages/ClientDetail/Statement/StatementDeals";
import formatDate from "helpers/formatDate";

function IbWallet(props) {
  const [loading, setLoading] = useState(false);
  const [wallets, setWallets] = useState([]);
  const [selectedWallet, setSelectedWallet] = useState(null);
  const [globalIbCOnfigurationModal, setGlobalIbCOnfigurationModal] = useState(false);
  const [convertModal, setConvertModal] = useState(false);
  const [specificIBDelayConfigurationModal, setSpecificIBDelayConfigurationModal] = useState(false);
  const [changeCommisionModal, setChangeCommisionModal] = useState(false);
  const [walletIdModal, setWalletIdModal] = useState(false);
  const [selectedWalletId, setSelectedWalletId] = useState(null);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    totalDocs: 0,
    totalPages: 0,
    hasNextPage: false,
    hasPrevPage: false,
  });

  const { ibWalletPermissions } = usePermissions();

  const handleConvert = (wallet) => {
    setSelectedWallet(wallet);
    setConvertModal(true);
  };

  const handleChangeCommision = (wallet) => {
    setSelectedWallet(wallet);
    setChangeCommisionModal(true);
  };

  const handlespecificIBDelayConfiguration = (wallet) => {
    setSelectedWallet(wallet);
    setSpecificIBDelayConfigurationModal(true);
  };

  const handleGlobalIbCOnfigurationModal = (wallet) => {
    setGlobalIbCOnfigurationModal(true);
  };

  const handleShowWalletId = (walletId) => {
    setSelectedWalletId(walletId);
    setWalletIdModal(true);
  };

  const columns = [
    {
      dataField: "createdAt",
      text: props.t("Date"),
      formatter: (val) => formatDate(val.createdAt, "DD/MM/YYYY"),
    },
    {
      dataField: "walletId",
      text: props.t("Wallet Id"),
      formatter: (item) => item._id,
    },
    {
      dataField: "customerId",
      text: props.t("IB"),
      formatter: (val) => {
        return (
          <div>
            <Link
              to={{
                pathname: `/clients/${val?.belongsTo?._id}/profile`,
                state: { clientId: val.belongsTo }
              }}>
              <i className="no-italics fw-bold">{val.belongsTo ? `${captilazeFirstLetter(val.belongsTo.firstName)} ${captilazeFirstLetter(val.belongsTo.lastName)}` : ""}</i>
            </Link>
          </div>
        );
      }
    },
    {
      dataField: "amount",
      text: props.t("Wallet Balance"),
      formatter: (item) => (
        item.amount === " " ? "-" : parseFloat(item.amount)
      )
    },
    {
      dataField: "freezeAmount",
      text: props.t("On Hold"),
      formatter: (item) => (
        item.freezeAmount === " " ? "N/A" : parseFloat(item.freezeAmount)
      )
    },
    {
      dataField: "pendingAmount",
      text: props.t("Pending Commission"),
      formatter: (item) => (
        item.pending === " " ? "N/A" : parseFloat(item?.pending || 0)
      )
    },
    {
      dataField: "transferPendingRebate",
      text: props.t("Convert Pending Rebate"),
      formatter: (item) => (
        item?.isIb && ibWalletPermissions.update ? <Button color="primary" size="sm" onClick={() => handleConvert(item)}>
          {props.t("Convert")}
        </Button> : <span>-</span>
      ),
    },
    {
      dataField: "transferPendingRebate",
      text: props.t("Commision Status"),
      formatter: (item) => (
        item?.isIb && ibWalletPermissions.update ? <Button color="primary" size="sm" onClick={() => handleChangeCommision(item)}>
          {props.t("Change")}
        </Button> : <span>-</span>
      ),
    },
    {
      dataField: "transferPendingRebate",
      text: props.t("Specific Configuration"),
      formatter: (item) => (
        item?.isIb && ibWalletPermissions.update ? <Button color="primary" size="sm" onClick={() => handlespecificIBDelayConfiguration(item)}>
          {props.t("Change")}
        </Button> : <span>-</span>
      ),
    },
    {
      dataField: "showWalletId",
      text: props.t("IB Statement"),
      formatter: (item) => (
        <FeatherIcon
          icon="eye"
          className="icon-lg"
          style={{ cursor: "pointer" }}
          onClick={() => handleShowWalletId(item.belongsTo._id)}
        />
      ),
    },
  ];
  const [sizePerPage, setSizePerPage] = useState(10);

  const initFilteredValues =  {
    amount: "",
    freezeAmount: "",
    pending: "",
    walletId: "",
    customerId: "",
    filterDate: {
      fromDate: "",
      toDate: "",
    },
  };

  const [filteredValues, setFilteredValues] = useState(initFilteredValues);
  const filterChangeHandler = (filteredValuesData) => {
    setFilteredValues(filteredValuesData);
  };


  useEffect(() => {
    loadIbs(1, sizePerPage);
  }, [
    
    // props.withdrawalAddLoading,
    sizePerPage,
    1,
    filteredValues,
  ]);

  const loadIbs = async (page, limit) => {
    setLoading(true);
    try {
      const response = await getIbWallets({
        payload: {
          page,
          limit,
          filteredValues
        }
      });
      if (response.status) {
        const { result } = response;
        console.log(result);
        setWallets(result.docs);
        setPagination(result);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <React.Fragment>
      <div className="page-content">  
        <MetaTags>
          <title>
            IB Wallets
          </title>
        </MetaTags>
        <div className="container-fluid">
          <h2>IB Wallets</h2>
          <Row>
            <Col className="col-12">
              <Card>
                <CardHeader className="d-flex justify-content-between  align-items-center">
                  <CardTitle className="color-primary">IB Wallets ({pagination.totalDocs})
                    <FeatherIcon
                      icon="refresh-cw"
                      className="icon-lg ms-2"
                      style={{ cursor: "pointer" }}
                      onClick={() => { loadIbs(1, sizePerPage) }}
                    />
                  </CardTitle>
                  <div className="d-flex gap-2">
                    {
                      ibWalletPermissions.update ? <Button color="primary" onClick={() => handleGlobalIbCOnfigurationModal()}>
                        {props.t("Configuration")}
                      </Button> : ""
                    }
                    <IbWalletFilter filterChangeHandler={filterChangeHandler} filteredValues={filteredValues} />
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table  table-hover "
                      >
                        <Thead className="text-center table-light" >
                          <Tr>
                            {columns.map((column, index) =>
                              <Th data-priority={index} key={index}><span className="color-primary">{column.text}</span></Th>
                            )}
                          </Tr>
                        </Thead>
                        <Tbody className="text-center" style={{
                          fontSize: "12px",
                          textAlign: "center",
                          textTransform: "capitalize"
                        }}>
                          {loading && <TableLoader colSpan={4} />}
                          {!loading && wallets.map((row, rowIndex) =>
                            <Tr key={rowIndex}>
                              {columns.map((column, index) =>
                                <Td key={`${rowIndex}-${index}`}>
                                  {column.formatter ? column.formatter(row, rowIndex) : row[column.dataField]}
                                </Td>
                              )}
                            </Tr>
                          )}
                        </Tbody>
                      </Table>
                      <CustomPagination
                        {...pagination}
                        setSizePerPage={setSizePerPage}
                        sizePerPage={sizePerPage}
                        onChange={loadIbs}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        {convertModal && <ConvertModal
          open={convertModal}
          wallet={selectedWallet}
          onClose={() => {
            setConvertModal(false);
            loadIbs(1, sizePerPage);
          }}
        />}
        {specificIBDelayConfigurationModal && <SpecificIBDelayConfiguration
          open={specificIBDelayConfigurationModal}
          wallet={selectedWallet}
          onClose={() => {
            setSpecificIBDelayConfigurationModal(false);
            loadIbs(1, sizePerPage);
          }}
        />}
        {globalIbCOnfigurationModal && <GeneralIBDelayConfiguration
          open={globalIbCOnfigurationModal}
          onClose={() => {
            setGlobalIbCOnfigurationModal(false);
            // loadIbs(1, sizePerPage);
          }}
        />}
        {changeCommisionModal && <ChangeCommisionModal
          open={changeCommisionModal}
          wallet={selectedWallet}
          onClose={() => {
            setChangeCommisionModal(false);
            loadIbs(1, sizePerPage);
          }}
        />}
        {walletIdModal && (
          <IbStatementModal
            open={walletIdModal}
            onClose={() => {
              setWalletIdModal(false);
            }}
            customerId={selectedWalletId}
          />
        )}
      </div>
    </React.Fragment>
  );
}

function GeneralIBDelayConfiguration({ open, onClose }) {
  const [loading, setLoading] = useState(false);
  const [generalRebateConfigurationHour, setGeneralRebateConfigurationHour] = useState("0");

  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await changeGeneralRebeateConfigurationHour({
        generalRebateConfigurationHour,
      });
    } catch (error) {
      dispatch(showErrorNotification(error.message));
      console.error("Error converting wallet:", error);
    } finally {
      setLoading(false);
      getConfiguration();
    }
  };
  
  const getConfiguration = async () => {
    setLoading(true);
    try {
      const response = await getGeneralRebeateConfigurationHour();
      setGeneralRebateConfigurationHour(response?.result);
    } catch (error) {
      dispatch(showErrorNotification(error.message));
      console.error("Error converting wallet:", error);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(async() =>{
    await getConfiguration();
  }, []);

  return (
    <Modal isOpen={open} toggle={onClose}>
      <ModalHeader toggle={onClose}>Change General Rebate Configuration Hour</ModalHeader>
      <ModalBody>
        <AvForm onValidSubmit={handleSubmit}>
          <AvField
            name="generalRebateConfigurationHour"
            label="General Rebate Configuration Hour"
            type="number"
            value={generalRebateConfigurationHour}
            onChange={(e) => setGeneralRebateConfigurationHour(e.target.value)}
            validate={{
              required: {
                value: true,
                errorMessage: "Please enter an General Rebate Configuration Hour"
              },
              min: {
                value: 0,
                errorMessage: "General Rebate Configuration Hour must be greater than 0"
              },
              // max: {
              //   value: convertType === "pending" ? wallet?.pending : wallet?.amount,
              //   errorMessage: `Amount exceeds available ${convertType === "pending" ? "pending" : "available"} balance`
              // }
            }}
          />
          <Button color="primary" type="submit" disabled={loading}>
            {loading ? "Change..." : "Change"}
          </Button>
        </AvForm>
      </ModalBody>
    </Modal>
  );
}

function SpecificIBDelayConfiguration({ open, wallet, onClose }) {
  const [loading, setLoading] = useState(false);
  const [schedule, setSchedule] = useState("1");

  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await updateIbWalletschedule({
        schedule,
        walletId: wallet?._id,
      });
      onClose();
    } catch (error) {
      dispatch(showErrorNotification(error.message));
      console.error("Error converting wallet:", error);
    } finally {
      setLoading(false);
    }
  };
  
  // const getConfiguration = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await getGeneralRebeateConfigurationHour();
  //     // setGeneralRebateConfigurationHour(response?.data?.generalRebateConfigurationHour);
  //     // onClose();
  //   } catch (error) {
  //     dispatch(showErrorNotification(error.message));
  //     console.error("Error converting wallet:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  
  useEffect(() =>{
    setSchedule(wallet?.schedule);
  }, [wallet]);

  return (
    <Modal isOpen={open} toggle={onClose}>
      <ModalHeader toggle={onClose}>Change Wallet Rebate Configuration Hour</ModalHeader>
      <ModalBody>
        <AvForm onValidSubmit={handleSubmit}>
          <AvField
            name="schedule"
            label="Specific Rebate Configuration Hour"
            type="number"
            value={schedule}
            onChange={(e) => setSchedule(e.target.value)}
            validate={{
              required: {
                value: true,
                errorMessage: "Please enter an Specific Rebate Configuration Hour"
              },
              min: {
                value: 0,
                errorMessage: "Specific Rebate Configuration Hour must be greater than 0"
              },
              // max: {
              //   value: convertType === "pending" ? wallet?.pending : wallet?.amount,
              //   errorMessage: `Amount exceeds available ${convertType === "pending" ? "pending" : "available"} balance`
              // }
            }}
          />
          <Button color="primary" type="submit" disabled={loading}>
            {loading ? "Change..." : "Change"}
          </Button>
        </AvForm>
      </ModalBody>
    </Modal>
  );
}

function ConvertModal({ open, wallet, onClose }) {
  const [loading, setLoading] = useState(false);
  const [convertType, setConvertType] = useState("pending");
  const [amount, setAmount] = useState("");

  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await convertRebate({
        walletId: wallet._id,
        amount: amount,
        type: convertType,
      });
      onClose();
    } catch (error) {
      dispatch(showErrorNotification(error.message));
      console.error("Error converting wallet:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={open} toggle={onClose}>
      <ModalHeader toggle={onClose}>Convert Wallet</ModalHeader>
      <ModalBody>
        <AvForm onValidSubmit={handleSubmit}>
          <AvField
            name="walletId"
            type="hidden"
            value={wallet._id}
          />
          <AvField
            name="pendingBalance"
            label="Pending Balance"
            type="number"
            value={wallet?.pending || "0"}
            disabled
          />
          <AvField
            name="availableBalance"
            label="Available Balance"
            type="number"
            value={wallet?.amount || "0"}
            disabled
          />
          <div className="d-flex gap-3">
            <Label>Convert Type</Label>
            <AvRadioGroup
              name="convertType"
              label=""
              value={convertType}
              onChange={(e) => setConvertType(e.target.value)}
              required
            >
              <AvRadio label="Convert Pending to Available" value="pending" />
              <AvRadio label="Convert Available to Pending" value="useable" />
              <AvRadio label="Forfeit Pending Commission" value="forfeit-pending" />
              <AvRadio label="Forfeit Available Balance" value="forfeit-available" />
            </AvRadioGroup>
          </div>
          <AvField
            name="amount"
            label="Amount to Convert/Forfeit"
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            validate={{
              required: {
                value: true,
                errorMessage: "Please enter an amount"
              },
              min: {
                value: 0,
                errorMessage: "Amount must be greater than 0"
              },
              max: {
                value: convertType === "pending" ? wallet?.pending : wallet?.amount,
                errorMessage: `Amount exceeds available ${convertType === "pending" ? "pending" : "available"} balance`
              }
            }}
          />
          <Button color="primary" type="submit" disabled={loading}>
            {loading ? "Converting..." : "Convert"}
          </Button>
        </AvForm>
      </ModalBody>
    </Modal>
  );
}

function ChangeCommisionModal({ open, wallet, onClose }) {
  const [loading, setLoading] = useState(false);
  const [commisionAddingField, setCommisionAddingField] = useState("pending");

  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await changeCommisionAddingField({
        walletId: wallet._id,
        commisionAddingField,
      });
      onClose();
    } catch (error) {
      dispatch(showErrorNotification(error.message));
      console.error("Error converting wallet:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    setCommisionAddingField(wallet?.commisionAddingField || "amount");
  }, [wallet]);

  return (
    <Modal isOpen={open} toggle={onClose}>
      <ModalHeader toggle={onClose}>Change Commision Status</ModalHeader>
      <ModalBody>
        <AvForm onValidSubmit={handleSubmit}>
          
          <div className="d-flex gap-3">
            <Label>Commision Status</Label>
            <AvRadioGroup
              name="commisionAddingField"
              label=""
              value={commisionAddingField}
              onChange={(e) => setCommisionAddingField(e.target.value)}
              required
            >
              <AvRadio label="Amount" value="amount" />
              <AvRadio label="Pending Amount" value="pendingAmount" />
            </AvRadioGroup>
          </div>
          <Button color="primary" type="submit" disabled={loading}>
            {loading ? "Changeing..." : "Change"}
          </Button>
        </AvForm>
      </ModalBody>
    </Modal>
  );
}

function IbStatementModal({ open, onClose, customerId }) {
  const { t } = useTranslation();
  const [sizePerPage, setSizePerPage] = useState(10);
  const [dateFrom, setDateFrom] = useState(String(moment().subtract(30, "days").format("YYYY-MM-DD")));
  const [dateTo, setDateTo] = useState(String(moment().add(1, "day").format("YYYY-MM-DD")));
  const [selectedPlatform, setSelectedPlatform] = useState("MT5");
  const [statement, setStatement] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentLogin, setCurrentLogin] = useState(null);

  const columns = [
    {
      dataField: "name",
      text: t("Name"),
      formatter: (obj) => {
        return (
          <Link href={`/clients/${(obj.client || {})._id}/profile`}>
            <strong className="text-capitalize">{`${(obj.client || {}).firstName} ${(obj.client || {}).lastName}`}</strong>
          </Link>
        );
      }
    },
    {
      dataField: "clientLogin",
      text: t("Client Login"),
      formatter: (obj) => {
        return (
          <div onClick={() => setCurrentLogin(obj.clientLogin)}>
            <strong className="text-capitalize">{obj.clientLogin}</strong>
          </div>
        );
      }
    },
    // {
    //   dataField: "lotsOpened",
    //   text: t("Lots Opened"),
    //   formatter: (val) => (val ? (parseFloat(val.lotsOpened) / 10000).toFixed(2) : "-"),
    // },
    {
      dataField: "commission",
      text: t("Commission"),
      formatter: (val) => (val ? parseFloat(val.commission)?.toFixed(2) : "-"),
    },
    {
      dataField: "lotsClosed",
      text: t("Lots Closed"),
      formatter: (val) => (val ? (parseFloat(val.lotsClosed) / 10000).toFixed(2) : "-"),
    },
    {
      dataField: "rebate",
      text: t("Rebate"),
      formatter: (val) => parseFloat(val.rebate)?.toFixed(2),
    },
    // {
    //   dataField: "profitShare",
    //   text: t("Profit Share"),
    //   formatter: (val) => parseFloat(val.profitShare)?.toFixed(2),
    // },
  ];

  const handleDateFrom = (e) => setDateFrom(e.target.value);
  const handleDateTo = (e) => setDateTo(e.target.value);

  const platformOptions = [
    {
      label: t("MT5"),
      value: "MT5",
    },
    {
      label: t("MT4"),
      value: "MT4",
    },
  ];

  const loadStatement = async (page = 1, limit = 10) => {
    setLoading(true);
    try {
      const response = await getStatement({
        payload: {
          limit,
          page,
          platform: selectedPlatform,
          customerId,
          dateFrom: dateFrom,
          dateTo: dateTo,
        }
      });
      if (response.status) {
        setStatement(response.result);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadStatement(1, sizePerPage);
  }, [sizePerPage, selectedPlatform]);

  return (
    <Modal isOpen={open} toggle={onClose} size="xl">
      <ModalHeader toggle={onClose}>IB Statement</ModalHeader>
      <ModalBody className="px-4">
        <AvForm onValidSubmit={(e, data) => loadStatement(1, sizePerPage)}>
          <Row className="mt-3 align-items-center px-2">
            <Col xs="12" md="3" lg="2">
              <Label>{t("Platform")}</Label>
              <AvFieldSelect
                className="mt-1 form-select" 
                name="platform"
                errorMessage={t("Platform is required")}
                validate={{ required: { value: true } }}
                options={platformOptions}
                onChange={(e) => setSelectedPlatform(e)}
              />
            </Col>
            <Col xs="12" md="3" lg="2">
              <AvField 
                className="mt-1 mb-2"
                type="date" 
                name="dateFrom" 
                label={t("From Date")}
                value={dateFrom}
                validate={{ date: { format: "YYYY-MM-DD" } }} 
                onChange={handleDateFrom}
              />
            </Col>
            <Col xs="12" md="3" lg="2">
              <AvField 
                className="mt-1 mb-2"
                type="date" 
                name="dateTo" 
                label={t("To Date")}
                validate={{ date: { format: "YYYY-MM-DD" } }} 
                value={dateTo}
                onChange={handleDateTo}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3 w-100 d-flex justify-content-center">
                <Button
                  color="primary"
                  className="btn-light "
                  disabled={loading}
                >
                  {loading ? <Loader/> : t("Search")}
                </Button>
              </div>
            </Col>
          </Row>
        </AvForm>
        <hr className="my-4" />
        <div className="table-rep-plugin">
          <div className="table-responsive mb-0" data-pattern="priority-columns">
            <Table id="tech-companies-1" className="table table-hover table-clients">
              <Thead className="text-center table-light">
                <Tr>
                  {columns.map((column, index) => (
                    <Th data-priority={index} key={index}>
                      <span className="color-primary">{column.text}</span>
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody className="text-center" style={{ fontSize: "13px" }}>
                {loading && <TableLoader colSpan={columns.length} />}
                {!loading && statement && statement.docs.map((row, rowIndex) => (
                  <Tr key={rowIndex}>
                    {columns.map((column, index) => (
                      <Td key={`${rowIndex}-${index}`}>
                        {column.formatter ? column.formatter(row, rowIndex) : row[column.dataField]}
                      </Td>
                    ))}
                  </Tr>
                ))}
              </Tbody>
            </Table>
            {!loading && statement && (
              <div className="d-flex justify-content-between align-items-center">
                {/* Add summary information here */}
              </div>
            )}
            <CustomPagination
              {...statement}
              setSizePerPage={setSizePerPage}
              sizePerPage={sizePerPage}
              onChange={loadStatement}
              docs={statement && statement.docs || []}
            />
          </div>
        </div>
        <hr className="my-4" />
        <StatementDeals
          dateFrom={dateFrom}
          dateTo={dateTo}
          currentLogin={currentLogin}
          selectedPlatform={selectedPlatform}
        />
      </ModalBody>
    </Modal>
  );
}

export default (withTranslation()(IbWallet));