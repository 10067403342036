import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
} from "reactstrap";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

// i18n
import { withTranslation } from "react-i18next";
import CustomPagination from "components/Common/CustomPagination";
import SearchBar from "components/Common/SearchBar";
import FeatherIcon from "feather-icons-react";
import TableLoader from "components/Common/Loader";
import { getAudits } from "apis/audit";
import formatDate from "helpers/formatDate";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import AuditFlow from "./AuditFlow";

function AuditList(props) {
  const [sizePerPage, setSizePerPage] = useState(50);
  const [searchInputText, setSearchInputText] = useState("");
  const [loading, setLoading] = useState(false);
  const [audits, setAudits] = useState([]);
  const [pagination, setPagination] = useState({});
  const [selectedAudit, setSelectedAudit] = useState(null);

  const columns = [
    {
      dataField: "createdAt",
      text: props.t("Timestamp"),
      formatter: (v) => {
        return formatDate(v.createdAt);
      }
    },
    {
      dataField: "sumsubId",
      text: props.t("Sumsub ID"),
      formatter: (v) => {
        return <a href={`https://cockpit.sumsub.com/checkus#/applicant/${v.applicantId}/client/basicInfo`} target="_blank" rel="noreferrer">{v.applicantId}</a>;
      }
    },
    {
      dataField: "customer",
      text: props.t("Customer"),
      formatter: ({ customerId: client = null }) => (
        client ? <div>
          <Link
            to={{
              pathname: "/clients/" + client._id + "/profile",
              state: { clientId: client._id },
            }}
          >
            <strong className="text-capitalize">{`${client.firstName}  ${
              client.lastName ? client.lastName : ""
            }`}</strong>
          </Link>
        </div> : "-"
      ),
    },
    {
      dataField: "details",
      text: props.t("Details"),
      formatter: (val) => (
        <div>
          {val.updates && val.updates.length > 0
          && val?.customerId && (
            <FeatherIcon
              icon="eye"
              className="icon-sm ms-2"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSelectedAudit(val);
              }}
            />
          )}
        </div>
      ),
    },
    {
      dataField: "status",
      text: props.t("Status"),
    },
    {
      dataField: "lastUpdate",
      text: props.t("Last Update"),
      formatter: (val) => {
        return (
          <div>
            {formatDate(val.updatedAt)}
          </div>
        );
      }
    },
  ];

  useEffect(() => {
    loadAudits(1, sizePerPage);
  }, [sizePerPage, searchInputText]);

  const loadAudits = async (page, limit) => {
    setLoading(true);
    try {
      const {
        result,
      } = await getAudits({
        payload: {
          page,
          limit,
        }
      });
      const { docs, ...pagination } = result;
      setAudits(docs);
      setPagination(pagination);
    } catch (error) {
      console.error("Error fetching audits:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchInput = (e) => setSearchInputText(e.target.value);


  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <h2>{props.t("KYC Audits")}</h2>
          <Row>
            <Col className="col-12">
              <Card>
                <CardHeader className="d-flex flex-column gap-3">
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <CardTitle className="color-primary">
                      <h5>{props.t("KYC Audits")} ({pagination.totalDocs}) 
                        <FeatherIcon
                          icon="refresh-cw"
                          className="icon-lg ms-2"
                          style={{ cursor: "pointer" }}
                          onClick={() => { loadAudits(1, sizePerPage) }}
                        />
                      </h5>
                    </CardTitle>
                  </div>
                  <div className="d-flex flex-column flex-md-row gap-2 align-items-center justify-content-between">
                    <SearchBar handleSearchInput={handleSearchInput} />
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="table-rep-plugin">
                    <div className="table-responsive mb-0" data-pattern="priority-columns">
                      <Table id="tech-companies-1" className="table table-hover table-striped">
                        <Thead className="text-center table-light">
                          <Tr>
                            {columns.map((column, index) => (
                              <Th data-priority={index} key={index}>
                                <span className="color-primary">{column.text}</span>
                              </Th>
                            ))}
                          </Tr>
                        </Thead>
                        <Tbody className="text-center">
                          {loading && <TableLoader colSpan={4} />}
                          {!loading && audits.map((row, rowIndex) => (
                            <Tr key={rowIndex}>
                              {columns.map((column, index) => (
                                <Td key={`${rowIndex}-${index}`}>
                                  {column.formatter ? column.formatter(row, rowIndex) : row[column.dataField]}
                                </Td>
                              ))}
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                      <CustomPagination
                        {...props}
                        {...pagination}
                        setSizePerPage={setSizePerPage}
                        sizePerPage={sizePerPage}
                        onChange={loadAudits}
                        docs={audits}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      {selectedAudit && (
        <AuditFlow customerId={selectedAudit?.customerId?._id} toggleModal={() => {
          loadAudits(1, sizePerPage);
          setSelectedAudit(null);
        }} />
      )}
    </React.Fragment>
  );
}

export default withTranslation()(AuditList);
