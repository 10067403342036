// fetch REBATE_RULES
export const FETCH_REBATE_RULES_REQUESTED = "FETCH_REBATE_RULES_REQUESTED";
export const FETCH_REBATE_RULES_SUCCESS = "FETCH_REBATE_RULES_SUCCESS";
export const FETCH_REBATE_RULES_FAIL = "FETCH_REBATE_RULES_FAIL";
// sync REBATE_RULES
export const SYNC_SYMBOL_REQUESTED = "SYNC_SYMBOL_REQUESTED";
export const SYNC_SYMBOL_SUCCESS = "SYNC_SYMBOL_SUCCESS";
export const SYNC_SYMBOL_FAIL = "SYNC_SYMBOL_FAIL";

export const ADD_REBATE_RULE_REQUESTED = "ADD_REBATE_RULE_REQUESTED";
export const ADD_REBATE_RULE_SUCCESS = "ADD_REBATE_RULE_SUCCESS";
export const ADD_REBATE_RULE_FAIL = "ADD_REBATE_RULE_FAIL";
export const ADD_REBATE_RULE_CLEAR = "ADD_REBATE_RULE_CLEAR";
export const ADD_REBATE_RULE_ERROR_CLEAR = "ADD_REBATE_RULE_ERROR_CLEAR";

export const DELETE_REBATE_RULE_START = "DELETE_REBATE_RULE_START";
export const DELETE_REBATE_RULE_END = "DELETE_REBATE_RULE_END";

export const EDIT_REBATE_RULES_START = "EDIT_REBATE_RULES_START";
export const EDIT_REBATE_RULES_DONE = "EDIT_REBATE_RULES_DONE";
export const EDIT_REBATE_RULES_CLEAR = "EDIT_REBATE_RULES_CLEAR";

export const CHANGE_REBATE_RULES_START = "CHANGE_REBATE_RULES_START";
export const CHANGE_REBATE_RULES_END = "CHANGE_REBATE_RULES_END";

export const APPROVE_INTERNAL_TRANSFER = "APPROVE_INTERNAL_TRANSFER";
export const APPROVE_INTERNAL_TRANSFER_SUCCESS = "APPROVE_INTERNAL_TRANSFER_SUCCESS";
export const REJECT_INTERNAL_TRANSFER = "REJECT_INTERNAL_TRANSFER";
export const REJECT_INTERNAL_TRANSFER_SUCCESS = "REJECT_INTERNAL_TRANSFER_SUCCESS";