// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   Modal,
//   ModalHeader,
//   ModalBody,
//   Input,
//   Label,
//   Spinner,
//   Button,
//   FormGroup,
// } from "reactstrap";
// import { editClientDetails } from "store/client/actions";
// import { AvForm } from "availity-reactstrap-validation";

// const TYPES = ["VU", "MU"];

// function PortalAccess(props) {
//   const [type, setType] = useState();
//   const [open, setOpen] = useState(false);
//   const [addModal, setAddUserModal] = useState(false);
//   const [deactivating, setDeactivating] = useState(
//     !props.clientDetails?.isActive
//   ); // Track deactivation state
//   const [deactivationReason, setDeactivationReason] = useState(""); // State for reason
//   const { editSuccess, updating } = useSelector((state) => state.clientReducer);
//   const clientDetails = props.clientDetails;
//   const clientId = props.clientId;
//   const dispatch = useDispatch();

//   const toggleModal = () => {
//     setOpen(!open);
//   };

//   const toggleAddModal = () => {
//     setAddUserModal(!addModal);
//   };

//   useEffect(() => {
//     if (addModal) {
//       setAddUserModal(false);
//     }
//   }, [editSuccess]);

//   useEffect(() => {
//     if (open && editSuccess) {
//       setTimeout(() => {
//         toggleModal();
//       }, 800);
//     }
//   }, [editSuccess]);

//   // Reformat date for end-user
//   function formatDate(dateString) {
//     const date = new Date(dateString);
//     const options = {
//       year: "numeric",
//       month: "long",
//       day: "numeric",
//       hour: "2-digit",
//       minute: "2-digit",
      
//     };
//     return date.toLocaleDateString(undefined, options);
//   }
//   const handleAddClient = (event, values) => {
//     event.preventDefault();
//     dispatch(
//       editClientDetails({
//         values,
//         id: clientId,
//       })
//     );
//   };

//   const handleConfirmDeactivation = () => {
//     dispatch(
//       editClientDetails({
//         values: {
//           isActive: false,
//           deactivationReason,
//         },
//         id: clientId,
//       })
//     );
//     setDeactivating(false);
//     setDeactivationReason(""); // Reset the reason
//   };

//   const handleCheckboxChange = () => {
//     if (clientDetails?.isActive) {
//       // Client is active, set deactivating state to show textarea
//       setDeactivating(true);
//     } else {
//       // Client is inactive, reactivate and hide textarea
//       dispatch(
//         editClientDetails({
//           values: {
//             isActive: true,
//             deactivationReason: "",
//             passwordRongCount: 0,
//             blockedDate: new Date(),
//           },
//           id: clientId,
//         })
//       );
//       setDeactivating(false);
//     }
//   };

//   return (
//     <React.Fragment>
//       <button
//         type="button"
//         className="btn btn-primary waves-effect waves-light w-100 me-1"
//         onClick={toggleModal}
//       >
//         Portal Access
//       </button>
//       <Modal isOpen={open} toggle={toggleModal} centered={true}>
//         <ModalHeader toggle={toggleModal}>Portal Access</ModalHeader>
//         <ModalBody>
//           <div className="d-flex gap-3 d-flex flex-column align-items-center">
//             <h3>
//               {clientDetails?.isActive
//                 ? "Client is active"
//                 : "Client is not active"}
//             </h3>

//             {updating ? (
//               <Spinner className="ms-2"></Spinner>
//             ) : (
//               <>
//                 <Input
//                   type="checkbox"
//                   id={props.clientDetails?._id}
//                   switch="none"
//                   checked={clientDetails?.isActive ? true : false}
//                   onChange={handleCheckboxChange}
//                 />
//                 <Label
//                   className="me-1"
//                   htmlFor={props.clientDetails?._id}
//                   data-on-label=""
//                   data-off-label=""
//                 ></Label>

//                 {/* If deactivating, show textarea for reason */}
//                 {deactivating && (
//                   <FormGroup>
//                     <Label for="deactivationReason">
//                       Reason for deactivation
//                     </Label>
//                     <textarea
//                       id="deactivationReason"
//                       value={deactivationReason}
//                       onChange={(e) => setDeactivationReason(e.target.value)}
//                       placeholder="Enter reason for deactivation"
//                       style={{
//                         width: "100%",
//                         minHeight: "120px",
//                         padding: "12px",
//                         border: "1px solid #ced4da",
//                         borderRadius: "8px",
//                         fontSize: "1rem",
//                         color: "#495057",
//                         lineHeight: "1.5",
//                         boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
//                       }}
//                     />
//                     <Button
//                       color="danger"
//                       className="mt-3"
//                       onClick={handleConfirmDeactivation}
//                       disabled={!deactivationReason}
//                     >
//                       Confirm Deactivation
//                     </Button>
//                   </FormGroup>
//                 )}

//                 {/* Show deactivation reason if client is not active */}
//                 {!clientDetails?.isActive &&
//                   clientDetails.deactivationReason && (
//                   <>
//                     <div
//                       style={{
//                         marginTop: "10px",
//                         backgroundColor: "#f8f9fa",
//                         padding: "10px",
//                         borderRadius: "8px",
//                         fontSize: "0.95rem",
//                         color: "#FF0000",
//                         textAlign: "center",
//                         boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
//                       }}
//                     >
//                       <strong>Deactivation Date: </strong>
//                       {formatDate(clientDetails.blockedDate)}
//                     </div>
//                     <div
//                       style={{
//                         marginTop: "10px",
//                         backgroundColor: "#f8f9fa",
//                         padding: "10px",
//                         borderRadius: "8px",
//                         fontSize: "0.95rem",
//                         color: "#333",
//                         textAlign: "center",
//                         boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
//                       }}
//                     >
//                       <strong>Deactivation Reason: </strong>
//                       {clientDetails.deactivationReason}
//                     </div>
//                   </>
//                 )}
//               </>
//             )}
//           </div>
//         </ModalBody>
//       </Modal>

//       <button
//         type="button"
//         className="btn btn-primary waves-effect waves-light w-100 me-1"
//         onClick={toggleAddModal}
//       >
//         Entity Type
//       </button>
//       <Modal isOpen={addModal} toggle={toggleAddModal} centered={true}>
//         <ModalHeader toggle={toggleAddModal}>Entity Type</ModalHeader>
//         <ModalBody>
//           <AvForm
//             className="p-4"
//             onValidSubmit={(e, v) => {
//               handleAddClient(e, v);
//             }}
//           >
//             {/* Entity Type form */}
//           </AvForm>
//         </ModalBody>
//       </Modal>
//     </React.Fragment>
//   );
// }

// export default PortalAccess;


import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Label,
  Spinner,
  Button,
  FormGroup,
} from "reactstrap";
import { editClientDetails } from "store/client/actions";
import { AvForm } from "availity-reactstrap-validation";
import moment from "moment";

const TYPES = ["VU", "MU"];

function PortalAccess(props) {
  const [type, setType] = useState();
  const [open, setOpen] = useState(false);
  const [addModal, setAddUserModal] = useState(false);
  const [deactivating, setDeactivating] = useState(
    !props.clientDetails?.isActive
  ); // Track deactivation state
  const [deactivationReason, setDeactivationReason] = useState(""); // State for reason
  const { editSuccess, updating } = useSelector((state) => state.clientReducer);
  const clientDetails = props.clientDetails;
  const clientId = props.clientId;
  const dispatch = useDispatch();

  const toggleModal = () => {
    setOpen(!open);
  };

  const toggleAddModal = () => {
    setAddUserModal(!addModal);
  };

  useEffect(() => {
    if (addModal) {
      setAddUserModal(false);
    }
  }, [editSuccess]);

  useEffect(() => {
    if (open && editSuccess) {
      setTimeout(() => {
        toggleModal();
      }, 800);
    }
  }, [editSuccess]);

  // Reformat date for end-user using Moment.js

  function formatDate(dateString) {
    return moment(dateString).format("MMMM Do YYYY, h:mm:ss a");
  }
  const handleAddClient = (event, values) => {
    event.preventDefault();
    dispatch(
      editClientDetails({
        values,
        id: clientId,
      })
    );
  };

  const handleConfirmDeactivation = () => {
    dispatch(
      editClientDetails({
        values: {
          isActive: false,
          deactivationReason,
        },
        id: clientId,
      })
    );
    setDeactivating(false);
    setDeactivationReason(""); // Reset the reason
  };

  const handleCheckboxChange = () => {
    if (clientDetails?.isActive) {
      // Client is active, set deactivating state to show textarea
      setDeactivating(true);
    } else {
      // Client is inactive, reactivate and hide textarea
      dispatch(
        editClientDetails({
          values: {
            isActive: true,
            deactivationReason: "",
            passwordRongCount: 0,
            blockedDate: new Date(),
          },
          id: clientId,
        })
      );
      setDeactivating(false);
    }
  };

  const blockedDate = moment(clientDetails.blockedDate).format(
    "MMMM Do YYYY, h:mm:ss a"
  );

  return (
    <React.Fragment>
      <button
        type="button"
        className="btn btn-primary waves-effect waves-light w-100 me-1"
        onClick={toggleModal}
      >
        Portal Access
      </button>
      <Modal isOpen={open} toggle={toggleModal} centered={true}>
        <ModalHeader toggle={toggleModal}>Portal Access</ModalHeader>
        <ModalBody>
          <div className="d-flex gap-3 d-flex flex-column align-items-center">
            <h3>
              {clientDetails?.isActive
                ? "Client is active"
                : "Client is not active"}
            </h3>

            {updating ? (
              <Spinner className="ms-2"></Spinner>
            ) : (
              <>
                <Input
                  type="checkbox"
                  id={props.clientDetails?._id}
                  switch="none"
                  checked={clientDetails?.isActive ? true : false}
                  onChange={handleCheckboxChange}
                />
                <Label
                  className="me-1"
                  htmlFor={props.clientDetails?._id}
                  data-on-label=""
                  data-off-label=""
                ></Label>

                {/* If deactivating, show textarea for reason */}
                {deactivating && (
                  <FormGroup>
                    <Label for="deactivationReason">
                      Reason for deactivation
                    </Label>
                    <textarea
                      id="deactivationReason"
                      value={deactivationReason}
                      onChange={(e) => setDeactivationReason(e.target.value)}
                      placeholder="Enter reason for deactivation"
                      style={{
                        width: "100%",
                        minHeight: "120px",
                        padding: "12px",
                        border: "1px solid #ced4da",
                        borderRadius: "8px",
                        fontSize: "1rem",
                        color: "#495057",
                        lineHeight: "1.5",
                        boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
                      }}
                    />
                    <Button
                      color="danger"
                      className="mt-3"
                      onClick={handleConfirmDeactivation}
                      disabled={!deactivationReason}
                    >
                      Confirm Deactivation
                    </Button>
                  </FormGroup>
                )}

                {/* Show deactivation reason if client is not active */}
                {!clientDetails?.isActive &&
                  clientDetails.deactivationReason && (
                  <>
                    <div
                      style={{
                        marginTop: "10px",
                        backgroundColor: "#f8f9fa",
                        padding: "10px",
                        borderRadius: "8px",
                        fontSize: "0.95rem",
                        color: "#FF0000",
                        textAlign: "center",
                        boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <strong>Deactivation Date: </strong>
                      {blockedDate}
                    </div>
                    <div
                      style={{
                        marginTop: "10px",
                        backgroundColor: "#f8f9fa",
                        padding: "10px",
                        borderRadius: "8px",
                        fontSize: "0.95rem",
                        color: "#333",
                        textAlign: "center",
                        boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <strong>Deactivation Reason: </strong>
                      {clientDetails.deactivationReason}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </ModalBody>
      </Modal>

      {/* <button
        type="button"
        className="btn btn-primary waves-effect waves-light w-100 me-1"
        onClick={toggleAddModal}
      >
        Entity Type
      </button> */}
      <Modal isOpen={addModal} toggle={toggleAddModal} centered={true}>
        <ModalHeader toggle={toggleAddModal}>Entity Type</ModalHeader>
        <ModalBody>
          <AvForm
            className="p-4"
            onValidSubmit={(e, v) => {
              handleAddClient(e, v);
            }}
          >
            {/* Entity Type form */}
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

export default PortalAccess;