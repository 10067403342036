import React, { useState, useEffect } from "react";
import OrganizationChart from "@dabeng/react-orgchart";
import MyNode from "./node";
import "./orgChart.scss";
import NodeModal from "./nodeModal";

const CustomNodeChart = (props) => {
  const [nodes, setNodes] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedNode, setSelectedNode] = useState({});
  const [zoom, setZoom] = useState(1);

  const { referrals, clientId } = props;

  useEffect(()=>{
    setNodes(referrals);
  }, [referrals]);

  const selectNode = (node) => {
    setIsOpen(true);
    setSelectedNode(node);
  };

  const handleClose = () => {
    setIsOpen(false);
    setSelectedNode(null);
  };

  const zoomIn = () => {
    let newZoom = zoom + 0.1;
    if (zoom < 1.5) {
      let matrix = document.querySelector(".myChart").style.transform;
      let fields = matrix.split(",");
      let width = fields && fields[4] ? fields[4] : "0";
      let height = fields && fields[5] ? fields[5].replace(")", "") : "0";

      document.querySelector(".myChart").style.transform = `matrix(${newZoom},0,0,${newZoom},${width},${height})`;
      zoom < 1.5 && setZoom(newZoom);
    }
  };

  const zoomOut = () => {
    let newZoom = zoom - 0.1;
    if (zoom >= 0.4) {
      let matrix = document.querySelector(".myChart").style.transform;
      let fields = matrix.split(",");
      let width = fields && fields[4] ? fields[4] : "0";
      let height = fields && fields[5] ? fields[5].replace(")", "") : "0";

      document.querySelector(".myChart").style.transform = `matrix(${newZoom},0,0,${newZoom},${width},${height})`;
      setZoom(newZoom);
    }
  };


  if (!nodes)
    return <p className="text-center small m-0">No data found.</p>;

  return (
    <section className="orgChart-section">
      <NodeModal isOpen={isOpen} onClose={handleClose} clientId={clientId} data={selectedNode} />
      <div className="nodes-wrapper">
        <OrganizationChart
          onClickNode={selectNode}
          toggleSiblingsResp={false}
          verticalLevel={10}
          collapsable={false}
          pan
          zoom={false}
          datasource={nodes}
          chartClass="myChart"
          NodeTemplate={(props) => <MyNode {...props} id={clientId} onSelect={selectNode} />}
        />
      </div>
      {/* <div className="zoom-btns">
        <span>{parseInt(zoom * 100)}%</span>
        <button onClick={zoomIn}>+</button>
        <button onClick={zoomOut}>-</button>
      </div> */}
    </section>
  );
};

export default CustomNodeChart;
