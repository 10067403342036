import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal, Button,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import {
  AvForm, AvField, AvCheckboxGroup, AvCheckbox
} from "availity-reactstrap-validation";
import Loader from "components/Common/Loader";
import useModal from "hooks/useModal";
import AvFieldSelect from "components/Common/AvFieldSelect";
import validatePositiveInputs from "helpers/validatePositiveInputs";
import { addAccountType, fetchServerStart } from "store/actions";

const PLATFORMS = ["MT5", "MT4"];
const TYPES = ["LIVE", "DEMO", "IB", "MAM", "INVESTOR"];
const LEVERAGE = [1, 25, 50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 2000];
const CURRENCIES = ["USD", "EUR", "GBP", "EGP"];

function AddAccountGroupType(props) {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  console.log("FROM ACC TYPE: ", state);
  const [showModal, toggleModal] = useModal(false);
  const [leverageOptions, setLeverageOptions] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [type, setType] = useState();
  const [platform, setPlatform] = useState();
  const [chosenServers, setChosenServersServers] = useState([]);
  const [selectedServer, setSelectedServer] = useState(null);

  const [isDefault, setIsDefault] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsDefault(event.target.checked); // Convert the value to a boolean
  };

  const create = useSelector((state) => state.Profile?.AccTypesPermissions?.create);
  const { submitting, error } = useSelector((state) => state.tradingAccountReducer);

  useEffect(() => {
    (!submitting && !error && showModal) && toggleModal();
  }, [submitting]);
  useEffect(() => {
    dispatch(fetchServerStart());
  }, []);

  const servers = useSelector((state) => state?.MTServer?.servers);
  const selectedServers = () => {
    if (servers?.docs) {
      return servers?.docs.filter((server) => server.platform === props.type).map((server) => {
        return {
          label: server.name,
          value: server._id
        };
      });
    }
    return [];
  };
  useEffect(() => {
    const res = selectedServers(platform, type);
    setChosenServersServers(() => res);
  }, [platform, type]);

  const isMamPam = ["MAM", "INVESTOR"].includes(type);

  useEffect(() => {
    setSelectedServer(null);
  }, [type, platform]);

  const handleSubmit = (e, v) => {
    dispatch(addAccountType({
      ...v,
      forCrm: true,
      forCp: true,
      type: "LIVE",
      isDefault: isDefault
    }));
  };
  return (
    <>
      <Link
        to="#"
        className={`${!create ? "d-none" : ""}`}
        onClick={() => {
          setType(null);
          setPlatform(null);
          setLeverageOptions([]);
          setCurrencies([]);
          setSelectedServer(null);
          toggleModal();
        }}
      >
        <i className="mdi mdi-plus font-size-18" id="edittooltip"></i>
      </Link>
      <Modal isOpen={showModal} toggle={toggleModal} centered={true}>
        <ModalHeader toggle={toggleModal} tag="h4">
          Add New Type
        </ModalHeader>
        <ModalBody>
          <AvForm
            className="px-4"
            onValidSubmit={(e, v) => {
              console.log("SUBMIT: ", v);
              handleSubmit(e, {
                ...v,
                platform: props.type,
              });
            }}
          >
            <AvField
              name="title"
              label="Title"
              placeholder="Select Title"
              type="text"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Title is required",
                },
              }}
            />
            <AvFieldSelect
              name="serverId"
              label="Server"
              placeholder="Select Server"
              options={chosenServers.map((type) => ({
                value: type?.value,
                label: type?.label,
              }))}
              value={selectedServer || ""}
              onChange={(e) => setSelectedServer(e)}
              validate={{
                required: {
                  value: true,
                  errorMessage: "Server is required",
                },
              }}
            />
            <AvFieldSelect
              name="leverages"
              label="Leverage Options"
              placeholder="Select Leverage Options"
              ismulti="true"
              options={LEVERAGE.map((type) => ({
                value: type,
                label: type,
              }))}
              onChange={(e) => {
                setLeverageOptions(e);
              }}
              validate={{
                required: {
                  value: true,
                  errorMessage: "Leverage options is required",
                },
              }}
            />
            <>
              <AvCheckboxGroup inline name="isDefault">
                <AvCheckbox
                  label="Default"
                  value={isDefault}
                  onChange={handleCheckboxChange}
                  checked={isDefault} // Ensures correct state synchronization
                />
              </AvCheckboxGroup>
            </>
            <div className="text-center mt-3 p-2">
              {submitting ? (
                <Loader />
              ) : (
                <Button
                  disabled={props.addLoading}
                  type="submit"
                  color="primary"
                >
                  Add
                </Button>
              )}
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
    </>
  );
}

export default AddAccountGroupType;