import { getCRMDuplicates } from "apis/audit";
import React, { useEffect, useState } from "react";
import {
  Table,
  Spinner,
} from "reactstrap";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

function CRMDuplicates(props) {
  const { clientId } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [duplicates, setDuplicates] = useState([]);
  const [error, setError] = useState(null);
  
  const loadDuplicates = async () => {
    setIsLoading(true);
    try {
      const response = await getCRMDuplicates({ customerId: clientId });
      setError(null);
      setDuplicates(response.result);
    } catch (error) {
      setError(error?.message || "An error occurred while fetching duplicates.");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (clientId) {
      loadDuplicates();
    }
  }, [clientId]);
  
  if (isLoading) {
    return (
      <div className="text-center my-5">
        <Spinner color="primary" />
        <p className="mt-2">Loading duplicates...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-danger rounded p-3 my-3 d-flex align-items-center">
        <FeatherIcon icon="alert-triangle" className="me-3" size={24} />
        <div>
          <h6 className="mb-1 font-weight-bold">Error</h6>
          <p className="mb-0">{error}</p>
        </div>
      </div>
    );
  }

  if (duplicates.length === 0) {
    return (
      <div className="text-center my-3">
        <FeatherIcon icon="check-circle" size={48} className="text-success mb-3" />
        <p>No duplicates found</p>
      </div>
    );
  }

  return (
    <Table responsive bordered striped hover className="mt-3">
      <thead>
        <tr>
          <th>CRM Id</th>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Email</th>
          <th>DOB</th>
          <th>Document Number</th>
        </tr>
      </thead>
      <tbody>
        {duplicates.map((duplicate, index) => (
          <tr key={index}>
            <td><Link to={`/clients/${duplicate._id}/profile`}>{duplicate._id}</Link></td>
            <td>{duplicate.firstName}</td>
            <td>{duplicate.lastName}</td>
            <td>{duplicate.email}</td>
            <td>{duplicate.dob}</td>
            <td>{duplicate.documentNo}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default CRMDuplicates;