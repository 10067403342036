import {
  useDispatch, connect, useSelector
} from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledAlert,
  Label,
  Input,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { addNewItem } from "store/dictionary/actions";
import CustomCreatableSelect from "components/Common/CustomCreatableSelect";
import SearchableSymbolGroupsDropdown from "./SearchableComponents/SearchableSymbolGroupsDropdown";
import AvFieldSelect from "components/Common/AvFieldSelect";
import { v4 as uuidv4 } from "uuid";
import {
  fetchAccountTypes, fetchServerStart, showErrorNotification, showSuccessNotification 
} from "store/actions";
import ReactSelect from "react-select";
import { fetchMtGroups } from "store/rebateSettings/groups/actions";
import { addPromoStart, fetchPromoStart } from "store/promo/actions";
import { addPromolink } from "apis/promolink";

// make beautify error message for validation under the field
const ErrorMessage = ({ message }) => (
  <div style={{
    color: "#dc3545",
    fontSize: "12px",
    marginTop: "4px",
    marginLeft: "2px"
  }} className="error">{message}</div>
);

function PromotionLinksAdd(props) {
  const ALL_PAGES = 15;
  const [addModal, setAddModal] = useState(false);
  const [selectedMTGroups, setSelectedMTGroups] = useState([]);
  const [selectedDefaultMTGroup, setSelectedDefaultMTGroup] = useState(null);
  const [mtgroupssOptions, setMtgroupssOptions] = useState([]);
  const [isApplicableToAm, setIsApplicableToAm] = useState(false);
  const [isDefault, setIsDefault] = useState(false);
  const [isMustBeBound, setIsMustBeBound] = useState(false);
  const [status, setStatus] = useState("Enabled");
  const { isLoading, setIsLoading } = props;
  const [selectedServer, setSelectedServer] = useState(null);
  const [selectedAccountType, setSelectedAccountType] = useState(null);
  const [titleError, setTitleError] = useState("");
  const [mtServerError, setMTServerError] = useState("");
  const [mtgroupsError, setMtgroupsError] = useState("");
  const [defaultMtGroupError, setDefaultMtGroupError] = useState("");
  const [accountTypeError, setAccountTypeError] = useState("");
  const dispatch = props?.dispatch || useDispatch();
  const toggleAddModal = () => {
    setAddModal(preValue => {
      if (preValue) { // if modal is being closed
        resetForm();
      }
      return !preValue;
    });
  };
  const { create } = props.dictionariesPermissions;

  useEffect(() => {
    loadServers(1, ALL_PAGES);
    loadAccountTypes();
    loadMTGroups();
    loadPromotionLinks(1, ALL_PAGES);
  }, [selectedAccountType]);

  useEffect(() => {
    loadPromotionLinks(1, ALL_PAGES);
  }, [dispatch, isLoading]);

  useEffect(() => {
    if (!props.showAddSuccessMessage && addModal) {
      setAddModal(false);
    }
  }, [props.showAddSuccessMessage]);


  useEffect(() => {
    if (props?.mtGroups) {
      const mtgroupssOptions = props?.mtGroups?.filter((group) => group.status === "Enabled")?.map((mtgroup) => {
        return {
          value: mtgroup?._id,
          label: mtgroup?.mtGroupName,
        };
      });
      setMtgroupssOptions(mtgroupssOptions);
    }
  }, [props.mtGroups]);


  // To be extracted and added to Util module later
  const generateRandomPromoCode = () => {
    return uuidv4().replace(/-/g, "").substring(0, 15);
  };


  const loadMTGroups = async (page = 1, limit = 100000) => {
    await dispatch(
      fetchMtGroups({
        limit,
        page,
        accountGroupType: selectedAccountType?.title
        // type: props?.type,
      })
    );
  };
  // To be extracted and added to api Calling module later
  const loadServers = async (page, limit) => {
    await dispatch(
      fetchServerStart({
        page,
        limit,
      })
    );
  };

  // To be extracted and added to api Calling module later
  const loadAccountTypes = async () => {
    await dispatch(fetchAccountTypes());
  };

  // To be extracted and added to api Calling module later
  const loadPromotionLinks = async (page, limit) => {
    await dispatch(fetchPromoStart());
  };

  // Generate options for MT Server
  const mtServerOptions = useSelector((state) => state?.MTServer?.servers?.docs.map((server) => ({
    label: server.name,
    value: server
  })));

  // Generate options for Account Type
  const accountTypeOptions = useSelector((state) =>
    state?.tradingAccountReducer?.accountTypes?.filter((type) => type.enabled && type.platform === selectedServer?.platform)?.map((accountType) => ({
      label: accountType.title,
      value: accountType,
    }))
  );

  // Reset dependent fields when MT Server changes
  const resetDependentFieldsForMTServer = () => {
    setSelectedAccountType(null);
    setSelectedMTGroups([]);
    setSelectedDefaultMTGroup(null);
    setAccountTypeError("");
    setMtgroupsError("");
    setDefaultMtGroupError("");
  };

  // Reset dependent fields when Account Type changes
  const resetDependentFieldsForAccountType = () => {
    setSelectedMTGroups([]);
    setSelectedDefaultMTGroup(null);
    setMtgroupsError("");
    setDefaultMtGroupError("");
  };

  // Reset form function
  const resetForm = () => {
    setSelectedServer(null);
    setSelectedAccountType(null);
    setSelectedMTGroups([]);
    setSelectedDefaultMTGroup(null);
    setTitleError("");
    setMTServerError("");
    setMtgroupsError("");
    setDefaultMtGroupError("");
    setAccountTypeError("");
    setIsApplicableToAm(false);
    setIsDefault(false);
    setIsMustBeBound(false);
    setStatus("Enabled");
  };

  useEffect(() => {
    if (!selectedServer) {
      resetDependentFieldsForMTServer();
    }
  }, [selectedServer]);

  useEffect(() => {
    if (!selectedAccountType) {
      resetDependentFieldsForAccountType();
    }
  }, [selectedAccountType]);

  // handle MT Groups change
  const handleMTGroupChange = (value) => {
    setSelectedMTGroups(value);
  };

  const validateForm = (formValues) => {
    let isValid = true;

    // Reset all error states first
    setTitleError("");
    setMTServerError("");
    setAccountTypeError("");
    setMtgroupsError("");
    setDefaultMtGroupError("");

    // Title validation
    if (!formValues.title || !formValues.title.trim()) {
      setTitleError("Title is required");
      isValid = false;
    }

    // MT Server validation
    if (!selectedServer || !selectedServer._id) {
      setMTServerError("MT Server is required");
      isValid = false;
    }

    // Account Type validation
    if (!selectedAccountType || !selectedAccountType._id) {
      setAccountTypeError("Account Type is required");
      isValid = false;
    }

    // MT Groups validation
    if (!selectedMTGroups || selectedMTGroups.length === 0) {
      setMtgroupsError("At least one MT Group is required");
      isValid = false;
    }

    // Default MT Group validation
    if (!selectedDefaultMTGroup || !selectedDefaultMTGroup.value) {
      setDefaultMtGroupError("Default MT Group is required");
      isValid = false;
    } else {
      // Validate that default MT group is one of the selected groups
      const isDefaultGroupInSelected = selectedMTGroups.some(
        group => group.value === selectedDefaultMTGroup.value
      );
      if (!isDefaultGroupInSelected) {
        setDefaultMtGroupError("Default MT Group must be one of the selected MT Groups");
        isValid = false;
      }
    }

    return isValid;
  };

  const handleAddPromoSubmit = async (e, v) => {
    e.preventDefault();

    const formData = {
      ...v,
      title: v.title.trim(),
      mtGroups: selectedMTGroups.map((group) => group.value),
      applicableToAm: isApplicableToAm,
      default: isDefault,
      mustBeBound: isMustBeBound,
      status: status,
      mtServer: selectedServer?._id,
      accountType: selectedAccountType?._id,
      defaultMTGroup: selectedDefaultMTGroup?.value
    };

    console.log("Form Data", formData);
    if (!validateForm(formData)) {
      return;
    }

    setIsLoading(true);

    try {
      const data = await addPromolink({ payload: { ...formData } });
      console.log(data);
      if (data.isError) {
        dispatch(showErrorNotification(data.message));
      } else {
        dispatch(showSuccessNotification("PromoLink added successfully"));
        resetForm();
        toggleAddModal();
      }
    } catch (error) {
      dispatch(showErrorNotification("PromoLink must be unique"));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Link
        to="#"
        className={`btn btn-primary ${!create ? "d-none" : ""}`}
        onClick={toggleAddModal}
      >
        <i className="bx bx-plus me-1"></i>
        {props.t("Add Promotion Link")}
      </Link>
      <Modal
        isOpen={addModal}
        toggle={toggleAddModal}
        centered={true}
        size="lg"
      >
        <ModalHeader toggle={toggleAddModal} tag="h4">
          {props.t("Add Promotion Link")}
        </ModalHeader>
        <ModalBody>
          <AvForm
            className="p-4"
            onValidSubmit={(e, v) => {
              handleAddPromoSubmit(e, v);
            }}
          >
            <div className="mb-3">
              <AvField
                name="title"
                label={props.t("Link Title")}
                placeholder={props.t("Enter Link Title")}
                type="text"
                onChange={(e) => {
                  if (!e.target.value.trim()) {
                    setTitleError("Title is required");
                  } else {
                    setTitleError("");
                  }
                }}
                validate={{ required: { value: true } }}
              />
              {titleError && <ErrorMessage message={titleError} />}
            </div>
            <div className="mb-3">
              <AvField
                name="promoCode"
                label={props.t("Promotion Code")}
                placeholder={props.t("Enter Promotion Code")}
                type="text"
                value={generateRandomPromoCode()}
                disabled={true}
                errorMessage={props.t("Enter valid Promotion Code")}
                validate={{ required: { value: true } }}
              />
            </div>

            <div className="my-3">
              <Label>{props.t("MT Server")}</Label>
              <ReactSelect
                name="mtServer"
                placeholder={props.t("Select MT Server")}
                options={mtServerOptions}
                value={selectedServer ? {
                  label: selectedServer.name,
                  value: selectedServer
                } : null}
                onChange={(e) => {
                  const newServer = e?.value;
                  if (selectedServer?.platform !== newServer?.platform) {
                    resetDependentFieldsForMTServer();
                  }
                  setSelectedServer(newServer);
                  if (!newServer) {
                    setMTServerError("MT Server is required");
                  } else {
                    setMTServerError("");
                  }
                }}
                styles={{
                  control: (base, state) => ({
                    ...base,
                    borderColor: mtServerError ? "#dc3545" : base.borderColor,
                    "&:hover": {
                      borderColor: mtServerError ? "#dc3545" : base.borderColor
                    }
                  })
                }}
              />
              {mtServerError && <ErrorMessage message={mtServerError} />}
            </div>
            <div className="my-3">
              <Label>{props.t("Account Type")}</Label>
              <ReactSelect
                name="accountType"
                placeholder={props.t("Select Account Type")}
                options={accountTypeOptions}
                value={selectedAccountType ? {
                  label: selectedAccountType.title,
                  value: selectedAccountType
                } : null}
                onChange={(e) => {
                  const newAccountType = e?.value;
                  if (selectedAccountType?.title !== newAccountType?.title) {
                    resetDependentFieldsForAccountType();
                  }
                  setSelectedAccountType(newAccountType);
                  if (!newAccountType) {
                    setAccountTypeError("Account Type is required");
                  } else {
                    setAccountTypeError("");
                  }
                }}
                styles={{
                  control: (base, state) => ({
                    ...base,
                    borderColor: accountTypeError ? "#dc3545" : base.borderColor,
                    "&:hover": {
                      borderColor: accountTypeError ? "#dc3545" : base.borderColor
                    }
                  })
                }}
                isDisabled={!selectedServer}
              />
              {accountTypeError && <ErrorMessage message={accountTypeError} />}
            </div>
            <div className="mb-3">
              <div className="d-felx justify-content-center align-items-center">
                <Label>{props.t("MT Group")}</Label>
                <ReactSelect
                  name="mtGroup"
                  placeholder={props.t("Select MT Groups")}
                  isMulti={true}
                  value={selectedMTGroups}
                  options={mtgroupssOptions}
                  onChange={(selected) => {
                    const newSelected = selected || [];
                    setSelectedMTGroups(newSelected);
                    // Reset default MT group if it's not in the selected groups anymore
                    if (selectedDefaultMTGroup && !newSelected.find(group => group.value === selectedDefaultMTGroup.value)) {
                      setSelectedDefaultMTGroup(null);
                    }
                    if (!newSelected.length) {
                      setMtgroupsError("At least one MT Group is required");
                    } else {
                      setMtgroupsError("");
                    }
                  }}
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      borderColor: mtgroupsError ? "#dc3545" : base.borderColor,
                      "&:hover": {
                        borderColor: mtgroupsError ? "#dc3545" : base.borderColor
                      }
                    })
                  }}
                  isDisabled={!selectedAccountType}
                />
                {mtgroupsError && <ErrorMessage message={mtgroupsError} />}
              </div>
            </div>
            <div className="my-3">
              <Label>{props.t("Default MT Group")}</Label>
              <ReactSelect
                name="defaultMTGroup"
                placeholder={props.t("Select Default MT Group")}
                options={selectedMTGroups}
                value={selectedDefaultMTGroup}
                onChange={(selected) => {
                  setSelectedDefaultMTGroup(selected);
                  if (!selected) {
                    setDefaultMtGroupError("Default MT Group is required");
                  } else {
                    setDefaultMtGroupError("");
                  }
                }}
                styles={{
                  control: (base, state) => ({
                    ...base,
                    borderColor: defaultMtGroupError ? "#dc3545" : base.borderColor,
                    "&:hover": {
                      borderColor: defaultMtGroupError ? "#dc3545" : base.borderColor
                    }
                  })
                }}
                isDisabled={!selectedMTGroups.length}
              />
              {defaultMtGroupError && <ErrorMessage message={defaultMtGroupError} />}
            </div>
            <div className="d-flex align-items-center" style={{ marginBottom: "1rem" }}>
              <h6 style={{
                margin: "0",
                marginRight: "1rem",
                width: "200px"
              }}>
                Is it applicable to AM
              </h6>
              <div className="d-flex align-items-center">
                <Input
                  type="radio"
                  id="applicableToAMYes"
                  name="applicableToAM"
                  value="yes"
                  style={{ marginRight: "0.5rem" }}
                  checked={isApplicableToAm === true}
                  onChange={() => setIsApplicableToAm(true)}
                />
                <Label style={{
                  marginRight: "0.5rem",
                  marginBottom: "0",
                  width: "50px"
                }} for="applicableToAMYes">
                  Yes
                </Label>
                <Input
                  type="radio"
                  id="applicableToAMNo"
                  name="applicableToAM"
                  value="no"
                  style={{ marginRight: "0.5rem" }}
                  checked={isApplicableToAm === false}
                  onChange={() => setIsApplicableToAm(false)}
                />
                <Label style={{
                  marginRight: "0.5rem",
                  marginBottom: "0"
                }} for="applicableToAMNo">
                  No
                </Label>
              </div>
            </div>

            <div className="d-flex align-items-center" style={{ marginBottom: "1rem" }}>
              <h6 style={{
                margin: "0",
                marginRight: "1rem",
                width: "200px"
              }}>
                Default
              </h6>
              <div className="d-flex align-items-center">
                <Input
                  type="radio"
                  id="defaultYes"
                  name="default"
                  value="yes"
                  style={{ marginRight: "0.5rem" }}
                  checked={isDefault === true}
                  onChange={() => setIsDefault(true)}
                />
                <Label style={{
                  marginRight: "0.5rem",
                  marginBottom: "0",
                  width: "50px"
                }} for="defaultYes">
                  Yes
                </Label>
                <Input
                  type="radio"
                  id="defaultNo"
                  name="default"
                  value="no"
                  style={{ marginRight: "0.5rem" }}
                  checked={isDefault === false}
                  onChange={() => setIsDefault(false)}
                />
                <Label style={{ marginBottom: "0" }} for="defaultNo">
                  No
                </Label>
              </div>
            </div>

            <div className="d-flex align-items-center" style={{ marginBottom: "1rem" }}>
              <h6 style={{
                margin: "0",
                marginRight: "1rem",
                width: "200px"
              }}>
                Must be bound
              </h6>
              <div className="d-flex align-items-center">
                <Input
                  type="radio"
                  id="mustBeBoundYes"
                  name="mustBeBound"
                  value="yes"
                  style={{ marginRight: "0.5rem" }}
                  checked={isMustBeBound === true}
                  onChange={() => setIsMustBeBound(true)}
                />
                <Label style={{
                  marginRight: "0.5rem",
                  marginBottom: "0",
                  width: "50px"
                }} for="mustBeBoundYes">
                  Yes
                </Label>
                <Input
                  type="radio"
                  id="mustBeBoundNo"
                  name="mustBeBound"
                  value="no"
                  style={{ marginRight: "0.5rem" }}
                  checked={isMustBeBound === false}
                  onChange={() => setIsMustBeBound(false)}
                />
                <Label style={{ marginBottom: "0" }} for="mustBeBoundNo">
                  No
                </Label>
              </div>
            </div>

            <div className="d-flex align-items-center" style={{ marginBottom: "1rem" }}>
              <h6 style={{
                margin: "0",
                marginRight: "1rem",
                width: "200px"
              }}>
                Status
              </h6>
              <div className="d-flex align-items-center">
                <Input
                  type="radio"
                  id="statusEnabled"
                  name="status"
                  value="enabled"
                  style={{ marginRight: "0.5rem" }}
                  checked={status === "Enabled"}
                  onChange={() => setStatus("Enabled")}
                />
                <Label style={{
                  marginRight: "0.5rem",
                  marginBottom: "0"
                }} for="statusEnabled">
                  Enabled
                </Label>
                <Input
                  type="radio"
                  id="statusDisabled"
                  name="status"
                  value="disabled"
                  style={{ marginRight: "0.5rem" }}
                  checked={status === "Disabled"}
                  onChange={() => setStatus("Disabled")}
                />
                <Label style={{ marginBottom: "0" }} for="statusDisabled">
                  Disabled
                </Label>
              </div>
            </div>

            <div className="text-center pt-3 p-2">
              <Button
                disabled={isLoading}
                type="submit"
                color="primary"
                className=""
              >
                {props.t("Add")}
              </Button>
            </div>
          </AvForm>
          {props.error && (
            <UncontrolledAlert color="danger">
              <i className="mdi mdi-block-helper me-2"></i>
              {props.editError}
            </UncontrolledAlert>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}
const mapStateToProps = (state) => ({
  id: state.dictionaryReducer.id,
  error: state.dictionaryReducer.error,
  showAddSuccessMessage: state.dictionaryReducer.showAddSuccessMessage,
  dictionariesPermissions: state.Profile.dictionariesPermissions || {},
  disableAddButton: state.dictionaryReducer.disableAddButton,
  mtGroups: state.groupReducer.mtGroups?.groups,
  promoLinks: state.Promo?.promos?.docs || [],
  isLoading: state.Promo?.promo?.fetchPromoLoading,
  promoError: state.Promo?.promo?.promoError
});
export default connect(mapStateToProps, null)(withTranslation()(PromotionLinksAdd));