import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import classNames from "classnames";
import {
  Button,
  ButtonGroup,
  CardBody, CardHeader, CardTitle,
} from "reactstrap";
import {
  Table, Thead, Tbody, Tr, Th, Td
} from "react-super-responsive-table";
import { showSuccessNotification } from "store/notifications/actions";
import dedicatedLinks from "./links";
import CardWrapper from "components/Common/CardWrapper";
import { toggleCurrentModal } from "store/actions";
import CreatePromotionLink from "./CreatePromotionLink";

const DedicatedLinksNew = ({ parentRef, agRef, t, history }) => {
  const dispatch = useDispatch();
  const [selectedPlatform, setSelectedPlatform] = useState("MT5");
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const [platformDedicatedLinks, setPlatformDedicatedLinks] = useState([]);
  const [platforms, setPlatforms] = useState([]);
  const { currentModal, modalData, layoutMode } = useSelector((state) => ({
    currentModal: state.Layout.currentModal,
    modalData: state.Layout.modalData,
    layoutMode: state.Layout.layoutMode,
  }));
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  // const { clientData } = useSelector(state => state.Profile);
  const clientData = useSelector((state) => state.clientReducer?.clientDetails);
  console.log("clientData", clientData, clientData?.promoLink);
  useEffect(() => {
    if (clientData?.promoLink) {
      const promoLinks = clientData?.promoLink;
      const dedicatedLinksModified = [];
      let platformsSet = new Set();
      dedicatedLinks?.filter((link) => customActiveTab === "1" ? link.isClient === true : link.isClient === false)?.forEach((link) => {
        promoLinks?.forEach(promo=>{
          platformsSet.add(promo?.accountType?.platform);
          dedicatedLinksModified?.push({
            ...link,
            platform: promo?.accountType?.platform,
            promoLink: promo,
          });
        });
      });
      // console.log("clientData?.promoLink x", clientData);
      
      // setPlatformDedicatedLinks(dedicatedLinks.filter((link) => customActiveTab === "1" ? link.isClient === true : link.isClient === false).map((link) => ({
      //   ...link,
      //   platform: clientData?.promoLink?.accountType?.platform
      // })));
      setPlatforms([...platformsSet]);
      setPlatformDedicatedLinks(dedicatedLinksModified);
    }

  }, [selectedPlatform, clientData?.promoLink, customActiveTab]);
  return (
    <CardWrapper className="dedicated-links mt-4 glass-card shadow p-3">
      {/* <CardHeader className="mb-2">
        <CardTitle className="mb-0 color-primary">{t("Dedicated Links")}</CardTitle>
      </CardHeader> */}
      <CardBody>
        {!agRef
          ? <p colSpan="100%" className="text-center">{t("No Agreements for this account.")}</p>
          :
          <>
            <div>
              <ButtonGroup className="my-2 shadow">
                <Button
                  className={classNames("btn btn-light border-0 ", {
                    "shadow-lg text-white color-bg-btn":
                      customActiveTab === "1",
                  })}
                  onClick={() => {
                    toggleCustom("1");
                  }}
                >
                  {t("Direct Client")}
                </Button>
                <Button
                  className={classNames("btn btn-light border-0", {
                    "shadow-lg text-white color-bg-btn": customActiveTab === "2",
                  })}
                  onClick={() => {
                    toggleCustom("2");
                  }}
                >
                  {t("Agent Link")}
                </Button>
              </ButtonGroup>
            </div>
            <div>
              <ButtonGroup className="my-2">
                {
                  platforms && platforms?.map((platform) => (
                    <Button
                      key={platform}
                      className={classNames("btn btn-light border-0", {
                        "shadow-lg text-white color-bg-btn": selectedPlatform === platform?.toUpperCase(),
                      })}
                      onClick={() => setSelectedPlatform(platform?.toUpperCase())}
                    >
                      {platform?.toUpperCase()}
                    </Button>
                  ))
                }
                {/* <Button
                  className={classNames("btn btn-light border-0", {
                    "shadow-lg text-white color-bg-btn": selectedPlatform === "MT5",
                  })}
                  onClick={() => setSelectedPlatform("MT5")}
                >
                  MT5
                </Button>
                <Button
                  className={classNames("btn btn-light border-0", {
                    "shadow-lg text-white color-bg-btn": selectedPlatform === "MT4",
                  })}
                  onClick={() => setSelectedPlatform("MT4")}
                >
                  MT4
                </Button> */}
              </ButtonGroup>
            </div>
            <Table className="table table-hover table-borderless">
              {/* <Thead>
                <Tr>
                  <Th className="py-0">{t("Promotion Link Name")}</Th>
                  <Th className="py-0">{t("Account Type")}</Th>
                  <Th className="py-0">{t("Link")}</Th>
                </Tr>
              </Thead> */}
              <Tbody>
                {platformDedicatedLinks.filter((link) => link.platform === selectedPlatform).map((link, idx) =>
                  <Tr key={idx}>
                    <Td className="py-0">
                      <p>{t(link?.promoLink?.title)}</p>
                      <p>{t(link?.promoLink?.accountType?.title)}</p>
                    </Td>
                    <Td className="py-0 d-flex justify-content-between align-items-center">
                      {`${link.url}?parentRef=${parentRef}${link.url?.split("/").includes("ib") ? `&agRef=${agRef}` : `&salesRef=${clientData?.agent?.recordId}`}&promotionLink=${link?.promoLink?._id}`}
                      <Button to="#"
                        className="me-2 color-bg-btn"
                        onClick={() => {
                          if (!link.url.includes("ib")) {
                            navigator.clipboard.writeText(`${link.url}?parentRef=${parentRef}${link.url?.split("/").includes("ib") ? `&agRef=${agRef}` : `&salesRef=${clientData?.agent?.recordId}`}&promotionLink=${link?.promoLink._id}`);
                            dispatch(showSuccessNotification("Link copied to clipboard"));
                          } else {
                            dispatch(toggleCurrentModal("CreatePromotionLink", {
                              text: "PromotionLink",
                              agRef,
                              promoLink: link?.promoLink,
                            }));
                          }
                        }}
                      >
                        Copy Link
                      </Button>
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </>  
        }
      </CardBody>
      {modalData && currentModal === "CreatePromotionLink" &&
        <CreatePromotionLink
          isOpen={currentModal === "CreatePromotionLink"}
          type={modalData}
          history={history}
          toggle={() => {
            dispatch(toggleCurrentModal(""));
          }}>
        </CreatePromotionLink>}
    </CardWrapper>
  );
};

export default withTranslation()(DedicatedLinksNew);
