import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import CustomSelect from "./CustomSelect";
import { fetchPromoStart } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import { fetchRebateRules } from "store/rebateSettings/rebateRules/actions";
import SearchableRebateRulesDropdown from "pages/RebateSettings/rebateRules/SearchableComponents/SearchableRebateRulesDropdown";

export default function PromoLinkDropDown({ setPromoLinkData }) {
  const dispatch = useDispatch();
  const [selectedPromoLink, setSelectedPromoLink] = useState(null);
  const promoLinks = useSelector((state) => state?.Promo?.promos?.docs);
  const rebateRules = useSelector((state) => state.RebateRulesReducer.rebateRules);
  const [selectedRebates, setSelectedRebates] = useState([]);
  const [filteredRebateRules, setFilteredRebateRules] = useState([]);
  const clientsDetails = useSelector((state) => state.clientReducer?.clientDetails);
  const [clientPromoLink, setClientPromoLink] = useState(null);

  useEffect(() => {
    if (clientsDetails?.promoLink?.length > 0) {
      setClientPromoLink(clientsDetails.promoLink[0]);
    } else {
      setClientPromoLink(null);
    }
  }, [clientsDetails]);
  

  useEffect(() => {
    setFilteredRebateRules(rebateRules?.filter(item => item?.accountTypeId?._id === selectedPromoLink?.accountType?._id && item.status === true));

  }, [selectedPromoLink]);


  useEffect(() => {
    dispatch(
      fetchPromoStart({
        page: 1,
        limit: 1000,
      })
    );
    dispatch(fetchRebateRules({}));


  }, []);


  const options = promoLinks
    .filter((link) => !clientPromoLink || link._id === clientPromoLink._id)
    .map((link) => ({
      label: link.title,
      value: link,
    }));

  const { t } = useTranslation();

  const handleChange = (e) => {
    setSelectedPromoLink(e.value);
  };

  const handleRebateChange = (e, rebate) => {
    const { _id, promotionOptionalRebateValue } = rebate;
    // const promotionOptionalRebateValueData = e.map((item) => parseInt(item.value));
    const selectedValue = parseFloat(e.value) || 0;
    setSelectedRebates(prevState => {
      return [...prevState, { 
        rebateId: _id,
        rebateValue: selectedValue,
        rebateName: rebate?.name,
        minRebateValue: Math.min(...rebate?.tier1RebateRules || 0),
        maxRebateValue: Math.max(...rebate?.tier1RebateRules || 0),
      }];
    });
  };
  useEffect(() => {
    setPromoLinkData({
      promotionLink: selectedPromoLink?._id,
      rebateRef: JSON.stringify(selectedRebates),
    });

  }, [selectedPromoLink, selectedRebates]);

  return (
    <React.Fragment>
      <div
        style={{
          minWidth: "200px",
        }}
      >
        <label htmlFor="choices-single-default" className="form-label font-size-14">{t("Promotion Link")}</label>
        <CustomSelect
          classNamePrefix="select2-selection"
          placeholder="Select Promotion Link"
          options={options}
          onChange={handleChange}
        />
      </div>
      {filteredRebateRules.map((rebate) => (
        <div className="my-3" key={rebate._id}>
          <div className="my-3">
            <SearchableRebateRulesDropdown
              name="promotionOptionalRebateValue"
              type="text"
              errorMessage="Rebate is required"
              validate={{ required: { value: true } }}
              label={rebate.name}
              isPromote={true}
              defaultOptions={rebate?.promotionOptionalRebateValue?.length > 0 
                ? rebate.promotionOptionalRebateValue.map((option) => ({
                  label: option,
                  value: option,
                }))
                : [{ 
                  label: "0",
                  value: 0 
                }]}
              isMulti={false}
              onChange={(e) => { handleRebateChange(e, rebate) }}
            />
          </div>
        </div>
      ))}
    </React.Fragment>
  );
}
