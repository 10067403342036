import { cpUrl } from "content";

export default [
  {
    type: "Register Live",
    isClient: true,
    url: `${cpUrl}/register/client/live`
  },
  // {
  //   type: "Register Demo",
  //   url: `${cpUrl}/register/client/demo`
  // },
  {
    type: "Register Sub IB",
    isClient: false,
    url: `${cpUrl}/register/client/ib`
  }
];
