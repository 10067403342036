import React, { useEffect, useState } from "react";
import { 
  useDispatch, 
  connect, 
  useSelector 
} from "react-redux";
import MetaTags from "react-meta-tags";
import { fetchDictionaryStart } from "store/dictionary/actions";
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import Withdrawal from "pages/Transactions/Crypto/Withdrawal";
import WithdrawalForex from "pages/Transactions/Forex/withdrawals/WithdrawalForex";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import WithdrawalForexFundingDepartmentInitial from "./Forex/withdrawals/forexFundingSteps/WithdrawalForexFundingDepartmentInitial";
import WithdrawalForexRiskDepartmentReview from "./Forex/withdrawals/forexFundingSteps/WithdrawalForexRiskDepartmentReview";
import WithdrawalForexFundingDepartmentDeduction from "./Forex/withdrawals/forexFundingSteps/WithdrawalForexFundingDepartmentDeduction";
import WithdrawalForexWithdrawalRemittance from "./Forex/withdrawals/forexFundingSteps/WithdrawalForexWithdrawalRemittance";
import WithdrawalFundingDepartmentInitial from "./Crypto/walletFundingSteps/WithdrawalFundingDepartmentInitial";
import WithdrawalRiskDepartmentReview from "./Crypto/walletFundingSteps/WithdrawalRiskDepartmentReview";
import WithdrawalFundingDepartmentDeduction from "./Crypto/walletFundingSteps/WithdrawalFundingDepartmentDeduction";
import WithdrawalWithdrawalRemittance from "./Crypto/walletFundingSteps/WithdrawalWithdrawalRemittance";

function WithdrawalIndex() {
  const dispatch = useDispatch();
  useState(() => {
    dispatch(fetchDictionaryStart());
  }, []);
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const tab = query.get("tab");
  const { profileMetaInfo = {} } = useSelector((state) => state.Profile);
  
  const { 
    fundingDepartmentInitialPermissions,
    riskDepartmentReviewPermissions,
    fundingDepartmentDeductionPermissions, 
    withdrawalRemittancePermissions, 
  } = useSelector((state) => (state.Profile || {}));

  // const { delete: deleteUserPermission, update } = userPermissions;
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };
  const toggleFunding = (tab) => {
    if (activeFundingTab !== tab) {
      setactiveFundingTab(tab);
    }
  };


  const loadTabs = ({
    transactions: { withdrawalDetails: { forex } = {} } = {},
  }) => [
    {
      tabId: "1",
      navLinkName: "Wallets",
      // component: <Withdrawal />,
      hidden: !forex,
    },
    {
      tabId: "2",
      navLinkName: "Forex",
      // component: <WithdrawalForex />,
      hidden: !forex,
    },
  ];
  
  const tabs = loadTabs(profileMetaInfo).filter((item) => !item.hidden);
  const [activeTab, setactiveTab] = useState(
    tabs.length > 0 ? tabs[0].tabId : ""
  );

  const [activeFundingTab, setactiveFundingTab] = useState("fundingDepartmentInitial");

  const loadForexFundingStepsTabs = () => [
    fundingDepartmentInitialPermissions?.get && {
      tabId: "fundingDepartmentInitial",
      navLinkName: "Funding Department Initial",
      component: activeFundingTab === "fundingDepartmentInitial" ? <WithdrawalForexFundingDepartmentInitial fundingStep={activeFundingTab} /> : <></>,
    },
    riskDepartmentReviewPermissions?.get && {
      tabId: "riskDepartmentReview",
      navLinkName: "Risk Department Review",
      component: activeFundingTab === "riskDepartmentReview" ? <WithdrawalForexRiskDepartmentReview fundingStep={activeFundingTab} /> : <></>,
    },
    fundingDepartmentDeductionPermissions?.get  && {
      tabId: "fundingDepartmentDeduction",
      navLinkName: "Funding Department Deduction",
      component: activeFundingTab === "fundingDepartmentDeduction" ? <WithdrawalForexFundingDepartmentDeduction fundingStep={activeFundingTab} /> : <></>,
    },
    withdrawalRemittancePermissions?.get && {
      tabId: "withdrawalRemittance",
      navLinkName: "Withdrawal Remittance",
      component: activeFundingTab === "withdrawalRemittance" ? <WithdrawalForexWithdrawalRemittance fundingStep={activeFundingTab} /> : <></>,
    },
  ];

  const forexFundingTabs = loadForexFundingStepsTabs()?.filter(tab=>tab);

  useEffect(()=> {
    if (fundingDepartmentInitialPermissions?.get) {
      setactiveFundingTab("fundingDepartmentInitial");
    } else if (riskDepartmentReviewPermissions?.get) {
      setactiveFundingTab("riskDepartmentReview");
    } else if (fundingDepartmentDeductionPermissions?.get) {
      setactiveFundingTab("fundingDepartmentDeduction");
    } else if (withdrawalRemittancePermissions?.get) {
      setactiveFundingTab("withdrawalRemittance");
    } else {
      setactiveFundingTab("");
    }
  }, [fundingDepartmentInitialPermissions, riskDepartmentReviewPermissions, fundingDepartmentDeductionPermissions, withdrawalRemittancePermissions]);
  
  const loadWalletFundingStepsTabs = () => [
    {
      tabId: "fundingDepartmentInitial",
      navLinkName: "Funding Department Initial",
      component: activeFundingTab === "fundingDepartmentInitial" ? <WithdrawalFundingDepartmentInitial fundingStep={activeFundingTab} /> : <></>,
    },
    {
      tabId: "riskDepartmentReview",
      navLinkName: "Risk Department Review",
      component: activeFundingTab === "riskDepartmentReview" ? <WithdrawalRiskDepartmentReview fundingStep={activeFundingTab} /> : <></>,
    },
    {
      tabId: "fundingDepartmentDeduction",
      navLinkName: "Funding Department Deduction",
      component: activeFundingTab === "fundingDepartmentDeduction" ? <WithdrawalFundingDepartmentDeduction fundingStep={activeFundingTab} /> : <></>,
    },
    {
      tabId: "withdrawalRemittance",
      navLinkName: "Withdrawal Remittance",
      component: activeFundingTab === "withdrawalRemittance" ? <WithdrawalWithdrawalRemittance fundingStep={activeFundingTab} /> : <></>,
    },
  ];

  const walletundingTabs = loadWalletFundingStepsTabs();

  useEffect(() => {
    if (tabs.length > 0) {
      setactiveTab(tabs[0].tabId);
    }
    if (tab) {
      setactiveTab(tab);
    }
  }, [profileMetaInfo]);

  // useEffect(() => {
  //   if (tabs.length > 0) {
  //     setactiveFundingTab(tabs[0].tabId);
  //   }
  //   if (tab) {
  //     setactiveFundingTab(tab);
  //   }
  // }, [profileMetaInfo]);
  // useEffect(() => {
  //   if (tab) {
  //     setactiveTab(tab);
  //   }
  // }, [tab]);
  // console.log(tab);
  return (
    <React.Fragment>
      <MetaTags>
        <title>Withdrawals</title>
      </MetaTags>
      <div className="page-content">
        <div className="container-fluid">
          <h2>Withdrawals</h2>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Nav tabs>
                    {tabs.map((tabItem) => (
                      <>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab === tabItem.tabId,
                            })}
                            onClick={() => {
                              toggle(tabItem.tabId);
                            }}
                          >
                            {tabItem.navLinkName}
                          </NavLink>
                        </NavItem>
                      </>
                    ))}
                  </Nav>
                  {
                    activeTab === "2" &&
                      <Nav tabs>
                        {forexFundingTabs?.map((tabItem) => (
                          <>
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: activeFundingTab === tabItem?.tabId,
                                })}
                                onClick={() => {
                                  toggleFunding(tabItem?.tabId);
                                }}
                              >
                                {tabItem?.navLinkName}
                              </NavLink>
                            </NavItem>
                          </>
                        ))}
                      </Nav>
                  }
                  {
                    activeTab === "1" &&
                      <Nav tabs>
                        {walletundingTabs?.map((tabItem) => (
                          <>
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: activeFundingTab === tabItem?.tabId,
                                })}
                                onClick={() => {
                                  toggleFunding(tabItem?.tabId);
                                }}
                              >
                                {tabItem?.navLinkName}
                              </NavLink>
                            </NavItem>
                          </>
                        ))}
                      </Nav>
                  }
                  {/* {
                    activeTab === "1" &&
                    <TabContent activeTab={activeTab} className="p-3 text-muted">
                      {tabs.map((tabItem) => (
                        <>
                          <TabPane tabId={tabItem.tabId}>
                            <Row>
                              <Col sm="12">{tabItem.component}</Col>
                            </Row>
                          </TabPane>
                        </>
                      ))}
                    </TabContent>
                  } */}
                  {
                    activeTab === "1" &&
                    <TabContent activeTab={activeFundingTab} className="p-3 text-muted">
                      {walletundingTabs.map((tabItem) => (
                        <>
                          <TabPane tabId={tabItem.tabId}>
                            <Row>
                              <Col sm="12">{tabItem.component}</Col>
                            </Row>
                          </TabPane>
                        </>
                      ))}
                    </TabContent>
                  }
                  {
                    activeTab === "2" &&
                    <TabContent activeTab={activeFundingTab} className="p-3 text-muted">
                      {forexFundingTabs.map((tabItem) => (
                        <>
                          <TabPane tabId={tabItem.tabId}>
                            <Row>
                              <Col sm="12">{tabItem.component}</Col>
                            </Row>
                          </TabPane>
                        </>
                      ))}
                    </TabContent>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}
const mapStateToProps = (state) => ({
  loading: state.dictionaryReducer.loading || false,
  dictionary: state.dictionaryReducer.dictionary || [],
  error: state.dictionaryReducer.error,
});
export default connect(mapStateToProps, null)(WithdrawalIndex);
