import qs from "qs";
import * as axiosHelper from "./api_helper";

export const getWallets = async  ({ payload })=>{
  const endpoint = payload?.walletType === "users" ? "/user-withdrawals" : "" ; 
  const wallets = await axiosHelper.get(`/wallets${endpoint}?${qs.stringify(payload)}`, { crypto:true });
  return wallets;
};

export const getUserWallets = async  ({ payload })=>{
  const wallets = await axiosHelper.get(`/wallets/user-withdrawals?${qs.stringify(payload)}`, { crypto:true });
  return wallets;
};


export const getClientWalletDetails = async ({ payload }) => {
  const endpoint = payload?.walletType === "users" ? "/user-withdrawals" : "" ; 
  const params = {
    belongsTo: payload.belongsTo,
    customerId: payload.belongsTo,
    ...payload,
  };
  const data = await axiosHelper.get(`/wallets${endpoint}?${qs.stringify(params)}`);
  if (data.isError){
    throw new Error(data.isError);
  }
  return data;
};

export const addNewWallet = async ({ payload }) => {
  const data = await axiosHelper.post("/wallets", payload);
  if (data.isError){
    throw new Error(data.message);
  }

  return data;
};
// export const changeStatusWallet = async({ payload }) => {
//   const data = await axiosHelper.post(`/wallets/${payload.id}/${payload.status}`);
//   if (data.isError) {
//     throw new Error(data.message);
//   } 
//   return data;
// };
export const changeStatusWallet = async (payload) => { 
  const { id, ...rest } = payload;
  const data = await axiosHelper.patch(`/wallets/${id}`, rest);
  if (data.isError) {
    throw new Error(data.message);
  }
  return {
    ...data,
  };
};
export const convertWallet = async (payload)=>{
  const data = await axiosHelper.post("/convert", payload);
  if (data.isError){
    throw new Error(data.message);
  }
  return data;
};

export const getWalletTransfers = async (params) => {
  const data = await axiosHelper.get(`/wallet/transfers?${qs.stringify(params)}`);
  if (data.isError){
    throw new Error(data.isError);
  }
  return data;
};

export const approveTransfer = async ( payload) => {
  const data = await axiosHelper.patch(`/wallet/transfers/approve/${payload}`);
  if (data.isError) {
    throw new Error(data.message);
  } 
  return data;
};

export const rejectTransfer = async (payload) => {
  const data = await axiosHelper.patch(`/wallet/transfers/reject/${payload}`);
  if (data.isError) {
    throw new Error(data.message);
  } 
  return data;
};

export const convertRebate = async (payload) => {
  const data = await axiosHelper.post("/wallets/convert-rebate", payload);
  if (data.isError) {
    throw new Error(data.message);
  }
  return data;
};

export const changeCommisionAddingField = async (payload) => {
  const data = await axiosHelper.post("/wallets/change-commision-adding-field", payload);
  if (data.isError) {
    throw new Error(data.message);
  }
  return data;
};

export const changeGeneralRebeateConfigurationHour = async (payload) => {
  const data = await axiosHelper.post("/wallets/general-rebate-configuration-hour", payload);
  if (data.isError) {
    throw new Error(data.message);
  }
  return data;
};

export const updateIbWalletschedule = async (payload) => {
  const data = await axiosHelper.post("/wallets/update-schedule", payload);
  if (data.isError) {
    throw new Error(data.message);
  }
  return data;
};

export const getGeneralRebeateConfigurationHour = async () => {
  const data = await axiosHelper.get("/wallets/general-rebate-configuration-hour");
  if (data.isError) {
    throw new Error(data.message);
  }
  return data;
};

export const getIbWallets = async ({ payload }) => {
  const data = await axiosHelper.get("/wallets/ib", {
    params: payload
  });
  if (data.isError) {
    throw new Error(data.isError);
  }
  return data;
};

export const getWalletTotals = async ({ start, end, customerId, walletId }) => {
  const data = await axiosHelper.get(`/wallets/totals?start=${start}&end=${end}&customerId=${customerId}&walletId=${walletId}`);
  if (data.isError) {
    throw new Error(data.isError);
  }
  return data;
};