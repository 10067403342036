import {
  takeEvery,
  call,
  put,
} from "redux-saga/effects";
import { showErrorNotification, showSuccessNotification } from "store/notifications/actions";
import {
  fetchPredefinedIbAgreements,
} from "apis/predefined-ib-agrements";
import {
  FETCH_PREDEFINED_IB_AGREEMENTS_START,
} from "./actionTypes";
import {
  fetchPredefinedAgreementsSuccess,
  apiError,
} from "./actions";
  
function* getIbAgreements({ payload }) {
  try {
    const { result } = yield call(fetchPredefinedIbAgreements, payload);
    yield put(fetchPredefinedAgreementsSuccess(result));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* IbAgreementSaga() {
  yield takeEvery(FETCH_PREDEFINED_IB_AGREEMENTS_START, getIbAgreements);
}

export default IbAgreementSaga;