import AvFieldSelect from "components/Common/AvFieldSelect";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { 
  Modal, 
  ModalBody, 
  ModalHeader, 
  Button, 
  Row,
  Col,
  Table
} from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { swapRequestApprove } from "store/requests/actions";
import { 
  Tbody, 
  Td, 
  Th, 
  Thead, 
  Tr 
} from "react-super-responsive-table";

export default function SwapApprovalModal({ show, toggle, swapRequest }) {
  const groupedAccounts = swapRequest.content.accounts.reduce((acc, account) => {
    const title = account.accountTypeId.title;
    if (!acc[title]) {
      acc[title] = [];
    }
    acc[title].push(account);
    return acc;
  }, {});

  const [selectedSwapGroup, setSelectedSwapGroup] = useState("");
  const [selectedSwapFreeGroup, setSelectedSwapFreeGroup] = useState("");
  const allGroups = [];

  const dispatch = useDispatch();

  const approveRequest = () => {
    dispatch(swapRequestApprove({
      requestId: swapRequest._id,
      accounts: swapRequest.content.accounts,
      accountTypeId: swapRequest.accountTypeId._id,
      isSwap: true,
      groups: allGroups
    }));
  };

  const handleAutoSelect = (groupOptions, setSelectedGroup) => {
    if (groupOptions.length > 0) {
      setSelectedGroup(groupOptions[0].value);
    }
  };

  const renderSelectField = (type, label, selectedGroup, setSelectedGroup, accountType) => {
    const groupOptions = accountType.groups[type]?.map((group) => ({
      value: group,
      label: group,
      title: accountType.title
    })) || [];

    allGroups.push(...groupOptions);

    useEffect(() => {
      handleAutoSelect(groupOptions, setSelectedGroup);
    }, [groupOptions]);

    return (
      <AvFieldSelect
        key={accountType._id}
        name={type}
        label={`${label} ${accountType.title}`}
        placeholder={`Select ${label}`}
        onChange={(e) => setSelectedGroup(e)}
        options={groupOptions}
        value={groupOptions[0]?.value}
        isDisabled={accountType.useDefault}
        validate={{
          required: {
            value: true,
            errorMessage: `${label} is required`,
          },
        }}
      />
    );
  };

  return (
    <Modal 
      isOpen={show} 
      toggle={toggle} 
      centered={true}
      size={"lg"}
    >
      <ModalHeader toggle={toggle} tag="h4">
        {`Choose ${swapRequest.type === "SWAP" ? "Swap" : "Swap Free"} Group`}
      </ModalHeader>
      <ModalBody className="py-3 px-5">
        <Row className="mb-3">
          <Col>
            <Table className="table table-hover text-center">
              <Thead>
                <Tr>
                  <Th>{"Account Type"}</Th>
                  <Th>{"Accounts"}</Th>
                  <Th>{"Account Platform"}</Th>
                  <Th>{"Account Group"}</Th>
                  <Th>{"Switched To"}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {Object.entries(groupedAccounts).map(([title, accounts], index) => (
                  <Tr key={index}>
                    <Td>{title}</Td>
                    <Td>
                      {accounts.map((account) => (
                        <div key={account._id}>
                          {account.login}
                        </div>
                      ))}
                    </Td>
                    <Td>
                      {accounts.map((account) => (
                        <div key={account._id}>
                          {account?.accountTypeId.platform || "N/A"}
                        </div>
                      ))}
                    </Td>
                    <Td>
                      {accounts.map((account) => (
                        <div key={account._id}>
                          {account?.accountTypeId.currencies?.[0].groupPath || "N/A"}
                        </div>
                      ))}
                    </Td>
                    <Td>
                      <AvForm onValidSubmit={approveRequest}>
                        {swapRequest.type === "SWAP" && 
                          renderSelectField("swapGroups", "Swap Groups", selectedSwapGroup, setSelectedSwapGroup, accounts[0].accountTypeId)
                        }
                        {swapRequest.type === "SWAP_FREE" && 
                          renderSelectField("swapFreeGroups", "Swap Free Groups", selectedSwapFreeGroup, setSelectedSwapFreeGroup, accounts[0].accountTypeId)
                        }
                      </AvForm>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Col>
        </Row>
        <Row className="d-flex justify-content-end mt-3">
          <Button color="primary" type="submit" onClick={approveRequest}>
            Approve Request
          </Button>
        </Row>
      </ModalBody>
    </Modal>
  );
}
