import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import LayuiTransfer from "components/Common/LayuiTransfer";
import { useDispatch, useSelector } from "react-redux";
import { editPromoStart } from "store/actions";
import { fetchClientsStart } from "store/client/actions";

const BindingIB = ({ show, setIbBinding, selectedPromo }) => {
  const dispatch = useDispatch();
  const [clientsOptions, setClientsOptions] = useState([]);
  const clients = useSelector((state) => state.clientReducer.clients);
  const editDone  = useSelector((state) => state.Promo.editDone);

  useEffect(() => {
    const ibsOptions = clients?.map((client) => {
      return {
        value: client?._id,
        title: client?.firstName + " " + client?.lastName,
      };
    });
    setClientsOptions(ibsOptions);

  }, [clients, selectedPromo]);

  useEffect(() => {
    if (clientsOptions && selectedPromo?._id) {
      const data = clients
        ?.filter((client) => client?.promoLink?.includes(selectedPromo._id))
        .map((client) => client._id) || [];
      setSelectedData(data);
    }
  }, [clientsOptions, selectedPromo, clients]);
  

  useEffect(() => {
    dispatch(
      fetchClientsStart({
        limit: 100000,
        page: 1,
        isIb: true
      })
    );
  }, [selectedPromo]);

  const [selectedData, setSelectedData] = useState([]);

  const handleTransferChange = (newSelectedData) => {
    setSelectedData(newSelectedData);
  };

  const toggleModal = () => {
    setIbBinding((prev) => !prev);
  };

  const adjustMtGroups = () => {
    dispatch(editPromoStart({
      id: selectedPromo?._id,
      ibs: selectedData
    }));
    toggleModal();
  };


  return (
    <div>
      <Modal isOpen={show} toggle={toggleModal} centered size="lg">
        <ModalHeader toggle={toggleModal}>IB Binding</ModalHeader>
        <ModalBody className="d-flex justify-content-center">
          <LayuiTransfer
            data={clientsOptions}
            selectedData={selectedData}
            onChange={handleTransferChange}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={adjustMtGroups}>
            Confirm
          </Button>
          <Button color="secondary" onClick={toggleModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default BindingIB;
