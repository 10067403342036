import React, { useCallback, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import AsyncAvFieldSelect from "../../../../components/Common/AsyncAvFieldSelect";


const SearchableMTGroupsDropdown = (props) => {

  const {
    label = "MT Groups",
    placeholder = "Search",
    isRequired = false,
    name = "mtGroups",
    isDisabled = false,
    defaultOptions = [],
    value = null,
    clientData = null,
    onChange = () => { },
    isMulti = true,
  } = props;


  return (
    <AsyncAvFieldSelect 
      name={name}
      options={defaultOptions}
      label={props.t(label)}
      errorMessage={props.t("MT Group is required")}
      defaultOptions={defaultOptions}
      value={value?.map((item) => ({
        label: item.label,
        value: item.value,
      }))}
      defaultValue={value?.map((item) => ({
        label: item,
        value: item,
      }))}    
      isRequired={isRequired}
      placeholder={placeholder}
      isSearchable={true}
      backspaceRemovesValue={true}
      onChange={onChange}
      isMulti={isMulti}
      isDisabled={isDisabled}
      disabled={isDisabled}
    />
  );
};


const mapStateToProps = (state) => ({
  agents: state.usersReducer.salesAgent,
  agentsLoading: state.usersReducer.loading,
});
export default connect(mapStateToProps, null)(withTranslation()(SearchableMTGroupsDropdown));