import * as axiosHelper from "./api_helper";
import qs from "qs";

// fetch symbols
export const getSymbols = async ({ payload }) => {
  const result = await axiosHelper.get(`/symbols?${qs.stringify(payload)}`);
  if (!result.status){
    throw new Error(result.message);
  }
  return result;
};

// fetch groups
export const getGroups = async ({ payload }) => {
  const result = await axiosHelper.get(`/groups?${qs.stringify(payload)}`);
  if (!result.status){
    throw new Error(result.message);
  }
  return result;
};

// fetch mt groups
export const getMtGroups = async ({ payload }) => {
  const result = await axiosHelper.get(`/mtgroups?${qs.stringify(payload)}`);
  if (!result.status){
    throw new Error(result.message);
  }
  return result;
};

export const adjustMtGroups = async({ payload }) => {
  const { id, values } = payload;
  delete payload?.id;

  const data = await axiosHelper.patch(`/mtgroups/${id}`, payload);
  if (data.isError) {
    throw new Error(data.message);
  } 
  return data;
};

// sync symbols
export const syncSymbols = async (payload) => {
  const result = await axiosHelper.post(`/symbols/sync-symbols?${qs.stringify(payload)}`);
  if (!result.status){
    throw new Error(result.message);
  }
  return result;
};
export const pullMTGroups = async (payload) => {
  const result = await axiosHelper.post(`/mtgroups/pull-mtgroups?${qs.stringify(payload.payload)}`);
  if (!result.status){
    throw new Error(result.message);
  }
  return result;
};

// sync symbols
export const changeRebateRulesStatus = async (payload) => {
  const result = await axiosHelper.patch(`/rebate-rules/update-status/${payload?.id}?${qs.stringify(payload)}`);
  if (!result.status){
    throw new Error(result.message);
  }
  return result;
};

// sync symbols
export const changeSymbolStatus = async (payload) => {
  const result = await axiosHelper.patch(`/symbols/${payload?.id}?${qs.stringify(payload)}`);
  if (!result.status){
    throw new Error(result.message);
  }
  return result;
};

// add Group
export const addGroup = async (values) => {
  const result = await axiosHelper.post("/groups/add-group", values);
  if (!result.status){
    throw new Error(result.message);
  }
  return result;
};

export const deleteGroup = async (payload) => {
  // const { id, values } = payload;
  console.log("deletedId", payload);
  const data = await axiosHelper.del("/groups/" + payload.id);
  if (data.isError) {
    throw new Error(data.message);
  }
  return data.result;
};

export const editGroup = async({ payload }) => {
  const { id, values } = payload;
  delete payload?.id;
  // const sendedData = {
  //   ...payload,
  //   symbols: payload?.symbols?.map((symbol) => symbol?.value)
  // };
  const data = await axiosHelper.patch(`/groups/${id}`, payload);
  if (data.isError) {
    throw new Error(data.message);
  } 
  return data;
};

// add Group
// fetch groups
export const getRebateRules = async ({ payload }) => {
  const result = await axiosHelper.get(`/rebate-rules?${qs.stringify(payload)}`);
  if (!result.status){
    throw new Error(result.message);
  }
  return result;
};
export const addRebateRules = async (values) => {
  const result = await axiosHelper.post("/rebate-rules/add-rebate-rule", values);
  if (!result.status){
    throw new Error(result.message);
  }
  return result;
};

export const deleteRebateRules = async (payload) => {
  // const { id, values } = payload;
  console.log("deletedId", payload);
  const data = await axiosHelper.del("/rebate-rules/" + payload.id);
  if (data.isError) {
    throw new Error(data.message);
  }
  return data.result;
};

export const editRebateRules = async({ payload }) => {
  const { id, values } = payload;
  delete payload?.id;
  // const sendedData = {
  //   ...payload,
  //   symbols: payload?.symbols?.map((symbol) => symbol?.value)
  // };
  const data = await axiosHelper.patch(`/rebate-rules/${id}`, payload);
  if (data.isError) {
    throw new Error(data.message);
  } 
  return data;
};


// add internal transfer
export const postInternalTransfer = async (values) => {
  const result = await axiosHelper.post("/fxtransactions/internalTransfers", values);
  if (!result.status){
    throw new Error(result.message);
  }

  return result;
};

export const postIbInternalTransfer = async (values) => {
  const result = await axiosHelper.post("/ib/internalTransfer", values);
  if (!result.status){
    throw new Error(result.message);
  }

  return result;
};

export const approveInternalTransferAPI = async (id)=>{
  const result = await axiosHelper.patch(`/fxtransactions/internalTransfers/${id}/approve`);
  if (result.isError){
    throw new Error(result.message);
  }
  return result;
};

export const rejectInternalTransferAPI = async (id)=>{
  const result = await axiosHelper.patch(`/fxtransactions/internalTransfers/${id}/reject`);
  if (result.isError){
    throw new Error(result.message);
  }
  return result;
};

export const addApprovedInternalTransferAPI = async (payload)=> {
  const result = await axiosHelper.post("/wallet/transfers", payload);
  if (result.isError){
    throw new Error(result.message);
  }
  return result;
};