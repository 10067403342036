import { Link as ReactLink } from "react-router-dom";
import React from "react";

const ClientLink = (props) => {
  const {
    firstName = "",
    lastName = "",
    id = "",
    isClient = true,
    state = {},
    search = null,
    redirect = false,
    hash = null
  } = props;
  
  const urlObj = {
    pathname: `/${isClient ? "clients" : "leads"}/${id}/profile`,
    search,
    hash,
    state
  };
  return (
    <div className="nowrap" title={`${firstName} ${lastName}`}>
      <ReactLink
        color="primary"
        className="highlight"
        to={urlObj}
        target={redirect ? "_blank" : ""}>
        {firstName} {lastName}
      </ReactLink>
    </div>
  );
};

export default ClientLink;
