import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";

import {
  Table, Thead, Tbody, Tr, Th, Td 
} from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import CustomPagination from "components/Common/CustomPagination";
import TableLoader from "components/Common/TableLoader";
import { withTranslation } from "react-i18next";
import { MetaTags } from "react-meta-tags";
import moment from "moment";
import AddServerModal from "./AddServerModal";
import {
  fetchAccountTypes,
  fetchProducts,
  fetchPredefinedAgreements,
} from "store/actions";
// import EditSharedIbModal from "pages/ClientDetail/Partnership/EditSharedIbModal";
import { format } from "path";
import { fetchServerStart, deleteServerStart } from "store/mtserver/actions";
import EditServerModal from "./EditServerModal";
import DeleteServerModal from "./DeleteServerModal";

function MTServers(props) {
  const [sizePerPage, setSizePerPage] = useState(10);
  const [show, setIsShowing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const toggleEdit = () => setShowEdit(!showEdit);
  const toggleDelete = () => setShowDelete(!showDelete);
  const [selectedServer, setSelectedServer] = useState(null);
  const dispatch = useDispatch();
  const { t } = props;

  const loadServers = (page, limit) => {
    dispatch(
      fetchServerStart({
        page,
        limit,
      })
    );
  };

  // useEffect(() => {
  //   dispatch(
  //     fetchAccountTypes({
  //       type: "LIVE",
  //     })
  //   );
  //   dispatch(fetchProducts());
  // }, []);

  useEffect(() => {
    loadServers(1, sizePerPage);
  }, [isLoading, sizePerPage]);

  const columns = [
    {
      text: t("Name"),
      dataField: "name",
      // formatter: (item) => moment(item.name).format("DD/MM/YYYY"),
    },
    {
      text: t("Address"),
      dataField: "address",
      // formatter: (item) => moment(item.address).format("DD/MM/YYYY"),
    },
    {
      text: t("Agent"),
      dataField: "agent",
      // formatter: (item) => moment(item.address).format("DD/MM/YYYY"),
    },
    {
      text: t("Port"),
      dataField: "port",
      // formatter: (item) => moment(item.address).format("DD/MM/YYYY"),
    },
    {
      text: t("Admin Account"),
      dataField: "adminAccount",
      // formatter: (item) => moment(item.address).format("DD/MM/YYYY"),
    },
    {
      text: t("Created At"),
      dataField: "createdAt",
      formatter: (item) => moment(item.createdAt).format("DD/MM/YYYY"),
    },
    {
      text: t("Type"),
      dataField: "type",
      // formatter: (val) => val === "LIVE" ? t("Live") : t("Demo")
    },
    {
      text: t("Platform"),
      dataField: "platform",
      // formatter: (val) => val === "LIVE" ? t("Live") : t("Demo")
    },
    // {
    //   text: t("Created By"),
    //   dataField: "createdBy",
    //   formatter: (val) =>
    //     `${val.createdBy.firstName} ${val.createdBy.lastName}`,
    // },
    {
      text: t("Order Permission"),
      dataField: "orderPermission",
      formatter: ({ orderPermission }) => (
        <span style={{ color: orderPermission ? "green" : "red" }}>
          {orderPermission ? t("Enabled") : t("Disabled")}
        </span>
      ),
    },
    {
      text: t("Link Status"),
      dataField: "linkStatus",
      formatter: ({ linkStatus }) => (
        <span style={{ color: linkStatus ? "green" : "red" }}>
          {linkStatus ? t("Enabled") : t("Disabled")}
        </span>
      ),
    },
    {
      text: t("Remark"),
      dataField: "remarks",
      //formatter: (val) => val === true ? t("Enabled") : t("Disabled")
    },
    {
      text: t("Actions"),
      dataField: "actions",
      formatter: (val) => {
        console.log(val);
        return (
          <Button
            disabled={isLoading}
            onClick={() => {
              setIsLoading(true);
              setShowEdit(true);
              setSelectedServer(val);
              setIsLoading(false);
            }}
          >
            {t("Edit")}
          </Button>
        );
      },
    },
    {
      text: t("Actions"),
      dataField: "actions",
      formatter: (val) => {
        console.log(val);
        return (
          <Button
            className="btn-danger"
            disabled={isLoading}
            onClick={() => {
              setIsLoading(true);
              setSelectedServer(val);
              setShowDelete(true);
              setIsLoading(false);
            }}
          >
            {t("Delete")}
          </Button>
        );
      },
    },
  ];
  const state = useSelector((state) => state);
  console.log("THIS IS STATE FROM SERVERS: ", state);
  // const servers = useSelector((state) => state.MTServer?.servers);
  const { loading, servers } = useSelector((state) => state.MTServer);
  // const { accountTypes } = useSelector((state) => state.tradingAccountReducer);
  // const { products } = useSelector((state) => state.ibAgreements);
  // const { _id } = useSelector((state) => state.clientReducer?.clientDetails);
  // const markups = useSelector((state) => state.dictionaryReducer.markups);
  console.log("THIS IS SERVERS: ", servers);
  return (
    <React.Fragment>
      <MetaTags>
        <title>{t("MT4/MT5 Servers")}</title>
      </MetaTags>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col>
              <Card>
                <CardHeader className="d-flex justify-content-between  align-items-center">
                  <CardTitle>
                    <h2>{t("MT4/MT5 Servers")}</h2>
                  </CardTitle>
                  <AddServerModal />
                </CardHeader>
                <CardBody>
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table  table-hover "
                      >
                        <Thead className="text-center table-light">
                          <Tr>
                            {columns.map((column, index) => (
                              <Th data-priority={index} key={index}>
                                {column.text}
                              </Th>
                            ))}
                          </Tr>
                        </Thead>
                        <Tbody className="text-center">
                          {loading && <TableLoader colSpan={6} />}
                          {!loading && servers?.docs.length === 0 && (
                            <Tr>
                              <Td colSpan={columns.length}>
                                <div
                                  style={{
                                    padding: "20px",
                                    fontSize: "18px",
                                    color: "gray",
                                  }}
                                >
                                  {t("No Servers Available")}
                                </div>
                              </Td>
                            </Tr>
                          )}
                          {!loading &&
                            servers?.docs.map((row, rowIndex) => (
                              <Tr key={rowIndex}>
                                {columns.map((column, index) => (
                                  <Td key={`${rowIndex}-${index}`}>
                                    {column.formatter
                                      ? column.formatter(row, rowIndex)
                                      : row[column.dataField]}
                                  </Td>
                                ))}
                              </Tr>
                            ))}
                        </Tbody>
                      </Table>
                      <CustomPagination
                        {...servers}
                        sizePerPage={sizePerPage}
                        setSizePerPage={setSizePerPage}
                        //onChange={loadPredefinedAgrements}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {
            <>
              <EditServerModal
                showEdit={showEdit}
                toggle={toggleEdit}
                serverId={selectedServer?._id}
                initialData={selectedServer}
                servers={servers}
                t={t}
              />
              <DeleteServerModal
                showDelete={showDelete}
                toggle={toggleDelete}
                serverId={selectedServer?._id}
                // initialData={selectedServer}
              />
            </>
          }
        </div>
      </div>
    </React.Fragment>
  );
}

export default withTranslation()(MTServers);
