import { useDispatch, connect } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledAlert,
  Label,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import CustomCreatableSelect from "components/Common/CustomCreatableSelect";
import AvFieldSelect from "components/Common/AvFieldSelect";
import { editRebateRules, fetchRebateRules } from "store/rebateSettings/rebateRules/actions";
import { fetchGroups, fetchMtGroups } from "store/rebateSettings/groups/actions";
import { fetchAccountTypes } from "store/actions";
import SearchableRebateRulesDropdown from "./SearchableComponents/SearchableRebateRulesDropdown";

const accountGroupOptions = [{
  value:"cent",
  label: "Cent",
}, {
  value:"rebate",
  label: "Rebate",
}, {
  value:"tradingAccount",
  label: "Trading Account",
}];

function SymbolsEdit(props){
  const [addModal, setAddModal] = useState(false);
  const [symbolGroup, setSymbolGroup] = useState("");
  const [accountGroup, setAccountGroup] = useState("");
  const [accountType, setAccountType] = useState("");
  const [mtGroup, setMtGroup] = useState("");
  const [statementMethod, setStatementMethod] = useState("");
  const [statementUnit, setStatementUnit] = useState("");
  const [tier1RebateRules, setTier1RebateRules] = useState([]);
  const [tier1RebateRulesItems, setTier1RebateRulesItems] = useState([]);
  const [promotionOptionalRebateValue, setPromotionOptionalRebateValue] = useState([]);
  const [promotionOptionalRebateValueItems, setPromotionOptionalRebateValueItems] = useState([]);
  const [status, setStatus] = useState("");
  const [symboloptions, setSymboloptions] = useState([]);
  const [accountTypesOptions, setAccountTypesOptions] = useState([]);
  const [mtgroupssOptions, setMtgroupssOptions] = useState([]);
  const [mtErrMsg, setMtErrMsg] = useState("");
  const [tierErrMsg, setTierErrMsg] = useState("");
  const [promoErrMsg, setPromoErrMsg] = useState("");
  const dispatch = useDispatch();
  const toggleAddModal = ()=>{
    props?.setEditModal(preValue => !preValue);
    setAddModal(preValue => !preValue);
  };

  const { create } = props.dictionariesPermissions;

  useEffect(()=>{
    console.log("tier1RebateRules", tier1RebateRules, promotionOptionalRebateValueItems);
  }, [promotionOptionalRebateValueItems]);

  useEffect(()=>{
    if (!props.showEditSuccessMessage && addModal){
      setAddModal(false);
    }
  }, [props.showEditSuccessMessage]);

  useEffect(()=>{
    if (props.editedItem) {
      console.log(props.editedItem);
      setSymbolGroup(props?.editedItem?.symbolGroupId ?._id);
      setAccountGroup(props?.editedItem?.accountGroup);
      setAccountType(props?.editedItem?.accountTypeId);
      setMtGroup(props?.editedItem?.mtGroupId);
      setStatementMethod(props?.editedItem?.statementMethod);
      setStatementUnit(props?.editedItem?.statementUnit);
      setStatus(String(props?.editedItem?.status));
      const x = props?.editedItem?.tier1RebateRules?.map((rule) => {
        return {
          value: String(rule),
          label: String(rule)
        };
      });
      const y = props?.editedItem?.promotionOptionalRebateValue?.map((rule) => {
        return {
          value: String(rule),
          label: String(rule)
        };
      });
      setPromotionOptionalRebateValue(y);
      setPromotionOptionalRebateValueItems(y);
      setTier1RebateRules(x);
      setTier1RebateRulesItems(x);
    }
  }, [props.editedItem]);
  
  useEffect(() => {
    dispatch(
      fetchGroups({
        limit: 100000,
        page: 1,
        type: props?.type,
      })
    );
    dispatch(
      fetchAccountTypes({
        limit: 100000,
        page: 1,
        type: "LIVE",
      })
    );
    dispatch(
      fetchMtGroups({
        limit: 100000,
        page: 1,
        // type: props?.type,
      })
    );
  }, [props.type]);

  useEffect(() => {
    if (props?.groups) {
      const symbolOptions = props?.groups?.map((group) => {
        return {
          value: group?._id,
          label: group?.groupName,
        };
      });
      setSymboloptions(symbolOptions);
    }
  }, [props.groups]);

  useEffect(() => {
    if (props?.accountTypes) {
      const accountTypesOptions = props?.accountTypes?.filter((type) => type.platform === props.type)?.map((accountType) => {
        return {
          value: accountType,
          label: accountType?.title,
        };
      });
      setAccountTypesOptions(accountTypesOptions);
    }
  }, [props.accountTypes]);

  useEffect(() => {
    if (props?.mtGroups) {
      const mtgroupssOptions = props?.mtGroups.filter((group) => group.status === "Enabled" && group.accountGroupType === accountType?.title)?.map((mtgroup) => {
        return {
          value: mtgroup?._id,
          label: mtgroup?.mtGroupName,
        };
      });
      setMtgroupssOptions(mtgroupssOptions);
    }
  }, [props.mtGroups, accountType]);

  useEffect(()=>{
    if (props.editSuccess && props?.show){
      toggleAddModal();
      dispatch(
        fetchRebateRules({
          limit: 10,
          page: 1,
          type: props.type
        })
      );
    }
  }, [props.editSuccess]);

  const validate = () => {

    if (mtGroup.length === 0) {
      setMtErrMsg("At least one MT Group is required");
    }
    if (tier1RebateRules.length === 0) {
      console.log("asd");
      setTierErrMsg("At least one Rebate Rule Value is required");
    }
    if (promotionOptionalRebateValue.length === 0) {
      setPromoErrMsg("At least one Rebate Rule Value is required");
    }
  };

  return (
    <React.Fragment >
      {/* <Link to="#"  className={`btn btn-primary ${!create ? "d-none" : ""}`} onClick={toggleAddModal}><i className="bx bx-plus me-1"></i>{props.t("Trading Volume Rebate Settings-Add")}</Link> */}
      <Modal isOpen={props?.show} toggle={toggleAddModal} centered={true} size="lg">
        <ModalHeader toggle={toggleAddModal} tag="h4">
          {props.t("Trading Volume Rebate Settings-Edit")}
        </ModalHeader>
        <ModalBody >
          <AvForm
            className='p-4'
            onValidSubmit={(e, v) => {
              e.preventDefault();
              const tier1RebateRulesData = tier1RebateRules?.map(item=> item?.value);
              const promotionOptionalRebateValueData = promotionOptionalRebateValue.map((item) => parseFloat(item.value));
              v.mtGroupId = mtGroup.map((item) => item.value);
              if (tier1RebateRulesData.length === 0 || promotionOptionalRebateValueData.length === 0 || mtGroup.length === 0 ) {
                return;
              }
              dispatch(editRebateRules({
                ...v,
                id: props?.editedItem?._id,
                tier1RebateRules: tier1RebateRulesData,
                promotionOptionalRebateValue: promotionOptionalRebateValueData,
                accountTypeId: accountType?._id,
                type: props?.type,
              }));
            }}
          > 
            <div className="mb-3">
              <AvField
                name="name"
                label={
                  <>
                    {props.t("Rule Name")} <span style={{ color: "red" }}>*</span>
                  </>
                }
                placeholder={props.t("Enter Rule Name")}
                type="text"
                value={props?.editedItem?.name}
                errorMessage={props.t("Enter valid Rule Name")}
                validate={{ required: { value: true } }}
              />
            </div>

            {/* <div className="my-3">
              <AvFieldSelect
                name="rulesApply"
                type="text"
                errorMessage={props.t(
                  "Statement Method is required"
                )}
                label={props.t("Rules Apply")}
                options={[]}
              />
            </div> */}
            <div className="my-3">
              <AvFieldSelect
                name="symbolGroupId"
                type="text"
                errorMessage={props.t(
                  "ٌSymbol Group is required"
                )}
                label={
                  <>
                    {props.t("Symbol Group")} <span style={{ color: "red" }}>*</span>
                  </>
                }
                options={symboloptions}
                value={symbolGroup}
                validate={{ required: { value: true } }}
                // symboloptions={symboloptions}
              />
            </div>
            <div className="my-3">
              <AvFieldSelect
                name="accountTypeId"
                type="text"
                errorMessage={props.t(
                  "ٌAccount Type is required"
                )}
                validate={{ required: { value: true } }}
                label={
                  <>
                    {props.t("Account Type")} <span style={{ color: "red" }}>*</span>
                  </>
                }
                value={accountTypesOptions.find(option => option.value._id === accountType?._id)?.value} 
                options={accountTypesOptions}
                onChange={(e) => {
                  setAccountType(e);
                  setMtGroup([]);
                } }
              />
            </div>
            {/* <div className="my-3">
              <AvFieldSelect
                name="mtGroupId"
                type="text"
                errorMessage={props.t(
                  "ٌMT Group is required"
                )}
                validate={{ required: { value: true } }}
                label={props.t("MT Group")}
                value={mtGroup}
                options={mtgroupssOptions}
              />
            </div> */}
            <div className="my-3">
              <SearchableRebateRulesDropdown
                name="mtGroupId"
                type="text"
                errorMessage={props.t(
                  "ٌMT Group is required"
                )}
                validate={{ required: { value: true } }}
                label={props.t("MT Group")}
                value={mtGroup}
                isRequired={true}
                onChange={(e) => {
                  setMtGroup(e);
                  setMtErrMsg("");
                }}
                defaultOptions={mtgroupssOptions}
                isMulti={true}
                closeMenuOnSelect={false}
              />
            </div>

            {mtErrMsg && (
              <div className="text-danger mb-2">
                {mtErrMsg}
              </div>
            )}
            {/* <div className="my-3">
              <AvFieldSelect
                name="spreadRebateGroup"
                type="text"
                errorMessage={props.t(
                  "ٌSpread Rebate Group is required"
                )}
                label={props.t("Spread Rebate Group")}
                options={[]}
              />
            </div> */}
            <div className="my-3">
              <AvFieldSelect
                name="statementMethod"
                type="text"
                errorMessage={props.t(
                  "Statement Method is required"
                )}
                validate={{ required: { value: true } }}
                label={
                  <>
                    {props.t("Statement Method")} <span style={{ color: "red" }}>*</span>
                  </>
                }
                value={statementMethod}
                options={[{
                  value:"$",
                  label: "$",
                }]}
              />
            </div>
            <div className="mt-3">
              <AvFieldSelect
                name="statementUnit"
                type="text"
                errorMessage={props.t(
                  "Statement Unit is required"
                )}
                validate={{ required: { value: true } }}
                value={statementUnit}
                label={
                  <>
                    {props.t("Statement Unit")} <span style={{ color: "red" }}>*</span>
                  </>
                }
                options={[{
                  value:"perLot",
                  label: "Per Lot",
                }]}
              />
            </div>
            <div className="mt-3">
              <Label>Tier 1 IB Rebate Rules <span style={{color: "red"}}> * </span></Label>
              <CustomCreatableSelect
                isMulti
                isClearable
                disableDeleteButton={false}
                disableCreateButton={false}
                setTier1RebateRules={setTier1RebateRules}
                tier1RebateRules={tier1RebateRules}
                placeholder="Please enter Tier 1 IB Rebate Rules"
                value={tier1RebateRulesItems}
                setTierErrMsg={setTierErrMsg}
              />
            </div>
            {tierErrMsg && (
              <div className="text-danger mb-2">
                {tierErrMsg}
              </div>
            )}
            {/* <div className="mt-3">
              <Label>Promotion Optional Rebate Value</Label>
              <CustomCreatableSelect
                isMulti
                isClearable
                disableDeleteButton={false}
                disableCreateButton={false}
                setPromotionOptionalRebateValue={setPromotionOptionalRebateValue}
                promotionOptionalRebateValue={promotionOptionalRebateValue}
                placeholder="Please enter Promotion Optional Rebate Value"
                value={promotionOptionalRebateValueItems}
              />
            </div> */}
            <div className="my-3">
              <SearchableRebateRulesDropdown
                name="promotionOptionalRebateValue"
                type="text"
                errorMessage={props.t(
                  "ٌPromotion Optional Rebate Value is required"
                )}
                isRequired={true}
                validate={{ required: { value: true } }}
                label={props.t("Promotion Optional Rebate Value")}
                onChange={(e) => {         
                  setPromotionOptionalRebateValue(e);
                  setPromoErrMsg("");
                }}
                value={promotionOptionalRebateValue}
                isPromote={false}
                defaultOptions={tier1RebateRules}
                isMulti={true}
              />
            </div>
            {promoErrMsg && (
              <div className="text-danger mb-2">
                {promoErrMsg}
              </div>
            )}
            {/* <div className="mt-3">
              <AvField
                name="sorting"
                label={props.t("Sorting")}
                placeholder={props.t("Enter Sorting")}
                type="text"
                errorMessage={props.t("Enter valid Sorting")}
                validate={{ required: { value: true } }}
              />
            </div> */}
            <div className="my-3">
              <AvFieldSelect
                name="status"
                type="text"
                errorMessage={props.t(
                  "Status is required"
                )}
                value={status}
                validate={{ required: { value: true } }}
                label={
                  <>
                    {props.t("Status")} <span style={{ color: "red" }}>*</span>
                  </>
                }
                options={[{
                  value: "true",
                  label: "Enabled",
                }, {
                  value: "false",
                  label: "Disabled",
                }]}
              />
            </div>
            <div className='text-center pt-3 p-2'>
              <Button disabled = {props.disableAddButton} type="submit" color="primary" onClick={validate}>
                {props.disableAddButton ? "Loading..." : props.t("Edit")}
              </Button>
            </div>
          </AvForm>
          {props.error && <UncontrolledAlert color="danger">
            <i className="mdi mdi-block-helper me-2"></i>
            {props.editError}
          </UncontrolledAlert>}
          
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}
const mapStateToProps = (state)=>({
  id :state.RebateRulesReducer.id,
  error:state.RebateRulesReducer.error,
  showAddSuccessMessage: state.RebateRulesReducer.showAddSuccessMessage,
  showEditSuccessMessage: state.RebateRulesReducer.showEditSuccessMessage,
  dictionariesPermissions : state.Profile.dictionariesPermissions || {},
  disableAddButton : state.RebateRulesReducer.disableAddButton,
  groups : state.groupReducer.groups,
  mtGroups : state.groupReducer.mtGroups?.groups,
  addSuccess : state.RebateRulesReducer.addSuccess,
  editSuccess : state.RebateRulesReducer.editSuccess,
  editSuccesss : state.RebateRulesReducer,
  accountTypes : state.tradingAccountReducer.accountTypes,
});
export default connect(mapStateToProps, null)(withTranslation()(SymbolsEdit));