import { approveKyc } from "apis/client";
import classNames from "classnames";
import formatDate from "helpers/formatDate";
import React, {
  useState,
  useCallback,
  useEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  Table,
  TabPane,
  Badge,
  Spinner,
  ModalFooter,
  Row,
  Col,
  Alert,
} from "reactstrap";
import FeatherIcon from "feather-icons-react";
import usePermissions from "routes/permissions";
import {
  fetchAccountTypes,
  showErrorNotification,
  showSuccessNotification,
} from "store/actions";
import { startCase } from "lodash";
import { getAuditByCustomerId, updateAccountRequest } from "apis/audit";
import CRMDuplicates from "./CRMDuplicates";
import { AvField, AvForm } from "availity-reactstrap-validation";
import AvFieldSelect from "components/Common/AvFieldSelect";
import { accountRequestApprove } from "store/requests/actions";
import { approveAccountRequest } from "apis/requests";

const AuditFlow = ({ customerId, toggleModal }) => {
  const [dataDiff, setDataDiff] = useState(null);
  const [activeTab, setActiveTab] = useState("1");
  const [isApprovingKyc, setIsApprovingKyc] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAudit, setSelectedAudit] = useState(null);
  const [error, setError] = useState(null);
  const [selectedAccountType, setSelectedAccountType] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const { auditPermissions } = usePermissions();
  const { accountTypes, accountTypesLoading } = useSelector((state) => state.tradingAccountReducer);

  const dispatch = useDispatch();
  
  const toggle = useCallback((tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  }, [activeTab]);

  const approveKycForClient = useCallback(async (clientId) => {
    setIsApprovingKyc(true);
    try {
      const { status } = await approveKyc(clientId);
      if (status) {
        dispatch(showSuccessNotification("KYC approved successfully"));
        toggleModal();
      }
    } catch (error) {
      dispatch(showErrorNotification(error.message));
      console.error("Error approving KYC:", error);
    } finally {
      setIsApprovingKyc(false);
    }
  }, [dispatch, toggleModal]);

  const loadAudit = async (customerId) => {
    setIsLoading(true);
    setError(null);
    try {
      const { result, status } = await getAuditByCustomerId({
        customerId,
      });
      if (status) {
        setSelectedAudit(result);
      } else {
        setError("Failed to load audit data. Please try again.");
      }
    } catch (error) {
      setSelectedAudit(null);
      setError("An error occurred while loading audit data. Please try again or contact admin.");
      dispatch(showErrorNotification(error.message));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (customerId) {
      loadAudit(customerId);
    }
  }, [customerId]);

  useEffect(() => {
    if (selectedAudit?.accountType) {
      setSelectedAccountType(selectedAudit.accountType);
    }
  }, [selectedAudit]);

  useEffect(() => {
    loadAccountTypes();
  }, []);

  const loadAccountTypes = () => {
    dispatch(fetchAccountTypes({
      page: 1,
      limit: 100,
    }));
  };

  const renderDataDiffModal = () => (
    <Modal isOpen={!!dataDiff} toggle={() => setDataDiff(null)} size="lg">
      <ModalHeader toggle={() => setDataDiff(null)}>Data Diff</ModalHeader>
      <ModalBody>
        <Table responsive bordered striped hover>
          <thead>
            <tr>
              <th>Field</th>
              <th>Old Value</th>
              <th>New Value</th>
            </tr>
          </thead>
          <tbody>
            {dataDiff && Object.keys(dataDiff.old).map((key) => (
              <tr key={key}>
                <td>{startCase(key)}</td>
                <td>{JSON.stringify(dataDiff.old[key])}</td>
                <td>{JSON.stringify(dataDiff.new[key])}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </ModalBody>
    </Modal>
  );

  if (isLoading) {
    return (
      <Modal isOpen={true} centered toggle={toggleModal} size="xl">
        <ModalBody className="text-center">
          <Spinner color="primary" />
          <p className="mt-2">Loading audit data...</p>
        </ModalBody>
      </Modal>
    );
  }

  if (!selectedAudit || error) {
    return (
      <Modal isOpen={true} centered toggle={toggleModal} size="xl">
        <ModalHeader toggle={toggleModal}>Audit Updates</ModalHeader>
        <ModalBody className="d-flex flex-column align-items-center justify-content-center py-5">
          <FeatherIcon icon="alert-triangle" size={48} className="text-warning mb-3" />
          <h4 className="text-center mb-3">No Audit Data Found</h4>
          <p className="text-muted text-center mb-4">
            {error || "We couldn't find any audit data for this selection. Please try again or contact an administrator for assistance."}
          </p>
          <Button color="primary" onClick={() => loadAudit(customerId)}>
            <FeatherIcon icon="refresh-cw" size={16} className="me-2" />
            Retry
          </Button>
        </ModalBody>
      </Modal>
    );
  }

  const handleEditRequest = async (e, d) => {
    const { accountType, newLeverage, newCurrency } = d;
    setIsLoading(true);
    try {
      const { status } = await updateAccountRequest({
        payload: {
          auditId: selectedAudit._id,
          accountType,
          leverage: newLeverage,
          currency: newCurrency,
        }
      });

      if (status) {
        dispatch(showSuccessNotification("Account request updated successfully"));
      }
    } catch (error) {
      dispatch(showErrorNotification(error.message));
    } finally {
      setIsLoading(false);
      setIsEditing(false);
      loadAudit(customerId);
    }
  };

  const handleCreateAccount = async (id) =>{
    setIsLoading(true);
    try {
      const { status } = await approveAccountRequest({ requestId: id });
      if (status) {
        dispatch(showSuccessNotification("Account created successfully"));
      }
    } catch (error) {
      dispatch(showErrorNotification(error.message));
    } finally {
      setIsLoading(false);
      loadAudit(customerId);
    }
  };

  return (
    <Modal isOpen={true} toggle={toggleModal} size="xl">
      <ModalHeader toggle={toggleModal}>Audit Updates</ModalHeader>
      <ModalBody>
        <div className="d-flex flex-wrap justify-content-between my-3">
          <div className="flex-grow-1">
            <h5 className="mb-2">Requested Account</h5>
            <AvForm onValidSubmit={handleEditRequest}>
              {!isEditing ? (
                <Row className="mb-3">
                  <Col md={2}><div></div></Col>
                  <Col md={2} className="mb-3 mb-md-0">
                    <AvField
                      name="platform"
                      label="Platform"
                      type="text"
                      disabled
                      value={selectedAudit?.request?.content?.platform}
                    />
                  </Col>
                  <Col md={2} className="mb-3 mb-md-0">
                    <AvField
                      name="title"
                      label="Title"
                      type="text"
                      disabled
                      value={selectedAudit?.accountType?.title}
                    />
                  </Col>
                  <Col md={2} className="mb-3 mb-md-0">
                    <AvField
                      name="leverage"
                      label="Requested Leverage"
                      type="text"
                      disabled
                      value={selectedAudit?.request?.content?.from}
                    />
                  </Col>
                  <Col md={2} className="mb-3 mb-md-0">
                    <AvField
                      name="currency"
                      label="Requested Currency"
                      type="text"
                      disabled
                      value={selectedAudit?.request?.content?.currency}
                    />
                  </Col>
                </Row>
              ) : (
                <>
                  <Row className="mb-3">
                    <Col md={2}><div></div></Col>
                    <Col md={4}>
                      <AvFieldSelect
                        name="accountType"
                        label="Account Type"
                        options={accountTypes
                          .filter((t) => t.type !== "IB") 
                          .map((type) => ({
                            label: `${type.title} - ${type?.platform}`,
                            value: type._id,
                          }))}
                        required
                        onChange={(e) => {
                          const sType = accountTypes.find((t) => t._id === e);
                          setSelectedAccountType(sType);
                        }}
                        value={selectedAccountType?._id}
                      />
                    </Col>
                    <Col md={2}>
                      <AvFieldSelect
                        name="newLeverage"
                        label="Change Leverage"
                        options={selectedAccountType?.leverages?.map((type) => ({
                          label: type,
                          value: type,
                        }))}
                        required
                      />
                    </Col>
                    <Col md={2}>
                      <AvFieldSelect
                        name="newCurrency"
                        label="Change Currency"
                        options={selectedAccountType?.currencies?.map((c) => ({
                          label: c.currency,
                          value: c.currency,
                        }))}
                        required
                      />
                    </Col>
                  </Row>
                </>
              )}
              <div className="d-flex justify-content-center w-100">
                {!isEditing ? (
                  <>
                    {selectedAudit?.request?.status !== "APPROVED" ? (
                      <Row className="justify-content-center w-100">
                        <Col xs={12} sm={6} md={4} lg={3} className="mb-3">
                          <Button color="primary" className="w-100" onClick={() => setIsEditing(true)}>
                            <FeatherIcon icon="edit" size={16} className="me-2" />
                            Edit
                          </Button>
                        </Col>
                        {
                          selectedAudit?.status === "DUPLICATE" &&
                          selectedAudit?.request?._id 
                          && selectedAudit?.request?.status === "PENDING" 
                          && (
                            <Col xs={12} sm={6} md={4} lg={3} className="mb-3">
                              <Button color="success" className="w-100" onClick={() => handleCreateAccount(selectedAudit.request._id)}>
                                <FeatherIcon icon="plus" size={16} className="me-2" />
                                Create Account
                              </Button>
                            </Col>
                          )
                        }
                      </Row>
                    ) : (
                      <div className="d-flex justify-content-center align-items-center my-3">
                        {selectedAudit?.request?.status === "APPROVED" && (
                          <Alert color="success" className="text-center">
                            Account request has been created
                          </Alert>
                        )}
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <Col md={2} className="mb-3 mb-md-0 mx-2">
                      <Button color="danger" className="w-100" type="submit">
                        <FeatherIcon icon="save" size={16} className="me-2" />
                        Save
                      </Button>
                    </Col>
                    <Col md={2} className="mb-3 mb-md-0 mx-2">
                      <Button color="secondary" className="w-100" onClick={() => setIsEditing(false)}>
                        <FeatherIcon icon="x" size={16} className="me-2" />
                        Cancel
                      </Button>
                    </Col>
                  </>
                )}
              </div>
            </AvForm>
          </div>
        </div>
        
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classNames({ active: activeTab === "1" })}
              onClick={() => { toggle("1") }}
            >
              Audit Updates
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classNames({ active: activeTab === "2" })}
              onClick={() => { toggle("2") }}
            >
              Questionnaires
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classNames({ active: activeTab === "3" })}
              onClick={() => { toggle("3") }}
            >
              CRM Duplicates
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Table responsive bordered striped hover className="mt-3">
              <thead>
                <tr>
                  <th>Timestamp</th>
                  <th>Status</th>
                  <th>Updated By</th>
                  <th>User ID</th>
                  <th>Remarks</th>
                  <th>Details</th>
                </tr>
              </thead>
              <tbody>
                {selectedAudit.updates.map((update, index) => (
                  <tr key={index}>
                    <td>{formatDate(update.createdAt)}</td>
                    <td><Badge color="info">{update.status}</Badge></td>
                    <td>{update.updatedBy}</td>
                    <td>{update.userId?._id ? `${update.userId.firstName} ${update.userId.lastName}` : "-"}</td>
                    <td>{update.remarks}</td>
                    <td>
                      {update.newData && update.oldData && (
                        <Button color="link" className="p-0" onClick={() => setDataDiff({
                          new: update.newData,
                          old: update.oldData,
                        })}>
                          <FeatherIcon icon="eye" className="icon-sm" />
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </TabPane>
          <TabPane tabId="2">
            {selectedAudit.questionnaire && selectedAudit.questionnaire.length > 0 ? (
              selectedAudit.questionnaire.map((questionnaire) => (
                questionnaire.questions.map((question, qIndex) => (
                  <div key={qIndex} className="card my-3 p-3">
                    <h6 className="mb-2">Q{qIndex + 1}: {question.question}</h6>
                    <p className="mb-0"><strong>A: </strong>{question.answer}</p>
                  </div>
                ))
              ))
            ) : (
              <div className="text-center my-3">
                <p>No questionnaires found</p>
              </div>
            )}
          </TabPane>
          <TabPane tabId="3">
            <CRMDuplicates customerId={customerId} />
          </TabPane>
        </TabContent>
        {renderDataDiffModal()}
        <div className="d-flex justify-content-end mb-3">
          
        </div>
      </ModalBody>
      
      {!selectedAudit?.customerId?.stages?.kycApproved && auditPermissions.update && selectedAudit?.status === "DUPLICATE" && (
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => approveKycForClient(selectedAudit?.customerId._id)}
            disabled={isApprovingKyc}
            className="mb-3"
          >
            {isApprovingKyc ? (
              <>
                <Spinner size="sm" className="me-2" />
                Approving...
              </>
            ) : (
              <>
                <i className="mdi mdi-check-circle me-2"></i>
                Approve KYC
              </>
            )}
          </Button>
        </ModalFooter>
      )}
    </Modal>
  );
};

export default AuditFlow;
