/* eslint-disable no-debugger */
import {
  Button,
  UncontrolledAlert,
  Col,
  Row,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import ClientDropDown from "components/Common/Filters/ClientDropDown";
import FromToDate from "components/Common/Filters/FromToDate";
import { useDispatch, useSelector } from "react-redux";
import { fetchTeams } from "store/teams/actions";
import SearchableAgentDropdown from "components/Common/SearchableAgentDropdown";

const InvestorFilter = (props) => {
  const { t } = useTranslation();
  // get query paramerters from url
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const regions = useSelector((state)=> state.dictionaryReducer.dictionary );
  const teams = useSelector((state) => state.teamsReducer.docs || []);
  const userData = useSelector((state) => state.Profile.userData);
  const teamsPermissions = useSelector((state) => state.Profile.teamsPermissions || {});

  const dispatch = useDispatch();

  const [openCanvas, setOpenCanvas] = useState(false);

  const initValues = {
    customerId: "",
    filterDate: {
      fromDate: "",
      toDate: "",
    },
    team: "",
    agent: "",
    status: query.get("status") || "",
    requestId: "",
    region: "",
  };

  const { filteredValues = initValues } = props;
  const [values, setValues] = useState(filteredValues);

  const [filtered, setFilter] = useState(false);
  useEffect(() => {
    let f = JSON.stringify(initValues) === JSON.stringify(values);
    setFilter(!f);
  }, [values]);

  const statusOptions = ["APPROVED", "PENDING", "REJECTED"];
  const regionChangeHandler = (e) => {
    setValues({
      ...values,
      region: e.target.value
    });
  };
  const clientChangeHandler = (obj) => {
    setValues({
      ...values,
      customerId: obj.value,
    });
  };
  const teamsChangeHandler = (e) =>
    setValues({
      ...values,
      team: e.target.value
    });

  const resetFilter = () => {
    setValues(initValues);
    props.filterChangeHandler(initValues);
    // setOpenCanvas(false);
  };

  const dateChangeHandler = (filterDate) => {
    setValues({
      ...values,
      filterDate: filterDate,
    });
  };

  const toggleCanvas = () => {
    setOpenCanvas(!openCanvas);
  };

  useEffect(() => {
    if (!props.showAddSuccessMessage && openCanvas) {
      setOpenCanvas(false);
    }
  }, [props.showAddSuccessMessage]);


  useEffect(() => {
    loadTeams(1, 100);
  }, []);

  const loadTeams = (page = 1, limit = 100) => {
    dispatch(fetchTeams({
      page,
      limit,
      ...(teamsPermissions.getAssigned && !teamsPermissions.get && { managerId: userData._id })
    }));
  };

  return (
    <React.Fragment>
      <Button
        color="primary"
        className={"btn btn-primary"}
        onClick={toggleCanvas}
      >
        <i className="bx bx-filter me-1" />
        {filtered ? t("Update Filter") : t("Advance Filter")}
      </Button>
      <Offcanvas
        isOpen={openCanvas}
        direction="end"
        scrollable
        toggle={toggleCanvas}
      >
        <OffcanvasHeader toggle={toggleCanvas} tag="h4">
          {t("Advance Filter")}
        </OffcanvasHeader>
        <OffcanvasBody>
          <AvForm
            className="p-4"
            onValidSubmit={() => {
              props.filterChangeHandler(values);
              setOpenCanvas(false);
            }}
          >
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    type="select"
                    name="status"
                    label={t("Status")}
                    value={props.filteredValues?.status || values.status}
                    onChange={(e) => {
                      setValues({
                        ...values,
                        status: e.target["value"],
                      });
                    }}
                  >
                    <option key={""} value={""}>
                      {t("All")}
                    </option>
                    {statusOptions.map((item) => {
                      return (
                        <option key={item} value={item}>
                          {t(item)}
                        </option>
                      );
                    })}
                  </AvField>
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="requestId"
                    label={t("Request Id")}
                    placeholder={t("Request Id")}
                    type="text"
                    value={props.filteredValues?.requestId || values.requestId}
                    onChange={(e) => {
                      setValues({
                        ...values,
                        requestId: e.target["value"],
                      });
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <div className="mb-3">
                  <AvField
                    type="select"
                    name="region"
                    label={t("Region")}
                    value={props.filteredValues?.region || values.region}
                    onChange={(e) => {
                      regionChangeHandler(e);
                    }}
                  >
                    <option key={""} value={""}>
                      {t("All")}
                    </option>
                    {regions[0]?.region?.map((item) => {
                      return (
                        <option key={item} value={item}>
                          {t(item)}
                        </option>
                      );
                    })}
                  </AvField>
                </div>
              </Col>
            </Row>
            <div className="mb-3">
              <FromToDate
                filterDate={values.filterDate}
                dateChangeHandler={dateChangeHandler}
              />
            </div>
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <SearchableAgentDropdown
                    isRequired={false}
                    value={props.filteredValues?.type || values.agent}
                    isMulti={true}
                    onChange={(e) => {
                      setValues({
                        ...values,
                        agent: e.map((item) => item.value).join(","),
                      });
                    }}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <AvField type="select" name="team" label={t("Team")} value={values.team || props.filteredValues?.team}
                    onChange={teamsChangeHandler}
                  >
                    {teams.map((team) => {
                      return (
                        <option key={team._id} value={team._id}>
                          {t(team.title)}
                        </option>
                      );
                    })}
                  </AvField>
                </div>
              </Col>
            </Row>
            <div className="mb-3">
              <ClientDropDown
                defaultValue={
                  props.filteredValues?.customerId || values.selectCountry
                }
                clientChangeHandler={clientChangeHandler}
              />
            </div>

            <div className="row">
              <div className="col-sm-12 text-center">
                <Button
                  disabled={!filtered}
                  type="submit"
                  color="primary"
                  className="btn btn-primary btn-md center-block"
                >
                  {t("Filter")}
                </Button>{" "}
                <Button
                  onClick={resetFilter}
                  color="primary"
                  type="reset"
                  className="btn btn-danger btn-md center-block"
                >
                  {t("Reset")}
                </Button>
              </div>
            </div>
          </AvForm>
          {props.error && (
            <UncontrolledAlert color="danger">
              <i className="mdi mdi-block-helper me-2"></i>
              {t(props.error)}
            </UncontrolledAlert>
          )}
        </OffcanvasBody>
      </Offcanvas>
    </React.Fragment>
  );
};

export default InvestorFilter;
