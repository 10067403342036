import { useDispatch, connect } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledAlert,
  Label,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { addNewItem } from "store/dictionary/actions";
import { fetchAccountTypes, updateAccountType } from "store/actions";
import { fetchAccountRequests } from "store/requests/actions";
function NumberSegment(props) {
  const dispatch = useDispatch();
  const [startNumber, setStartNumber] = useState("");
  const [endNumber, setEndNumber] = useState("");
  const [totalQuantity, setTotalQuantity] = useState("");

  const toggleEditModal = () => {
    props.setAccountSegment((preValue) => !preValue);
  };

  const handleStartChange = (value) => {
    setStartNumber(value);
    updateTotalQuantity(value, endNumber);
  };

  const handleEndChange = (value) => {
    setEndNumber(value);
    updateTotalQuantity(startNumber, value);
  };

  const updateTotalQuantity = (start, end) => {
    const startVal = parseInt(start, 10);
    const endVal = parseInt(end, 10);

    if (!isNaN(startVal) && !isNaN(endVal) && endVal >= startVal) {
      setTotalQuantity(endVal - startVal);
    } else {
      setTotalQuantity("");
    }
  };

  useEffect(() => {
    if (props?.selectedGroup) {
      const { sequence = "", endSequence = "" } = props.selectedGroup || {};
  
      setStartNumber(String(sequence));
      setEndNumber(String(endSequence));
    }
  }, [props?.selectedGroup]);

  useEffect(()=>{
    if (props.editedSuccess && props?.show){
      toggleEditModal();
      dispatch(
        fetchAccountTypes({
          limit: 100000,
          page: 1,
        })
      );
    }
  }, [props.editedSuccess]);
  const { create } = props.dictionariesPermissions;

  return (
    <React.Fragment>
      <Modal isOpen={props.show} toggle={toggleEditModal} centered={true}>
        <ModalHeader toggle={toggleEditModal} tag="h4">
          {props.t("Set Rebate Account Number Segment")}
        </ModalHeader>
        <ModalBody>
          <AvForm
            className="p-4"
            onValidSubmit={(e, v) => {
              delete v.totalAvailableQuantity;
              e.preventDefault();
              dispatch(updateAccountType(props.selectedGroup._id, v));
            }}
          >
            <div className="mb-3">
              <AvField
                name="sequence"
                label={props.t("Start Number Segment")}
                placeholder={props.t("Enter Start Number Segment")}
                type="text"
                value={startNumber}
                onChange={(e) => handleStartChange(e.target.value)}
                errorMessage={props.t("Enter valid Start Number Segment")}
                validate={{ required: { value: true } }}
              />
            </div>
            <div className="mb-3">
              <AvField
                name="endSequence"
                label={props.t("End Number Segment")}
                placeholder={props.t("Enter End Number Segment")}
                type="text"
                value={endNumber}
                onChange={(e) => handleEndChange(e.target.value)}
                errorMessage={props.t("Enter valid End Number Segment")}
                validate={{ required: { value: true } }}
              />
            </div>
            <div className="mb-3">
              <AvField
                name="totalAvailableQuantity"
                label={props.t("Total Available Quantity")}
                type="text"
                value={endNumber && endNumber - startNumber}
                disabled={true}
                validate={{
                  required: { 
                    value: true, 
                    errorMessage: props.t("This field is required") 
                  },
                  pattern: {
                    value: /^[1-9]\d*$/,
                    errorMessage: props.t("The value must be a positive number"),
                  },
                }}
              />
            </div>
            <div className="mb-3">
              <AvField
                name="nextSequence"
                label={props.t("Next Account")}
                type="text"
                value={props.selectedGroup?.nextSequence?.toString() || startNumber}
                disabled={true}
                validate={{ required: { value: true } }}
              />
            </div>
            <div className="text-center pt-3 p-2">
              <Button
                disabled={props.disableAddButton}
                type="submit"
                color="primary"
              >
                {props.t("Set")}
              </Button>
            </div>
          </AvForm>
          {props.error && (
            <UncontrolledAlert color="danger">
              <i className="mdi mdi-block-helper me-2"></i>
              {props.editError}
            </UncontrolledAlert>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}
const mapStateToProps = (state)=>({
  id :state.tradingAccountReducer.id,
  error:state.tradingAccountReducer.error,
  editedSuccess:state.tradingAccountReducer.editedSuccess,
  showAddSuccessMessage: state.tradingAccountReducer.showAddSuccessMessage,
  dictionariesPermissions : state.Profile.dictionariesPermissions || {},
  disableAddButton : state.tradingAccountReducer.disableAddButton
});
export default connect(mapStateToProps, null)(withTranslation()(NumberSegment));