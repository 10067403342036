/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import { 
  useDispatch, 
  connect, 
  useSelector 
} from "react-redux";
// eslint-disable-next-line object-curly-newline
import { Row, Col, Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import SearchBar from "components/Common/SearchBar";
import CustomPagination from "components/Common/CustomPagination";
// eslint-disable-next-line object-curly-newline
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import TableLoader from "components/Common/TableLoader";
import Notification from "components/Common/Notification";
import logo from "assets/images/logo-sm.svg";
import { withTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import Sync from "./Sync";
import SwitchStatus from "./switchStatus";
import { fetchSymbols } from "store/rebateSettings/symbols/actions";

function MTSymbols(props) {
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const [switchStatus, setSwitchStatus] = useState(false);

  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState("");
  const [showNotication, setShowNotifaction] = useState(false);
  const [switchedId, setSwitchedId] = useState("");
  const [sizePerPage, setSizePerPage] = useState(10);
  const [selectedFilter, setSelectedFilter] = useState("ALL");
  const [isLoading, setIsLoading] = useState(false);
  const addSymbolSuccess = useSelector((state) => state.symbolReducer.addSymbolSuccess);
  const { userData } = useSelector((state) => ({
    userData: state.Profile,
  }));
  const permissions = userData?.roleId?.permissions;
  const symbols = (permissions || {})?.symbols;
  const columns = [
    // {
    //   dataField: "checkbox",
    //   text: (
    //     <input
    //       type="checkbox"
    //       id="check-all-deposits"
    //       onChange={() =>
    //         checkAllBoxes("check-all-deposits", ".deposit-checkbox")
    //       }
    //     />
    //   ),
    // },
    // {
    //   dataField: "recordId",
    //   text: props.t("Record Number"),
    // },
    {
      dataField: "symbolName",
      text: props.t("Symbol Name"),
    },
    {
      dataField: "description",
      text: props.t("Symbol Description"),
    },
    {
      dataField: "type",
      text: props.t("Symbol Type"),
      formatter: (val) => <span>{val?.groupId?.groupName}</span>
    },
    {
      dataField: "server",
      text: props.t("Server"),
      formatter: (val) => <span>{val?.serverId?.name}</span>
    },
    {
      dataField: "contractSize",
      text: props.t("Contract Size"),
    },
    {
      dataField: "status",
      text: props.t("Status"),
      formatter: (val) => val?.status === true ? <span className="fw-bold text-success">Enabled</span> : <span className="fw-bold text-danger">Disabled</span>
    },
    {
      dataField: "tradingPermissions",
      text: props.t("Trading Permissions of Server"),
    },
    symbols?.update &&
    {
      dataField: "",
      isDummyField: true,
      editable: false,
      text: "Action",
      formatter: (team) => (
        <div className="text-center">
          <Link className={`${team?.status === false ? "text-danger" : "text-success"}`} to="#">
            <i
              className={`mdi ${team?.status === false ? "mdi-lock" : "mdi-lock-open"}  font-size-18`}
              id="edittooltip"
              onClick={() => {
                setSwitchedId(team?._id);
                setSwitchStatus(true);
              }}
            ></i>
          </Link>
        </div>
      ),
    },
  ];

  const handleSearchInput = (e) => {
    setSearchInput(e.target.value);
  };
  const initFilteredValues = {
    customerId: "",
    filterDate: {
      fromDate: "",
      toDate: "",
    },
    status: query.get("status") || "",
    currency: "",
    amount: "",
    fee: "",
    tradingAccountFrom: "",
    tradingAccountTo: "",
    agent: "",
  };
  const [filteredValues, setFilteredValues] = useState(initFilteredValues);
  const filterChangeHandler = (filteredValuesData) => {
    setFilteredValues(filteredValuesData);
  };
  const loadSymbols = (page, limit) => {
    if (searchInput && searchInput.length > 3) {
      dispatch(
        fetchSymbols({
          limit,
          page,
          filteredValues,
          searchText: searchInput,
          platform: props.type
        })
      );
    } else {
      dispatch(
        fetchSymbols({
          limit,
          page,
          filteredValues,
          platform: props.type
        })
      );
    }
  };

  const closeNotifaction = () => {
    setShowNotifaction(false);
  };

  useEffect(() => {
    loadSymbols(1, sizePerPage);
  }, [
    props.type,
    props?.deleting,
    sizePerPage,
    1,
    filteredValues,
    searchInput,
    selectedFilter,
    addSymbolSuccess
  ]);

  return (
    <React.Fragment>
      <Notification
        onClose={closeNotifaction}
        body={props.t("The Symbol has been updated successfully")}
        show={showNotication}
        header={props.t("Symbol Update")}
        logo={logo}
      />
      <Row>
        <Col className="col-12">
          <Card>
            <CardHeader className="d-flex flex-column gap-3 ">
              <div className="d-flex justify-content-between align-items-center">
                <CardTitle className="color-primary">
                  {props.t(
                    `${props.type} Symbols(${props.symbolsTotalDocs})`
                  )}
                  <FeatherIcon
                    icon="refresh-cw"
                    className="icon-lg ms-2"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      loadSymbols(1, sizePerPage);
                    }}
                  />
                </CardTitle>
              </div>
              <div className="d-flex flex-row align-items-center justify-content-between gap-2">
                <div className="d-flex flex-row align-items-center">
                  <SearchBar
                    handleSearchInput={handleSearchInput}
                    placeholder={props.t("Search By Symbol")}
                  />
                  {/* <Filter
                    filterChangeHandler={filterChangeHandler}
                    filteredValues={filteredValues}
                  /> */}
                </div>
                {
                  symbols.sync && (
                    <div className="d-flex flex-row align-items-center justify-content-between">
                      <Sync type={props.type} isLoading={isLoading} setIsLoading={setIsLoading}/>
                    </div>
                  )
                }
              </div>
            </CardHeader>
            <CardBody>
              <div className="table-rep-plugin">
                <div
                  className="table-responsive mb-0"
                  data-pattern="priority-columns"
                >
                  <Table id="tech-companies-1" className="table  table-hover ">
                    <Thead className="text-center table-light">
                      <Tr>
                        {columns.map((column, index) =>
                          <Th data-priority={index} key={index}><span className="color-primary">{column.text}</span></Th>
                        )}
                      </Tr>
                    </Thead>

                    {props?.symbolsTotalDocs === 0 ? (
                      <Tbody>
                        {props?.fetchSymbolsLoading && (
                          <TableLoader colSpan={4} />
                        )}
                        {!props?.fetchSymbolsLoading && (
                          <>
                            <Tr>
                              <Td
                                colSpan={"100%"}
                                className="fw-bolder text-center"
                                st
                              >
                                <h3 className="fw-bolder text-center">
                                  No records
                                </h3>
                              </Td>
                            </Tr>
                          </>
                        )}
                      </Tbody>
                    ) : (
                      <Tbody
                        style={{
                          fontSize: "12px",
                          textAlign: "center",
                          textTransform: "capitalize",
                        }}
                      >
                        {props?.fetchSymbolsLoading && <TableLoader colSpan={4} />}
                        {!props?.fetchSymbolsLoading &&
                          props?.symbols.map((row, rowIndex) => (
                            <Tr key={rowIndex}>
                              {columns.map((column, index) => (
                                <Td
                                  key={`${rowIndex}-${index}`}
                                  className="pt-4"
                                >
                                  {column.formatter
                                    ? column.formatter(row, rowIndex)
                                    : row[column.dataField]}
                                </Td>
                              ))}
                            </Tr>
                          ))}
                      </Tbody>
                    )}
                  </Table>
                  <CustomPagination
                    {...props}
                    setSizePerPage={setSizePerPage}
                    sizePerPage={sizePerPage}
                    onChange={loadSymbols}
                    docs={props?.symbols}
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {/* {switchStatus && <SwitchStatus show={switchStatus} setSwitchStatus={setSwitchStatus} text="symbols" />} */}
      {switchStatus && <SwitchStatus switchedId={switchedId} show={switchStatus} setSwitchStatus={setSwitchStatus} text="Symbol" type={props?.type} delteSuccess={props?.deleting} />}
      </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  fetchSymbolsLoading:
    state.symbolReducer.fetchSymbolsLoading || false,
  symbols: state.symbolReducer.symbols || [],
  page: state.symbolReducer.page || 1,
  symbolsTotalDocs:
    state.symbolReducer.symbolsTotalDocs || 0,
  totalPages: state.symbolReducer.totalPages || 0,
  hasNextPage: state.symbolReducer.hasNextPage,
  hasPrevPage: state.symbolReducer.hasPrevPage,
  limit: state.symbolReducer.limit,
  nextPage: state.symbolReducer.nextPage,
  deleting: state.symbolReducer.deleting,
  pagingCounter: state.symbolReducer.pagingCounter,
  prevPage: state.symbolReducer.prevPage,
  depositsPermissions: state.Profile.depositsPermissions || {},
  tradingAccounts: state.tradingAccountReducer.tradingAccounts,
  addSymbolsLoading:
    state.symbolReducer.addSymbolsLoading,
  symbolsPermissions: state.Profile.depositsPermissions,
  totalDocs: state.symbolReducer.totalDocs || 0,
});
export default connect(mapStateToProps, null)(withTranslation()(MTSymbols));
