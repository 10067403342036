/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import { useDispatch, connect } from "react-redux";
// eslint-disable-next-line object-curly-newline
import { Row, Col, Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import SearchBar from "components/Common/SearchBar";
import CustomPagination from "components/Common/CustomPagination";
// eslint-disable-next-line object-curly-newline
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import CustomDropdown from "components/Common/CustomDropDown";
import TableLoader from "components/Common/TableLoader";
import Notification from "components/Common/Notification";
import logo from "assets/images/logo-sm.svg";
import { withTranslation } from "react-i18next";
import { checkAllBoxes } from "common/utils/checkAllBoxes";
import { Link, useLocation } from "react-router-dom";
import { captilazeFirstLetter } from "common/utils/manipulateString";
import {
  approveInternalTransfer,
  fetchInternalTransfers,
  rejectInternalTransfer,
} from "store/forexTransactions/internalTransfers/actions";
import Badge from "components/Common/Badge";
import Filter from "./Filter";
import formatDate from "helpers/formatDate";
import FeatherIcon from "feather-icons-react";
import SymbolsAdd from "./PromotionLinksAdd";
import DeleteModal from "components/Common/DeleteModal";
import SymbolsEdit from "./PromotionLinkEdit";
import SwitchStatus from "../symbols/switchStatus";
import RebateRulesModal from "./BindingIB";
import useModal from "hooks/useModal";
import DeleteStatus from "../symbolsGroups/deleteStatus";
import PromotionLinkEdit from "./PromotionLinkEdit";

function MT4Groups(props) {
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedSymbol, setSelectedSymbol] = useState();
  const [editModal, setEditModal] = useState(false);
  const [switchStatus, setSwitchStatus] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [viewRules, toggleViewRules] = useModal();


  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState("");
  const [showNotication, setShowNotifaction] = useState(false);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [selectedFilter, setSelectedFilter] = useState("ALL");
  const deleteSymbol = ()=>{
    console.log("deleted");
  
  };
  const columns = [
    // {
    //   dataField: "checkbox",
    //   text: (
    //     <input
    //       type="checkbox"
    //       id="check-all-deposits"
    //       onChange={() =>
    //         checkAllBoxes("check-all-deposits", ".deposit-checkbox")
    //       }
    //     />
    //   ),
    // },
    {
      dataField: "linkTitle",
      text: props.t("Link Title"),
    },
    {
      dataField: "mtServer",
      text: props.t("MT Server"),
    },
    {
      dataField: "accountType",
      text: props.t("Account Type"),
    },
    {
      dataField: "mtGroup",
      text: props.t("MT Group"),
    },
    {
      dataField: "defaultMtGroup",
      text: props.t("Default MT Group"),
    },
    {
      dataField: "status",
      text: props.t("Status"),
    },
    {
      dataField: "promotionCode",
      text: props.t("Promotion Code"),
    },
    {
      dataField: "createdAt",
      text: props.t("Created Time"),
    },
    {
      dataField: "",
      isDummyField: true,
      editable: false,
      text: "Operation",
      formatter: (team) => (
        <div className="text-center">
          <Link className={"text-success"} to="#">
            <i
              className="mdi mdi-pencil font-size-18"
              id="edittooltip"
              onClick={() => {
                setEditModal(true);
              }}
            ></i>
          </Link>
          <Link className={"text-success"} to="#">
            <i
              className="mdi mdi-near-me font-size-18"
              id="edittooltip"
              onClick={() => {
                setSwitchStatus(true);
              }}
            ></i>
          </Link>
          <Link className={"text-danger"} to="#">
            <i
              className="mdi mdi-delete font-size-18"
              id="deletetooltip"
              onClick={() => { 
                setDeleteStatus(true);
              }}>

            </i>
          </Link>
        </div>
      ),
    },
  ];


  const handleSearchInput = (e) => {
    setSearchInput(e.target.value);
  };
  const initFilteredValues = {
    customerId: "",
    filterDate: {
      fromDate: "",
      toDate: "",
    },
    status: query.get("status") || "",
    currency: "",
    amount: "",
    fee: "",
    tradingAccountFrom: "",
    tradingAccountTo: "",
    agent: "",
  };
  const [filteredValues, setFilteredValues] = useState(initFilteredValues);
  const filterChangeHandler = (filteredValuesData) => {
    setFilteredValues(filteredValuesData);
  };
  const loadInternalTransfers = (page, limit) => {
    if (searchInput && searchInput.length > 3) {
      dispatch(
        fetchInternalTransfers({
          limit,
          page,
          filteredValues,
          searchText: searchInput,
        })
      );
    } else {
      dispatch(
        fetchInternalTransfers({
          limit,
          page,
          filteredValues,
        })
      );
    }
  };

  const closeNotifaction = () => {
    setShowNotifaction(false);
  };

  useEffect(() => {
    loadInternalTransfers(1, sizePerPage);
  }, [
    props.addInternalTransferLoading,
    sizePerPage,
    1,
    filteredValues,
    searchInput,
    selectedFilter,
  ]);

  return (
    <React.Fragment>
      <Notification
        onClose={closeNotifaction}
        body={props.t("The internal transfer has been updated successfully")}
        show={showNotication}
        header={props.t("Internal Transfer Update")}
        logo={logo}
      />
      <Row>
        <Col className="col-12">
          <Card>
            <CardHeader className="d-flex flex-column gap-3 ">
              <div className="d-flex justify-content-between align-items-center">
                <CardTitle className="color-primary">
                  {props.t(
                    `MT4 Groups(${props.internalTransfersTotalDocs})`
                  )}
                  <FeatherIcon
                    icon="refresh-cw"
                    className="icon-lg ms-2"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      loadInternalTransfers(1, sizePerPage);
                    }}
                  />
                </CardTitle>
              </div>
              <div className="d-flex flex-row align-items-center justify-content-between gap-2">
                <div className="d-flex flex-row align-items-center">
                  <SearchBar
                    handleSearchInput={handleSearchInput}
                    placeholder={props.t("Enter Link Title")}
                  />
                  <Filter
                    filterChangeHandler={filterChangeHandler}
                    filteredValues={filteredValues}
                  />
                </div>
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <SymbolsAdd />
                </div>
              </div>
            </CardHeader>
            <CardBody>
              <div className="table-rep-plugin">
                <div
                  className="table-responsive mb-0"
                  data-pattern="priority-columns"
                >
                  <Table id="tech-companies-1" className="table  table-hover ">
                    <Thead className="text-center table-light">
                      <Tr>
                        {columns.map((column, index) =>
                          <Th data-priority={index} key={index}><span className="color-primary">{column.text}</span></Th>
                        )}
                      </Tr>
                    </Thead>

                    {props.internalTransfersTotalDocs === 0 ? (
                      <Tbody>
                        {props.fetchInternalTransfersLoading && (
                          <TableLoader colSpan={4} />
                        )}
                        {!props.fetchInternalTransfersLoading && (
                          <>
                            <Tr>
                              <Td
                                colSpan={"100%"}
                                className="fw-bolder text-center"
                                st
                              >
                                <h3 className="fw-bolder text-center">
                                  No records
                                </h3>
                              </Td>
                            </Tr>
                          </>
                        )}
                      </Tbody>
                    ) : (
                      <Tbody
                        style={{
                          fontSize: "12px",
                          textAlign: "center",
                          textTransform: "capitalize",
                        }}
                      >
                        {props.loading && <TableLoader colSpan={4} />}
                        {!props.loading &&
                          props.internalTransfers.map((row, rowIndex) => (
                            <Tr key={rowIndex}>
                              {columns.map((column, index) => (
                                <Td
                                  key={`${rowIndex}-${index}`}
                                  className="pt-4"
                                >
                                  {column.formatter
                                    ? column.formatter(row, rowIndex)
                                    : row[column.dataField]}
                                </Td>
                              ))}
                            </Tr>
                          ))}
                      </Tbody>
                    )}
                  </Table>
                  <CustomPagination
                    {...props}
                    setSizePerPage={setSizePerPage}
                    sizePerPage={sizePerPage}
                    onChange={loadInternalTransfers}
                    docs={props.internalTransfers}
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {editModal && <PromotionLinkEdit show={editModal} setEditModal={setEditModal} />}
      {switchStatus && <SwitchStatus show={switchStatus} setSwitchStatus={setSwitchStatus} text="promotion link" />}
      {deleteStatus && <DeleteStatus show={deleteStatus} setSwitchStatus={setDeleteStatus} text="promotion link" />}
      {/* <RebateRulesModal show={viewRules} toggle={toggleViewRules} /> */}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  fetchInternalTransfersLoading:
    state.internalTransferReducer.fetchInternalTransfersLoading || false,
  internalTransfers: state.internalTransferReducer.internalTransfers || [],
  page: state.internalTransferReducer.page || 1,
  internalTransfersTotalDocs:
    state.internalTransferReducer.internalTransfersTotalDocs || 0,
  totalPages: state.internalTransferReducer.totalPages || 0,
  hasNextPage: state.internalTransferReducer.hasNextPage,
  hasPrevPage: state.internalTransferReducer.hasPrevPage,
  limit: state.internalTransferReducer.limit,
  nextPage: state.internalTransferReducer.nextPage,
  pagingCounter: state.internalTransferReducer.pagingCounter,
  prevPage: state.internalTransferReducer.prevPage,
  depositsPermissions: state.Profile.depositsPermissions || {},
  tradingAccounts: state.tradingAccountReducer.tradingAccounts,
  addInternalTransferLoading:
    state.internalTransferReducer.addInternalTransferLoading,
  internalTransferPermissions: state.Profile.depositsPermissions,
  totalDocs: state.internalTransferReducer.totalDocs || 0,
});
export default connect(mapStateToProps, null)(withTranslation()(MT4Groups));
