import {
  call,
  put,
  takeEvery,
  delay
} from "redux-saga/effects";
import { 
  FETCH_SYMBOLS_REQUESTED,
  SYNC_SYMBOL_REQUESTED,
  CHANGE_SYMBOL_START
} from "./actionTypes";
import {

  syncSymbolRequestsuccess,
  addInternalTransferFail,
  addInternalTransferClear,
  addInternalTransferErrorClear,
  fetchSymbolsSuccess,
  fetchSymbolsFail,
  changeSymbolEnd
} from "./actions";
import * as rebateSettingsApis from "apis/rebateSettings";
import { showErrorNotification, showSuccessNotification } from "store/notifications/actions";

function * fetchSymbols(params){
  try {
    const data = yield call(rebateSettingsApis.getSymbols, params);
    yield put(fetchSymbolsSuccess(data));
  } catch (err){
    yield put(fetchSymbolsFail(err.message));
  }
}

function * syncSymbols(params){
  try {
    const { payload } = params;
    const data = yield call(rebateSettingsApis.syncSymbols, payload);
    // if (payload.accountType === "ibAccount"){
    //   data = yield call(rebateSettingsApis.postIbInternalTransfer, values);
    // } else data = yield call(rebateSettingsApis.postInternalTransfer, values);
    yield put(syncSymbolRequestsuccess(data));
    yield put(showSuccessNotification(`${data.result.length} Symbols Synced Successfully`));
    yield put(addInternalTransferClear());
  } catch (err){
    console.log("CALLED FROM SYNC LOCAL: ", err);
    yield put(showErrorNotification(err.message));
    yield put(addInternalTransferFail(err.message));
    yield delay(5000);
    yield put(addInternalTransferErrorClear());
  }
}

function* changeSymbolStatus(params) {
  try {
    yield call(rebateSettingsApis.changeSymbolStatus, params.payload);
    yield put(changeSymbolEnd({ id: params.payload }));
    yield put(showSuccessNotification("Symbol status changed successfully"));
  }
  catch (error) {
    yield put(changeSymbolEnd({ error }));
    yield put(showErrorNotification(error.message));
  }
}


function * symbolSaga(){
  yield takeEvery(FETCH_SYMBOLS_REQUESTED, fetchSymbols);
  yield takeEvery(SYNC_SYMBOL_REQUESTED, syncSymbols);
  yield takeEvery(CHANGE_SYMBOL_START, changeSymbolStatus);
}
export default symbolSaga;