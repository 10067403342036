import * as axiosHelper from "./api_helper";
import qs from "qs";

export const addMtServer = async ({ payload }) => {
  const data = await axiosHelper.post("/mtserver", payload);
  if (data.isError) {
    throw new Error(data.isError);
  }

  return data;
};

export const getServers = async ({ payload }) => {
  const data = await axiosHelper.get(`/mtserver?${qs.stringify(payload)}`);
  if (data.isError) {
    throw new Error(data.isError);
  }
  return data.result;
};

// export const addTodo = async (params) => {
//   const { id, ...obj } = params;
//   let data;
//   if (id) {
//     data = data = await axiosHelper.patch(`/todos/${id}`, obj);
//   } else {
//     data = await axiosHelper.post("/todos", obj);
//   }
//   if (data.isError) {
//     throw new Error(data.message);
//   }
//   return data.result;
// };

// export const updateTodo = async (id, params) => {
//   // const { id, values } = payload;
//   const data = await axiosHelper.post(`/mtserver/${id}`, params);
//   if (data.isError) {
//     throw new Error(data.message);
//   }
//   return data.result;
// };

export const deleteServer = async (payload) => {
  // const { id, values } = payload;
  const data = await axiosHelper.del("/mtserver/" + payload.id);
  if (data.isError) {
    throw new Error(data.message);
  }
  return data.result;
};

export const editServer = async (params) => {
  const id = params.id;
  delete params.id;
  const data = await axiosHelper.patch("/mtserver/" + id, params);
  if (data.isError) {
    throw new Error(data.message);
  }
  return data.result;
};